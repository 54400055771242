<label>{{ '__Accounting.Label.PaymentType' | translate }}</label>
<div class="x-input-group">
  <input disabled class="form-control" [value]="paymentTypeName" />

  <div class="x-input-group-append">
    <button
      class="btn x-btn-default x-btn-icon-only"
      (click)="showDetails = !showDetails"
      [disabled]="isPaymentDetailsEmpty"
    >
      <sc-icon *ngIf="!showDetails" class="x-icon-sm" iconName="chevron-down-circle-outline"></sc-icon>
      <sc-icon *ngIf="showDetails" class="x-icon-sm" iconName="chevron-up-circle-outline"></sc-icon>
    </button>
  </div>
</div>
<div
  *ngIf="paymentTypeId !== undefined && !isPaymentDetailsEmpty && showDetails"
  class="mt-1 x-details-info form-control x-form-control-textarea disabled"
  (click)="openPaymentDetailsModal()"
>
  <div *ngIf="paymentTypeId === PaymentTypeId.CashiersCheque">
    <div *ngIf="displayDetails?.Drawer">
      <strong> {{ '__Accounting.OutflowPayment.PayableTo' | translate }}</strong>
      <span [innerHTML]="payableToValue"></span>
    </div>

    <div *ngIf="displayDetails?.Memo">
      <strong> {{ '__Accounting.OutflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails?.Memo"></span>
    </div>
  </div>

  <div *ngIf="paymentTypeId === PaymentTypeId.Cash">
    <div *ngIf="displayDetails?.Memo">
      <strong> {{ '__Accounting.OutflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails?.Memo"></span>
    </div>
  </div>

  <div *ngIf="paymentTypeId === PaymentTypeId.CreditCard">
    <div *ngIf="displayDetails?.Memo">
      <strong> {{ '__Accounting.OutflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails?.Memo"></span>
    </div>
  </div>

  <div *ngIf="paymentTypeId === PaymentTypeId.Wire && paymentSubTypeId !== 1">
    <div *ngIf="displayDetails?.BSB && !isNZ">
      <strong> {{ '__Accounting.OutflowPayment.BSB' | translate }}</strong>
      <span [innerHTML]="displayDetails?.BSB"></span>
    </div>
    <div *ngIf="displayDetails?.AccountNumber">
      <strong> {{ '__Accounting.OutflowPayment.AccountNumber' | translate }}</strong>
      <span [innerHTML]="displayDetails?.AccountNumber"></span>
    </div>
    <div *ngIf="displayDetails?.AccountName">
      <strong> {{ '__Accounting.OutflowPayment.AccountName' | translate }}</strong>
      <span [innerHTML]="displayDetails?.AccountName"></span>
    </div>
    <div *ngIf="displayDetails?.Addressee">
      <strong> {{ '__Accounting.OutflowPayment.PayableTo' | translate }}</strong>
      <span [innerHTML]="displayDetails?.Addressee"></span>
    </div>
    <div *ngIf="displayDetails?.Memo">
      <strong> {{ '__Accounting.OutflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails?.Memo"></span>
    </div>
  </div>

  <div *ngIf="paymentTypeId === PaymentTypeId.Wire && paymentSubTypeId === 1">
    <div *ngIf="displayDetails?.BillerCode">
      <strong> {{ '__Accounting.OutflowPayment.BillerCode' | translate }}</strong>
      <span [innerHTML]="displayDetails?.BillerCode"></span>
    </div>
    <div *ngIf="displayDetails?.BillerReference">
      <strong> {{ '__Accounting.OutflowPayment.BillerReference' | translate }}</strong>
      <span [innerHTML]="displayDetails?.BillerReference"></span>
    </div>
    <div *ngIf="displayDetails?.Memo">
      <strong> {{ '__Accounting.OutflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails?.Memo"></span>
    </div>
  </div>

  <div *ngIf="paymentTypeId === PaymentTypeId.Cheque">
    <div *ngIf="displayDetails?.Memo">
      <strong> {{ '__Accounting.OutflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails?.Memo"></span>
    </div>
  </div>
</div>
