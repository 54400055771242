<label>{{ '__Accounting.Label.PaymentType' | translate }}</label>
<div class="x-input-group">
  <input disabled class="form-control" [value]="paymentTypeName" />

  <div class="x-input-group-append">
    <button
      class="btn x-btn-default x-btn-icon-only"
      (click)="showDetails = !showDetails"
      [disabled]="isPaymentDetailsEmpty"
    >
      <sc-icon *ngIf="!showDetails" class="x-icon-sm" iconName="chevron-down-circle-outline"></sc-icon>
      <sc-icon *ngIf="showDetails" class="x-icon-sm" iconName="chevron-up-circle-outline"></sc-icon>
    </button>
  </div>
</div>
<div
  *ngIf="paymentTypeId !== undefined && !isPaymentDetailsEmpty && showDetails"
  class="mt-1 x-details-info form-control x-form-control-textarea disabled"
  (click)="openPaymentDetailsModal()"
>
  <div *ngIf="paymentTypeId === PaymentTypeId.CashiersCheque">
    <div *ngIf="displayDetails.BSB">
      <strong>{{ '__Accounting.InflowPayment.BSB' | translate }}</strong> {{ displayDetails.BSB }}
    </div>

    <div *ngIf="displayDetails.AccountNumber">
      <strong>{{ '__Accounting.InflowPayment.AccountNumber' | translate }}</strong>
      <span [innerHTML]="displayDetails.AccountNumber"></span>
    </div>

    <div *ngIf="displayDetails.AccountName">
      <strong>{{ '__Accounting.InflowPayment.AccountName' | translate }}</strong>
      <span [innerHTML]="displayDetails.AccountName"></span>
    </div>
    <div *ngIf="displayDetails.Drawer">
      <strong>{{ '__Accounting.InflowPayment.Drawer' | translate }}</strong>
      <span [innerHTML]="displayDetails.Drawer"></span>
    </div>
    <div *ngIf="displayDetails.ChequeNumber">
      <strong>{{ '__Accounting.InflowPayment.ChequeNumber' | translate }}</strong>
      <span [innerHTML]="displayDetails.ChequeNumber"></span>
    </div>
    <div *ngIf="displayDetails.Memo">
      <strong>{{ '__Accounting.InflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails.Memo"></span>
    </div>
  </div>

  <div *ngIf="paymentTypeId === PaymentTypeId.Cash">
    <div *ngIf="displayDetails.Notes">
      <strong>{{ '__Accounting.InflowPayment.Notes' | translate }}</strong>
      <span [innerHTML]="displayDetails.Notes | money"></span>
    </div>
    <div *ngIf="displayDetails.Coins">
      <strong>{{ '__Accounting.InflowPayment.Coins' | translate }}</strong>
      <span [innerHTML]="displayDetails.Coins | money"></span>
    </div>
    <div *ngIf="displayDetails.Notes || displayDetails.Coins">
      <strong>{{ '__Accounting.InflowPayment.Total' | translate }}</strong>
      <span [innerHTML]="displayDetails.Notes * 1 + displayDetails.Coins * 1 | money"></span>
    </div>
    <div *ngIf="displayDetails.Memo">
      <strong>{{ '__Accounting.InflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails.Memo"></span>
    </div>
  </div>

  <div *ngIf="paymentTypeId === PaymentTypeId.CreditCard">
    <div *ngIf="displayDetails.AccountName">
      <strong>{{ '__Accounting.InflowPayment.NameOnCard' | translate }}</strong>
      <span [innerHTML]="displayDetails.AccountName"></span>
    </div>
    <div *ngIf="displayDetails.ExpiryDate">
      <strong>{{ '__Accounting.InflowPayment.ExpiryDate' | translate }}</strong>
      <span [innerHTML]="displayDetails.ExpiryDate"></span>
    </div>
    <div *ngIf="displayDetails.AuthorisationNumber">
      <strong>{{ '__Accounting.InflowPayment.AuthCode' | translate }}</strong>
      <span [innerHTML]="displayDetails.AuthorisationNumber"></span>
    </div>
    <div *ngIf="displayDetails.Memo">
      <strong>{{ '__Accounting.InflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails.Memo"></span>
    </div>
  </div>

  <div *ngIf="paymentTypeId === PaymentTypeId.Wire">
    <div *ngIf="displayDetails.Memo">
      <strong>{{ '__Accounting.InflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails.Memo"></span>
    </div>
  </div>

  <div *ngIf="paymentTypeId === 201">
    <div *ngIf="displayDetails.BSB && !isNZ">
      <strong>{{ '__Accounting.InflowPayment.BSB' | translate }}</strong> <span [innerHTML]="displayDetails.BSB"></span>
    </div>
    <div *ngIf="displayDetails.AccountNumber">
      <strong>{{ '__Accounting.InflowPayment.AccountNumber' | translate }}</strong>
      <span [innerHTML]="displayDetails.AccountNumber"></span>
    </div>
    <div *ngIf="displayDetails.AccountName">
      <strong>{{ '__Accounting.InflowPayment.AccountName' | translate }}</strong>
      <span [innerHTML]="displayDetails.AccountName"></span>
    </div>
    <div *ngIf="displayDetails.Drawer">
      <strong>{{ '__Accounting.InflowPayment.Drawer' | translate }}</strong>
      <span [innerHTML]="displayDetails.Drawer"></span>
    </div>
    <div *ngIf="displayDetails.ChequeNumber">
      <strong>{{ '__Accounting.InflowPayment.ChequeNumber' | translate }}</strong>
      <span [innerHTML]="displayDetails.ChequeNumber"></span>
    </div>
    <div *ngIf="displayDetails.Memo">
      <strong>{{ '__Accounting.InflowPayment.Memo' | translate }}</strong>
      <span [innerHTML]="displayDetails.Memo"></span>
    </div>
  </div>
</div>
