import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

import { BaseService } from '@app/shared/services';
import {
  ETrustPaymentTransactionMode,
  INewTrustPaymentResponse,
  ITrustPayment,
  ITrustPaymentItem,
  ITrustPaymentResponse,
  IMatterBalance,
} from '@app/features/+trust-payment/models';
import { v4 as uuidv4 } from 'uuid';
import { OutflowPaymentTypeParserService } from '@app/features/accounting/services';
import { formatDateForAccounting, getPaymentSubTypeIdFunc } from '@app/features/accounting/functions';
import { formatStringToDate, getDateForRegion } from '@app/shared/utils';
import { omitEmptyValue, sortAscBy } from '@server/modules/shared/functions';
import { PaymentTypeId } from '@app/features/accounting/constants';
import { BrandService } from '@app/core/services';

@Injectable()
export class TrustPaymentService extends BaseService {
  url: string;

  constructor(
    private _http: HttpClient,
    private _formBuilder: FormBuilder,
    private _paymentParserSvc: OutflowPaymentTypeParserService,
    private _brandSrv: BrandService,
  ) {
    super();
    this.url = `${this.accountingPath}/api/cloud/trustpayment`;
  }

  getNewTrustPaymentInitData(
    matterId: string,
    protectedTrustId: string,
    bankAccountId: string,
    payTypeDeleted: boolean,
    accountDeleted: boolean,
  ): Observable<INewTrustPaymentResponse> {
    const url = this.urlJoin(
      this.url,
      '/initialisationdata',
      `?payTypeDeleted=${payTypeDeleted}`,
      `&accountDeleted=${accountDeleted}`,
      protectedTrustId ? `&protectedTrustId=${protectedTrustId}` : null,
      bankAccountId ? `&bankAccountId=${bankAccountId}` : null,
      matterId ? `&matterId=${matterId}` : null,
    );
    return this._http.get<INewTrustPaymentResponse>(url).pipe(
      map((response: INewTrustPaymentResponse) => ({
        ...response,
        BankAccounts: sortAscBy(response.BankAccounts, (p) => p.NameFileAs),
        PaymentTypeList: sortAscBy(response.PaymentTypeList, (p) => p.NameFull),
      })),
    );
  }

  getTrustBalanceData(matterId: string, bankAccountId: string, atDate: Date = null): Observable<IMatterBalance> {
    const url = this.urlJoin(
      `${this.accountingPath}/api/cloud/matter`,
      `/trustbalance/${matterId}`,
      bankAccountId ? `?bankAccountId=${bankAccountId}` : null,
      atDate ? `&atDate=${atDate}` : null,
    );
    return this._http.get<IMatterBalance>(url);
  }

  getTrustPayment(
    trustPaymentId: string,
    transactionMode: ETrustPaymentTransactionMode = ETrustPaymentTransactionMode.View,
  ): Observable<ITrustPaymentResponse> {
    const url: string = this.urlJoin(
      this.url,
      `/${trustPaymentId}/initialisationdata?transactionMode=${transactionMode}`,
    );
    return this._http
      .get<ITrustPaymentResponse>(url)
      .pipe(map((response: ITrustPaymentResponse) => formatStringToDate(response)));
  }

  createDetailsFormGroup(context: 'create' | 'update' | 'reverse', options: Partial<ITrustPayment> = {}, paymentType) {
    return this._formBuilder.group({
      autoTransactionNumber: this._formBuilder.group({
        transactionNumber: [{ value: options.TransactionNumber || '', disabled: context !== 'create' }],
        autoNumber: [{ value: options.TransactionNumberAuto || 0, disabled: context !== 'create' }],
      }),
      PaymentType: this._formBuilder.group({
        paymentType: {
          value: paymentType || '',
          disabled: context !== 'create',
        },
        paymentTypeDetails:
          this._paymentParserSvc.parse(
            options.PaymentTypeDetails,
            options.PaymentTypeId,
            getPaymentSubTypeIdFunc(options),
          ) || '',
      }),

      BankAccountGUID: [{ value: options.BankAccountGUID || '', disabled: context !== 'create' }],
      PayToAddressee: this._formBuilder.group({
        name: [{ value: options.PayToName || '', disabled: context !== 'create' }],
        address: [{ value: options.PayToAddressee || '', disabled: context !== 'create' }],
      }),
      ReversedOrReversal: [{ value: options.ReversedOrReversal || false, disabled: true }],
      PaymentProcessed: [
        { value: [1, true].includes(options.PaymentProcessed) || false, disabled: context === 'reverse' },
      ],
      Void: [{ value: options.Void || false, disabled: context !== 'create' }],
      TransactionDate: [
        {
          value: options.TransactionDate ? new Date(options.TransactionDate) : new Date(),
          disabled: context === 'update',
        },
      ],
      EntryDate: [
        {
          value: options.EntryDate ? new Date(options.EntryDate) : getDateForRegion(this._brandSrv.brandRegion),
          disabled: true,
        },
      ],
    });
  }

  createTrustPaymentItem(options: Partial<ITrustPaymentItem> = {}): ITrustPaymentItem {
    return {
      TrustPaymentItemGUID: options.TrustPaymentItemGUID || uuidv4(),
      SeqNum: options.SeqNum || '',
      ReversalGUID: options.ReversalGUID || null,
      Description: options.Description || '',
      TrustPaymentReversedGUID: options.TrustPaymentReversedGUID || null,
      MatterGUID: options.MatterGUID || null,
      Amount: options.Amount || 0,
      Reason: options.Reason || '',
      RowVersion: options.RowVersion || 0,
      MatterFileNo: options.MatterFileNo || null,
      AvailableAmount: options.AvailableAmount || 0,
      DisplayedAvailableAmount: options.DisplayedAvailableAmount || 0,
      Withdrawal: options.Withdrawal || 0,
      Deposit: options.Deposit || 0,
      ProtectedTrustFundGUID: options.ProtectedTrustFundGUID || null,
    } as ITrustPaymentItem;
  }

  createRequestObject(options: Partial<ITrustPayment> = {}, mode: 'create' | 'update' | 'reverse' | 'void'): any {
    const commonObject = {
      AutoNumber: options.AutoNumber ? 1 : 0,
      BankAccountGUID: options.BankAccountGUID,
      EntryDate: formatDateForAccounting(options.EntryDate),
      PayToName: options.PayToName,
      PayToAddressee: options.PayToAddressee,
      PaymentNumber: options.PaymentNumber || options.TransactionNumber,
      PaymentProcessed: [PaymentTypeId.Cheque, PaymentTypeId.CashiersCheque].includes(options.PaymentTypeId)
        ? options.PaymentProcessed || 0
        : 0,
      PaymentTypeDetails: { PaymentTypeDetailsGUID: uuidv4(), ...omitEmptyValue(options.PaymentTypeDetails) },
      OfficeReceiptGUID: options.TOTOfficeReceiptGUID ? options.TOTOfficeReceiptGUID : '',
      PaymentTypeGUID: options.PaymentTypeGUID,
      PaymentTypeId: options.PaymentTypeId,
      PaymentTypeName: options.PaymentTypeName,
      Reconciled: options.Reconciled,
      ReversedOrReversal: options.ReversedOrReversal,
      TransactionDate: formatDateForAccounting(options.TransactionDate),
      TrustPaymentGUID: options.TrustPaymentGUID || uuidv4(),
      TrustPaymentItems: options.TrustPaymentItems,
      Void: options.Void,
      WarningAcknowledgments: options.WarningAcknowledgments || [],
    };
    const createObject = {};
    const updateObject = {
      RowVersion: options.RowVersion,
    };
    const reverseObject = {
      ReversedOrReversal: true,
      ReverseeRowVersion: options.ReverseeRowVersion || options.RowVersion,
      ReverseeTrustPaymentGUID: options.ReverseeTrustPaymentGUID || options.TrustPaymentGUID,
      TrustPaymentGUID: options.ReverseeTrustPaymentGUID ? options.TrustPaymentGUID : uuidv4(),
      RowVersion: options.RowVersion,
      TrustPaymentItems: (options.TrustPaymentItems || []).map(
        (item: ITrustPaymentItem): ITrustPaymentItem => ({
          ...item,
          ReversalGUID: item.TrustPaymentReversedGUID,
          Amount: Number(item.Amount),
        }),
      ),
    };
    const voidObject = {
      TrustPaymentItems: [],
      PayToName: '',
      PayToAddressee: '',
    };
    const extendedObject =
      mode === 'create'
        ? createObject
        : mode === 'update'
          ? updateObject
          : mode === 'reverse'
            ? reverseObject
            : voidObject;

    return {
      ...commonObject,
      ...extendedObject,
      SecurityOverride: { Username: '', Password: '' },
    };
  }
}
