import { createAction, props } from '@ngrx/store';
import { OfficeAccountUserPermission, OfficeAccountSharedResponse } from '@app/features/+document-share/models/models';

export const InitOfficeAccountShare = createAction(
  '[office account share] init folder sharing',
  props<{ matterNumber: string }>()
);

export const InitOfficeAccountShareFailure = createAction(
  '[office account share] init folder sharing',
  props<{ error: any }>()
);

export const GetOfficeAccountUserAccessStart = createAction(
  '[office account share] get user access start',
  props<{ matterNumber: string }>()
);

export const GetOfficeAccountUserAccessSuccess = createAction(
  '[office account share] get user access success',
  props<{ result: OfficeAccountUserPermission[] }>()
);

export const GetOfficeAccountUserAccessFailure = createAction(
  '[office account share] get user access failure',
  props<{ error: any }>()
);

export const UpdateOfficeAccountUserAccessList = createAction(
  '[office account share] update revoke and updated permission user list in store',
  props<{ updateAccessRow: OfficeAccountUserPermission }>()
);

export const SubmitLatestOfficeAccountUserAccessList = createAction(
  '[office account share] submit the existing and new recipient to api',
  props<{ newlyAdded?: OfficeAccountUserPermission[]; message: string }>()
);

export const SubmitLatestOfficeAccountUserAccessListSuccess = createAction(
  '[office account share] submit the existing and new recipient to api success',
  props<{ response: OfficeAccountSharedResponse; request: OfficeAccountUserPermission[] }>()
);

export const SubmitLatestOfficeAccountUserAccessListFailure = createAction(
  '[office account share] submit the existing and new recipient to api failure',
  props<{ error: any }>()
);

export const ClearOfficeAccountShareProcessingState = createAction(
  '[office account share] clear error, loading and success state'
);
