import { createSelector } from '@ngrx/store';
import {
  selectCardModalPageLeapAppsState,
  selectCardPageLeapAppsState,
  selectPersonPageLeapAppsState,
  selectGlobalLeapAppsState,
  selectViewLeapAppsState,
} from '../../store';
import * as fromViewLeapApp from '../reducer/view-leap-app.reducer';
import * as fromGlobalLeapApp from '../reducer/global-leap-app.reducer';
import * as fromCardPageLeapApp from '../reducer/card-page-leap-app.reducer';
import * as fromCardModalPageLeapApp from '../reducer/card-modal-leap-app.reducer';
import * as fromPersonPageLeapApp from '../reducer/person-page-leap-app.reducer';

/*
 * Global Leap App Selectors
 * */
export const selectGlobalLeapApps = createSelector(
  selectGlobalLeapAppsState,
  fromGlobalLeapApp.selectAllGlobalLeapApps
);

export const selectGlobalLeapAppEntities = createSelector(
  selectGlobalLeapAppsState,
  fromGlobalLeapApp.selectGlobalLeapAppEntities
);

export const selectGlobalLeapAppNoAppFlag = createSelector(
  selectGlobalLeapAppsState,
  fromGlobalLeapApp.selectGlobalLeapAppNoAppStatus
);

/*
 * View Leap App Selectors
 * */
export const selectViewLeapApps = createSelector(selectViewLeapAppsState, fromViewLeapApp.selectAllViewLeapApps);

export const selectViewLeapAppEntities = createSelector(
  selectViewLeapAppsState,
  fromViewLeapApp.selectViewLeapAppEntities
);

/*
 * Card Page Leap App Selectors
 * */
export const selectCardPageLeapApps = createSelector(
  selectCardPageLeapAppsState,
  fromCardPageLeapApp.selectAllCardPageLeapApps
);

export const selectCardPageLeapAppEntities = createSelector(
  selectCardPageLeapAppsState,
  fromCardPageLeapApp.selectCardPageLeapAppEntities
);

export const selectCardPageLeapAppLoading = createSelector(selectCardPageLeapAppsState, fromCardPageLeapApp.loading);

/*
 * Card Modal Page Leap App Selectors
 * */
export const selectCardModalPageLeapApps = createSelector(
  selectCardModalPageLeapAppsState,
  fromCardModalPageLeapApp.selectAllCardModalPageLeapApps
);

export const selectCardModalPageLeapAppEntities = createSelector(
  selectCardModalPageLeapAppsState,
  fromCardModalPageLeapApp.selectCardModalPageLeapAppEntities
);

export const selectCardModalPageLeapAppLoading = createSelector(
  selectCardModalPageLeapAppsState,
  fromCardModalPageLeapApp.loading
);

/*
 * Person Page Leap App Selectors
 * */
export const selectPersonPageLeapApps = createSelector(
  selectPersonPageLeapAppsState,
  fromPersonPageLeapApp.selectAllPersonPageLeapApps
);

export const selectPersonPageLeapAppEntities = createSelector(
  selectPersonPageLeapAppsState,
  fromPersonPageLeapApp.selectPersonPageLeapAppEntities
);

export const selectPersonPageLeapAppLoading = createSelector(
  selectPersonPageLeapAppsState,
  fromPersonPageLeapApp.loading
);
