import { createSelector } from '@ngrx/store';

import * as fromTrustLedger from '../reducers/trust-ledger.reducer';
import { selectTrustLedgerState } from '../reducers';

export const selectTrustLedgerError = createSelector(selectTrustLedgerState, (state: fromTrustLedger.State) =>
  fromTrustLedger.selectError(state)
);

export const selectTrustLedgerLoading = createSelector(selectTrustLedgerState, (state: fromTrustLedger.State) =>
  fromTrustLedger.selectLoading(state)
);

export const selectTrustBalance = createSelector(selectTrustLedgerState, (state: fromTrustLedger.State) =>
  fromTrustLedger.selectBalance(state)
);

export const selectTrustAntDepositAmount = createSelector(selectTrustLedgerState, (state: fromTrustLedger.State) =>
  fromTrustLedger.selectAntDepositAmount(state)
);

export const selectTrustTotalDeposits = createSelector(selectTrustLedgerState, (state: fromTrustLedger.State) =>
  fromTrustLedger.selectTotalDeposits(state)
);

export const selectTrustTotalWithdrawals = createSelector(selectTrustLedgerState, (state: fromTrustLedger.State) =>
  fromTrustLedger.selectTotalWithdrawals(state)
);

export const selectTrustReason = createSelector(selectTrustLedgerState, (state: fromTrustLedger.State) =>
  fromTrustLedger.selectReason(state)
);

export const selectHistoryTrustTransactions = createSelector(selectTrustLedgerState, (state: fromTrustLedger.State) =>
  fromTrustLedger.selectHistory(state)
);

export const selectTrustBankAccounts = createSelector(selectTrustLedgerState, (state: fromTrustLedger.State) =>
  fromTrustLedger.selectBankAccounts(state)
);

export const selectTrustSelectedBankAccountGuid = createSelector(
  selectTrustLedgerState,
  (state: fromTrustLedger.State) => fromTrustLedger.selectSelectedBankAccountGuid(state)
);

export const selectTrustSelectedBankAccount = createSelector(
  selectTrustBankAccounts,
  selectTrustSelectedBankAccountGuid,
  (accounts, accountGuid) => accounts?.find((x) => x.BankAccountGUID === accountGuid)
);

export const selectAllTrustTransactions = fromTrustLedger.adapter.getSelectors(selectTrustLedgerState).selectAll;
