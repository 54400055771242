import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AppSlice } from '@app/core/store';

import { reducers, TrustLedgerEffect } from '@app/features/+trust/store';
import { TrustLedgerService } from '@app/features/+trust/services';
import { TrustPaymentService } from '@app/features/+trust-payment/services';
import { AccountingModule } from '@app/features/accounting/accounting.module';

@NgModule({
  imports: [
    CommonModule,
    AccountingModule,
    StoreModule.forFeature(AppSlice.TrustLedger, reducers),
    EffectsModule.forFeature([TrustLedgerEffect]),
  ],
  declarations: [],
  providers: [TrustLedgerService, TrustPaymentService],
})
export class TrustLedgerStateModule {}
