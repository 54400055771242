import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map as rxjsMap } from 'rxjs/operators';

import { selectFirmDetails } from '@app/core/store/selectors';
import { Feature } from '@app/core/models';
import { BrandService } from '@app/core/services/brand/brand.service';
import { FirmDetails } from '@app/shared/models';
import { Regions } from '@app/shared/models/config.model';

@Injectable()
export class FeatureControlService {
  constructor(private _brandSvc: BrandService, private _store: Store<any>) {}

  isEnabled(feature: Feature): Observable<boolean> {
    return this._store.pipe(
      select(selectFirmDetails),
      rxjsMap((firmDetails: FirmDetails) => {
        const region = !!firmDetails && !!firmDetails.region ? firmDetails.region : Regions.AU;
        const isAU = region === Regions.AU;
        const isNZ = region === Regions.NZ;
        const isTitleX = this._brandSvc.isTitleX;

        switch (feature) {
          case Feature.PowerMoney:
            return isAU;
          case Feature.TransitMoney:
            return isAU || isNZ;
          case Feature.SuperDiary:
          case Feature.PrintCheques:
          case Feature.LiveChat:
          case Feature.ByLawyers:
            return isTitleX;
          case Feature.ProtectedFunds:
          case Feature.SpecialFees:
          case Feature.RapidPay:
          case Feature.MatterAddin:
            return !isTitleX;
          case Feature.QuickCalculator:
            return isTitleX && isAU;
          case Feature.CdnPerEnvironment:
            return isTitleX;
          default:
            return true;
        }
      })
    );
  }
}
