import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService, EApiPath } from 'app/shared/services/base/base.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  AccountingRequest,
  AccountingUpdateResponse,
  DocumentPrintInformFromDocBuilder,
  DocumentPrintViaDocBuilderReq,
  EAccountingTransaction,
  InvoicePrintInformFromDocBuilder,
  InvoicePrintViaDocBuilderReq,
  IReportingSaveToMatter,
  PreviewViaReportingReq,
} from '../../models/index';
import { PrintService } from '@app/shared/services/print/print.service';
import { v4 as uuidv4 } from 'uuid';
import { CorrespondenceType, IDocMetaInfo } from '@app/shared/models';
import { TextUtilsService } from '@app/shared/services/text-utils/text-utils.service';
import { formatDateHttp } from '@app/shared/utils';
import { DebtorForAccounting } from '@app/core/constants';
import { BrandService } from '@app/core/services/brand/brand.service';

@Injectable({
  providedIn: 'root',
})
export class AccountingApiService extends BaseService {
  constructor(
    private _http: HttpClient,
    private _printSvc: PrintService,
    private _textUtilsSvc: TextUtilsService,
    private _brandSvc: BrandService
  ) {
    super();
  }

  save(accReq: AccountingRequest) {
    return accReq.operation === 'post'
      ? this.create(accReq.url, accReq.model, accReq.absolute)
      : this.update(accReq.url, accReq.model, accReq.absolute);
  }

  getSplitCardsByMatterId(params: { matterId: string }): Observable<{ splitCards: string[] }> {
    const { matterId } = params;
    const url = this.urlJoin(this.siriusPath, `api/sirius-accounting/matter/${matterId}/splitcards`);
    return this._http.get<{ splitCards: string[] }>(url);
  }

  getSplitCardsByInvoiceId(params: { invoiceId: string }): Observable<{ splitCards: string[] }> {
    const { invoiceId } = params;
    const url = this.urlJoin(this.siriusPath, `api/sirius-accounting/invoice/${invoiceId}/splitcards`);
    return this._http.get<{ splitCards: string[] }>(url);
  }

  getPreviewUrlViaReportingApi(params: PreviewViaReportingReq): Observable<string> {
    const { fileName, reportName, options, source } = params;
    const originalUrl = this._printSvc.buildSimplePrintUrl(reportName, options, source);
    const url = `${this.siriusPath}/api/reporting/preview-url`;
    return this._http.post(url, { originalUrl, fileName }, { responseType: 'text' }).pipe(
      catchError((error) => {
        this._printSvc.print(reportName, options, source);
        return throwError(() => error);
      })
    );
  }

  // get the reportOptions value for previewing a doc via reporting api
  getReportOptions(params: {
    accountingType: EAccountingTransaction;
    transactionGuid: string;
    matterGuid: string;
    firmGuid?: string;
    splitCardGuid?: string;
    split?: number;
  }): any {
    const { accountingType, transactionGuid, matterGuid, firmGuid, splitCardGuid, split } = params;
    let reportOptions = {};
    if (!!splitCardGuid) {
      reportOptions = {
        ...reportOptions,
        splitGuidId: splitCardGuid,
      };
    }

    if (!!split) {
      reportOptions = {
        ...reportOptions,
        split,
      };
    }

    if (!!firmGuid) {
      reportOptions = {
        ...reportOptions,
        FirmGUID: firmGuid,
      };
    }

    switch (accountingType) {
      case EAccountingTransaction.ApplyCredits:
        return {
          ...reportOptions,
          CreditApply_GUID: transactionGuid,
        };

      case EAccountingTransaction.OfficeReceipt:
      case EAccountingTransaction.CreditReceipt:
      case EAccountingTransaction.TrustReceipt:
        return {
          ...reportOptions,
          ReceiptGUID: transactionGuid,
        };

      case EAccountingTransaction.OfficeStatementDebtorsLedger:
      case EAccountingTransaction.OfficeStatementSummary:
        return {
          ...reportOptions,
          MatterGUID: matterGuid,
          App: 1,
        };
      case EAccountingTransaction.PowerMoneyBankAccount:
        return {
          ...reportOptions,
          MatterGUID: matterGuid,
          BankAccGUID: transactionGuid,
        };
      case EAccountingTransaction.PowerMoneyTransaction:
        return {
          ...reportOptions,
          MatterGUID: matterGuid,
          PowerMoney_GUID: transactionGuid,
        };

      case EAccountingTransaction.TrustInvestmentTransaction:
        return {
          ...reportOptions,
          InvestTrans_GUID: transactionGuid,
        };

      case EAccountingTransaction.ControlledMoneyAccountDetail:
      case EAccountingTransaction.TrustInvestmentAccountDetail:
        return {
          ...reportOptions,
          BankAccGUID: transactionGuid,
          MatterGUID: matterGuid,
        };

      default:
        return reportOptions;
    }
  }

  saveToMatterViaReportingApi(params: IReportingSaveToMatter): Observable<any> {
    const { matterId, reportName, reportUrl, staffInitials, user } = params;
    const documentId = uuidv4();
    const data: IDocMetaInfo = {
      firmId: user.firmId,
      matterId,
      userId: user.userId,
      documentId,
      docName: reportName,
      fileType: 'pdf',
      staffInitials,
      latestVersionId: documentId,
      docTypeId: CorrespondenceType.Document,
      precedentId: null,
      customPrecedentId: null,
      defaultTableId: null,
      defaultTableOrder: null,
      created: formatDateHttp(new Date(), {
        preserveTime: true,
        includeTimezoneOffset: true,
      }),
      folderId: '',
    };
    const docmetainfo = this._textUtilsSvc.b64EncodeUnicode(JSON.stringify(data));

    const url = `${this.siriusPath}/api/reporting/save-to-matter`;
    const body = { reportName, reportUrl, docmetainfo };
    return this._http.post(url, body);
  }

  documentPrintViaDocBuilder(data: DocumentPrintViaDocBuilderReq): Observable<DocumentPrintInformFromDocBuilder> {
    const {
      precedentId,
      isCustomPrecedent,
      matterId,
      saveToMatter,
      saveToSuperDiaryTempStorage,
      documentName,
      staffInitials,
      parameters,
      returnFileContent,
    } = data;
    const defaultTableId = !!DebtorForAccounting[this._brandSvc.brandRegion]
      ? DebtorForAccounting[this._brandSvc.brandRegion].id
      : DebtorForAccounting['au'].id;
    const payload = {
      precedentId,
      isCustomPrecedent,
      matterId,
      saveToMatter,
      saveToSuperDiaryTempStorage,
      documentName,
      staffInitials,
      parameters,
      defaultTableId,
      defaultTableOrder: 1,
      defaultPersonInstance: '',
      outputType: 'pdf',
      returnFileContent,
    };
    const url = `${this.docBuilderPath}/api/documents`;
    return this._http.post<DocumentPrintInformFromDocBuilder>(url, payload);
  }

  invoicePrintViaDocBuilder(data: InvoicePrintViaDocBuilderReq): Observable<InvoicePrintInformFromDocBuilder[]> {
    const { precedentId, isCustomPrecedent, invoices, combinePdfs, combinePdfsName, returnFileContent } = data;
    const defaultTableId = !!DebtorForAccounting[this._brandSvc.brandRegion]
      ? DebtorForAccounting[this._brandSvc.brandRegion].id
      : DebtorForAccounting['au'].id;
    const payload = {
      precedentId,
      invoices,
      isCustomPrecedent,
      defaultTableId,
      defaultTableOrder: 1,
      defaultPersonInstance: '',
      outputType: 'pdf',
      combinePdfs,
      combinePdfsName,
      itemise: true,
      returnFileContent,
    };
    const url = `${this.docBuilderPath}/api/v2/invoices`;

    return this._http.post<InvoicePrintInformFromDocBuilder[]>(url, payload);
  }

  getAccountPreferences(): Observable<any> {
    const url = this.urlJoin(this.optionsPath, '/api/preferences');

    return this._http.get<any>(url);
  }

  public getBillingCategories(): Observable<any> {
    const url = this.urlJoin(this.optionsPath, '/api/BillingCategory');
    
    return this._http.get<any>(url);
  }

  private create(url: string, model: any, absolute?: boolean): Observable<AccountingUpdateResponse> {
    const apiUrl = url.includes(EApiPath.Accounting) || absolute ? url : this.urlJoin(this.accountingPath, url);
    return this._http.post<AccountingUpdateResponse>(apiUrl, model);
  }

  private update(url: string, model: any, absolute?: boolean): Observable<AccountingUpdateResponse> {
    const apiUrl = url.includes(EApiPath.Accounting) || absolute ? url : this.urlJoin(this.accountingPath, url);
    return this._http.put<AccountingUpdateResponse>(apiUrl, model);
  }
}
