// Card List GroupBy
import { ICardListEntry } from '@app/features/+card/models/card-list';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { IMatterCard } from '@app/shared/models/matter-details.model';
import { ViewLeapAppLocationId } from '../../matter-addin/models';

export const GroupByNone = { id: 1, value: 'Ungroup' };
export const GroupByType = { id: 2, value: 'Type' };
export const GroupByName = { id: 3, value: 'FullName' };
export const GroupByAddress = { id: 4, value: 'Suburb' };

export enum ECardType {
  People = 'People',
  Business = 'Business',
  Company = 'Company',
  Government = 'Government',
  Trust = 'Trust',
}

export enum ECardDetailsTabType {
  GeneralDetails,
  Address,
  SpecialFees,
  NotesOthers,
  Documents,
  People,
  Matters,
  Layout,
}

export enum EPersonDetailsTab {
  General,
  Other,
  AdditionalFields,
}

export const CardDetailsTabViewLeapAppLocationId = {
  [ECardDetailsTabType.GeneralDetails]: ViewLeapAppLocationId.CardDetails,
  [ECardDetailsTabType.Address]: ViewLeapAppLocationId.CardAddress,
  [ECardDetailsTabType.People]: ViewLeapAppLocationId.CardPeople,
  [ECardDetailsTabType.Matters]: ViewLeapAppLocationId.CardMatter,
  [ECardDetailsTabType.NotesOthers]: ViewLeapAppLocationId.CardOther,
};

export enum ECardListModelEvent {
  Cancel,
  Confirm,
}

export interface ICardListModelSelection {
  action: ECardListModelEvent;
  cardEntries: ICardListEntry[];
}

export interface IMatterListModelSelection {
  matterEntry: IMatterListEntry | null;
}

export const CardErrors = {
  generalDetailsEmailList: {
    inValidTab: ECardDetailsTabType.GeneralDetails,
    message: 'This Card cannot be saved. Please enter a valid email address.',
  },
  personDetailsEmailList: {
    inValidTab: ECardDetailsTabType.People,
    message: 'This Card cannot be saved. Please enter a valid email address.',
  },
  firstNames: {
    inValidTab: ECardDetailsTabType.People,
    message: 'This Card cannot be saved. Please enter at least one person.',
  },
  lastName: {
    inValidTab: ECardDetailsTabType.People,
    message: 'This Card cannot be saved. Please enter at least one person.',
  },
  tradingNameTrustName: {
    inValidTab: ECardDetailsTabType.GeneralDetails,
    message: 'This Card cannot be saved. Please enter the Trading Name.',
  },
  companyOwnerName: {
    inValidTab: ECardDetailsTabType.GeneralDetails,
    message: 'This Card cannot be saved. Please enter the Company Name.',
  },
  default: {
    inValidTab: null,
    message: 'This Card cannot be saved.',
  },
};

export const PhoneNumberTypes = [
  { value: 'Phone', displayText: 'Card.PhoneNumberTypes.Phone' },
  { value: 'Fax', displayText: 'Card.PhoneNumberTypes.Fax' },
  { value: 'Home', displayText: 'Card.PhoneNumberTypes.Home' },
  { value: 'Work', displayText: 'Card.PhoneNumberTypes.Work' },
  { value: 'Mobile', displayText: 'Card.PhoneNumberTypes.Mobile' },
  { value: 'Mobile2', displayText: 'Card.PhoneNumberTypes.Mobile2' },
  { value: 'Mobile3', displayText: 'Card.PhoneNumberTypes.Mobile3' },
];

export const EmailTypes = [
  { value: 'Email', displayText: 'Card.EmailTypes.Email' },
  { value: 'Email2', displayText: 'Card.EmailTypes.Email2' },
];

export const WebTypes = [
  { value: 'Web', displayText: 'Card.WebTypes.Web' },
  { value: 'Web2', displayText: 'Card.WebTypes.Web2' },
];

export type PersonalDetailsAdditionalField = {
  fieldName: string;
  listId: string | null;
  personProperty: string;
  value: string;
};

export const NoneCardId = 'none';

export const NoneMatterCard = {
  cardId: NoneCardId,
  cardPersonList: [],
  subsTableId: null,
  tableId: null,
  __description: 'No recipient by intention',
  default: false,
  __displayOrder: null,
  __fileOrder: null,
  __name: 'Select None',
  __inherited: '',
  detailType: null,
  isCard: true,
  isPersonCard: true,
  isDebtor: false,
  phoneNumberList: [],
  persons: [],
  reference: '',
  relatedTable: null,
  unusedPersons: [],
} as IMatterCard;

export const NoneCardEntry = {
  cardId: NoneCardId,
  fullName: 'Select None',
  shortName: 'Select None',
  type: ECardType.People,
  isSupplier: false,
  persons: [],
  webAddressList: [],
  phoneNumberList: [],
  addressList: [],
  roles: null,
  email: null,
  phone: null,
  address: null,
  suburb: '',
  deleteCode: 0,
} as ICardListEntry;

export const CardTableName = 'Card';
