export enum Regions {
  AU = 'au',
  US = 'us',
  UK = 'uk',
  CA = 'ca',
  IE = 'ie',
  NZ = 'nz',
  NL = 'nl',
}

export enum DateFormatType {
  A = 0, // 'M/d/yy', 'y/MM/dd'
  B = 1, // 'mm/dd/yy', 'dd/mm/yy', 'y/mm/dd'
  C = 2, // 'mm/dd/yyyy', 'dd/mm/yyyy', 'y/mm/dd'
  D = 3, // 'EE MMM d y', 'EE d MMM y', 'EE y/MM/dd',
  E = 4, // 'MMM d y', 'd MMM y', 'y/MM/dd',
}

export const REGIONS: Regions[] = [
  Regions.AU, // 0
  Regions.UK, // 1
  Regions.US, // 2
  Regions.CA, // 3
  Regions.IE, // 4
  Regions.NZ  // 5
];

export function getRegionFromCode(code: number): Regions {
  return REGIONS[code] || Regions.AU;
}
