// TODO: To be re-organized - Card and Person
import { IDefinableTable } from '@app/shared/models';
import { IAddress, IAlternativeAddressSettings, ICardRoles, IOriginalAddressSettings } from '@app/features/+card/models';
import { IPhoneNumber, IWebAddress } from '@app/core/models';

export const CUSTOMISE_LIST_OPTION_VALUE = 'customise-list-option-value';

export interface IPersonCardRole {
  id: string;
  isPrimary: boolean;
}

export interface IPerson {
  __id: string;
  __className: string;
  __firmId: string;
  userId: string;

  __personId: string;
  salutation: string;
  firstNames: string;
  lastName: string;
  previousNames: string;
  maidenName: string;
  gender: string;
  nationality: string;
  passportNumber: string;
  passportName: string;
  dateOfBirth: Date | string;
  placeOfBirth: string;
  countryOfBirth: string;
  dateOfDeath: Date | string;
  placeOfDeath: string;
  primary: boolean;
  webAddressList: IWebAddress[];
  phoneNumberList: IPhoneNumber[];
  cardRoles: IPersonCardRole[];
  cards: ICard[];
  primaryCards: string[]; // list of primary card ids,
  dirty: boolean;

  version: number;
  deleteCode: number;
  comments: string;
  occupation: string;
  eMailAddressesText: string;
  interpreterLanguage: string;
  specialNeeds: string;
  maritalStatus: string;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;
  custom6: string;
  custom7: string;
  custom8: string;
  custom9: string;
  custom10: string;
  custom11: string;
  custom12: string;
}

export interface ITrustee {
  id: string;
  __className?: string;
  trusteeName: string;
  trusteeIsACompany: boolean;
  company: string;
  trusteeCrn: string;
  trusteeAcn: string;
}

export interface IBeneficiary {
  beneficiaryName: string;
  dateOfBirth: string;
}

export interface IBusiness {
  companyOwnerName: string;
  businessType: string;
  abn: string;
  tin: string;
  taxCountry: string;
  irdNumber: string | null;
}

export interface ICompany {
  companyOwnerName: string;
  companyTitle: string;
  tradingTitle: string;
  businessType: string;
  abn: string;
  tin: string;
}

export interface IGovernment {
  companyOwnerName: string;
  abn: string;
  tin: string;
}

export type Trust = {
  trustPurpose: string;
  settlorName: string;
}

export interface ITrust {
  dateOfTrust: Date;
  trustStatus: string;
  trusteeList: ITrustee[];
  trust: Trust | null;
}

export interface IOrganisation extends IBusiness, ICompany, IGovernment, ITrust {
  tradingNameTrustName: string;
  propertyBuildingName: string;
  soleDirectorCompany: boolean;
}

export interface ICardDocument {
  created: string;
  deleteCode: number;
  id: string;
  name: string;
  staffInitials: string;
  type: string;
}

type ICardType = 'Trust' | 'People' | 'Organisation' | 'Business' | 'Government' | 'Company';

export interface ICardDTO extends IOrganisation {
  __id: string;
  __className: string;
  __firmId: string;
  description?: string;
  title?: string;
  shortName?: string;
  fullName?: string;
  cardType: ICardType;
  type: ICardType;
  isSupplier: boolean;
  marketingConsent: boolean;
  personList: IPerson[];

  // Address lists
  originalAddressList: IAddress[];
  originalAddressSettings?: IOriginalAddressSettings;
  defaultOriginalAddress?: string;
  alternativeAddressList: IAddress[];
  alternativeAddressSettings?: IAlternativeAddressSettings;

  // Phone lists
  phoneNumberList: IPhoneNumber[];

  // Emails
  webAddressList: IWebAddress[];
  webAddress: IWebAddress[];

  // Letter
  userTitle: string;
  useDefaultTitle: boolean;
  useFriendlyDear: boolean;
  dear: string;
  useDefaultDear: boolean;

  // Comment
  comments: string;

  bankAccount?: {
    bsb?: string;
    accountName?: string;
    accountNumber?: string;
  };

  bankAccountBsb?: string; // property from DocAPI
  bankAccountName?: string; // property from DocAPI
  bankAccountNumber?: string; // property from DocAPI

  roles: ICardRoles;

  // for card entry
  __cardId: string;
  reference: string; // For matter card

  asicKey: string;
  acn: string;
  acnArbnEtc: string;
  acln: string;
  matterCardReference?: string;

  relatedTables?: IDefinableTable[];

  version: number;
  deleteCode?: number;

  documents: ICardDocument[]
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICard extends ICardDTO { }
