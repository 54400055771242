import { Observable, map, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '../base/base.service';
import { ITableSchema, StateItem } from '../../models';
import { Regions } from '@app/shared/models/config.model';
import { AuthService, BrandService } from '@app/core/services';

@Injectable()
export class SchemaService extends BaseService {
  constructor(private httpClient: HttpClient, private _brandSvc: BrandService, private _authSvc: AuthService) {
    super();
  }

  getTables(ids: string[], matterTypeId: string, state: string, withLayout: boolean): Observable<ITableSchema[]> {
    let qsIds = '';

    ids.forEach((id, idx) => {
      if (idx === 0) {
        qsIds = `ids[0]=${id}`;
      } else {
        qsIds = `${qsIds}&ids[${idx}]=${id}`;
      }
    });

    const queryUrl = `${qsIds}&matterTypeId=${matterTypeId}&state=${state}&withLayout=${withLayout}`;
    const url = `${this.siriusPath}/api/scema/tables?${queryUrl}`;
    return this.httpClient.get<ITableSchema[]>(url);
  }

  getTable(id: string, matterTypeId = '', state = ''): Observable<any> {
    const queryUrl = `region=${Regions.AU}&matterTypeId=${matterTypeId}&state=${state}`;
    const url = `${this.siriusPath}/api/scema/table/${id}?${queryUrl}`;
    return this.httpClient.get<any>(url);
  }

  getStates(): Observable<string[]> {
    if (this._brandSvc.isTitleX) {
      return of(['NSW', 'VIC']);
    }
  
    const url = `${this.leapDesignPath}/Content/getStates`;
  
    return this.httpClient.post<StateItem[]>(url, []).pipe(
      map((stateItems) => {
        const filtered = stateItems.filter(item => item.code !== 'ALL' && item.display);
        const codes = filtered.map(item => item.code).sort((a, b) => a.localeCompare(b));
  
        return codes;
      })
    );
  }

  getLists(listIds: string[]): Observable<string[]> {
    const listIdsAsString = listIds.join(',');
    const url = `${this.siriusPath}/api/scema/lists/${listIdsAsString}`;
    return this.httpClient.get<string[]>(url);
  }
}
