export const AnalyticsCategories = {
  Init: 'Init',
  MatterList: 'Matter List - List',
  MatterListKeyboard: 'Matter List - Keyboard',
  MatterListMenu: 'Matter List - Menu',
  MatterListContextMenu: 'Matter List - Context Menu',
  MatterListSidebar: 'Matter List - Sidebar',
  NewMatterWizard: 'New Matter Wizard',
  MatterDetails: 'Matter Details - Details',
  MatterDetailsOptions: 'Matter Details - Matter Options',
  MatterDetailsOptionsContextMenu: 'Matter Details - Matter Options - Context Menu',
  MatterDetailsMatterCard: 'Matter Details - Matter Card',
  MatterDetailsMatterCardContextMenu: 'Matter Details - Matter Card - Context Menu',
  MatterDetailsDefinable: 'Matter Details - Definable',
  MatterDetailsDefinableContextMenu: 'Matter Details - Definable - Context Menu',
  MatterDetailsCorrespondenceMenu: 'Matter Details - Correspondence Menu',
  MatterDetailsCorrespondenceList: 'Matter Details - Correspondence List - List',
  MatterDetailsCorrespondenceListContextMenu: 'Matter Details - Correspondence List - Context Menu',
  MatterDetailsSidebar: 'Matter Details - Sidebar',
  NewLetterRecipientSelector: 'New Letter - Recipient Selector',
  CustomContainerRecipientSelector: 'New Custom Container Document - Recipient Selector',
  EmailClient: 'Email Client',
  EmailClientRecipientSelector: 'Email Client - Recipient Selector',
  FormsPrecedents: 'Forms & Precedent',
  FinancialSummarySidebar: 'Financial Summary - Sidebar',
  TimeFeeList: 'Time Fee - List',
  TimeFeeContextMenu: 'Time Fee - Context Menu',
  TimeFeeMenu: 'Time Fee - Menu',
  TimeFeeSidebar: 'Time Fee - Sidebar',
  TimeForm: 'Time Form',
  TimeFeeFooter: 'Time Fee - Footer',
  FeeForm: 'Fee Form',
  PaymentDebtorsList: 'Payments & Debtors - List',
  PaymentDebtorsContextMenu: 'Payments & Debtors - Context Menu',
  PaymentDebtorsMenu: 'Payments & Debtors - Menu',
  PaymentDebtorsSidebar: 'Payments & Debtors - Sidebar',
  PaymentDebtorsPrint: 'Payments & Debtors - Print',
  CardList: 'Card List - List',
  CardListKeyboard: 'Card List - List - Keyboard',
  CardListContextMenu: 'Card List - Context Menu',
  CardListMenu: 'Card List - Menu',
  CardListSidebar: 'Card List - Sidebar',
  CardModal: 'Card Modal',
  CardModalPerson: 'Card Modal - Person',
  CardModalAddress: 'Card Modal - Address',
  CardModalOtherDetails: 'Card Modal - Other Details',
  CardModalMenu: 'Card Modal - Menu',
  CardModalDocumentListContextMenu: 'Card Modal - Document List - Context Menu',
  Global: 'Global',
  GlobalSignout: 'Global - signout',
  SuperDiaryScheduledItemsMenu: 'Super Diary - Scheduled Items -Menu',
  SuperDiaryScheduledItemsList: 'Super Diary - Scheduled Items -List',
  SuperDiaryScheduledItemsContextMenu: 'Super Diary - Scheduled Items -Context Menu',
  SuperDiaryUnallocatedCriticalDatesList: 'Super Diary - Unallocated Critical Dates - List',
  SuperDiaryUnscheduledTasksList: 'Super Diary - Unscheduled Tasks - List',
  SuperDiarySidebar: 'Super Diary - Sidebar',
  SuperDiaryDesktopIntegration: 'Super Diary - Desktop Integration',
  SuperDiaryAppointmentModal: 'Super Diary - Appointment Modal',
  SuperDiaryTaskModal: 'Super Diary - Task Modal',
  CreditLedger: 'Credit Ledger',
  CreditJournalForm: 'Credit Journal Form',
  CreditRefundForm: 'Credit Refund Form',
  ApplyCreditToInvoicesForm: 'Apply Credit to Invoices Form',
  InvoiceForm: 'Invoice Form',
  InvoiceFormAlterTimeAndFees: 'Invoice Form Alter Time and Fees',
  OfficePaymentForm: 'Office Payment Form',
  OfficePaymentRequestForm: 'Office Payment Request Form',
  OfficeDisbursementJournalForm: 'Office Disbursement Journal Form',
  OfficeDisbursementJournalReversalForm: 'OfficeDisbursementJournalReversalForm',
  OfficeDisbursementJournalViewForm: 'Office Disbursement Journal View Form',
  AnticipatedPaymentLedger: 'Anticipated Payment Ledger',
  AnticipatedPaymentForm: 'AnticipatedPaymentForm',
  PayAnticipatedForm: 'Pay Anticipated Form',
  CostRecoveryLedger: 'Cost Recovery Ledger',
  CostRecoveryForm: 'Cost Recovery Form',
  TrustLedger: 'Trust Ledger',
  TrustReceiptCreateForm: 'Trust Receipt Create Form',
  TrustReceiptReverseForm: 'Trust Receipt Reverse Form',
  TrustReceiptViewPage: 'Trust Receipt View Page',
  TrustPaymentForm: 'Trust Payment Form',
  TrustPaymentRequestForm: 'Trust Payment Request Form',
  TrustJournalCreateForm: 'Trust Journal Create Form',
  TrustJournalReverseForm: 'Trust Journal Reverse Form',
  TrustJournalViewPage: 'Trust Journal View Page',
  TrustToOfficeForm: 'Trust to Office Form',
  ShareTrustAccountForm: 'Share Trust Account Form',
  ProtectedTrustFundsLedger: 'Protected Trust Funds Ledger',
  ProtectedTrustFundForm: 'Protected Trust Fund Form',
  BankAccountRegister: 'Bank Account Register',
  ShareOfficeAccount: 'Share Office Account',
  CreditReceiptForm: 'Credit Receipt Form',
  SearchTransactions: 'Search Transactions',
  NewComment: 'New Comment',
  Popup: 'Popup',
  TrustBankDeposit: 'Trust Bank Deposit',
  AppMenu: 'App Menu',
  GlobalLeapApp: 'Leap App (Global)',
  GridLeapApp: 'Leap App (Grid)',
  GridMatterDetailLeapApp: 'Leap App (Matter Detail Grid)',
  SideBarLeapApp: 'Leap App (SideBar)',
};
