import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';

import { BrandService } from '@app/core/services';
import { ILeapDesignTableContent, ITableSchema, StateItem } from '../../models';
import { BaseService } from '../base/base.service';

@Injectable()
export class LeapDesignService extends BaseService {
  private leapDesignUrl = `${this.leapDesignPath}/Content`;
  constructor(
    private httpClient: HttpClient,
    private _brandSvc: BrandService,
  ) {
    super();
  }

  mapLeapDesignToITableSchema(item: any): ITableSchema {
    const data = item?.additionalData ?? {};

    const boolFromString = (val: string | undefined): boolean => val === 'true';

    return {
      id: item.id,
      className: data.JsName || '',
      docName: data.DocName || '',

      tableType: data.Type || '',

      basedOnCard: boolFromString(data.BasedOnCard),
      legalRates: boolFromString(data.LegalRates),
      apiTable: boolFromString(data.ApiTable),
      singleton: boolFromString(data.Singleton),

      tableName: data.Name || '',

      region: '',
      deleteCode: 0,
      deleteDescription: '',

      jsCalc: '',
      dynamicURL: '',
      fields: [],
      layouts: [],

      contentApp: null,
      contentAppId: '',
    };
  }

  getTables(): Observable<ITableSchema[]> {
    const url = `${this.leapDesignUrl}/athena/list/Table`;

    return this.httpClient.post<any[]>(url, [], {}).pipe(
      map((response: any[]) => {
        if (!Array.isArray(response)) {
          return [];
        }
        const allItems = response.flatMap((r) => r.items || []);
        return allItems.map(this.mapLeapDesignToITableSchema);
      }),
    );
  }

  getStates(): Observable<string[]> {
    if (this._brandSvc.isTitleX) {
      return of(['NSW', 'VIC']);
    }

    if (this._brandSvc.isNZ()) {
      return of(['FED']);
    }

    const url = `${this.leapDesignUrl}/getStates`;

    return this.httpClient.post<StateItem[]>(url, []).pipe(
      map((stateItems) => {
        const filtered = stateItems.filter((item) => item.code !== 'ALL' && item.display);
        const codes = filtered.map((item) => item.code).sort((a, b) => a.localeCompare(b));

        return codes;
      }),
    );
  }

  getTableContent(tableId: string): Observable<ILeapDesignTableContent> {
    return this.httpClient.get<ILeapDesignTableContent>(`${this.leapDesignUrl}/tables/content/${tableId}`);
  }
}
