import { createAction, props } from '@ngrx/store';
import { IEmailAddress } from '@app/features/+email/models';
import { AccessType } from '@app/features/+document-share/models/models';

export const ShareViaOutlookStart = createAction(
  '[document share] share via outlook start',
  props<{ docs: string[]; accessType: AccessType }>()
);

export const ShareViaOutlookSuccess = createAction(
  '[document share] share via outlook success',
  props<{ ticketId: string }>()
);

export const ShareViaOutlookFailure = createAction(
  '[document share] share via outlook failure',
  props<{ error: any }>()
);

export const StoreEmailRecipients = createAction(
  '[document share] store recipients list from email',
  props<{ recipients: IEmailAddress[] }>()
);

export const SetShareLinkEmailContent = createAction(
  '[document share] email body with icon and share link',
  props<{ body: any }>()
);
