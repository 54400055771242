import { Action } from '@ngrx/store';
import { ITrustLedgerResponse } from '@app/features/+trust/models';
import { IBankAccount } from '@app/features/accounting/models';

export const LIST_TRUST_FUNDS_START = '[Trust funds List from api] start';
export const LIST_TRUST_FUNDS_SUCCESS = '[Trust funds List from api] success';
export const LIST_TRUST_FUNDS_FAILURE = '[Trust funds List from api] failure';

export const GET_TRUST_BANK_ACCOUNTS_START = '[Trust funds] get bank accounts start';
export const GET_TRUST_BANK_ACCOUNTS_SUCCESS = '[Trust funds] get bank accounts success';
export const GET_TRUST_BANK_ACCOUNTS_FAILURE = '[Trust funds] get bank accounts failure';

export const NEW_TRUST_RECEIPT = '[Trust funds] open new trust receipt modal page';

export const SELECT_TRUST_BANK_ACCOUNT = '[Trust funds] select bank account for filter';

export const PRINT_TRUST_REPORT = '[Trust funds] print report';
export const CLEAR = '[Trust funds] clear store';

export class ListTrustFundsStart implements Action {
  readonly type = LIST_TRUST_FUNDS_START;

  constructor(public payload: string) {}
}

export class ListTrustFundsSuccess implements Action {
  readonly type = LIST_TRUST_FUNDS_SUCCESS;

  constructor(public payload: ITrustLedgerResponse) {}
}

export class ListTrustFundsFailure implements Action {
  readonly type = LIST_TRUST_FUNDS_FAILURE;

  constructor(public payload: any) {}
}

export class GetTrustBankAccountsStart implements Action {
  readonly type = GET_TRUST_BANK_ACCOUNTS_START;

  constructor(public payload: any) {}
}

export class GetTrustBankAccountsSuccess implements Action {
  readonly type = GET_TRUST_BANK_ACCOUNTS_SUCCESS;

  constructor(public payload: IBankAccount[]) {}
}

export class GetTrustBankAccountsFailure implements Action {
  readonly type = GET_TRUST_BANK_ACCOUNTS_FAILURE;

  constructor(public payload: any) {}
}

export class SelectTrustBankAccount implements Action {
  readonly type = SELECT_TRUST_BANK_ACCOUNT;

  constructor(public payload: string) {}
}

export class PrintTrustReport implements Action {
  readonly type = PRINT_TRUST_REPORT;

  constructor(public payload: string) {}
}

export class NewTrustReceipt implements Action {
  readonly type = NEW_TRUST_RECEIPT;

  constructor(public payload: any) {}
}

export class ClearStore implements Action {
  readonly type = CLEAR;

  constructor(public payload: any) {}
}

export type TrustLedgerActions =
  | ListTrustFundsStart
  | ListTrustFundsSuccess
  | ListTrustFundsFailure
  | GetTrustBankAccountsStart
  | GetTrustBankAccountsSuccess
  | GetTrustBankAccountsFailure
  | SelectTrustBankAccount
  | NewTrustReceipt
  | PrintTrustReport
  | ClearStore;
