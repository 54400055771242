import { ILeapDesignTableContent } from '../../../shared/models';
import * as actions from '../actions/table-content.actions';

export interface TableContent {
  [name: string]: ILeapDesignTableContent;
}

export const INITIAL_STATE: TableContent = {};

export const reducer = (state: TableContent = {}, action: actions.TableContentAction) => {
  switch (action.type) {
    case actions.TableContentActions.LOAD_TABLE_CONTENT_SUCCESS:
      return {
        ...state,
        [action.payload.tableContent.name]: action.payload.tableContent,
      };
    default:
      return state;
  }
};
