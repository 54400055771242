import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IPaymentTypes } from 'app/features/+payments-debtors/models/payment-debtors.models';
import { FormGroup } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { distinctUntilChanged, take, tap, switchMap, filter as rxjsFilter, takeUntil } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { AppApiService } from '@app/core/api';
import * as inflowActions from '@app/features/+payment-type-details/store/actions/inflow-payment-type-details.actions';
import { selectOnCloseResponse } from '@app/features/+payment-type-details/store/selectors/payment-type-details.selector';
import { IPaymentDetailsTypeInit } from '@app/features/+payment-type-details/models';
import { isEmptyObj, isObjEqual } from '@server/modules/shared/functions/common-util.functions';
import { getPaymentSubTypeIdFunc } from '../../functions/accounting-common.function';
import { BrandService } from '@app/core/services';

@Component({
  selector: 'sc-inflow-payment-type',
  templateUrl: './inflow-payment-type.component.html',
})
export class InflowPaymentTypeComponent implements OnInit, OnDestroy {
  isDisabled = false;
  modalCloseSub: Subscription;
  paymentTypeSub: Subscription;
  private _paymentTypeForm: FormGroup;
  private _paymentTypeDetailModalSub: Subscription;
  private unsub = new Subject<void>();

  get paymentTypeId(): number {
    const paymentType = this._paymentTypeForm.controls.paymentType.value;
    return this._paymentTypeForm && paymentType && paymentType.PaymentTypeID !== undefined
      ? paymentType.PaymentTypeID
      : undefined;
  }

  get paymentSubTypeId(): number {
    const paymentType = this._paymentTypeForm?.controls.paymentType.value;
    return getPaymentSubTypeIdFunc(paymentType);
  }

  get paymentTypeDetails(): any {
    const paymentTypeDetails = this._paymentTypeForm.controls.paymentTypeDetails.value;
    return this._paymentTypeForm && paymentTypeDetails ? paymentTypeDetails : undefined;
  }

  get paymentType(): IPaymentTypes {
    const paymentType = this._paymentTypeForm.controls.paymentType.value;
    return this._paymentTypeForm && paymentType ? paymentType : undefined;
  }

  @Input()
  validationErrors: boolean;

  @Input()
  set paymentTypeForm(form: FormGroup) {
    if (form) {
      this._paymentTypeForm = form;
      this.subscribetoPaymentType();
    }
  }
  get paymentTypeForm() {
    return this._paymentTypeForm;
  }

  private _paymentTypeList: IPaymentTypes[];

  @Input()
  set paymentTypeList(value: IPaymentTypes[]) {
    this._paymentTypeList = value;
    
    if (this._brandSvc.isNZ()) {
      this._paymentTypeList = this._paymentTypeList
      .filter(type => type?.NameFull !== 'International' && type?.NameFull !== 'Bank Cheque')
      .map(type => {
        if (type?.NameFull === 'EFT') {
          return { ...type, NameFull: 'BECS' };
        }
        return type;
      });
    }
  }

  get paymentTypeList(): IPaymentTypes[] {
    return this._paymentTypeList;
  }

  @Input() 
  enforcePaymentTypeDetails: boolean = true;

  constructor(private _appApiSvc: AppApiService, private _store: Store<any>, private _brandSvc: BrandService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  showPaymentTypeDetailsModal(selected: IPaymentTypes) {
    if (!isEmptyObj(selected)) {
      this._paymentTypeForm.patchValue({
        paymentType: selected || {},
      });
      this.showPaymentModal();
    }
  }

  subscribetoPaymentType() {
    const paymentTypeControl = this._paymentTypeForm.controls.paymentType;
    this.paymentTypeSub = paymentTypeControl.valueChanges
      .pipe(distinctUntilChanged(isObjEqual), takeUntil(this.unsub))
      .subscribe((paymentType: IPaymentTypes) => {
        if (paymentType !== undefined && this._paymentTypeForm.dirty && this.enforcePaymentTypeDetails) {
          this.showPaymentTypeDetailsModal(paymentType);
        }
      });
  }

  showPaymentModal() {
    if (!!this._paymentTypeDetailModalSub) {
      this._paymentTypeDetailModalSub.unsubscribe();
    }

    const init: IPaymentDetailsTypeInit = {
      paymentDetails: this._paymentTypeForm.controls.paymentTypeDetails.value,
      paymentType: this.paymentType,
      paymentTypeId: this.paymentTypeId,
      paymentSubTypeId: this.paymentSubTypeId,
    };

    this._store.dispatch(new inflowActions.InflowPaymentTypePreInit(init));
    this._paymentTypeDetailModalSub = this._store
      .pipe(
        take(1),
        tap(() => {
          this._appApiSvc.navigate({
            path: [{ outlets: { selector: ['payment-type-details'] } }],
            extras: { queryParamsHandling: 'merge', skipLocationChange: true },
          });
        }),
        switchMap(() => this._store.pipe(
          select(selectOnCloseResponse),
          rxjsFilter((response) => response !== null),
          take(1),
          tap((response) => {
            this._paymentTypeForm.patchValue({
              paymentTypeDetails: response || {},
            });
          })
        )),
        takeUntil(this.unsub)
      )
      .subscribe();
  }
}
