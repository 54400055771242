import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sc-modal-body',
  templateUrl: './modal-body.component.html',
})
export class ModalBodyComponent implements OnInit, OnDestroy {

  @Output()
  onModalBodyDestroyed = new EventEmitter<void>();


  constructor() {}
  @HostBinding('class.modal-body')
  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.onModalBodyDestroyed) {
      this.onModalBodyDestroyed.emit();
    }
  }
}
