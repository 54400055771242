import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalcEngineService } from './services/calc-engine.service';
import { CalcEngineCacheService } from './services/calc-engine-cache.service';
@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [CalcEngineService, CalcEngineCacheService],
})
export class CalcEngineModule {}
