import { ExtraProperty } from '@leapdev/leap-host/dist/api';
import * as actions from '../actions';
import { DocumentRecipient } from '@app/features/+document/models';

export type NewDocumentPayload = {
  body?: string;
  extra?: ExtraProperty;
} | null;

export interface State {
  error: any | string | null;
  loading: boolean;
  laddaLoading: boolean;
  selectedRecipient: DocumentRecipient;
  newDocumentPayload?: NewDocumentPayload;
}

export const initialState: State = {
  error: null,
  loading: true,
  laddaLoading: false,
  selectedRecipient: undefined,
};

export const reducers = (state = initialState, action: actions.DocumentActions): State => {
  switch (action.type) {
    case actions.NEW_DOCUMENT: {
      return {
        ...state,
        newDocumentPayload: action.payload,
        loading: true,
      };
    }

    case actions.NEW_DOCUMENT_CREATE:
      return {
        ...state,
        laddaLoading: true,
        selectedRecipient: action.payload,
      };

    case actions.NEW_DOCUMENT_CREATE_SUCCESS:
    case actions.EDIT_PRECEDENT_SUCCESS:
      return {
        ...state,
        newDocumentPayload: undefined,
        laddaLoading: false,
      };

    case actions.NEW_DOCUMENT_FAILURE:
    case actions.NEW_DOCUMENT_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        laddaLoading: false,
        newDocumentPayload: undefined,
        error: action.payload,
      };

    default: {
      return state;
    }
  }
};

export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;
export const selectLaddaLoading = (state: State) => state.laddaLoading;
export const selectRecipient = (state: State) => state.selectedRecipient;
export const selectNewDocumentPayload = (state: State) => state.newDocumentPayload;
