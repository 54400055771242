import { createFeatureSelector } from '@ngrx/store';

import { AppSlice } from '@app/core/store';
import * as fromTrustLedger from './trust-ledger.reducer';

export type State = fromTrustLedger.State;

export const initialState: State = fromTrustLedger.INITIAL_STATE;

export const reducers = fromTrustLedger.reducer;

export const selectTrustLedgerState = createFeatureSelector<State>(AppSlice.TrustLedger);
