import { createAction, props } from '@ngrx/store';
import {
  DocumentShareRecipient,
  MatterCardEmailModel,
  SetUserAccessResponse,
  ShareDocumentUIModel,
  RevokeAccessResponse,
  LCIntegratorTicketResponse,
  DocAPIDocsResponse,
  ShareViaOutlookResponse,
} from '@app/features/+document-share/models/models';
import { IDoc, IDocAttachment } from '@app/shared/models';
import { ELawConnectPathOptions, ILawConnectOfficeReceiptShare, ILawConnectTrustReceiptShare } from '@app/features/+lawconnect-share/models/models';
import { EAccountingTransaction } from '@app/features/accounting/models';

export const InitDocumentShareByTicketIdStart = createAction(
  '[document share] init document share by ticket id',
  props<{ ticketId: string }>()
);

export const InitDocumentShareByTicketIdSuccess = createAction(
  '[document share] init document share by ticket id success',
  props<{ data: LCIntegratorTicketResponse }>()
);

export const InitDocumentShareByTicketIdFailure = createAction(
  '[document share] init document share by ticket id failure',
  props<{ error: any }>()
);

export const GetDocumentsSuccess = createAction(
  '[document share] get documents success',
  props<{ data: DocAPIDocsResponse[] }>()
);

export const GetDocumentsFailure = createAction('[document share] get documents failure', props<{ error: any }>());

export const GetV2MatterEmailStart = createAction(
  '[document share] v2 matter emails start',
  props<{ matterId: string }>()
);

export const GetV2MatterEmailSuccess = createAction(
  '[document share] v2 matter emails success',
  props<{ emails: MatterCardEmailModel[] }>()
);

export const GetV2MatterEmailFailure = createAction(
  '[document share] v2 matter emails failure',
  props<{ error: any }>()
);

export const InitDocumentShare = createAction('[document share] init document sharing');

export const InitLawConnectSharingScreen = createAction('[document share] init lawconnect sharing screen', props<{ path: ELawConnectPathOptions }>());

export const GetAllowedExtensionsStart = createAction('[document share] get allowed document extensions start');

export const GetAllowedExtensionsSuccess = createAction(
  '[document share] get allowed document extensions success',
  props<{ extensions: string[] }>()
);

export const GetAllowedExtensionsFailure = createAction(
  '[document share] get allowed document extensions failure',
  props<{ error: any }>()
);

export const GetDocumentUserAccessStart = createAction(
  '[document share] get user access per document start',
  props<{ docs: IDoc[] }>()
);

export const GetDocumentUserAccessSuccess = createAction(
  '[document share] get user access per document success',
  props<{ results: any }>()
);

export const GetDocumentUserAccessFailure = createAction(
  '[document share] get user access per document failure',
  props<{ error: any }>()
);

export const LawConnectShareStart = createAction(
  '[document share] lawconnect share start',
  props<{ docs: IDoc[]; path: ELawConnectPathOptions }>()
);

export const LawConnectAttachmentShareStart = createAction(
  '[document share] lawconnect attachment share start',
  props<{ attachment: IDocAttachment; emailId: string; path: ELawConnectPathOptions }>()
);

export const UpdateRevokeUpdateAccessList = createAction(
  '[document share] update revoke and updated permission user list in store',
  props<{ updateAccessRow: DocumentShareRecipient }>()
);

export const RemoveFromSharedDocs = createAction(
  '[document share] remove this doc from shared docs',
  props<{ docId: string }>()
);

export const ManageUserAccessStart = createAction(
  '[document share] manage access on selected document start',
  props<{ formObject?: ShareDocumentUIModel }>()
);

export const ManageUserAccessSuccess = createAction(
  '[document share] manage access on selected document success',
  props<{ count: number; response: [SetUserAccessResponse | ShareViaOutlookResponse, RevokeAccessResponse] }>()
);

export const ManageUserAccessFailure = createAction(
  '[document share] manage access on selected document failure',
  props<{ error: any }>()
);

export const ClearEmailRecipients = createAction('[document share] clear email recipients');

export const ClearDocumentShareState = createAction('[document share] clear document share state');

export const ClearDocumentShareProcessingState = createAction(
  '[document share] clear error, loading and success state'
);

export const ClearOutlookTicketId = createAction('[document share] clear outlook ticket id');
