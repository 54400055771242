<header class="navbar x-navbar-primary">
  <ng-template [ngxPermissionsExcept]="[permissionEnum.LawConnectUser]">
    <nav class="navbar-nav">
      <li class="nav-item">
        <a href (click)="appMenu()" class="nav-link x-nav-link-icon" scPreventDefault>
          <sc-icon iconName="grid-grid-20"></sc-icon>
        </a>
      </li>
    </nav>
    <a class="navbar-brand" routerLink="/"> <img class="x-navbar-logo" [src]="brandLogo" [alt]="brand" /> </a>
  </ng-template>

  <ng-template [ngxPermissionsOnly]="[permissionEnum.LawConnectUser]">
    <a class="navbar-brand"> <img class="x-navbar-logo" [src]="brandLogo" [alt]="brand" /> </a>
  </ng-template>
  <h4 class="x-main-heading">
    <span class="text-truncate">{{ mainTitle | translate }}</span>
  </h4>

  <nav class="navbar-nav">
    <!-- Start - Timesheets -->
    <li class="nav-item" [tooltip]="'Global.PrimaryNavbar.TimesheetTooltip' | translate" [placement]="'bottom'" [container]="'body'" (click)="onClickTimesheet()" *ngIf="timeSheetMenuAvailable()">
      <a href scPreventDefault class="nav-link x-nav-link-icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="x-icon">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13 13V18H18V13H13ZM11.5 11C11.2239 11 11 11.2239 11 11.5V19.5C11 19.7761 11.2239 20 11.5 20H19.5C19.7761 20 20 19.7761 20 19.5V11.5C20 11.2239 19.7761 11 19.5 11H11.5Z" fill="#fff"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17 14H14V15H17V14Z" fill="#fff"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17 16H14V17H17V16Z" fill="#fff"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6 10H10V11.5072L6.83269 13.3358L6.03269 11.9502L9 10.237V5H10.6V10ZM10 1C14.9706 1 19 5.02944 19 10H17C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17V19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1Z" fill="#fff"/>
        </svg>
      </a>
    </li>
    <!-- End - Timesheets -->
    <!-- Notifications Bell -->
    <ng-template [ngxPermissionsExcept]="[]">
      <li
        class="nav-item"
        [tooltip]="'Global.PrimaryNavbar.NotificationsTooltip' | translate"
        container="body"
        [placement]="'bottom'"
        [container]="'body'"
        (click)="notifications()"
      >
        <a href scPreventDefault class="nav-link">
          <sc-notification-icon [count]="notificationTotal$ | async"></sc-notification-icon>
        </a>
      </li>
    </ng-template>
    <!-- End Notifications Bell -->
    <!-- Feedback -->
    <ng-template [ngxPermissionsExcept]="[permissionEnum.LawConnectUser]">
      <li class="nav-item" [tooltip]="'Global.PrimaryNavbar.FeedbackTooltip' | translate" [placement]="'bottom'" [container]="'body'" (click)="feedback()">
        <a href scPreventDefault class="nav-link x-nav-link-icon">
          <sc-icon iconName="feedback-grid-20"></sc-icon>
        </a>
      </li>
    </ng-template>
    <!-- End - Feedback -->
    <!-- Support drop down -->
    <ng-template [ngxPermissionsExcept]="[permissionEnum.LawConnectUser]">
      <li class="nav-item" dropdown container="body" placement="bottom right">
        <a href class="nav-link dropdown-toggle x-nav-link-icon x-icon-no-hover" scPreventDefault dropdownToggle>
          <sc-icon iconName="support-grid-20"></sc-icon>
        </a>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" [@xAnimation]>
          <li class="nav-item" role="menuitem">
            <a href scPreventDefault class="dropdown-item" (click)="supportCommunity()">{{
              'Support.Support.Community' | translate
            }}</a>
          </li>
          <!-- <li class="nav-item" role="menuitem">
            <a href scPreventDefault class="dropdown-item" (click)="statusAlert()">{{
              'Support.Status.Alerts' | translate
            }}</a>
          </li> -->
          <li class="nav-item" role="menuitem" *ngIf="walkmeReady">
            <a href scPreventDefault class="dropdown-item" (click)="walkMe()">{{ 'Support.Walk.Me' | translate }}</a>
          </li>
          <li class="dropdown-divider" *ngIf="isLiveChatEnabled$ | async"></li>
          <li class="nav-item" role="menuitem" *ngIf="isLiveChatEnabled$ | async">
            <a href scPreventDefault class="dropdown-item" (click)="liveChat()">{{
              'Support.Live.Chat' | translate
            }}</a>
          </li>
        </ul>
      </li>
    </ng-template>
    <!-- End - Support drop down -->
    <!-- MAR Auto Record Billable Toggle -->
    <!--
     >> [LS-5831] We are hiding this currently as its broken and not working << 

    <sc-autotime-header
      [permissionsExcept]="[permissionEnum.FeeRead]"
    ></sc-autotime-header>
    -->
    <ng-template [ngxPermissionsExcept]="[permissionEnum.FeeRead]">
      <li
        class="nav-item"
        dropdown
        #dropdown="bs-dropdown"
        container="body"
        placement="bottom right"
        (isOpenChange)="onTimerOpenChange($event)"
        *ngIf="feeTimerClockHidden$ | async"
        [autoClose]="false"
        (clickOutside)="insideTimer === false ? dropdown.hide() : null"
      >
        <a
          href
          class="nav-link dropdown-toggle x-timer-toggle"
          scPreventDefault
          dropdownToggle
          [class.x-timer-active]="timerRunning$ | async"
        >
          <sc-time-clock></sc-time-clock>
        </a>
        <ul
          *dropdownMenu
          class="x-timer-dropdown-menu dropdown-menu dropdown-menu-right"
          [@xAnimation]="timerMenuOpened"
          (mouseover)="timerMouseover($event)"
          (mouseout)="timerMouseout($event)"
        >
          <sc-time-activity-layout (closeDropdown)="dropdown.hide()"></sc-time-activity-layout>
        </ul>
      </li>
    </ng-template>
    <ng-template [ngxPermissionsExcept]="[permissionEnum.LawConnectUser]">
      <li class="nav-item" dropdown container="body" placement="bottom right">
        <a
          href
          class="nav-link dropdown-toggle"
          scPreventDefault
          dropdownToggle
          #navDropdownToggle
          angulartics2On
          [angularticsCategory]="getGlobalAnalyticsCategory"
          angularticsAction="Open settings"
        >
          <div class="x-nav-user-info">
            <span>
              <strong>{{ firstname }}</strong
              >&nbsp;{{ surname }}&nbsp;
            </span>
            <small>{{ email }}</small>
          </div>
        </a>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" [@xAnimation]="dropdownMenuOpened">
          <!-- <li class="dropdown-divider"></li> -->
          <li role="menuitem"><a href class="dropdown-item" scPreventDefault (click)="about()">About</a></li>
          <li role="menuitem"><a href class="dropdown-item" scPreventDefault (click)="profile()">Profile</a></li>
          <li role="menuitem">
            <a
              href
              class="dropdown-item"
              scPreventDefault
              (click)="logout()"
              angulartics2On
              [angularticsCategory]="getGlobalSignoutAnalyticsCategory"
              angularticsAction="Signout from dropdown"
              >Sign out</a
            >
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a
          href
          class="nav-link x-nav-link-icon"
          scPreventDefault
          (click)="logout()"
          angulartics2On
          [angularticsCategory]="getGlobalSignoutAnalyticsCategory"
          angularticsAction="Signout from button"
        >
          <sc-icon iconName="power-grid-20"></sc-icon>
        </a>
      </li>
    </ng-template>
    <ng-template [ngxPermissionsOnly]="[permissionEnum.LawConnectUser]">
      <span class="navbar-text d-flex align-items-center text-white mr-3">
        <strong>{{ firstname }}</strong
        >&nbsp;{{ surname }}&nbsp;
      </span>
      <li class="nav-item invisible"><span class="nav-link p-0"></span></li>
    </ng-template>
  </nav>
</header>
