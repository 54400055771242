import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map as rxjsMap } from 'rxjs/operators';

import { BaseService } from '@app/shared/services/base/base.service';
import { IMatterType } from '@app/shared/models';
import { getDisplayMatterType } from '@app/features/+matter-types/functions';

@Injectable()
/**
 * @deprecated use CalcEngineService instead
 */
export class CalcService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  getCustomDescription(matterType: IMatterType, matterId: string, state?: string): Observable<string> {
    if (!matterId || !matterType || !matterType.customDescription) {
      return of('');
    }

    const displayMatterType = getDisplayMatterType(matterType, state);
    const { fieldId, tableId } = matterType.customDescription;
    if (!fieldId || !tableId) {
      return of(displayMatterType);
    }
    const queryUrl = `fieldId=${fieldId}&tableId=${tableId}&matterId=${matterId}`;
    const url = `${this.siriusPath}/api/calc/matterCustomDescription?${queryUrl}`;
    return this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(rxjsMap((customDesc) => customDesc || displayMatterType));
  }

  /**
   * Call Sirius leap calc service for card fields. Used in card details letter form
   * */
  queryCardCustomDescription(cardFields: string[], cardId?: string, cardDetails?: any): Observable<any> {
    if ((!cardId && !cardDetails) || !cardFields) {
      return of('');
    }

    const url = `${this.siriusPath}/api/calc/cardCustomDescription`;
    const body = {
      cardId,
      cardDetails,
      path: cardFields,
    };
    return this.httpClient.post<any>(url, body).pipe(rxjsMap((customDesc) => customDesc || ''));
  }

  evaluatePaths(calcRequest: { executionContext: any; paths: string[] }) {
    return this.httpClient.post<any>(`${this.siriusPath}/api/calc/evaluateCalcPaths`, calcRequest);
  }
}
