import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AnalyticsCategories } from '@app/core/constants/analytics.constant';
import { AppApiService } from '@app/core/api';

@Component({
  selector: 'sc-modal-header',
  templateUrl: './modal-header.component.html',
})
export class ModalHeaderComponent implements OnInit {
  useKeyTab = false;
  @Input() noBorder = false;
  @Input() hideCloseBtn = false;
  @Input() hideNewWindowBtn = true;
  @Input() hideOutlookBtn = true;
  @Input() disableCloseBtn: boolean;
  @Input() showRecurringMattersCreate = false;
  @Input() analyticsCategory: string;
  @Output() modalDismiss = new EventEmitter<boolean>();
  @Output() onOpenPopout = new EventEmitter<boolean>();
  @Output() onOpenOutlook = new EventEmitter<boolean>();

  get getDesktopIntegrationAnalyticsCategory(): string {
    return AnalyticsCategories.SuperDiaryDesktopIntegration;
  }

  constructor(private _appApiSvc: AppApiService) {}

  ngOnInit() {}

  closeModal(event: any) {
    this.modalDismiss.emit(event);
  }

  openPopout(event: any) {
    this.onOpenPopout.emit(event);
  }

  openOutlook(event: any) {
    this.onOpenOutlook.emit(event);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.useKeyTab = true;
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.useKeyTab = false;
  }

  showRecurringMatters() {
    this._appApiSvc.recurringMattersList({
      readonly: true,
    });
  }
}
