import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as FileSaver from 'file-saver';
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../core/services";
import { ICardDocument } from "../../../shared/models";
import { BaseService } from "../../../shared/services";

@Injectable()
export class CardDocumentsService extends BaseService {
  constructor(
    private _http: HttpClient,
    private _authSvc: AuthService,
    private _toastrSvc: ToastrService
  ) {
    super();
  }

  private async _getHeaders() {
    const token = await this._authSvc.getToken();
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    })
  }

  public async download(document: ICardDocument) {
    const { type, name } = document
    const isPDF = document.type === 'pdf';

    const headers = await this._getHeaders()
    const url = `${this.apiPath}/api/v1/docfiles/${document.id}.${document.type}${isPDF ? '?format=pdf' : ''}`;
    const fileName = `${name}.${type}`

    this._http.get(url, { headers, responseType: 'blob' }).subscribe({
      next: (data) => {
        FileSaver.saveAs(data, fileName);
        this._toastrSvc.show('Document downloaded.', 'Success', {}, 'success');
      },
      error: (err) => {
        this._toastrSvc.show('Error downloading document.', 'Error', {}, 'error');
      }
    })
  }

  public async delete(cardId: string, document: ICardDocument) {
    const headers = await this._getHeaders()
    const url = `${this.apiPath}/api/v1/cards/${cardId}/document/${document.id}`;
    this._http.delete(url, { headers }).subscribe({
      next: () => {
        this._toastrSvc.show('Document deleted.', 'Success', {}, 'success');
      },
      error: () => {
        this._toastrSvc.show('Error deleting document.', 'Error', {}, 'error');
      }
    })
  }
}
