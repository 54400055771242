import { inject, Injectable } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import type {
  IExecutionContext,
  ICreateSessionResponse,
  IPathResult,
  IRequestMetadata,
  IFieldResult,
  IFieldDefinition,
  MAIN_OBJECT_TYPES,
  IObjectResult,
} from '@leap/calc-core/types';

import { environment } from '@env/environment';

import { AuthService } from '@app/core/services';
import { CalcEngineCacheService } from './calc-engine-cache.service';

// We remove documentId from the execution context because we cannot fetch it early, use documentGUID instead
type ICalcExecutionContext = Omit<IExecutionContext, 'documentId'>;

@Injectable()
export class CalcEngineService {
  private _authService: AuthService = inject(AuthService);

  private worker: Worker | undefined;

  constructor(private _calcEngineCacheSvc: CalcEngineCacheService) {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('../workers/calc.worker', import.meta.url), { type: 'module' });
      this.setupWorker();
    } else {
      console.error('Web Workers are not supported in this environment.');
    }
  }

  private async setupWorker() {
    await this._calcEngineCacheSvc.init();

    const legalRatesJSON = await this.fetchLegalRatesJSON();
    const schemaDataJSON = await this.fetchSchemaDataJSON();

    this.worker?.postMessage({
      type: 'calcSetup',
      data: {
        legalRatesJSON,
        schemaDataJSON,
        region: environment.config.brand.region.toLowerCase(),
        environment: environment.config.brand.env.toLowerCase(),
        brand: environment.config.brand.leapcalc.toLowerCase(),
      },
    });
  }

  public isWebWorkerSupported(): boolean {
    return this.worker !== undefined;
  }

  private sendRequestWaitForResonse<T>(type: string, data: unknown): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.worker) {
        reject(new Error('Web Worker is not supported in this environment.'));
        return;
      }

      const requestId = uuidv4();

      this.worker?.postMessage({
        requestId,
        type,
        data,
      });

      const waitForResponse = (event: MessageEvent) => {
        if (event.data.requestId === requestId) {
          this.worker?.removeEventListener('message', waitForResponse);
          resolve(event.data.response);
        }
      };

      this.worker?.addEventListener('message', waitForResponse);
    });
  }

  async clearCache(cacheOptions: {
    firmId?: string;
    matterId?: string;
    cardId?: string;
    correspondenceMatterId?: string;
  }) {
    console.debug('CalcEngineService.clearCache', cacheOptions);
    if (cacheOptions.firmId) {
      await this._calcEngineCacheSvc.clearAllWithPrefix(`firm:${cacheOptions.firmId}`);
    }

    if (cacheOptions.matterId) {
      await this._calcEngineCacheSvc.clearAllWithPrefix(`matter:${cacheOptions.matterId}`);
    }

    if (cacheOptions.cardId) {
      await this._calcEngineCacheSvc.clearAllWithPrefix(`card:${cacheOptions.cardId}`);
    }

    return await this.sendRequestWaitForResonse('clearCache', cacheOptions);
  }

  async reload() {
    return await this.sendRequestWaitForResonse('reload', {});
  }

  async createSession(executionContext: ICalcExecutionContext): Promise<ICreateSessionResponse> {
    return await this.sendRequestWaitForResonse('createSession', {
      executionContext: await this.populateExecutionContext(executionContext),
    });
  }

  async destroySession(sessionId: string) {
    return await this.sendRequestWaitForResonse('destroySession', {
      sessionId,
    });
  }

  async sessionSetDefaultFile(sessionId: string, jsPath: string) {
    return await this.sendRequestWaitForResonse('sessionSetDefaultFile', {
      sessionId,
      jsPath,
    });
  }

  async sessionGetObjectById(sessionId: string, objectId: string) {
    return await this.sendRequestWaitForResonse<IObjectResult>('sessionGetObjectById', {
      sessionId,
      objectId,
    });
  }

  async evaluatePath(
    executionContext: ICalcExecutionContext,
    path: string,
    rootObjectId?: string,
    includeDefaults?: boolean,
    isoDateFormat?: boolean,
    params?: { [id: string]: any },
    supportTempObject?: boolean,
  ): Promise<IPathResult> {
    return await this.sendRequestWaitForResonse('evaluatePath', {
      executionContext: await this.populateExecutionContext(executionContext),
      path,
      rootObjectId,
      includeDefaults,
      isoDateFormat,
      params,
      supportTempObject,
    });
  }

  async evaluatePaths(
    executionContext: ICalcExecutionContext,
    paths: string[],
    rootObjectId?: string,
    includeDefaults?: boolean,
    isoDateFormat?: boolean,
    params?: { [id: string]: any },
    supportTempObject?: boolean,
    requestMetadata?: IRequestMetadata,
  ): Promise<IPathResult[]> {
    return await this.sendRequestWaitForResonse('evaluatePaths', {
      executionContext: await this.populateExecutionContext(executionContext),
      paths,
      rootObjectId,
      includeDefaults,
      isoDateFormat,
      params,
      supportTempObject,
      requestMetadata,
    });
  }

  async evaluateField(
    executionContext: ICalcExecutionContext,
    fieldRef: IFieldDefinition,
    includeFormatting = false,
    includeDefaults = true,
    isoDateFormat = false,
    params: { [id: string]: any },
  ): Promise<IFieldResult> {
    return await this.sendRequestWaitForResonse('evaluateField', {
      executionContext: await this.populateExecutionContext(executionContext),
      fieldRef,
      includeFormatting,
      includeDefaults,
      isoDateFormat,
      params,
    });
  }

  async evaluateFields(
    executionContext: ICalcExecutionContext,
    fields: IFieldDefinition[],
    includeFormatting?: boolean,
    includeDefaults?: boolean,
    isoDateFormat?: boolean,
    params?: { [id: string]: any },
  ) {
    return await this.sendRequestWaitForResonse('evaluateFields', {
      executionContext: await this.populateExecutionContext(executionContext),
      fields,
      includeFormatting,
      includeDefaults,
      isoDateFormat,
      params,
    });
  }

  async getExecutionContextObject(sessionId: string, type: MAIN_OBJECT_TYPES) {
    return await this.sendRequestWaitForResonse<any>('getExecutionContextObject', {
      sessionId,
      type,
    });
  }

  // --------------------------------------------------
  // EXECUTION CONTEXT HELPERS
  // --------------------------------------------------

  /**
   * Calc engine runs in a web worker, and because Kasada cannot support a web worker we are going to populate the execution context
   * so that calc does not have to.
   */
  private async populateExecutionContext(executionContext: ICalcExecutionContext) {
    const newExecutionContext = { ...executionContext };

    if (!newExecutionContext.firmGUID) {
      newExecutionContext.firmGUID = (await this._authService.userDetails()).firmId;
    }

    if (newExecutionContext.firmGUID && !newExecutionContext.firmJSON) {
      newExecutionContext.firmJSON = await this.fetchFirmJSON(newExecutionContext.firmGUID);
      delete newExecutionContext.firmGUID;
    }

    if (newExecutionContext.matterGUID && !newExecutionContext.matterJSON) {
      newExecutionContext.matterJSON = await this.fetchMatterJSON(newExecutionContext.matterGUID);
      delete newExecutionContext.matterGUID;
    }

    if (newExecutionContext.cardGUID && !newExecutionContext.cardJSON) {
      newExecutionContext.cardJSON = await this.fetchCardJSON(newExecutionContext.cardGUID);
      delete newExecutionContext.cardGUID;
    }

    if (newExecutionContext.documentGUID && !newExecutionContext.documentJSON) {
      newExecutionContext.documentJSON = await this.fetchDocumentJSON(newExecutionContext.documentGUID);
      delete newExecutionContext.documentGUID;
    }

    console.log('newExecutionContext', newExecutionContext);
    return newExecutionContext;
  }

  private async fetchCardJSON(cardGUID: string) {
    const cachedValue = await this._calcEngineCacheSvc.get(`card:${cardGUID}`);
    if (cachedValue) {
      return cachedValue;
    }

    const url = `${environment.config.endpoint.docs}/api/v1/cards/${cardGUID}`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${await this._authService.getRefreshedToken()}`,
      },
    });

    const result = await response.json();

    await this._calcEngineCacheSvc.set(`card:${cardGUID}`, result, 5 * 60 * 1000);

    return result;
  }

  private async fetchMatterJSON(matterGUID: string) {
    const cachedValue = await this._calcEngineCacheSvc.get(`matter:${matterGUID}`);
    if (cachedValue) {
      return cachedValue;
    }

    const url = `${environment.config.endpoint.docs}/api/v1/matters/${matterGUID}`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${await this._authService.getRefreshedToken()}`,
      },
    });

    const result = await response.json();

    await this._calcEngineCacheSvc.set(`matter:${matterGUID}`, result, 5 * 60 * 1000);

    return result;
  }

  private async fetchFirmJSON(firmGUID: string) {
    const cachedValue = await this._calcEngineCacheSvc.get(`firm:${firmGUID}`);
    if (cachedValue) {
      return cachedValue;
    }
    const url = `${environment.config.endpoint.docs}/api/v1/firms/${firmGUID}`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${await this._authService.getRefreshedToken()}`,
      },
    });

    const result = await response.json();

    await this._calcEngineCacheSvc.set(`firm:${firmGUID}`, result, 5 * 60 * 1000);

    return result;
  }

  private async fetchDocumentJSON(documentGUID: string) {
    const cachedValue = await this._calcEngineCacheSvc.get(`document:${documentGUID}`);
    if (cachedValue) {
      return cachedValue;
    }
    const url = `${environment.config.endpoint.docs}/api/v1/documents/${documentGUID}`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${await this._authService.getRefreshedToken()}`,
      },
    });

    const result = await response.json();

    await this._calcEngineCacheSvc.set(`document:${documentGUID}`, result, 5 * 60 * 1000);

    return result;
  }

  private async fetchLegalRatesJSON() {
    const url = `${environment.config.endpoint.docs}/api/v1/content/legalRates`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${await this._authService.getRefreshedToken()}`,
      },
    });

    return await response.json();
  }

  private async fetchSchemaDataJSON() {
    const url = `${environment.config.endpoint.leapDesign}/content/calcdata`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${await this._authService.getRefreshedToken()}`,
      },
    });

    return await response.json();
  }

  // --------------------------------------------------
  // LEGACY PUBLIC CALC HELPERS
  // --------------------------------------------------

  // TODO: this was copied from sirius server - evaluate usage
  extractCalcResult<ResultArg extends Record<string, any>>(
    result: ResultArg,
    mergeToObject: boolean = false,
  ): any | Error {
    if (!result) {
      return null;
    }

    if (Array.isArray(result)) {
      if (!mergeToObject) {
        // Pick the first one as result
        if (result[0].status === 'ok') {
          return result[0].result;
        } else {
          return new Error(result[0].error);
        }
      } else {
        // Merge the results into a single object
        return this.fullObjMerge(
          ...result.map((resultEntry) => {
            return resultEntry.status === 'ok'
              ? this.setOrCreateObjValue({}, resultEntry['path'], resultEntry['result'])
              : {};
          }),
        );
      }
    } else {
      if (result.status === 'ok') {
        return result.result;
      } else {
        return new Error(result.error);
      }
    }
  }

  // --------------------------------------------------
  // LEGACY CALC HELPERS
  // --------------------------------------------------

  // TODO: this was copied from sirius server - evaluate usage
  private fullObjMerge(...argument: any[]) {
    // enhanced from: https://attacomsian.com/blog/javascript-merge-objects

    // array match obj or non obj
    const arrMerge = (arrA, arrB) => {
      const result = [];
      arrA.forEach((a, idx) => {
        result[idx] = typeof arrA[idx] !== 'object' ? arrB[idx] : this.fullObjMerge(arrA[idx], arrB[idx]);
      });

      return result;
    };

    // create a new object
    const target = {};

    // deep merge the object into the target object
    const merger = (obj: object) => {
      for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
            // if the property is a nested object
            target[prop] = this.fullObjMerge(target[prop], obj[prop]);
          } else {
            // for regular property
            if (Array.isArray(obj[prop])) {
              if (target[prop] === undefined) {
                target[prop] = obj[prop];
              } else {
                if (target[prop].length > obj[prop].length) {
                  target[prop] = [
                    ...arrMerge(obj[prop], target[prop].slice(0, obj[prop].length)),
                    ...target[prop].slice(obj[prop].length),
                  ];
                } else {
                  target[prop] = obj[prop];
                }
              }
            } else {
              target[prop] = obj[prop];
            }
          }
        }
      }
    };

    // iterate through all objects and
    // deep merge them with target
    argument?.forEach((ar, idx) => {
      merger(argument[idx]);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return target as any;
  }

  // TODO: this was copied from sirius server - evaluate usage
  private setOrCreateObjValue(object, path, value) {
    // refer to https://stackoverflow.com/questions/54733539/javascript-implementation-of-lodash-set-method
    // When obj is not an object
    if (typeof object !== 'object') {
      return object;
    }

    // If not yet an array, get the keys from the string-path
    if (!Array.isArray(path)) {
      path = path.toString().match(/[^.[\]]+/g) || [];
    }

    path.slice(0, -1).reduce(
      (
        a,
        c,
        i, // Iterate all of them except the last one
      ) =>
        Object(a[c]) === a[c] // Does the key exist and is its value an object?
          ? // Yes: then follow that path
            a[c]
          : // No: create the key. Is the next key a potential array-index?
            (a[c] =
              Math.abs(path[i + 1]) > 0 && Math.abs(path[i + 1]) === +path[i + 1]
                ? [] // Yes: assign a new array object
                : {}), // No: assign a new plain object
      object,
    )[path[path.length - 1]] = value; // Finally assign the value to the last key

    return object; // Return the top-level object to allow chaining
  }
}
