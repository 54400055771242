import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@app/core/services/feature-flag/feature-flag.service';
import { CardListV2Service } from './card-list-v2.service';
import { CardListService } from './card-list.service';
import { Observable, map } from 'rxjs';
import {
  AddressDocsCore,
  AddressType,
  CardRoleInMatter,
  IAddress,
  ICardListEntry,
  ICardListResponseSchema,
  ICardRoles,
  ListCardDocsCore,
  SyncDocsCore,
} from '../models';

const FLAG_USE_NEW_DOCS_CORE_API_SERVICE = 'can-use-docs-core-api';
@Injectable({
  providedIn: 'root',
})
export class CardListAdapterService {
  constructor(
    private _v1Service: CardListService,
    private _v2Service: CardListV2Service,
    private _featureFlagSvc: FeatureFlagService,
  ) {}

  private useNewDocsCoreAAPIService = this._featureFlagSvc.isFeatureEnabled(FLAG_USE_NEW_DOCS_CORE_API_SERVICE);

  public getAll(lastRowVer = 0, shardId: string = 'myShardId'): Observable<ICardListResponseSchema> {
    if (!this.useNewDocsCoreAAPIService) {
      return this._v1Service.getAll(lastRowVer);
    } else {
      return this._v2Service
        .getAll(lastRowVer, shardId)
        .pipe(map((res: SyncDocsCore) => this.transformV2ToCardList(res)));
    }
  }

  /**
   * Transform v2 => ICardListResponseSchema
   */
  private transformV2ToCardList(syncDocsCore: SyncDocsCore): ICardListResponseSchema {
    return {
      data: syncDocsCore.data.map((item: ListCardDocsCore) => this.mapV2DocToCardEntry(item)),
      lastRowVer: syncDocsCore.lastRowVer,
      total: syncDocsCore.total,
    };
  }

  /**
   * Transform a single v2 doc into an ICardListEntry
   */
  private mapV2DocToCardEntry(item: ListCardDocsCore): ICardListEntry {
    return {
      cardId: item.cardId,
      fullName: item.fullName,
      shortName: item.shortName,
      type: item.type,
      isSupplier: item.isSupplier || false,
      persons: item.persons || [],
      deleteCode: item.deleteCode, // or cast to `number`
      addressList: item.addresses ? item.addresses.map((addr) => this.mapV2AddressToV1Address(addr)) : [],
      roles: this.mapV2RolesToCardRoles(item.roles),
      webAddressList: item.email
        ? [
            {
              __className: 'WebAddressDocsCore',
              type: item.email.type,
              address: item.email.address,
            },
          ]
        : [],
      email: item.email
        ? {
            __className: 'WebAddressDocsCore',
            type: item.email.type,
            address: item.email.address,
          }
        : null,
      phoneNumberList: [],
      phone: null,
      address: item.addresses && item.addresses.length ? this.mapV2AddressToV1Address(item.addresses[0]) : null,
      defaultAddress: '',
      suburb: '',
    };
  }

  private mapV2AddressToV1Address(addr: AddressDocsCore): IAddress {
    return {
      __className: 'AddressDocsCore',
      addressType: addr.addressType as AddressType,
      type: addr.type,
      propertyBuildingName: addr.propertyBuildingName,
      levelUnitShop: addr.levelUnitShop,
      number: addr.number,
      street: addr.street,
      streetDirection: addr.streetDirection,
      suburb: addr.suburb,
      county: addr.county,
      state: addr.state,
      postcode: addr.postcode,
      country: addr.country,
      specialInstructions: addr.specialInstructions || '',
      dateFrom: undefined,
      dateTo: undefined,
    };
  }

  private mapV2RolesToCardRoles(roles: CardRoleInMatter): ICardRoles {
    return {
      tables: roles?.tables || [],
      subsTables: roles?.substitutionTables || [],
    };
  }
}
