import { Routes } from '@angular/router';
import { MainComponent } from '@app/core/pages';
import { SuperDiaryGuard } from '@app/core/guards/super-diary.guard';
import { UniversalGuard } from '../guards/universal.guard';

const _mainRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'matters',
        pathMatch: 'full',
      },
      {
        path: 'matters',
        loadChildren: () => import('app/features/+matter-list/matter-list.module').then((m) => m.MatterListModule),
      },
      {
        path: 'callback',
        loadChildren: () =>
          import('app/features/+link-account/link-account-callback-routing.module').then(
            (m) => m.LinkAccountCallbackRoutingModule
          ),
      },
      {
        path: 'matters/:matterNumber/financial-summary',
        loadChildren: () =>
          import('app/features/+financial-summary/financial-summary.module').then((m) => m.FinancialSummaryModule),
      },
      {
        path: 'matters/:matterNumber/payments-debtors',
        loadChildren: () =>
          import('app/features/+payments-debtors/payments-debtors-routing.module').then(
            (m) => m.PaymentsDebtorsRoutingModule
          ),
      },
      {
        path: 'matters/:matterNumber/credit-ledger',
        loadChildren: () => import('app/features/+credit/credit.module').then((m) => m.CreditModule),
      },
      {
        path: 'matters/:matterNumber/trust-fund',
        loadChildren: () => import('app/features/+trust/trust.module').then((m) => m.TrustModule),
      },
      {
        path: 'matters/:matterNumber/protected-trust-funds',
        loadChildren: () =>
          import('app/features/+protected-fund/protected-fund-ledger-routing.module').then(
            (m) => m.ProtectedFundLedgerRoutingModule
          ),
      },
      {
        path: 'matters/:matterNumber/trust-investment-ledger',
        loadChildren: () =>
          import('app/features/+trust-investment-ledger/trust-investment-ledger-routing.module').then(
            (m) => m.TrustInvestmentLedgerRoutingModule
          ),
      },
      {
        path: 'matters/:matterNumber/power-money',
        loadChildren: () =>
          import('app/features/+power-money-ledger/power-money-ledger-routing.module').then(
            (m) => m.PowerMoneyLedgerRoutingModule
          ),
      },
      {
        path: 'matters/:matterNumber/anticipated-payments',
        loadChildren: () =>
          import('app/features/+anticipated-payment-ledger/anticipated-payment-ledger-routing.module').then(
            (m) => m.AnticipatedPaymentLedgerRoutingModule
          ),
      },
      {
        path: 'matters/:matterNumber',
        loadChildren: () =>
          import('app/features/+matter-details/matter-details.module').then((m) => m.MatterDetailsModule),
      },
      {
        path: 'recurring-matters/:id',
        loadChildren: () =>
          import('@app/features/+recurring-matter-details/recurring-matter-details-routing.module').then(
            (m) => m.RecurringMatterDetailsRoutingModule
          ),
      },
      {
        path: 'recurring-matters/:recurringMatterId/schedule',
        loadChildren: () =>
          import('app/features/+calendar-readonly/calendar-readonly.module').then((m) => m.CalendarReadonlyModule),
      },
      {
        path: 'recurring-matters/:recurringMatterId/fee',
        loadChildren: () =>
          import('@app/features/+recurring-matter-fee-ledger/recurring-matter-fee-ledger-routing.module').then(
            (m) => m.RecurringMatterFeeLedgerRoutingModule
          ),
      },
      {
        path: 'recurring-matters/:recurringMatterId/cost-recoveries',
        loadChildren: () =>
          import(
            '@app/features/+recurring-matter-cost-recovery-ledger/recurring-matter-cost-recovery-ledger-routing.module'
          ).then((m) => m.RecurringMatterCostRecoveryLedgerRoutingModule),
      },
      {
        path: 'cards',
        loadChildren: () => import('app/features/+card/card-routing.module').then((m) => m.CardRoutingModule),
      },
      {
        path: 'persons',
        loadChildren: () => import('app/features/+person/person-routing.module').then((m) => m.PersonRoutingModule),
      },
      {
        path: 'matters/:matterNumber/time-fee',
        loadChildren: () =>
          import('app/features/+time-fee-ledger/time-fee-ledger.module').then((m) => m.TimeFeeLedgerModule),
      },
      {
        path: 'matters/:matterNumber/cost-recoveries',
        loadChildren: () =>
          import('app/features/+cost-recovery-ledger/cost-recovery-ledger.module').then(
            (m) => m.CostRecoveryLedgerModule
          ),
      },
      {
        path: 'trust-bank-register',
        loadChildren: () =>
          import('app/features/+trust-bank-register/trust-bank-register-routing.module').then(
            (m) => m.TrustBankRegisterRoutingModule
          ),
      },
      {
        path: 'office-search-transaction',
        loadChildren: () =>
          import('app/features/+office-search-transaction/office-search-transaction-routing.module').then(
            (m) => m.OfficeSearchTransactionRoutingModule
          ),
      },
      {
        path: 'matters/:matterNumber/schedule',
        canActivate: [SuperDiaryGuard],
        loadChildren: () => import('app/features/+calendar/calendar.module').then((m) => m.AppCalendarModule),
      },
      {
        path: 'matters/:matterNumber/calendar',
        loadChildren: () =>
          import('app/features/+calendar-readonly/calendar-readonly.module').then((m) => m.CalendarReadonlyModule),
      },
      {
        path: 'preview',
        loadChildren: () => import('app/features/+preview/preview-routing.module').then((m) => m.PreviewRoutingModule),
      },
    ],
  },
  {
    path: 'officeonline/:action/:documentId',
    loadChildren: () => import('app/features/+office-online/office-online.module').then((m) => m.OfficeOnlineModule),
  },
  {
    path: 'new-win',
    data: {
      newWin: true,
    },
    children: [
      {
        path: 'app',
        loadChildren: () => import('app/features/+leaphost/leaphost-routing.module').then((m) => m.LeaphostRoutingModule),
      },
      {
        path: 'email',
        loadChildren: () => import('app/features/+email/email-routing.module').then((m) => m.EmailRoutingModule),
      },
      {
        path: 'fee',
        loadChildren: () => import('app/features/+time-fee-ledger/fee-form.module').then((m) => m.FeeFormModule),
      },
      {
        path: 'time',
        loadChildren: () => import('app/features/+time-fee-ledger/time-form.module').then((m) => m.TimeFormModule),
      },
      {
        path: 'document-share',
        loadChildren: () =>
          import('app/features/+document-share/document-share-routing.module').then(
            (m) => m.DocumentShareRoutingModule
          ),
      },
      {
        path: 'reauthenticate-account',
        loadChildren: () =>
          import('app/features/+reauthentication/reauthentication-routing.module').then(
            (m) => m.ReauthenticationRoutingModule
          ),
      },
      {
        path: 'layout',
        loadChildren: () =>
          import('app/features/+layout-renderer/layout-renderer-routing.module').then(
            (m) => m.LayoutRendererRoutingModule
          ),
      },
    ],
  },
];

export const mainRoutes: Routes = [
  {
    path: '',
    canActivate: [UniversalGuard],
    children: [..._mainRoutes],
  },
];
