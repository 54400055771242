import { IEnvironment } from '@env/environment.model';

export const environment: IEnvironment = {
  production: true,
  config: {
    logs: {
      // required config
      collector:
        'ZaVnC4dhaV3N_ggyOcSe98LF28rv99wCMah7fw5_jec0mDVidQtgSciXEXZmhTz9eGRcdf6IxU9kEybVxh7QuN--WX7kgeBj0Dy7R_EeT3kjKA9p6Wx1zA==',
      // optional config
      endpoint: 'https://collectors.au.sumologic.com/receiver/v1/http/',
      level: 'info',
    },
    layout: {
      renderer: 'https://leapcalc-renderer-au-test.leap.services',
    },
    cache: {
      roleARN: 'arn:aws:iam::438555434192:role/redis-shared-parameterstore-ap-southeast-2',
      sessionName: 'leap365-sirius',
      parameterStoreKey: 'redis-shared-connection',
      awsRegion: 'ap-southeast-2',
    },
    brand: {
      analytics: 'GTM-MD8QMN6',
      id: 'test.leap365.com.au',
      name: 'Leap',
      leapcalc: 'leap',
      env: 'test',
      region: 'au',
      helpUrl: '//community.leap.com.au/s/',
      termsUrl: 'https://www.leap.com.au/terms',
      privacyUrl: 'https://www.leap.com.au/privacy',
      brandUrl: 'test.leap365.com.au',
      bylawyersUrl: 'https://www.leap.com.au/exclusive-integrations/by-lawyers',
      copyrightName: 'LEAP Software Developments Pty. Limited',
    },
    precedent: {
      letterContainerId: '1681',
    },
    support: {
      communityUrl: 'https://leap--community.sandbox.my.site.com/auhelpcentre/s',
      liveChatUrl: 'https://secure.livechatinc.com/licence/4997331/v2/open_chat.cgi',
      feedbackUrl: 'https://uxform.typeform.com/to',
      feedbackCode: 'XbNsAv',
    },
    oauth: {
      scopes: '*',
      endpoint: 'https://login.test.leap365.com.au',
      user_info_uri: '/api/userinfo',
      provider_id: 'LEO',
      client_id: 'MBUHPIFEIVJ8CNH0',
      uniqueSessionActive: true,
      autoLogoutActive: false,
      userPresence: true,
      backendClientId: 'SNAXWYYI1RRKYGT2',
    },
    automation: {
      uriSchema: 'leapautest',
    },
    keys: {
      pnSubscribe: 'sub-a456f002-0095-11e2-9638-9581afc33ebf',
    },
    apps: {
      glLink: 'https://test-gllinkapp.leapaws.com',
      marketPlace: 'https://test.marketplace.leap.build/apps',
      lawconnect: 'https://lawconnect.test.leap365.com.au',
      appIds: {
        infotrack: [],
        timesheet: ['f75e3070-b5f6-4bdf-a683-b59549a9f52a', 'fe0fecb5-0494-4cbb-820f-71705d56afbc'],
      },
      appFunctions: {
        timesheet: ['7677c28e-c794-4e8d-8859-beccdfb2fc0c'],
      },
    },
    endpoint: {
      account: 'https://account2-api-au-test.leap.services',
      accounting: 'https://api-offexaccounting-test.leapaws.com.au',
      accountingv2: 'https://acc-api-test.leapaws.com.au',
      adminLawConnect: 'https://admin.test.lawconnect.com.au',
      appConsole: 'https://api.test.console.leap.build',
      automation: 'https://office-automation-api-test.leapaws.com.au',
      bankrec: 'https://bankrec.test.leap365.com.au',
      bylawyers: 'https://test.bylawyers.com.au',
      calendar: 'https://superdiary-api-test.leapaws.com.au',
      cdn: 'https://cdn.leap.com.au',
      docs: 'https://api-docs-test.leapaws.com.au',
      docsPublic: 'https://api-docs-public-test.leapaws.com.au',
      docBuilder: 'https://doc-builder-test.leapaws.com.au',
      infotrack: 'https://stagesearch.infotrack.com.au',
      trisearch: 'https://stagesearch.infotrack.com.au',
      leaponline: 'https://updates.test.leapaws.com.au/leap-online/public/LEAPWebInstaller.exe',
      myobweb: 'https://myobweb-test.leapaws.com.au',
      pdfservice: 'https://pdfservice-test.leapaws.com.au',
      quickbook: '',
      reporting: 'https://reporting-test.leapaws.com.au',
      reportingApi: 'https://reporting-api-test.leapaws.com.au',
      schema: 'https://schema-api-au-test.leap.services',
      wopi: 'https://office-wopi-api-test.leapaws.com.au',
      xero: 'https://xeroweb-test.leapaws.com.au',
      auth: 'https://login.test.leap365.com.au',
      globalAuth: 'https://auth-test.leap.services',
      options: 'https://acc-options-api-test.leapaws.com.au',
      options_app: 'https://settings.test.leap365.com.au',
      leapadin: 'https://leapaddins-api-test.leapaws.com.au',
      listaddin: 'https://updates-leapaws-com-au.s3.amazonaws.com/1/test/x64/matteraddin/api-listV3.json',
      notifications: 'https://notificationsapi-test.leapaws.com.au',
      lcintegrator: 'https://lcintegrator-test.leapaws.com.au',
      phoenix: 'https://guides.test.bylawyers.com.au',
      msteams: 'https://teams-test.leapaws.com.au',
      content: 'https://api-contentgateway-test.leapaws.com.au',
      docsV2: 'https://api-docs-core.test.leapaws.com.au',
      leapDesign: 'https://leapdesign-test.leapaws.com.au/au/live',
    },
    aws: {
      reportingBucket: 'sirius-transactions-test-leapaws-com-au',
      region: 'ap-southeast-2',
      paramStorePath: '/officecloud/leap365/',
      accountId: '064865921863',
    },
    ksd: {
      enabled: true,
      configurations: [
        {
          protocol: 'https',
          method: '*',
          domain: `api-docs-test.leapaws.com.au`,
          path: '/api/*',
        },
      ],
    },
    esignature: {
      enabled: true,
    },
    invoiceSharing: {
      enabled: true,
    },
    featureFlag: {
      client_id: '6655571aaba81f105a9219d3',
    },
    auth0: {
      domain: 'leapauthtest.au.auth0.com',
      clientId: 'FM6QHtgu9Ftwrn5HBs0XKjADVMX74IzV',
    },
  },
};
