import { environment } from '@env/environment';
import { trim } from '../../../../../server/modules/shared/functions/common-util.functions';

export enum EApiPath {
  Accounting = '/api/accounting',
}

export abstract class BaseService {
  /**
   * use docsApiPath instead - this name is misleading
   */
  apiPath: string;

  docsApiPath: string;
  docsPublicPath: string;
  accountPath: string;
  accountingPath: string;
  accountingv2Path: string;
  appConsolePath: string;
  automationPath: string;
  optionsPath: string;

  /**
   * @deprecated move to LEAP Design API
   */
  schemaPath: string;

  calendarPath: string;
  wopiPath: string;
  pdfPath: string;
  siriusPath: string;
  leapAddinPath: string;
  listAddinPath: string;
  notificationsPath: string;
  lcIntegratorPath: string;
  docBuilderPath: string;
  contentPath: string;
  infotrackPath: string;
  docsV2Path: string;
  leapDesignPath: string;

  protected constructor() {
    this.apiPath = environment.config.endpoint.docs;
    this.docsApiPath = environment.config.endpoint.docs;
    this.docsPublicPath = environment.config.endpoint.docsPublic;
    this.accountPath = '/api/userprofile';
    this.accountingPath = EApiPath.Accounting;
    this.accountingv2Path = '/api/acc-api';
    this.appConsolePath = '/api/app-console';
    this.automationPath = '/api/automtn';
    this.optionsPath = '/api/options';
    this.schemaPath = '/api/schema';
    this.calendarPath = '/api/calendar';
    this.wopiPath = 'api/wopi';
    this.pdfPath = '/api/pdfservice';
    this.siriusPath = '/api/internal';
    this.leapAddinPath = '/api/leapadin';
    this.listAddinPath = '/api/listaddin';
    this.notificationsPath = '/api/notifications';
    this.lcIntegratorPath = '/api/lcintegrator';
    this.docBuilderPath = '/api/doc-builder';
    this.contentPath = '/api/content';
    this.infotrackPath = environment.config.endpoint.infotrack;
    this.docsV2Path = environment.config.endpoint.docsV2;
    this.leapDesignPath = environment.config.endpoint.leapDesign;
  }

  urlJoin(...parts: string[]): string {
    // get the leading slashes from the first valid part, we need to keep them around otherwise the url will be
    // a relative URL, not an absolute one as it needs to be.

    const firstValidPart = parts?.length > 0 ? parts[0] : '';
    const startingSlashes = firstValidPart ? firstValidPart.replace(/^((https?:)?(\/*)).*$/, '$1') : '';

    if (startingSlashes) {
      // remove the starting part (http:// or //) from the first part, so it doesn't get added tp the URL twice
      const firstValidPartIndex = parts.findIndex((p) => p === firstValidPart);
      if (firstValidPartIndex !== -1) {
        parts[firstValidPartIndex] = firstValidPart.replace(startingSlashes, '');
      }
    }

    parts = parts
      ?.map((part) => (part ? part.split(/\/+/g) : null))
      .reduce((a, b) => a.concat(b), [])
      .filter(Boolean);

    const queryStringStartIndex = parts.findIndex((part: string) => part.match(/[?&]/));
    if (queryStringStartIndex < 0) {
      return startingSlashes + parts.join('/');
    }
    const urlParts: string[] = parts.slice(0, queryStringStartIndex);
    const qsStartsPartComponents = parts[queryStringStartIndex].split(/[?^&]/g);
    // the part where the query string begins might contain the last part of the url,
    // so put that bit in the urlParts array.
    if (qsStartsPartComponents[0] !== '') {
      urlParts.push(qsStartsPartComponents.shift());
    }

    const qsParts = qsStartsPartComponents
      .concat(parts.slice(queryStringStartIndex + 1))
      .map((part: string) => (part ? part.split(/[?&]/g) : null))
      .reduce((a, b) => a.concat(b), [])
      .filter(Boolean);

    return startingSlashes + urlParts.map((part: string) => trim(part, '/')).join('/') + '?' + qsParts.join('&');
  }
}
