import { createAction, props } from '@ngrx/store';
import { TrustAccountUserPermission, TrustAccountSharedResponse } from '@app/features/+document-share/models/models';

export const InitTrustAccountShare = createAction(
  '[trust account share] init folder sharing',
  props<{ matterNumber: string; accountId: string }>()
);

export const InitTrustAccountShareFailure = createAction(
  '[trust account share] init folder sharing',
  props<{ error: any }>()
);

export const GetTrustAccountUserAccessStart = createAction(
  '[trust account share] get user access start',
  props<{ matterNumber: string; accountId: string }>()
);

export const GetTrustAccountUserAccessSuccess = createAction(
  '[trust account share] get user access success',
  props<{ result: TrustAccountUserPermission[] }>()
);

export const GetTrustAccountUserAccessFailure = createAction(
  '[trust account share] get user access failure',
  props<{ error: any }>()
);

export const UpdateTrustAccountUserAccessList = createAction(
  '[trust account share] update revoke and updated permission user list in store',
  props<{ updateAccessRow: TrustAccountUserPermission }>()
);

export const SubmitLatestTrustAccountUserAccessList = createAction(
  '[trust account share] submit the existing and new recipient to api',
  props<{ newlyAdded?: TrustAccountUserPermission[]; message: string }>()
);

export const SubmitLatestTrustAccountUserAccessListSuccess = createAction(
  '[trust account share] submit the existing and new recipient to api success',
  props<{ response: TrustAccountSharedResponse; request: TrustAccountUserPermission[] }>()
);

export const SubmitLatestTrustAccountUserAccessListFailure = createAction(
  '[trust account share] submit the existing and new recipient to api failure',
  props<{ error: any }>()
);

export const ClearTrustAccountShareProcessingState = createAction(
  '[trust account share] clear error, loading and success state'
);
