import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { exhaustMap, map as rxjsMap, mergeMap, take, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { StartupService } from '@app/core/services/startup/startup.service';
import { AppState } from '@app/core/store/reducers';
import * as appActions from '@app/core/store/actions';
import { selectCurrentMatterId } from '@app/core/store/selectors';
import { PlatformService } from '@app/core/services';
import { environment } from '@env/environment';

import {
  AutomationActionType,
  AutomationHostApplicationType,
  DesktopModeNotSupportedCallback,
  IAutomationWorkflowParams,
  ICoreLauncherRequestData,
  ICreateNewBasedOnDocumentTicketParams,
  ICreateNewContainerTicketParams,
  ICreateNewCustomPrecedentTicketParams,
  ICreateNewDocumentTicketParams,
  ICreateTaskDocumentTicketParams,
  IDocumentLauncherRequest,
  IDocumentTicketParams,
  IEditContainerTicketParams,
  IEmailData,
  ILauncherTicketInfo,
  IOpenPrecedentTicketParams,
  IPrintPdfLauncherRequest,
  MailLauncherRequest,
} from '@app/shared/models';

import { mapExtToHostApplication } from '@app/shared/functions/document-automation.functions';
import { UiUtilsService } from '../../ui/ui-utils.service';
import { selectSelectedFolderId } from '@app/features/+correspondence/store/selectors';
import { selectDocumentNewDocumentPayload } from '../../../../features/+document/store';

@Injectable()
export class OfflineLauncherService {
  private _uriScheme = environment.config.automation.uriSchema;

  constructor(
    private _store: Store<AppState>,
    private _uiUtilsSvc: UiUtilsService,
    private _startupSvc: StartupService,
    private _platformSvc: PlatformService,
    private _http: HttpClient,
  ) {}

  createOpenAppointmentTicket(appointmentId: string, workflowParams?: IAutomationWorkflowParams): Observable<string> {
    return this.buildCoreLauncherData().pipe(
      rxjsMap(
        (body) =>
          ({
            ...body,
            hostApplication: AutomationHostApplicationType.Outlook,
            action: AutomationActionType.OpenAppointment,
            documentData: {
              appointmentId,
            },
          }) as ILauncherTicketInfo,
      ),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
    );
  }

  createNewTaskPrecedentTicket(
    params: ICreateTaskDocumentTicketParams,
    workflowParams?: IAutomationWorkflowParams,
  ): Observable<string> {
    return this.buildCoreLauncherData().pipe(
      rxjsMap(
        (body) =>
          ({
            ...body,
            hostApplication: AutomationHostApplicationType.Word,
            action: AutomationActionType.New,
            folderId: params.folderId,
            documentData: {
              isContainer: false,
              precedentId: params.precedentId,
              taskId: params.taskId,
              contentMode: params.contentMode,
            },
          }) as IDocumentLauncherRequest,
      ),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
    );
  }

  createOpenTaskTicket(taskId: string, workflowParams?: IAutomationWorkflowParams): Observable<string> {
    return this.buildCoreLauncherData().pipe(
      rxjsMap(
        (body) =>
          ({
            ...body,
            hostApplication: AutomationHostApplicationType.Outlook,
            action: AutomationActionType.OpenTask,
            documentData: {
              taskId,
            },
          }) as ILauncherTicketInfo,
      ),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
    );
  }

  createNewDocumentTicket(
    params: ICreateNewDocumentTicketParams,
    workflowParams?: IAutomationWorkflowParams,
    documentId?: string,
  ): Observable<string> {
    return combineLatest([
      this._store.pipe(select(selectSelectedFolderId)),
      this.buildCoreLauncherData(params.matterId),
      this._store.pipe(select(selectDocumentNewDocumentPayload)),
    ]).pipe(
      rxjsMap((data) => {
        const [folderId, coreData, newDocumentPayload] = data;
        let automationAction = AutomationActionType.New;

        const isOverwriteBody =
          newDocumentPayload?.body || newDocumentPayload?.extra || params.extra || params.documentBody;

        if (isOverwriteBody) {
          automationAction = AutomationActionType.OpenAndOverwriteBody;
        }

        return {
          ...coreData,
          hostApplication: AutomationHostApplicationType.Word,
          action: automationAction,
          folderId,
          documentData: {
            documentId: documentId ? documentId : uuidv4(),
            defaultTableId: params.defaultTableId,
            defaultTableOrder: params.defaultTableOrder,
            isContainer: true,
            precedentId: params.precedentId,
            defaultPersonInstance: params.defaultPersonInstance || '',
            contentMode: params.contentMode,
            pendingPrecedentId: params.pendingPrecedentId,
          },
          documentBody: params.documentBody || newDocumentPayload?.body,
          documentBodyFormat: params.documentBodyFormat,
          xmlData: params.extra?.xmlData || newDocumentPayload?.extra?.xmlData,
        } as IDocumentLauncherRequest;
      }),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      take(1),
    );
  }

  createNewBasedOnDocumentTicket(
    params: ICreateNewBasedOnDocumentTicketParams,
    workflowParams?: IAutomationWorkflowParams,
  ): Observable<string> {
    return this.buildCoreLauncherData().pipe(
      rxjsMap((coreData) => {
        return {
          ...coreData,
          hostApplication: AutomationHostApplicationType.Word,
          action: AutomationActionType.CreateBasedOnDocument,
          documentData: {
            documentId: params.documentId,
            isContainer: false,
            contentMode: params.contentMode,
            saveAsName: params.saveAsName,
          },
          xmlData: params.xmlData,
        } as IDocumentLauncherRequest;
      }),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      take(1),
    );
  }

  createNewCustomPrecedentTicket(params: ICreateNewCustomPrecedentTicketParams) {
    return this.buildCoreLauncherData().pipe(
      rxjsMap(
        (coreData) =>
          ({
            ...coreData,
            hostApplication: AutomationHostApplicationType.Word,
            action: AutomationActionType.NewPrecedent,
            folderId: null,
            documentData: {
              documentId: uuidv4(),
              isContainer: true,
              precedentId: params.precedentId,
              pendingPrecedentId: params.pendingPrecedentId,
              contentMode: params.contentMode,
              ...(!!params.defaultTableId && { defaultTableId: params.defaultTableId }),
              ...(!!params.defaultTableOrder && { defaultTableOrder: params.defaultTableOrder }),
              ...(!!params.parentPrecedentId && { parentPrecedentId: params.parentPrecedentId }),
              ...(!params.parentPrecedentId && { precedentMatterTypeId: params.precedentMatterTypeId }),
            },
          }) as IDocumentLauncherRequest,
      ),
      exhaustMap((body) =>
        this.createOfflineTicket({
          info: body,
          workflowParams: {
            navigateClear: true,
          },
        }),
      ),
    );
  }

  createEditContainerTicket(
    params: IEditContainerTicketParams,
    workflowParams?: IAutomationWorkflowParams,
  ): Observable<string> {
    return this.buildCoreLauncherData().pipe(
      rxjsMap(
        (body) =>
          ({
            ...body,
            hostApplication: AutomationHostApplicationType.Word,
            action: AutomationActionType.EditCustomPrecedent,
            documentData: {
              isContainer: true,
              precedentId: params.containerId,
              contentMode: params.contentMode,
            },
          }) as IDocumentLauncherRequest,
      ),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
    );
  }

  createEditDocumentTicket(data: {
    params: IDocumentTicketParams;
    workflowParams?: IAutomationWorkflowParams;
  }): Observable<string> {
    const { params, workflowParams } = data;
    return combineLatest([this._store.pipe(select(selectSelectedFolderId)), this.buildCoreLauncherData()]).pipe(
      rxjsMap((res) => {
        const [folderId, coreData] = res;
        return {
          ...coreData,
          hostApplication: mapExtToHostApplication(params.ext),
          action: AutomationActionType.Edit,
          folderId,
          documentData: {
            documentId: params.documentId,
            isPending: !!params.isPending,
            contentMode: params.contentMode,
          },
        } as IDocumentLauncherRequest;
      }),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      tap((ticketId) => {
        if (ticketId) {
          this._store.dispatch(new appActions.AddAutomationTicket({ ticket: { ticketId, docId: params.documentId } }));
        }
      }),
      take(1),
    );
  }

  generatePendingPrecedent(
    params: IDocumentTicketParams,
    workflowParams?: IAutomationWorkflowParams,
  ): Observable<string> {
    return combineLatest([this._store.pipe(select(selectSelectedFolderId)), this.buildCoreLauncherData()]).pipe(
      rxjsMap((data) => {
        const [folderId, coreData] = data;
        return {
          ...coreData,
          hostApplication: mapExtToHostApplication(params.ext),
          action: AutomationActionType.New,
          folderId,
          documentData: {
            precedentId: params.precedentId,
            isPending: !!params.isPending,
            contentMode: params.contentMode,
            pendingPrecedentId: params.pendingPrecedentId,
          },
        } as IDocumentLauncherRequest;
      }),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      take(1),
    );
  }

  createEditEmailTicket(data: {
    params: IDocumentTicketParams;
    workflowParams?: IAutomationWorkflowParams;
  }): Observable<string> {
    const { params, workflowParams } = data;
    return combineLatest([this._store.pipe(select(selectSelectedFolderId)), this.buildCoreLauncherData()]).pipe(
      rxjsMap((res) => {
        const [folderId, coreData] = res;
        return {
          ...coreData,
          action: AutomationActionType.OpenMailItem,
          hostApplication: AutomationHostApplicationType.Outlook,
          folderId,
          emailData: {
            documentId: params.documentId,
            ext: params.ext,
            latestVersionId: params.latestVersionId,
            isPending: params.isPending,
          },
        } as MailLauncherRequest;
      }),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      tap((ticketId) => {
        if (ticketId) {
          this._store.dispatch(new appActions.AddAutomationTicket({ ticket: { ticketId, docId: params.documentId } }));
        }
      }),
      take(1),
    );
  }

  createNewEmailTicket(data: {
    emailData: IEmailData;
    matterId?: string;
    workflowParams?: IAutomationWorkflowParams;
    xmlData?: string;
  }): Observable<string> {
    const { emailData, matterId, workflowParams, xmlData } = data;
    return combineLatest([this._store.pipe(select(selectSelectedFolderId)), this.buildCoreLauncherData(matterId)]).pipe(
      rxjsMap((res) => {
        const [folderId, coreData] = res;
        return {
          ...coreData,
          action: AutomationActionType.NewMailItem,
          hostApplication: AutomationHostApplicationType.Outlook,
          folderId,
          emailData,
          xmlData,
        } as MailLauncherRequest;
      }),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      take(1),
    );
  }

  createOpenPrecedentTicket(
    params: IOpenPrecedentTicketParams,
    workflowParams?: IAutomationWorkflowParams,
  ): Observable<string> {
    return combineLatest([this._store.pipe(select(selectSelectedFolderId)), this.buildCoreLauncherData()]).pipe(
      rxjsMap((data) => {
        const [folderId, coreData] = data;
        return {
          ...coreData,
          hostApplication: mapExtToHostApplication(params.ext),
          action: params.action,
          folderId,
          documentData: {
            documentId: uuidv4(),
            precedentId: params.precedentId,
            isContainer: false,
            contentMode: params.contentMode,
          },
          xmlData: params.xmlData,
        } as IDocumentLauncherRequest;
      }),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      take(1),
    );
  }

  createPreviewTicket(params: IDocumentTicketParams, workflowParams?: IAutomationWorkflowParams): Observable<string> {
    return combineLatest([this._store.pipe(select(selectSelectedFolderId)), this.buildCoreLauncherData()]).pipe(
      rxjsMap((data) => {
        const [folderId, coreData] = data;
        return {
          ...coreData,
          hostApplication: AutomationHostApplicationType.Launcher,
          action: AutomationActionType.PrintPdf,
          folderId,
          printData: {
            ext: params.ext,
            latestVersionId: params.latestVersionId,
            documentName: params.documentName,
          },
        } as IPrintPdfLauncherRequest;
      }),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      take(1),
    );
  }

  createReportPreviewTicket(
    params: IDocumentTicketParams,
    workflowParams?: IAutomationWorkflowParams,
  ): Observable<string> {
    return this.buildCoreLauncherData().pipe(
      rxjsMap(
        (coreData) =>
          ({
            ...coreData,
            action: AutomationActionType.PrintPdf,
            hostApplication: AutomationHostApplicationType.Launcher,
            printData: {
              ext: params.ext,
              documentName: params.documentName,
              url: params.url,
            },
          }) as IPrintPdfLauncherRequest,
      ),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      take(1),
    );
  }

  createNewContainerTicket(
    params: ICreateNewContainerTicketParams,
    workflowParams?: IAutomationWorkflowParams,
  ): Observable<string> {
    return this.buildCoreLauncherData().pipe(
      rxjsMap(
        (body) =>
          ({
            ...body,
            hostApplication: params.applicationType,
            action: AutomationActionType.NewContainer,
            folderId: null,
            documentData: {
              isContainer: true,
              precedentId: params.blank.id,
              parentPrecedentId: '0',
              contentMode: params.contentMode,
            },
          }) as IDocumentLauncherRequest,
      ),
      mergeMap((body) => this.createOfflineTicket({ info: body, workflowParams })),
      take(1),
    );
  }

  public createOfflineTicket(data: {
    info: ILauncherTicketInfo | IDocumentLauncherRequest | MailLauncherRequest | IPrintPdfLauncherRequest;
    workflowParams: IAutomationWorkflowParams;
  }): Observable<string> {
    const { info, workflowParams } = data;
    const params = workflowParams
      ? workflowParams
      : {
          navigateClear: false,
        };

    return this.postTicket(info).pipe(
      tap((res) => {
        this._store.dispatch(new appActions.UpdateAutomationLocalTicketId(res));
        if (this.launchDocumentOffline(res, this._startupSvc.userDetails.firmId, params.notSupportedCallback)) {
          this._store.dispatch(new appActions.LaunchAutomationDialogStart(params));
        }
      }),
      take(1),
    );
  }

  public launchDocumentOffline(
    ticketId: string,
    firmId: string,
    notSupportedCallback?: DesktopModeNotSupportedCallback,
  ): boolean {
    if (this._platformSvc.isMacLikeOS) {
      this._uiUtilsSvc.handleDesktopModeNotSupported(notSupportedCallback);
      return false;
    }

    const message = this.getNewMessageValue(ticketId, firmId);
    launchUsingHiddenIframe(`${this._uriScheme}:${message}`);

    return true;
  }

  private getNewMessageValue(ticketId: string, firmId: string) {
    return encodeURIComponent(JSON.stringify({ ticketId, firmId }));
  }

  public buildCoreLauncherData(matterId?: string): Observable<ICoreLauncherRequestData> {
    const { staffId, firmId, userId } = this._startupSvc.userDetails;

    return matterId
      ? of({
          matterId,
          staffId,
          firmId,
          userId,
        } as ICoreLauncherRequestData)
      : this._store.pipe(select(selectCurrentMatterId)).pipe(
          rxjsMap(
            (mId) =>
              ({
                matterId: mId,
                staffId,
                firmId,
                userId,
              }) as ICoreLauncherRequestData,
          ),
        );
  }

  public postTicket(body): Observable<string> {
    if (body === undefined) {
      return new Observable((observer) => observer.error('Invalid ticket launcher content to create document.'));
    }

    return this._http.post(`${environment.config.endpoint.automation}/api/v2/automation/tickets`, body, {
      responseType: 'text',
    });
  }
}

const launchUsingHiddenIframe = (uri: string): void => {
  let iframe = document.querySelector('#hiddenIframe') as HTMLIFrameElement;
  if (!iframe) {
    iframe = document.createElement('iframe') as HTMLIFrameElement;
    iframe.src = uri;
    iframe.id = 'hiddenIframe';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  } else {
    iframe.contentWindow.location.href = uri;
  }
};
