import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { LogService } from '../log/log.service';
import { IClearCacheParams } from '@app/core/models';
import { BaseService } from '../../../shared/services/base/base.service';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { FEATURE_FLAG_USE_CALC_WEB_WORKER } from '@app/core/constants/features-flags.constant';
import { CalcEngineService } from '@app/features/calc-engine/services/calc-engine.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class CacheService extends BaseService {
  private _calcEngineSvc: CalcEngineService = inject(CalcEngineService);

  constructor(
    private http: HttpClient,
    private _logSvc: LogService,
    private _featureFlagSvc: FeatureFlagService,
    private _authService: AuthService,
  ) {
    super();
    _logSvc.init('config-service');
  }

  clearCache(params: IClearCacheParams): Observable<void> {
    if (this._featureFlagSvc.isFeatureEnabled(FEATURE_FLAG_USE_CALC_WEB_WORKER)) {
      return from(
        new Promise<void>((resolve) => {
          this._calcEngineSvc
            .clearCache({
              firmId: this._authService.decodedToken.firmId,
              [params.context]: params.objectId,
            })
            .then(() => resolve(void 0));
        }),
      );
    } else {
      const { context, objectId } = params;
      const url = `${this.siriusPath}/api/calc/clearcache?${context}=${objectId}`;
      return this.http.get<void>(url);
    }
  }

  reloadLeapCalc(): Observable<void> {
    if (this._featureFlagSvc.isFeatureEnabled(FEATURE_FLAG_USE_CALC_WEB_WORKER)) {
      return from(
        new Promise<void>((resolve) => {
          this._calcEngineSvc.reload().then(() => resolve(void 0));
        }),
      );
    } else {
      const url = `${this.siriusPath}/api/calc/reload`;
      return this.http.get<void>(url);
    }
  }

  refreshMatterCache(matterId: string): Observable<void> {
    const url = `${this.siriusPath}/api/matter/${matterId}/syncMatterCache`;
    return this.http.get<void>(url);
  }
}
