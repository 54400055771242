import { IBankAccount } from '@app/features/accounting/models';
import { IPaymentDetail, IPaymentTypes, TransactionNumberInput } from '@app/features/+payments-debtors/models';
import { ITrustItem } from '@app/features/+trust-receipt/models';

export interface INewTrustPaymentResponse {
  MatterBalance: IMatterBalance;
  ProtectedTrustFund: IProtectedTrustFund;
  Preference: ITrustPreference;
  MatterSetting: IMatterSetting;
  BankAccounts: IBankAccount[];
  PaymentTypeList: IPaymentTypes[];
}

export interface IMatterBalance {
  Balance: number;
  Uncleared: number;
  Available: number;
  AvailableAbsolute: number;
  ProtectedTrustFundsAmount: number;
  ProtectedTrustFundsInvoice: number;
  ProtectedTrustFundsPayment: number;
}

export interface IMatterSetting {
  DefaultTrustAccountGUID: string;
}

export interface IProtectedTrustFund {
  ProtectedTrustId: string;
  PTFDate: Date;
  MatterId: string;
  Reason: string;
  Amount: number;
  TrustAccountId: string;
  ProtectedTypeId: number;
  InvoiceId: string;
  TransactionNumber: string;
  FileNumberString: string;
}

export interface ITrustPreference {
  TrustBankAccountGUID: string;
  OfficeBankAccountGUID: string;
  EFTNumberPrefix: string;
  PayTypeDetailsEnforce: boolean;
}

export enum ETrustPaymentTransactionMode {
  View = 1,
  Reversal = 2,
}

export enum ETrustPaymentReconciledState {
  Unreconciled = 0,
  Pending = 1,
  Reconciled = 2,
}

export interface ITrustPaymentCommon {
  TrustPaymentGUID: string;
  FirmGUID: string;
  BankAccountGUID: string;
  PaymentTypeDetailsGUID: string;
  EntryDate: Date;
  TransactionDate: Date;
  TransactionNumber: string;
  TransactionNumberAuto: boolean;
  PayToName: string;
  PayToAddressee: string;
  PaymentTypeName: string;
  PaymentTypeId: number;
  SubPaymentTypeID?: number;
  PaymentSubTypeId?: number;
  ReversedOrReversal: boolean;
  PaymentProcessed: number;
  Reconciled: ETrustPaymentReconciledState;
  RowVersion: number;
  Void: boolean;
  TOTOfficeReceiptGUID: string;
  rptTotalAmount: number;
  rptReversalEntryDate: Date;
  ReversalInfo: string;
  CreatedByStaffId: string;
  CreatedDate: Date;
}

export interface ITrustPayment extends ITrustPaymentCommon {
  ReverseeTrustPaymentGUID: string;
  OfficeReceiptGUID: string;
  ReverseeRowVersion: number;
  TrustPaymentItems: ITrustPaymentItem[];
  PaymentTypeDetails: IPaymentDetail;

  PaymentNumber: string;
  PaymentTypeGUID: string;
  AutoNumber: number;
  WarningAcknowledgments: number[];

  ReversalGUID: string;
  ModifiedByStaffGUID: string;
}

export interface ITrustPaymentItem extends ITrustItem {
  // used by GET /api/cloud/trustpayment/{trustPaymentId}/initialisationdata
  TrustPaymentItemGUID: string;
  SeqNum: number;
  ReversalGUID: string;
  Description: string;
  TrustPaymentReversedGUID: string;
  ProtectedTrustFundGUID: string;
}

export interface ITrustPaymentResponse {
  // data structure for  GET /api/cloud/trustpayment/{trustPaymentId}/initialisationdata
  BankAccounts?: IBankAccount[];
  TrustPayment: ITrustPaymentCommon;
  TrustPaymentItems: ITrustPaymentItem[];
  PaymentTypeDetails: IPaymentDetail;
}

export interface ISaveTrustPaymentParams {
  saveAndNew: boolean;
}

/** VM models **/
export interface ITrustPaymentListStatus {
  matterEditable?: boolean;
  reasonEditable?: boolean;
  amountEditable?: boolean;
  availableHidden?: boolean;
  itemAddable?: boolean;
  itemRemovable?: boolean;
}

export interface ITrustPaymentFormStatus {
  paymentSentHidden: boolean;
  voidChequeHidden: boolean;
  selectCardHidden: boolean;
  reversalDetailsHidden: boolean;
}

export interface ITrustPaymentFormData {
  paymentDetails: Partial<ITrustPayment>;
  paymentNumberInput: TransactionNumberInput;
  context: 'create' | 'update' | 'reverse';
}

export interface IToolbarFormStatus {
  printOnSaveHidden?: boolean;
  printChequeOnSaveHidden?: boolean;
  reconciledDisabled?: boolean;
  printChequeDisabled?: boolean;
  emailOnSaveHidden?: boolean;
}

export interface IToolbarFormData {
  printOnSave?: boolean;
  reconciled?: boolean;
  printCheque?: boolean;
  emailOnSave?: boolean;
}
/** End - VM models **/
