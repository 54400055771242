import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { UserInfo } from '@leapdev/auth-agent/src/lib/types';
import { EPrintSource } from '@app/shared/services/print/print.service';

export type IAccountingSuccessAction = (payload?: unknown) => Action;
export type IAccountingFailureAction = (payload?: HttpErrorResponse) => Action;

export interface AccountingRequest {
  model: any;
  url: string;
  operation: UpdateOperation;
  successAction?: IAccountingSuccessAction;
  failureAction?: IAccountingFailureAction;
  rejectAction?: IAccountingFailureAction;
  absolute?: boolean; // whether the accounting api service treat the url as absolute path
}

export interface AccountingUpdateRequest {
  AppId?: number | string;
  WarningAcknowledgments: Array<number>;
}

export interface AccountingUpdateResponse {
  Code: number;
  RowVersion: number;
  Message: string;
  MessageHeader?: string;
  LineItemNumber?: number;
  PropertyName?: string;
  HistoryId: string;
}

export type UpdateOperation = 'post' | 'put';

export type AppId = 'Cloud' | 'Leo' | 'Mobile' | 'TimeSheet';

export interface SplitDebtorCard {
  CardId: string;
  InvoiceId: string;
  SplitAmount: number;
  CardAddress: string;
}

export interface PreviewViaReportingReq {
  reportName: string;
  options?: any;
  source?: EPrintSource;
  fileName?: string;
  fileNameOnly?: string;
}

export interface IReportingSaveToMatter {
  reportName: string;
  reportUrl: string;
  matterId: string;
  staffInitials: string;
  user: UserInfo;
}

export enum EAccountingTransaction {
  ApplyCredits = 'apply-credits',
  ControlledMoneyAccountDetail = 'controlled-money-account-detail',
  CreditReceipt = 'credit-receipt',
  OfficeBulkInvoice = 'office-bulk-invoice',
  OfficeInvoice = 'office-invoice',
  OfficeReceipt = 'office-receipt',
  OfficeStatementDebtorsLedger = 'office-statement-debtors-ledger',
  OfficeStatementSummary = 'office-statement-summary',
  PowerMoneyBankAccount = 'power-money-bank-account',
  PowerMoneyTransaction = 'power-money-transaction',
  ProtectedTrustFund = 'protected-trust-fund',
  TrustInvestmentAccountDetail = 'trust-investment-account-detail',
  TrustInvestmentTransaction = 'trust-investment-transaction',
  TrustReceipt = 'trust-receipt',
  TrustToOffice = 'trust-to-office',
}

export interface DocumentPrintViaDocBuilderReq {
  precedentId: string;
  isCustomPrecedent: boolean;
  matterId: string;
  saveToMatter: boolean;
  saveToSuperDiaryTempStorage: boolean;
  documentName: string;
  staffInitials: string;
  parameters: {
    matterId?: string;
    splitCardId?: string;
  };
  returnFileContent: boolean;
}

export interface DocumentPrintInformFromDocBuilder {
  documentContent: string;
  superDiaryDocumentId: string;
  documentId: string;
}

export interface InvoicePrintViaDocBuilderReq {
  precedentId: string;
  isCustomPrecedent: boolean;
  invoices: Array<{
    matterId: string;
    invoiceId: string;
    saveToMatter: boolean;
    saveToSuperDiaryTempStorage: boolean;
    documentName: string;
    folderId: string;
    parameters: { splitCardId?: string };
  }>;
  combinePdfs: boolean;
  combinePdfsName: string;
  returnFileContent: boolean;
}

export interface InvoicePrintInformFromDocBuilder {
  InvoiceId: string;
  DocumentContent: string;
  MatterId: string;
  DocumentId: string;
  SuperDiaryDocumentId: string;
  DocumentName: string;
  FileTypes: string;
  FolderId: string;
  SaveToMatter: boolean;
  SaveToSuperDiaryTempStorage: boolean;
}

export interface AccountingPrintReq {
  type: EAccountingTransaction;
  cardGuids: string[];
  matterId: string;
  transactionList: Array<{
    matterGuid?: string;
    transactionGuid?: string;
    transactionNumber?: string;
    accountName?: string;
  }>;
  firmGuid?: string;
  closeTransactionPage?: boolean;
  nextAction?: EAccountingTransaction;
  nextActionQuery?: any;
}

export const AccountingPrintConstants = {
  [EAccountingTransaction.ApplyCredits]: {
    fileName: 'Credit.Title.CreditReceipt',
    reportName: 'Credit_Applied',
  },
  [EAccountingTransaction.ControlledMoneyAccountDetail]: {
    fileName: 'Trust.Investment.ControlledMoney.FormType',
    reportName: 'Controlled_Money_Account_Detail',
  },
  [EAccountingTransaction.CreditReceipt]: {
    precedentClass: 'Office Receipt',
    fileName: 'Credit.Title.CreditReceipt',
    reportName: 'Office+Receipt+Single',
  },
  [EAccountingTransaction.OfficeReceipt]: {
    precedentClass: 'Office Receipt',
    fileName: 'PaymentDebtors.OfficeReceipt.Heading.View',
    reportName: 'Office+Receipt+Single',
  },
  [EAccountingTransaction.OfficeStatementDebtorsLedger]: {
    precedentClass: 'Office Statement - Debtors Ledger',
    fileName: 'Office Statement - Debtors Ledger',
    reportName: 'APP_Office_Statement_Debtor_R1066',
  },
  [EAccountingTransaction.OfficeStatementSummary]: {
    precedentClass: 'Office Statement - Summary',
    fileName: 'Office Statement - Summary',
    reportName: 'APP_Office_Statement_Summary_R1069',
  },
  [EAccountingTransaction.PowerMoneyBankAccount]: {
    fileName: 'Trust.PowerMoney.Account.Print.Filename',
    reportName: 'PM_BankAccountDetails',
  },
  [EAccountingTransaction.PowerMoneyTransaction]: {
    fileName: 'Trust.PowerMoney.Transaction.Print.Filename',
    reportName: 'PowerMoneyTransaction',
  },
  [EAccountingTransaction.TrustInvestmentAccountDetail]: {
    fileName: 'Trust.Investment.Account.FormType',
    reportName: 'Trust_Investment_Account_Detail',
  },
  [EAccountingTransaction.TrustInvestmentTransaction]: {
    fileName: 'Trust.Investment.Transaction.FormType',
    reportName: 'TrustCMoneyTrustInvestTrans',
  },
  [EAccountingTransaction.TrustReceipt]: {
    precedentClass: 'Trust Receipt',
    fileName: 'Trust.Receipt.View',
    reportName: 'Trust+Receipt+Single',
  },
};
