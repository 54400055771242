import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ILeapDesignTableContent } from '../../../shared/models';
import { LeapDesignService } from '../../../shared/services';
import * as actions from '../actions';
import { AppState } from '../reducers';
import { of } from 'rxjs';

@Injectable()
export class TableContentEffects {
  getTableContentStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoadTableContent>(actions.TableContentActions.LOAD_TABLE_CONTENT),
      switchMap((action) => {
        const { tableId } = action.payload;
        return this._leapDesignSvc.getTableContent(tableId).pipe(
          map((tableContent: ILeapDesignTableContent) => new actions.LoadTableContentSuccess({ tableContent })),
          catchError((error) => of(new actions.LoadTableContentFailure(error))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private _leapDesignSvc: LeapDesignService,
    private _store: Store<AppState>,
  ) {}
}
