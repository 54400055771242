import { inject, Injectable } from '@angular/core';
import { AuthService } from '@app/core/services';

@Injectable()
export class CalcEngineCacheService {
  private db: IDBDatabase | null = null;

  constructor() {}

  private _authService: AuthService = inject(AuthService);

  async init() {
    return new Promise<void>((resolve, reject) => {
      const prefix = this.getPrefix();
      prefix.then((prefixValue) => {
        const request = indexedDB.open('Calc_' + prefixValue, 1);

        request.onupgradeneeded = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          db.createObjectStore('cache', { keyPath: 'key' });
        };

        request.onsuccess = async (event) => {
          this.db = (event.target as IDBOpenDBRequest).result;
          await this.cleanupExpiredItems();
          resolve();
        };

        request.onerror = (event) => {
          console.error('IndexedDB error:', (event.target as IDBOpenDBRequest).error);
          reject(event.target as IDBOpenDBRequest);
        };
      });
    });
  }

  async getPrefix() {
    return `${(await this._authService.userDetails()).firmId}_${(await this._authService.userDetails()).userId}`;
  }

  async get<T>(key: string): Promise<T | null> {
    if (!this.db) {
      return null;
    }

    return new Promise<T | null>((resolve, reject) => {
      const transaction = this.db!.transaction('cache', 'readonly');
      const store = transaction.objectStore('cache');
      const request = store.get(key);

      request.onsuccess = () => {
        const item = request.result;
        if (!item) {
          resolve(null);
          return;
        }

        if (!('value' in item) && !('expiresAt' in item)) {
          console.debug('Invalid item in calc engine cache', key, item);
          this.del(key);
          resolve(null);
          return;
        }

        if (item.ttl && item.ttl > 0 && item.ttl < Date.now()) {
          this.del(key);
          resolve(null);
          return;
        }

        resolve(item.value);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async set<T>(key: string, value: T, ttlInMilliseconds?: number) {
    if (!this.db) {
      return;
    }

    const transaction = this.db.transaction('cache', 'readwrite');
    const store = transaction.objectStore('cache');

    store.put({
      key,
      value,
      expiresAt: ttlInMilliseconds ? Date.now() + ttlInMilliseconds : -1,
    });

    if (ttlInMilliseconds) {
      // We call get as it will handle the expiry checks
      setTimeout(async () => {
        await this.get(key);
      }, ttlInMilliseconds);
    }
  }

  async del(key: string) {
    if (!this.db) {
      return;
    }

    const transaction = this.db.transaction('cache', 'readwrite');
    const store = transaction.objectStore('cache');
    store.delete(key);
  }

  private async cleanupExpiredItems() {
    if (!this.db) {
      return;
    }

    const transaction = this.db.transaction('cache', 'readwrite');
    const store = transaction.objectStore('cache');

    const request = store.openCursor();
    request.onsuccess = () => {
      const cursor = request.result;
      if (cursor) {
        const item = cursor.value;

        if (item.expiresAt && item.expiresAt < Date.now()) {
          store.delete(cursor.primaryKey);
        }
        cursor.continue();
      }
    };

    request.onerror = () => {
      console.error('Error during cleanup of expired items');
    };
  }

  clearAllWithPrefix(prefix: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.db) {
        return;
      }

      const transaction = this.db.transaction('cache', 'readwrite');
      const store = transaction.objectStore('cache');

      const request = store.openCursor();
      request.onsuccess = () => {
        const cursor = request.result;
        if (cursor) {
          const itemKey = cursor.key as string;
          if (itemKey.startsWith(prefix)) {
            store.delete(cursor.primaryKey);
          }
          cursor.continue();
        }

        resolve();
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }
}
