import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as leapAppActions from '../action/leap-app.action';
import { catchError, delayWhen, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { LeapAppService } from '@app/features/matter-addin/services';
import { select, Store } from '@ngrx/store';
import { selectCurrentMatterCore, selectFirmDetails } from '@app/core/store';
import { selectPersonPageLeapAppLoading } from '@app/features/matter-addin/store/selector';

@Injectable()
export class LeapAppEffect {

  getGlobalLeapApps$ = createEffect(() => this.actions$.pipe(
    ofType<leapAppActions.GetGlobalLeapApps>(leapAppActions.GET_GLOBAL_LEAP_APPS),
    delayWhen(() =>
      // ensure the matterCore being loaded into store
      this._store.pipe(
        select(selectFirmDetails),
        filter((firmDetails) => !!firmDetails)
      )
    ),
    withLatestFrom(this._store.pipe(select(selectFirmDetails)), (action, firmDetails) => ({ firmDetails })),
    switchMap((data) => {
      const { firmDetails } = data; // using firmDetails for filterVisibility purpose
      return this._leapAppSvc.getGlobalLeapApps().pipe(
        mergeMap((res) => {
          const apps = this._leapAppSvc.checkVisibilityForGlobalLeapApps({ firmDetails, apps: res });
          return [new leapAppActions.GetGlobalLeapAppsSuccess({ apps })];
        }),
        catchError((err) => [new leapAppActions.GetGlobalLeapAppsFail({ err })])
      );
    })
  ));


  getViewLeapApps$ = createEffect(() => this.actions$.pipe(
    ofType<leapAppActions.GetViewLeapApps>(leapAppActions.GET_VIEW_LEAP_APPS),
    delayWhen(() =>
      // ensure the matterCore being loaded into store
      this._store.pipe(
        select(selectCurrentMatterCore),
        filter((currentMatterCore) => !!currentMatterCore)
      )
    ),
    withLatestFrom(this._store.pipe(select(selectCurrentMatterCore)), (action, matterCore) => ({ action, matterCore })),
    switchMap((data) => {
      const { action, matterCore } = data; // using matterCore for filterVisibility purpose
      const { viewId } = action.payload;
      const { matterTypeId, state } = matterCore;
      return this._leapAppSvc.getViewLeapApp({ viewId, matterTypeId, state }).pipe(
        mergeMap((res) => {
          const apps = this._leapAppSvc.checkVisibilityForViewLeapApps({ matterCore, apps: res });
          return [new leapAppActions.GetViewLeapAppsSuccess({ apps })];
        }),
        catchError((err) => [new leapAppActions.GetViewLeapAppsFail({ err })])
      );
    })
  ));


  getCardPageLeapApps$ = createEffect(() => this.actions$.pipe(
    ofType<leapAppActions.GetCardPageLeapApps | leapAppActions.GetCardModalPageLeapApps>(
      leapAppActions.GET_CARD_PAGE_LEAP_APPS,
      leapAppActions.GET_CARD_MODAL_PAGE_LEAP_APPS
    ),
    switchMap((action) => {
      const { viewId } = action.payload;
      const isModelPage = action.type === leapAppActions.GET_CARD_MODAL_PAGE_LEAP_APPS;
      return this._leapAppSvc.getViewLeapApp({ viewId }).pipe(
        mergeMap((res) => isModelPage
        ? [new leapAppActions.GetCardModalPageLeapAppsSuccess({ apps: res })]
        : [new leapAppActions.GetCardPageLeapAppsSuccess({ apps: res })]),
        catchError((err) =>
          isModelPage
            ? [new leapAppActions.GetCardModalPageLeapAppsFail({ err })]
            : [new leapAppActions.GetCardPageLeapAppsFail({ err })]
        )
      );
    })
  ));


  getPersonPageLeapApps$ = createEffect(() => this.actions$.pipe(
    ofType<leapAppActions.GetPersonPageLeapApps>(leapAppActions.GET_PERSON_PAGE_LEAP_APPS),
    withLatestFrom(
      this._store.pipe(select(selectPersonPageLeapAppLoading)),
      (action, loading) => ({viewId: action.payload.viewId, loading})
    ),
    switchMap((data) => {
      const { viewId, loading } = data;
      if (!loading) {
        return [];
      }
      return this._leapAppSvc.getViewLeapApp({ viewId }).pipe(
        mergeMap((res) => [new leapAppActions.GetPersonPageLeapAppsSuccess({ apps: res })]),
        catchError((err) => [new leapAppActions.GetPersonPageLeapAppsFail({ err })])
      );
    })
  ));

  constructor(private actions$: Actions, private _store: Store<any>, private _leapAppSvc: LeapAppService) {}
}
