import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({
  selector: 'sc-footer-cell',
  templateUrl: './footer-cell.component.html',
})
export class FooterCellComponent implements AgRendererComponent {
  content: string;
  isPrimaryFooter: boolean;
  isHidden: boolean;
  isTitle: boolean;
  wrapperClass: string;
  iconButton: boolean;

  constructor() {}

  @HostBinding('class')
  classes;

  paramHandler<T>(property: T | ((data: any) => T), data: any): T | null {
    if (property == null || property === undefined) return null;
    return typeof property === 'function' ? (property as (data: any) => T)(data) : property;
  }

  //Footer will now support Function and Property Setters
  agInit(params: any): void {
    this.isPrimaryFooter = this.paramHandler<boolean>(params?.getIsPrimaryFooter, params.data) ?? false;
    const defaultClass = this.isPrimaryFooter ? 'x-ag-footer-primary' : 'x-ag-footer';
    this.wrapperClass = this.paramHandler<string>(params?.getWrapperClass, params.data) ?? '';
    this.classes = defaultClass + ' ' + this.wrapperClass;
    this.isHidden = this.paramHandler<boolean>(params?.getIsHidden, params.data) ?? false;
    this.isTitle = this.paramHandler<boolean>(params?.getIsTitle, params.data) ?? false;
    this.content = this.paramHandler<string>(params?.getContent, params.data) ?? '';
    this.iconButton = this.paramHandler<boolean>(params.iconButton, params.data) ?? false;
  }

  refresh(params: any): boolean {
    return true;
  }
}
