import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { StartupService } from '@app/core/services';
import { PersonUtilsService, PersonUtilsV2Service } from '@app/shared/services';
import { CardAddressService } from './card-address.service';
import { CardPersonDetailsService } from '@app/features/+card/services/card-person-details.service';
import { EPrintSource, PrintService } from '@app/shared/services/print/print.service';
import { CardDocsCore, CardViewModel, EAddressType, ECardType, INamedList, SalutationTitleType, TitleType } from '../models';
import { AppApiService } from '@app/core/api/app-api.service';

import { BaseService } from '@app/shared/services/base/base.service';
import { CUSTOMISE_LIST_OPTION_VALUE } from '@app/shared/models';
import { isBoolean, isEmptyObj, isEmptyValue, isNumber, uniqBy } from '@server/modules/shared/functions';
import { formatDateHttp } from '@app/shared/utils';
import { environment } from '@env/environment';
import { CardRelatedMatterDocsCore } from '../models/card-view.model';
import { CardAddressV2Service } from './card-address-v2.service';


@Injectable()
export class CardDetailsV2Service extends BaseService {

  constructor(
    private http: HttpClient,
    private startupSvc: StartupService,
    private addressSvc: CardAddressV2Service,
    private printSvc: PrintService,
    private appApiSvc: AppApiService,
    private personUtilsSvc: PersonUtilsV2Service,
    private personDetailsSvc: CardPersonDetailsService
  ) {
    super();
  }

  public loadCard(id: string): Observable<CardViewModel> {
    const url = this.urlJoin(`${environment.config.endpoint.docsV2}`, `/api/v1/cards/${id}`);
    return this.http.get<CardViewModel>(url);
  }

  public createFormValue(options: CardViewModel): Partial<CardViewModel> {
    const id: string = isEmptyValue(options.id) === false ? options.id : uuidv4();

    let addresses = options.addresses ?? [];
    let futureAddresses = options.futureAddresses ?? [];

    if (addresses !== undefined && addresses.length > 0) {
      addresses = uniqBy([...addresses], 'addressType');
      addresses = this.addressSvc.sort(addresses);
    } else {
      addresses = [];
    }

    if (futureAddresses !== undefined && futureAddresses.length > 0) {
      futureAddresses = uniqBy([...futureAddresses], 'addressType');
      futureAddresses = this.addressSvc.sort(futureAddresses);
    } else {
      futureAddresses = [];
    }

    const useDefaultTitle = isBoolean(options.useDefaultTitle) === true ? options.useDefaultTitle : options.titleType === TitleType.Default;
    const useDefaultDear = isBoolean(options.useDefaultDear) === true ? options.useDefaultDear : options.salutationTitleType !== SalutationTitleType.Custom;
    const useFriendlyDear = isBoolean(options.useFriendlyDear) === true ? options.useFriendlyDear : options.salutationTitleType === SalutationTitleType.DefaultFriendly;
    const title = useDefaultTitle ? options.autoTitle ?? '' : options.title ?? '';
    const userDear = useDefaultDear
      ? useFriendlyDear
        ? options.autoDearInformal ?? ''
        : options.autoDearFormal ?? ''
      : options.userDear ?? options.salutationTitle ?? '';

    const persons = options.persons
      ? options.persons.map((person) => this.personUtilsSvc.createPerson(person))
      : [];
    const phoneNumbers =
      options.phoneNumbers && options.phoneNumbers.length > 0
        ? options.phoneNumbers
          .filter((phone) => !!phone.value)
          .map((phone) => this.personUtilsSvc.createPhone(phone.numberType, phone))
        : [];
    const web =
      options.webAddresses && options.webAddresses.length > 0
        ? options.webAddresses
          .filter((w) => (w.type === 'Web' || w.type === 'Web2') && !!w.address)
          .map((w) => this.personUtilsSvc.createWebAddress(w.type, w)) || []
        : [];
    const emails =
      options.webAddresses && options.webAddresses.length > 0
        ? options.webAddresses
          .filter((w) => (w.type === 'Email' || w.type === 'Email2') && !!w.address)
          .map((w) => this.personUtilsSvc.createWebAddress(w.type, w)) || []
        : [];
    const webAddresses = [...emails, ...web];

    return {
      isNew: !options.id,
      id,
      firmId: options.firmId || this.startupSvc.userDetails.firmId,
      type: options.type || ECardType.People,
      asicKey: options.asicKey || '',
      bankAccount: {
        bankAccountBSB: options.bankAccountBSB,
        bankAccountName: options.bankAccountName,
        bankAccountNumber: options.bankAccountNumber,
      },
      isSupplier: !!options.isSupplier,
      marketingConsent: !!options.marketingConsent,
      useDefaultTitle,
      useFriendlyDear,
      useDefaultDear,
      comments: options.comments || '',
      title,
      userDear,
      persons,
      addresses: addresses || [],
      addressSettings: {
        defaultAddress: options.addressSettings?.defaultAddress || EAddressType.STREET,
      },
      futureAddresses: futureAddresses || [],
      futureAddressSettings: {
        defaultAddress:
          options.futureAddressSettings && options.futureAddressSettings.defaultAddress
            ? options.futureAddressSettings.defaultAddress
            : null,
        startDate:
          options.futureAddressSettings && options.futureAddressSettings.startDate
            ? options.futureAddressSettings.startDate
            : null,
        endDate:
          options.futureAddressSettings && options.futureAddressSettings.endDate
            ? options.futureAddressSettings.endDate
            : null,
      },
      phoneNumbers,
      webAddresses,
      deleteCode: isNumber(options.deleteCode) === true ? options.deleteCode : 0,
      version: isNumber(options.version) === true ? options.version : 0,
      abn: options.abn ?? '',
      tin: options.tin ?? '',

      tradingName: options.tradingName ?? '',
      propertyBuildingName: options.propertyBuildingName ?? '',

      acn: options.acn ?? '',
      acnArbnEtc: options.acnArbnEtc ?? '',
      acln: options.acln ?? '',

      soleDirector: options.soleDirector ?? false,

      companyName: options.companyName ?? '',
      companyTitle: options.companyTitle ?? '',
      tradingTitle: options.tradingTitle ?? '',
      businessType: options.businessType ?? '',
      trustDate: options.trustDate ?? formatDateHttp(new Date(), { includeTimezoneOffset: false, preserveTime: false }),
      trustStatus: options.trustStatus ?? '',
      trustees:
        options.trustees && options.trustees.length > 0
          ? options.trustees.map((trustee) => ({ ...trustee, id: uuidv4() }))
          : [],

      autoTitle: options.autoTitle || '',
      autoDearFormal: options.autoDearFormal || '',
      autoDearInformal: options.autoDearInformal || '',

      reference: isEmptyValue(options.reference) ? null : options.reference,
      userTitle: title,
      fullName: options.fullName ?? '',
      shortName: options.shortName ?? '',
      documents: options.documents,
      addressesDisplayValue: options.addressesDisplayValue,
      futureAddressesDisplayValue: options.futureAddressesDisplayValue,
      regOffice: options.regOffice,
      fields: options.fields
    };
  }

  public formatFormValue(formValue: CardViewModel): CardViewModel {
    if (!!formValue.trustDate) {
      formValue = {
        ...formValue,
        trustDate: new Date(formValue.trustDate).toISOString(),
      };
    }

    if (formValue.futureAddressSettings) {
      formValue = {
        ...formValue,
        futureAddressSettings: {
          ...formValue.futureAddressSettings,
          startDate: formValue.futureAddressSettings.startDate
            ? formatDateHttp(new Date(formValue.futureAddressSettings.startDate), {
              includeTimezoneOffset: false,
              preserveTime: false,
            })
            : null,
          endDate: formValue.futureAddressSettings.endDate
            ? formatDateHttp(new Date(formValue.futureAddressSettings.endDate), {
              includeTimezoneOffset: false,
              preserveTime: false,
            })
            : null,
        },
      };
    }

    return {
      ...formValue,
      trustees: formValue.trustees?.filter((trustee) => !!trustee.trusteeName) ?? [],
    };
  }

  /**
   * Handle option being selected in named list
   */
  public handleOptionSelect(params: { option: string; namedList: INamedList; formControlName: string }) {
    const { option, namedList, formControlName } = params;
    if (option === CUSTOMISE_LIST_OPTION_VALUE) {
      this.appApiSvc.navigate({
        path: [{ outlets: { overlay: ['custom-list'] } }],
        query: { listId: namedList.baseId || namedList.id, fieldId: formControlName },
        extras: { skipLocationChange: true },
      });
    }
  }

  private mapToDocsCoreModel = (data: CardViewModel) => {
    const { isNew, autoTitle, autoDearFormal, autoDearInformal,
      bankAccount, deleteCode, lastModifiedUserId, addressesDisplayValue, futureAddressesDisplayValue,
      useDefaultDear,
      useDefaultTitle,
      useFriendlyDear,
      userDear,
      userTitle,
      ...onlyValids } = data;

    const valids = { ...onlyValids };

    valids.personIds = valids.persons?.map(p => p.id) ?? [];
    valids.bankAccountBSB = bankAccount?.bankAccountBSB ?? null;
    valids.bankAccountName = bankAccount?.bankAccountName ?? null;
    valids.bankAccountNumber = bankAccount?.bankAccountNumber ?? null;

    valids.salutationTitle = userDear ?? '';
    if (useDefaultDear) {
      valids.salutationTitleType = useFriendlyDear ? SalutationTitleType.DefaultFriendly : SalutationTitleType.DefaultFormal;
    } else {
      valids.salutationTitleType = SalutationTitleType.Custom;
    }

    valids.title = userTitle ?? '';
    valids.titleType = useDefaultTitle ? TitleType.Default : TitleType.Custom;
    valids.addresses = valids.addresses ?? [];
    valids.futureAddresses = valids.futureAddresses ?? [];

    return valids as CardDocsCore;
  };

  public save = (card: CardViewModel) => {
    const validModel = this.mapToDocsCoreModel(card);
    const url = this.urlJoin(environment.config.endpoint.docsV2, `api/v1/cards/${card.id}`);
    return this.http.put(url, validModel);
  };

  public delete(cardId: string): Observable<any> {
    const url = this.urlJoin(environment.config.endpoint.docsV2, `api/v1/cards/${cardId}/deleteCode`);
    return this.http.patch(url, { deleteCode: 1 });
  }

  public undelete(cardId: string): Observable<any> {
    const url = this.urlJoin(environment.config.endpoint.docsV2, `api/v1/cards/${cardId}/deleteCode`);
    return this.http.patch(url, { deleteCode: 0 });
  }

  public hasValidPerson(card: CardViewModel): boolean {
    return isEmptyObj(card) === true
      ? false
      : card.type === ECardType.People
        ? !this.personDetailsSvc.isEmptyPerson(card.persons)
        : true;
  }

  public statementOfAccountPrint(cardId: string) {
    this.printSvc.print(
      'APP_Office_Statement_Client_R1178',
      { Client: cardId, FirmGUID: this.startupSvc.userDetails.firmId },
      EPrintSource.Custom
    );
  }

  public getMatters(cardId: string): Observable<CardRelatedMatterDocsCore[]> {
    const url = this.urlJoin(environment.config.endpoint.docsV2, `api/v1/cards/${cardId}/matters`);
    return this.http.get<CardRelatedMatterDocsCore[]>(url);
  }
}
