import { Injectable } from '@angular/core';

import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import { Regions } from '@app/shared/models/config.model';
import { BrandService } from '@app/core/services';
import { AddressDocsCore, AddressType } from '../models';
import { sortByAsc } from '@app/shared/functions';
import { everyMatchCondition, isEmptyValue, maxBy } from '@server/modules/shared/functions';

@Injectable()
export class CardAddressV2Service {
  constructor(private _brandSvc: BrandService) { }

  private SORT_ORDER = {
    Street: 1,
    POBox: 2,
    DX: 3,
  };

  private STREET_DIRECTION = [
    'east',
    'est',
    'north',
    'nord',
    'northeast',
    'nord-est',
    'northwest',
    'nord-ouest',
    'south',
    'sud',
    'southeast',
    'sud-est',
    'southwest',
    'sud-ouest',
    'west',
    'ouest',
  ];

  public sort(addresses: AddressDocsCore[]): AddressDocsCore[] {
    // note: not sure why we are doing this.
    const maxIdx = maxBy(Object.values(this.SORT_ORDER), (order: number) => order) + 1;
    return sortByAsc(addresses, [
      (address: AddressDocsCore) => this.SORT_ORDER[address.addressType],
      (_address: AddressDocsCore) => maxIdx
    ]);
  }

  public create(addressType: AddressType = 'Street', options?: Partial<AddressDocsCore>): AddressDocsCore {
    return {
      addressType,
      type: options?.type ?? '',
      propertyBuildingName: options?.propertyBuildingName ?? '',
      levelUnitShop: options?.levelUnitShop ?? '',
      number: options?.number ?? '', // eslint-disable-line
      street: options?.street ?? '',
      streetDirection: options?.streetDirection ?? '',
      suburb: options?.suburb ?? '',
      county: options?.county ?? '',
      state: options?.state ?? '',
      postcode: options?.postcode ?? '',
      country: options?.country ?? '',
      specialInstructions: options?.specialInstructions ?? '',
      district: options?.district ?? '',
      municipality: options?.municipality ?? '',
      stateType: options?.stateType ?? '',
      stationInfo: options?.stationInfo ?? ''
    };
  }

  public isEmpty(address: AddressDocsCore | AddressDocsCore[]): boolean {
    const addresses: AddressDocsCore[] = Array.isArray(address) ? address : [address];

    return everyMatchCondition(addresses, (a) => everyMatchCondition(
      [
        a.propertyBuildingName,
        a.levelUnitShop,
        a.number,
        a.street,
        a.suburb,
        a.county,
        a.state,
        a.postcode,
        a.country,
      ],
      (value) => isEmptyValue(value)
    ));
  }


  /**
   * Processing google maps results
   * based on https://leapdev.atlassian.net/wiki/spaces/LS/pages/803143892/Mapping+Google+Places+API+result+to+LEAP+address
   * */
  public processGoogleMaps(result: google.maps.places.PlaceResult): AddressDocsCore {
    const envRegion = this._brandSvc.brandRegion;
    const components: GeocoderAddressComponent[] = result.address_components;

    const levelUnitShop: GeocoderAddressComponent = this.getComponent(components, 'subpremise');
    const number: GeocoderAddressComponent = this.getComponent(components, 'street_number'); // eslint-disable-line
    const route: GeocoderAddressComponent = this.getComponent(components, 'route');
    const street = envRegion === Regions.CA ? this.getStreet(route?.long_name) : route?.long_name;
    const streetDirection = envRegion === Regions.CA ? this.getStreetDirection(route?.long_name) : '';
    const suburb: GeocoderAddressComponent =
      envRegion === Regions.AU || envRegion === Regions.CA || envRegion === Regions.NZ
        ? this.getComponent(components, 'locality')
        : this.getComponent(components, 'neighborhood') ||
        this.getComponent(components, 'postal_town') ||
        this.getComponent(components, 'locality');
    const country: GeocoderAddressComponent = this.getComponent(components, 'country');
    const state: GeocoderAddressComponent =
      envRegion === Regions.UK && country && country.short_name === 'GB'
        ? this.getComponent(components, 'administrative_area_level_2')
        : this.getComponent(components, 'administrative_area_level_1');
    const postcode: GeocoderAddressComponent = this.getComponent(components, 'postal_code');
    const county: GeocoderAddressComponent =
      envRegion === Regions.US ? this.getComponent(components, 'administrative_area_level_2') : undefined;

    const options = {
      levelUnitShop: levelUnitShop === undefined ? '' : levelUnitShop.short_name,
      number: number === undefined ? '' : number.short_name, // eslint-disable-line
      street: street === undefined ? '' : street,
      streetDirection: streetDirection === undefined ? '' : streetDirection,
      suburb: suburb === undefined ? '' : suburb.long_name,
      state: state === undefined ? '' : state.short_name,
      county: county === undefined ? '' : county.short_name,
      country: country === undefined ? '' : country.long_name,
      postcode: postcode === undefined ? '' : postcode.long_name,
    } as Partial<AddressDocsCore>;

    return this.create('Street', options);
  }

  private getComponent(components: GeocoderAddressComponent[], type: string): GeocoderAddressComponent {
    return components.find((component) => component.types.includes(type));
  }

  private getStreet = (route: string) => {
    if (route) {
      const split = route.split(' ');
      const candidate = split[split.length - 1].toLowerCase();
      const isStreetDirection = this.STREET_DIRECTION.findIndex((r) => candidate === r) !== -1;
      if (isStreetDirection) {
        if (split.length > 1) {
          return split.slice(0, split.length - 1).join(' ');
        }
      }

      return route;
    }

    return '';
  };

  private getStreetDirection = (route: string) => {
    if (route) {
      const split = route.split(' ');
      const candidate = split[split.length - 1].toLowerCase();
      const isStreetDirection = this.STREET_DIRECTION.findIndex((r) => candidate === r) !== -1;
      if (isStreetDirection) {
        if (split.length > 1) {
          return split.slice(split.length - 1).join(' ');
        }
      }
    }

    return '';
  };
}
