import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPeopleResponseByDocAPI, IPersonListResponseSchema } from '@app/features/+person/models';
import { map } from 'rxjs/operators';
import { IPerson } from '@app/shared/models';
import { PersonDocsCore } from '@app/features/+card/models/card-docscore.model';
import { mapPersonFromDocsCore, mapPersonToDocsCore } from '@app/shared/functions/person-mapping.function';

@Injectable()
export class PersonV2Service extends BaseService {
  constructor(private _http: HttpClient) {
    super();
  }

  public getAll(lastRowVer = 0, shardId = 'myShardId', _isReadFromLocalSource: boolean = false): Observable<IPeopleResponseByDocAPI> {
    const url = `${this.docsV2Path}/api/v1/persons?lastRowVersion=${lastRowVer}&shardId=${shardId}`;
    return this._http.get<IPeopleResponseByDocAPI>(url).pipe(
      map((res: IPeopleResponseByDocAPI) => ({
        ...res,
        data: res.data ?? [],
      }))
    );
  }

  getPerson(id: string): Observable<IPerson> {
    const url = `${this.docsV2Path}/api/v1/persons/${id}`;
    return this._http.get<PersonDocsCore>(url).pipe(
      map((personV2) => mapPersonFromDocsCore(personV2))
    );
  }

  savePersons(persons: IPerson[]): Observable<any> {
    const requestBody = mapPersonToDocsCore(persons[0]);
  
    const url = `${this.docsV2Path}/api/v1/persons/${persons[0].__id}`;
  
    return this._http.put<PersonDocsCore>(url, requestBody).pipe(
      map((updatedPersonV2) => mapPersonFromDocsCore(updatedPersonV2))
    );
  }

  getRelatedCardsIds(id: string): Observable<string[]> {
    const url = `${this.docsV2Path}/api/v1/persons/${id}/related-cards`;
    return this._http.get<string[]>(url);
  }

  getRelatedMattersIds(id: string): Observable<string[]> {
    const url = `${this.docsV2Path}/api/v1/persons/${id}/related-matters`;
    return this._http.get<string[]>(url);
  }
}
