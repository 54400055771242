import { createAction, props } from '@ngrx/store';
import {
  DocumentShareRecipient,
  MatterCardEmailModel,
  SetUserAccessResponse,
  ShareDocumentUIModel,
  RevokeAccessResponse,
  LCIntegratorTicketResponse,
  DocAPIDocsResponse,
  ShareViaOutlookResponse,
  FolderAccessResponse,
  CurrrentFolderAccessResponse,
  CurrentFolderAccessStatus,
  LCIntegratorFolderTicketResponse,
} from '@app/features/+document-share/models/models';
import { IDoc, IDocsFolder } from '@app/shared/models';
import { ELawConnectPathOptions } from '@app/features/+lawconnect-share/models/models';

export const InitLawConnectFolderShareStart = createAction(
  '[folder share] lawconnect start',
  props<{ path: ELawConnectPathOptions; folders: IDocsFolder[] }>()
);

export const InitFolderShareByTicketIdStart = createAction(
  '[folder share] init folder share by ticket id',
  props<{ ticketId: string }>()
);

export const InitFolderShareByTicketIdSuccess = createAction(
  '[folder share] init folder share by ticket id success',
  props<{ data: LCIntegratorFolderTicketResponse }>()
);

export const InitFolderShareByTicketIdFailure = createAction(
  '[folder share] init folder share by ticket id failure',
  props<{ error: any }>()
);

export const GetFoldersSuccess = createAction(
  '[folder share] get folders success',
  props<{ data: DocAPIDocsResponse[] }>()
);

export const GetFoldersFailure = createAction('[folder share] get folders failure', props<{ error: any }>());

export const InitFolderShare = createAction('[folder share] init folder sharing', props<{ folders: IDocsFolder[] }>());

export const UpdateSelectedFolders = createAction(
  '[folder share] update selected folders',
  props<{ folders: IDocsFolder[] }>()
);

export const GetFolderUserAccessStart = createAction(
  '[folder share] get user access per folder start',
  props<{ folders: IDocsFolder[]; matterId: string }>()
);

export const GetFolderUserAccessSuccess = createAction(
  '[folder share] get user access per folder success',
  props<{ result: CurrentFolderAccessStatus }>()
);

export const GetFolderUserAccessFailure = createAction(
  '[folder share] get user access per folder failure',
  props<{ error: any }>()
);

export const UpdateFolderAccessList = createAction(
  '[folder share] update revoke and updated permission user list in store',
  props<{ updateAccessRow: DocumentShareRecipient }>()
);

export const RemoveFromSharedFolders = createAction(
  '[folder share] remove this folder from shared folders',
  props<{ docId: string }>()
);

export const ManageFolderAccessStart = createAction(
  '[folder share] manage access on selected folder start',
  props<{ formObject?: ShareDocumentUIModel }>()
);

export const ManageFolderAccessSuccess = createAction(
  '[folder share] manage access on selected folder success',
  props<{ count: number; response: FolderAccessResponse }>()
);

export const ManageFolderAccessFailure = createAction(
  '[folder share] manage access on selected folder failure',
  props<{ error: any }>()
);

export const ClearFolderEmailRecipients = createAction('[folder share] clear email recipients');

export const ClearFolderShareState = createAction('[folder share] clear folder share state');

export const ClearFolderShareProcessingState = createAction('[folder share] clear error, loading and success state');

export const ClearFolderOutlookTicketId = createAction('[folder share] clear outlook ticket id');
