import { createReducer, on, Action } from '@ngrx/store';
import * as appActions from '../actions/app.action';
import * as tableMappingActions from '../actions/tablemapping.action';
import * as sidebarActions from '../actions/sidebar.action';
import { ListMattersSuccess } from '@app/features/+matter-list/store/actions/matter-list'; // Created with createAction
import * as pubnubActions from '../actions/pubnub.action';
import { TableMapping, FirmDetails } from '../../../shared/models';
import { Preference } from '@app/shared/models/user-preferences.model';
import { IGlobalUIPreference } from '../../models';
import { IMatterCore, IMatterListEntry } from '@app/features/+matter-list/models';
import * as recurringMatterActions from '@app/features/+recurring-matter-details/store/actions';
import { UserInfo } from '@leapdev/auth-agent/src/lib/types';

export interface CoreState {
  matterEntry: IMatterListEntry;
  matterCore: IMatterCore;
  tableMappings: TableMapping[];
  userPreferences: Preference[];
  userPreferencesDefaults: any;
  uiPreference: IGlobalUIPreference;
  firmDetails: FirmDetails;
  userDetails: UserInfo;
  appDisplayLoading: boolean;
  appEnableCloseNotification: boolean;
  appLoadingMessage: string;
  recurringMatterName: string;
  walkmeReady: boolean;
}

export const INIT_STATE: CoreState = {
  tableMappings: [],
  recurringMatterName: null,
  userPreferences: undefined,
  userPreferencesDefaults: undefined,
  uiPreference: {
    asideExpanded: true,
    headerHidden: false,
    officeOnline: false,
    previewAsideExpanded: true,
    resizeExpanded: false,
  },
  matterEntry: undefined,
  matterCore: undefined,
  firmDetails: undefined,
  userDetails: undefined,
  appDisplayLoading: false,
  appEnableCloseNotification: false,
  appLoadingMessage: undefined,
  walkmeReady: false,
};

// Create a reducer using createReducer for the action that uses createAction
const createReducerPart = createReducer(
  INIT_STATE,

  // Handle ListMattersSuccess (created using createAction)
  on(ListMattersSuccess, (state, { response }) => {
    const currentMatterId = state.matterEntry ? state.matterEntry.matterId : null;
    const currentMatter = response?.data?.find((d) => d.matterId === currentMatterId);
    return currentMatter ? { ...state, matterEntry: currentMatter } : state;
  })
);

// Fallback switch-case for the rest of the actions (traditional Action classes)
export const reducer = (state: CoreState = INIT_STATE, action: Action): CoreState => {
  state = createReducerPart(state, action); // Process the createAction part first

  // Traditional switch-case for old-style Action class actions
  switch (action.type) {
    case appActions.GET_MATTER_CORE_SUCCESS: {
      return {
        ...state,
        matterCore: (action as any).payload?.matterCore,
      };
    }

    case appActions.SET_CURRENT_MATTER: {
      return {
        ...state,
        matterEntry: (action as any).payload?.matter,
      };
    }

    case recurringMatterActions.SELECT_RECURRING_MATTER_SUCCESS: {
      return {
        ...state,
        recurringMatterName: (action as any).payload?.name,
      };
    }

    case recurringMatterActions.CLEAR_RECURRING_MATTER_DETAILS: {
      return {
        ...state,
        recurringMatterName: null,
      };
    }

    case tableMappingActions.ADD_TABLE_MAPPING_SUCCESS: {
      return {
        ...state,
        tableMappings: (action as any).payload as TableMapping[],
      };
    }

    case appActions.LOAD_USER_PREFERENCE_SUCCESS: {
      return {
        ...state,
        userPreferences: (action as any).payload?.userPreferences,
        userPreferencesDefaults: (action as any).payload?.userPreferencesDefaults,
      };
    }

    case appActions.UPDATE_USER_PREFERENCE_SUCCESS: {
      return {
        ...state,
        userPreferences: (action as any).payload,
      };
    }

    case appActions.LOAD_UI_PREFERENCE_DB_SUCCESS: {
      return {
        ...state,
        uiPreference: (action as any).payload as IGlobalUIPreference,
      };
    }

    case appActions.SAVE_PREVIEW_UI_PREFERENCE:
    case appActions.SAVE_UI_PREFERENCE: {
      return {
        ...state,
        uiPreference: {
          ...state.uiPreference,
          ...((action as any).payload as Partial<IGlobalUIPreference>),
        },
      };
    }

    case appActions.SAVE_MATTER: {
      return {
        ...state,
        matterEntry: (action as any).payload as IMatterListEntry,
      };
    }

    case appActions.ADD_FIRM_DETAILS_SUCCESS: {
      const firmDetails = (action as any).payload as FirmDetails;
      return {
        ...state,
        firmDetails: { ...firmDetails, emailIntegrationLinked: !!state?.firmDetails?.emailIntegrationLinked },
      };
    }

    case appActions.UPDATE_FIRM_CONSENT: {
      const isGranted = (action as any).payload as boolean;
      return {
        ...state,
        firmDetails: { ...state?.firmDetails, emailIntegrationLinked: isGranted },
      };
    }

    case appActions.UPDATE_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: (action as any).payload as UserInfo,
      };
    }

    case appActions.APP_LOADING: {
      return {
        ...state,
        appDisplayLoading: (action as any).payload,
        appLoadingMessage: !!(action as any).payload ? state.appLoadingMessage : undefined,
      };
    }

    case appActions.SET_APP_CLOSE_NOTIFICATION: {
      return {
        ...state,
        appEnableCloseNotification: (action as any).payload,
      };
    }

    case appActions.SET_APP_LOADING_MESSAGE: {
      return {
        ...state,
        appLoadingMessage: (action as any).payload,
      };
    }

    case appActions.CLEAR_APP_LOADING_MESSAGE: {
      return {
        ...state,
        appLoadingMessage: undefined,
      };
    }

    case appActions.CLEAR_MATTER: {
      return {
        ...state,
        matterEntry: null,
        matterCore: null,
      };
    }

    case appActions.SET_WALKME_READY:
      return {
        ...state,
        walkmeReady: (action as any).payload.status,
      };

    default:
      return state;
  }
};
