import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  delayWhen,
  exhaustMap,
  filter as rxjsFilter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as matterAddinActions from '../action';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, of, pipe, throwError } from 'rxjs';
import { LeapAppService, MatterAddinService } from '@app/features/matter-addin/services';

import { IMatterCore, IMatterListEntry } from '@app/features/+matter-list/models';
import { selectAllAddins, selectPageNumber } from '../selector/matter-addin.selector';
import { AppApiService } from '@app/core/api';
import {
  selectCurrentMatter,
  selectCurrentMatterCore,
  selectCurrentMatterId,
  selectRouterSnapshot,
  selectUserPreferences,
} from '@app/core/store';
import { BrandService, EventBusService } from '@app/core/services';
import { OfflineLauncherService } from '@app/shared/services';
import * as currentMatterActions from '@app/features/+matter-details/store/actions/current-matter';
import { EInfoTrackType, ESiriusEvents, UserPreferenceTypes } from '@app/core/models';
import { selectDetailEntries, selectDetailInfo } from '@app/features/+matter-details/store';
import { LayoutRendererService } from '@app/features/+layout-renderer/services';
import { AutomationActionType, IDefinableTable, IDoc, IOpenPrecedentTicketParams } from '@app/shared/models';
import {
  EmailDocumentsCommand,
  OpenCommand,
  OpenTableCommand,
  ShareCommand,
} from '@app/shared/models/leap-host.models';
import { selectTrustBankAccounts } from '@app/features/+trust/store';
import { selectRoot } from '@app/features/+correspondence/store/selectors';
import * as correspondenceActions from '@app/features/+correspondence/store/actions/correspondence';
import * as documentShareActions from '@app/features/+document-share/store/actions';
import { LeapHostHandlerService } from '@app/features/matter-addin/services/leap-host-handler.service';
import { CorrespondenceService } from '@app/features/+correspondence/services';
import { IDocCombinePDFHttpRequestParams, IPdfServiceDocumentDTO } from '@app/features/+create-pdf/models';
import * as appActions from '@app/core/store/actions';
import * as createPDFActions from 'app/features/+create-pdf/store/actions';
import * as attachCorrespondenceActions from '@app/features/+create-pdf/store/actions';
import { Attachment, AttachmentType } from '@app/features/+email/models';
import { DocumentIconHelper } from '@app/shared/utils';
import { OfficePreferences } from '@app/core/constants/preferences.constant';
import { IPrecedent } from '@app/features/+precedent/models';
import { Addin, AddinButton } from '@app/features/matter-addin/models';
import { LEGAL_AID_BILLING_MODE } from '@app/features/accounting/constants';
import { selectViewLeapApps } from '../selector/leap-app.selector';
import { PrecedentService } from '@app/features/+precedent/services/precedent/precedent.service';

@Injectable()
export class MatterAddinEffect {

  matterAddinInit$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinInit>(matterAddinActions.MATTER_INIT),
    withLatestFrom(
      this._store.pipe(select(selectRouterSnapshot)),
      this._store.pipe(select(selectPageNumber)),
      this._store.pipe(select(selectAllAddins)),
      (action, snapshot, currentPageNumber, allAddins) => ({ snapshot, currentPageNumber, allAddins })
    ),
    switchMap((data) => {
      const { snapshot, currentPageNumber, allAddins } = data;
      let route = snapshot;

      // we loop through to get the latest route
      while (route.children && route.children.length > 0) {
        const result = this.getPrimaryRoute(route.children);
        if (result) {
          route = result;
        } else {
          break; // make sure the route has value
        }
      }

      const pageNumber = route.data.pageNumber || 0;
      const actions = [];

      // get all all addins
      if (!allAddins || allAddins.length === 0) {
        actions.push(new matterAddinActions.MatterAddinAllInitStart(0));
      }

      if (pageNumber !== 0 && pageNumber !== currentPageNumber) {
        actions.push(new matterAddinActions.MatterAddinInitStart({ pageNumber }));
        return actions;
      } else {
        return actions;
      }
    })
  ));


  matterAddinAllInitStart$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinAllInitStart>(matterAddinActions.MATTER_ADDIN_ALL_INIT_START),
    exhaustMap((action) =>
      forkJoin([
        this._matterAddinSvc.loadAddins(),
        !this._brandSvc.isTitleX ? this._matterAddinSvc.getEnabledAddins() : of(null),
      ]).pipe(
        mergeMap((data) => {
          const [addins, enabledAddins] = data;
          const addinsContextMenu = [];
          const filteredAddins = (!!enabledAddins
            ? addins.filter((addin) => enabledAddins.includes(addin.Name))
            : addins
          ).map((addin: Addin) => {
            const a = (addin ? { ...addin } : {}) as Addin;
            a.Button.forEach((button) => {
              // currently must sanitize buttons and dropdown menus for addin
              button.Label = button.Label.replace(/\\/g, ' ');
              button.URL = button.URL.indexOf('?') > -1 ? `${button.URL}&isWeb=true` : `${button.URL}?isWeb=true`;
              button.MessageBus = !!addin.MessageBus;
              button.DropdownMenu.forEach((dropdown) => {
                dropdown.MessageBus = !!addin.MessageBus;
                dropdown.Title = dropdown.Title.replace(/\\/g, ' ');
                dropdown.URL =
                  dropdown.URL.indexOf('?') > -1 ? `${dropdown.URL}&isWeb=true` : `${dropdown.URL}?isWeb=true`;
              });
            });
            if (!!a.ContextMenu && a.ContextMenu.length > 0) {
              addinsContextMenu.push('separator');
            }
            a.ContextMenu.forEach((contextMenu) => {
              contextMenu.MessageBus = !!addin.MessageBus;
              // must sanitize any context menu information
              contextMenu.Title = contextMenu.Title.replace(/\\/g, ' ');
              contextMenu.URL =
                contextMenu.URL.indexOf('?') > -1 ? `${contextMenu.URL}&isWeb=true` : `${contextMenu.URL}?isWeb=true`;
              addinsContextMenu.push(contextMenu);
            });
            return a;
          });
          return of(new matterAddinActions.MatterAddinAllInitSuccess(filteredAddins));
        }),
        catchError(() => of(new matterAddinActions.MatterAddinAllInitFailure(0)))
      )
    )
  ));


  matterAddinInitStart$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinInitStart>(matterAddinActions.MATTER_ADDIN_INIT_START),
    delayWhen(() =>
      // must ensure that matterCore is loaded and its id is the same as currentMatterId
      combineLatest([
        this._store.pipe(select(selectAllAddins)),
        this._store.pipe(select(selectCurrentMatterId)),
        this._store.pipe(select(selectCurrentMatterCore)),
      ]).pipe(
        rxjsFilter((data) => {
          const [allAddins, currentMatterId, currentMatterCore] = data;
          return (
            !!allAddins &&
            allAddins.length > 0 &&
            !!currentMatterId &&
            !!currentMatterCore &&
            currentMatterId === currentMatterCore.__id &&
            !!currentMatterCore.matterTypeId
          );
        }),
        take(1)
      )
    ),
    withLatestFrom(
      this._store.pipe(select(selectAllAddins)),
      this._store.pipe(select(selectCurrentMatterCore)),
      this._store.pipe(select(selectPageNumber)),
      (action: any, allAddins, matterCore: IMatterCore, pageNumber: number) => ({
        allAddins,
        matterCore,
        pageNumber,
      })
    ),
    exhaustMap(({ allAddins, matterCore, pageNumber }) =>
      this._matterAddinSvc.getMatterTypeAncestors(matterCore.matterTypeId).pipe(
        mergeMap((paths) => {
          const addinsContextMenu = [];
          const filteredAddins = allAddins.map((addin) => {
            const a = (addin ? { ...addin } : {}) as Addin;
            a.Button =
              a.Button?.filter((button) =>
                this._matterAddinSvc.shouldShowAddinButtons(matterCore, paths, button, pageNumber)
              ) || [];
            a.ContextMenu =
              a.ContextMenu?.filter((menu) =>
                this._matterAddinSvc.shouldShowAddinContextMenu(matterCore, paths, menu, pageNumber)
              ) || [];
            if (!!a.ContextMenu && a.ContextMenu.length > 0) {
              addinsContextMenu.push('separator');
            }
            return a;
          });
          return of(
            new matterAddinActions.MatterAddinInitSuccess({
              addins: filteredAddins,
              addinsContextMenu,
            })
          );
        }),
        catchError(() => of(new matterAddinActions.MatterAddinInitFailure(0)))
      )
    )
  ));


  matterAddinClicked$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinClicked>(matterAddinActions.MATTER_ADDIN_CLICKED),
    withLatestFrom(this._store.pipe(select(selectCurrentMatterCore))),
    rxjsFilter((data) => !!data[1]),
    tap(([action, matterCore]) => {
      const addinItem = action.payload.addinItem;
      const transactions = action.payload.transactions || [];
      return of(this._matterAddinSvc.openAddin(addinItem, transactions, matterCore));
    })
  ), { dispatch: false });


  openMatterAddinTimeEntry$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.OpenMatterAddinTimeEntry>(matterAddinActions.OPEN_MATTER_ADDIN_TIME_ENTRY),
    withLatestFrom(this._store.pipe(select(selectCurrentMatterCore)), this._store.pipe(select(selectViewLeapApps))),
    tap(([action, matterCore, apps]) => {
      if (matterCore && matterCore.accounting && matterCore.accounting.billingMode === LEGAL_AID_BILLING_MODE) {
        const legalAid = this._leapAppSvc.getAddinByName({ apps, name: 'New Billable Item' });
        if (legalAid) {
          const { app, addin } = legalAid;
          this._leapAppSvc.openViewLeapAppAddin({
            appId: app.id,
            functionGroupId: addin.functionGroupId,
            windowType: addin.windowType,
            url: addin.url,
          });
          return;
        }
      }

      const { data, ...query } = action.payload || { data: undefined };
      this._appApiSvc.newNonAddinTimeEntry(query, data);
    })
  ), { dispatch: false });


  matterAddinCreateAppointment$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinCreateAppointment>(matterAddinActions.MATTER_ADDIN_CREATE_APPOINTMENT),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    tap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num, 'schedule'],
              popup: ['appointment'],
            },
          },
        ],
      });
    })
  ), { dispatch: false });


  matterAddinCreateTask$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinCreateTask>(matterAddinActions.MATTER_ADDIN_CREATE_TASK),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    tap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId, subject } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num, 'schedule'],
              popup: ['task'],
            },
          },
        ],
        query: { subject },
      });
    })
  ), { dispatch: false });


  MatterAddinCreateDocumentFromPrecedent$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinCreateDocumentFromPrecedent>(
      matterAddinActions.MATTER_ADDIN_CREATE_DOCUMENT_FROM_PRECEDENT
    ),
    withLatestFrom(this._store.pipe(select(selectUserPreferences))),
    exhaustMap(([action, UserPreference]) => this._precedentService.getPrecedent(action.payload.precedentId).pipe(
      map((precedent) => ({
        matterId: action.payload.matterId,
        precedent,
        UserPreference,
      }))
    )),
    exhaustMap((data) => {
      const { matterId, precedent, UserPreference: userPreference } = data;
      const online =
        userPreference.find((p) => p.Key === UserPreferenceTypes.OpenOffice).Value === OfficePreferences.OfficeOnline;

      this._appApiSvc.navigateClear(['popup']);

      if (online) {
        return this._matterAddinSvc.createOnlinePrecedentDocument(matterId, precedent);
      } else {
        return this._matterAddinSvc.createPrecedentDocument(precedent);
      }
    })
  ), { dispatch: false });


  matterAddinCreateDocumentFromContainer$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinCreateDocumentFromContainer>(
      matterAddinActions.MATTER_ADDIN_CREATE_DOCUMENT_FROM_CONTAINER
    ),
    withLatestFrom(this._store.pipe(select(selectUserPreferences))),
    exhaustMap(([action, UserPreference]) => this._precedentService.getCustomPrecedent().pipe(
      map((containers) => {
        const container = containers.find((c) => c.id === action.payload.containerId);

        return {
          params: action.payload,
          container,
          UserPreference,
        };
      })
    )),
    mergeMap((data) => {
      const { params, container, UserPreference: userPreference } = data;
      const { matterId, containerId, tableId, order, instance, resultDocumentId } = params;
      const online =
        userPreference.find((p) => p.Key === UserPreferenceTypes.OpenOffice).Value === OfficePreferences.OfficeOnline;

      this._appApiSvc.navigateClear(['popup']);

      if (online) {
        return this._matterAddinSvc.createOnlineDocument(matterId, tableId, order, instance, containerId, container);
      } else {
        return this._matterAddinSvc.createNewDocument(
          matterId,
          containerId,
          tableId,
          order,
          instance,
          resultDocumentId
        );
      }
    })
  ), { dispatch: false });


  matterAddinCreateSearch$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinCreateSearch>(matterAddinActions.MATTER_ADDIN_CREATE_SEARCH),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    mergeMap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      if (currentMatter.matterId !== matterId) {
        const num = this.getMatterNum(currentMatter, matterId);
        this._appApiSvc.navigate({
          path: [
            {
              outlets: {
                primary: ['matters', num],
                popup: null,
              },
            },
          ],
        });
      }

      return [new currentMatterActions.InfotrackNewWin({ type: EInfoTrackType.Search })];
    })
  ));


  matterAddinCreateOfficePayment$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinCreateOfficePayment>(matterAddinActions.MATTER_ADDIN_CREATE_OFFICE_PAYMENT),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    tap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num, 'payments-debtors'],
              popup: ['office-payment'],
            },
          },
        ],
        query: { matterNumber: matterId },
      });
    })
  ), { dispatch: false });


  matterAddinCreateTrustPayment$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinCreateTrustPayment>(matterAddinActions.MATTER_ADDIN_CREATE_TRUST_PAYMENT),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    tap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num, 'trust-fund'],
              popup: ['trust-payment'],
            },
          },
        ],
      });
    })
  ), { dispatch: false });


  matterAddinCreateTimeEntry$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinCreateTimeEntry>(matterAddinActions.MATTER_ADDIN_CREATE_TIME_ENTRY),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    tap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num, 'time-fee'],
              popup: ['time'],
            },
          },
        ],
        query: { matterNumber: matterId },
      });
    })
  ), { dispatch: false });


  matterAddinOpenTable$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinOpenTable>(matterAddinActions.MATTER_ADDIN_OPEN_TABLE),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    map((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      // navigate to the matter details page
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num],
            },
          },
        ],
      });
      return leapHostArgs;
    }),
    delayWhen((leapHostArgs: OpenTableCommand) =>
      // ensure the matter details page has been navigate to
      this._store.pipe(
        select(selectDetailInfo),
        rxjsFilter((detailInfo) => !!detailInfo && detailInfo.matterId === leapHostArgs.matterId)
      )
    ),
    withLatestFrom(this._store.pipe(select(selectDetailEntries)), (leapHostArgs, detailEntries) => ({
      leapHostArgs,
      detailEntries,
    })),
    tap((data) => {
      const { leapHostArgs, detailEntries } = data;
      // find the definable table
      const selectedEntry = detailEntries.find(
        (entry) => !!entry && !!entry.__tableId && entry.__tableId === leapHostArgs.tableId
      );
      if (selectedEntry) {
        this._layoutRendererService.setDetails(selectedEntry as IDefinableTable, leapHostArgs.matterId);
        this._appApiSvc.navigate({
          path: [{ outlets: { popup: ['layout-renderer'] } }],
          extras: { skipLocationChange: true },
        });
      } else {
        // if no definable table found, show an error toarstr
        this._eventBus.emit({
          name: ESiriusEvents.ShowToastr,
          value: {
            type: 'error',
            title: 'Error',
            message: 'Unable to find the definable table in current matter',
          },
        });
      }
    })
  ), { dispatch: false });


  matterAddinShareTrust$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinShareTrust>(matterAddinActions.MATTER_ADDIN_SHARE_TRUST),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    map((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      // navigate to the trust ledger page
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num, 'trust-fund'],
            },
          },
        ],
      });
      return leapHostArgs;
    }),
    delayWhen((leapHostArgs: ShareCommand) =>
      // ensure the trust ledger page has been navigated to
      this._store.pipe(
        select(selectTrustBankAccounts),
        rxjsFilter((accounts) => !!accounts && accounts.length > 0)
      )
    ),
    withLatestFrom(this._store.pipe(select(selectTrustBankAccounts)), (leapHostArgs, trustBankAccounts) => ({
      leapHostArgs,
      trustBankAccounts,
    })),
    tap((data) => {
      const { leapHostArgs, trustBankAccounts } = data;
      // find the definable table
      const selectedAccount = trustBankAccounts.find(
        (account) =>
          !!account &&
          !!account.BankAccountGUID &&
          leapHostArgs.recordIds &&
          leapHostArgs.recordIds[0] &&
          account.BankAccountGUID === leapHostArgs.recordIds[0]
      );
      if (selectedAccount) {
        this._appApiSvc.navigate({
          path: [{ outlets: { popup: ['document-share', 'trust-account'] } }],
          query: { matterNumber: leapHostArgs.matterId, accountId: selectedAccount.BankAccountGUID },
        });
      } else {
        // if no definable table found, show an error toarstr
        this._eventBus.emit({
          name: ESiriusEvents.ShowToastr,
          value: {
            type: 'error',
            title: 'Error',
            message: 'Unable to find the trust account.',
          },
        });
      }
    })
  ), { dispatch: false });


  matterAddinShareBilling$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinShareBilling>(matterAddinActions.MATTER_ADDIN_SHARE_BILLING),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    tap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num, 'payments-debtors'],
              popup: ['document-share', 'office-account'],
            },
          },
        ],
        query: { matterNumber: leapHostArgs.matterId },
      });
    })
  ), { dispatch: false });


  matterAddinShareDoc$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinShareDoc>(matterAddinActions.MATTER_ADDIN_SHARE_DOC),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    map((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      // navigate to the matter details page
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num],
            },
          },
        ],
      });
      return leapHostArgs;
    }),
    delayWhen((leapHostArgs: ShareCommand) =>
      // ensure the matter details page has been navigate to
      this._store.pipe(
        select(selectDetailInfo),
        rxjsFilter((detailInfo) => !!detailInfo && detailInfo.matterId === leapHostArgs.matterId)
      )
    ),
    withLatestFrom(this._store.pipe(select(selectRoot)), (leapHostArgs, root) => ({
      leapHostArgs,
      root,
    })),
    mergeMap((data) => {
      const { leapHostArgs, root } = data;
      const correspondenceList = root ? root.fullDocuments : null;

      if (correspondenceList && correspondenceList.length > 0 && leapHostArgs && leapHostArgs.recordIds.length > 0) {
        // find the selected doc
        const selectedDocs = correspondenceList.filter((entry) => leapHostArgs.recordIds.indexOf(entry.id) > -1);

        if (selectedDocs) {
          return [
            new correspondenceActions.StoreCorrespondenceSelectedDocs(selectedDocs),
            documentShareActions.InitDocumentShare(),
          ];
        } else {
          return throwError(() => 'Unable to share docs');
        }
      } else {
        return throwError(() => 'Unable to share docs');
      }
    }),
    catchError((e) => {
      this._eventBus.emit({
        name: ESiriusEvents.ShowToastr,
        value: {
          type: 'error',
          title: 'Error',
          message: e,
        },
      });
      return [];
    })
  ));


  matterAddinShareFolder$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinShareFolder>(matterAddinActions.MATTER_ADDIN_SHARE_FOLDER),
    this.loadMatterDetails<matterAddinActions.MatterAddinShareFolder, ShareCommand>(),
    mergeMap((data) => {
      const { leapHostArgs, root } = data;
      const folderList = root ? root.folders : null;

      if (folderList && folderList.length > 0 && leapHostArgs && leapHostArgs.recordIds.length > 0) {
        // find the selected doc
        const selectedFolders = folderList.filter((entry) => leapHostArgs.recordIds.indexOf(entry.id) > -1);

        if (selectedFolders) {
          return [documentShareActions.InitFolderShare({ folders: selectedFolders })];
        } else {
          return throwError(() => 'Unable to share folders');
        }
      } else {
        return throwError(() => 'Unable to share folders');
      }
    }),
    catchError((e) => {
      this._eventBus.emit({
        name: ESiriusEvents.ShowToastr,
        value: {
          type: 'error',
          title: 'Error',
          message: e,
        },
      });
      return [];
    })
  ));


  matterAddinEmailDocuments$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinEmailDocuments>(matterAddinActions.MATTER_ADDIN_EMAIL_DOCUMENTS),
    this.loadMatterDetails<matterAddinActions.MatterAddinEmailDocuments, EmailDocumentsCommand>(),
    exhaustMap((data) => {
      const { leapHostArgs, currentMatter, root } = data;
      const documents = root.fullDocuments.filter((d) => leapHostArgs.documentIds.some((id) => id === d.id));
      let actions: any[] = [];

      if (leapHostArgs.format === 'EmailOriginal') {
        actions = this.emailOriginal(documents, actions, currentMatter);
      } else if (leapHostArgs.format === 'EmailPDF') {
        actions = this.emailPdf(documents, actions, currentMatter);
      }

      return [...actions];
    })
  ));


  matterAddinOpenMatter$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinOpenMatter>(matterAddinActions.MATTER_ADDIN_OPEN_MATTER),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    tap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      if (currentMatter.matterId === matterId) {
        const num = this.getMatterNum(currentMatter, matterId);
        this._appApiSvc.navigate({
          path: [
            {
              outlets: {
                primary: ['matters', num],
              },
            },
          ],
        });
      } else {
        this._leapHostHandlerSvc.handleOpenMatter(leapHostArgs);
      }
    })
  ), { dispatch: false });


  matterAddinOpenDoc$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinOpenDoc>(matterAddinActions.MATTER_ADDIN_OPEN_DOC),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    switchMap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId, transactionId } = leapHostArgs;
      const id = matterId || currentMatter.matterId;
      return this._correspondenceService
        .get(id)
        .pipe(
          map((documents) => ({ document: documents.find((document) => document.id === transactionId), matterId: id }))
        );
    }),
    withLatestFrom(this._store.pipe(select(selectUserPreferences)), (data, preferences) => {
      const userPreference = preferences.find((u) => u.Key === 'OpenOffice');
      const isOnline = !!userPreference && userPreference.Value === 'Office Online';
      return {
        document: data.document,
        matterId: data.matterId,
        isOnline,
      };
    }),
    mergeMap((data) => {
      const { document, matterId, isOnline } = data;
      if (document) {
        return isOnline
          ? this._leapHostHandlerSvc.openWordOnline(document, matterId)
          : this._leapHostHandlerSvc.openWord(document);
      } else {
        return throwError(() => 'Unable to open document.');
      }
    }),
    catchError((e) => {
      this._eventBus.emit({
        name: ESiriusEvents.ShowToastr,
        value: {
          type: 'error',
          title: 'Error',
          message: e,
        },
      });
      return [];
    })
  ), { dispatch: false });


  matterAddinOpenPrecedent$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinOpenPrecedent>(matterAddinActions.MATTER_ADDIN_OPEN_PRECEDENT),
    this.loadMatterDetails<matterAddinActions.MatterAddinOpenPrecedent, any>(),
    switchMap((data: { leapHostArgs: OpenCommand; currentMatter: IMatterListEntry }) => {
      const { leapHostArgs } = data;
      const { transactionId: precedentId } = leapHostArgs;
      return this._precedentService.getPrecedent(precedentId).pipe(
        switchMap((enrichedPrecedent: IPrecedent) => {
          const params = {
            action: AutomationActionType.New,
            precedentId: enrichedPrecedent.shortcutId || enrichedPrecedent.customPrecedentId || enrichedPrecedent.id,
            ext: enrichedPrecedent.type,
          } as IOpenPrecedentTicketParams;
          return this._offlineLauncherSvc.createOpenPrecedentTicket(params, {
            navigateClear: true,
          });
        })
      );
    }),
    catchError((e) => {
      this._eventBus.emit({
        name: ESiriusEvents.ShowToastr,
        value: {
          type: 'error',
          title: 'Error',
          message: e,
        },
      });
      return [];
    })
  ), { dispatch: false });


  matterAddinOpenApp$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinOpenApp>(matterAddinActions.MATTER_ADDIN_OPEN_APP),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    map((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { matterId } = leapHostArgs;
      const num = this.getMatterNum(currentMatter, matterId);
      // navigate to the matter details page
      this._appApiSvc.navigate({
        path: [
          {
            outlets: {
              primary: ['matters', num],
            },
          },
        ],
      });
      return leapHostArgs;
    }),
    delayWhen((leapHostArgs: OpenCommand) =>
      combineLatest([
        this._store.pipe(select(selectAllAddins)),
        this._store.pipe(
          select(selectDetailInfo),
          rxjsFilter((detailInfo) => !!detailInfo && detailInfo.matterId === leapHostArgs.matterId)
        ),
      ]).pipe(
        // ensure the matter addins are loaded
        // ensure the matter details page has been navigate to
        rxjsFilter((d) => !!d && !!d[0] && d[0].length > 0 && !!d[1])
      )
    ),
    withLatestFrom(this._store.pipe(select(selectAllAddins)), (leapHostArgs, allAddins) => ({
      leapHostArgs,
      allAddins,
    })),
    tap((data) => {
      const { leapHostArgs, allAddins } = data;
      // find the definable table
      const buttons: AddinButton[] = allAddins.reduce((total, addin) => {
        const b = !!addin && !!addin.Button ? addin.Button : [];
        return [...total, ...b];
      }, []);

      const addinItem = buttons.find((b) => !!b && !!b.Name && b.Name === leapHostArgs.transactionId);
      if (addinItem) {
        this._store.dispatch(new matterAddinActions.MatterAddinClicked({ addinItem }));
      } else {
        // if no definable table found, show an error toarstr
        this._eventBus.emit({
          name: ESiriusEvents.ShowToastr,
          value: {
            type: 'error',
            title: 'Error',
            message: 'Unable to find app',
          },
        });
      }
    })
  ), { dispatch: false });


  matterAddinPreviewDoc$ = createEffect(() => this.actions$.pipe(
    ofType<matterAddinActions.MatterAddinPreviewDoc>(matterAddinActions.MATTER_ADDIN_PREVIEW_DOC),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action, currentMatter) => ({
      currentMatter,
      leapHostArgs: action.payload,
    })),
    switchMap((data) => {
      const { currentMatter, leapHostArgs } = data;
      const { transactionId } = leapHostArgs;
      const id = currentMatter.matterId;
      return this._correspondenceService
        .get(id)
        .pipe(map((documents) => documents.find((document) => document.id === transactionId)));
    }),
    mergeMap((document) => {
      if (document) {
        this._leapHostHandlerSvc.previewDoc({
          data: document,
        });
        return [];
      } else {
        return throwError(() => 'Unable to preview document.');
      }
    }),
    catchError((e) => {
      this._eventBus.emit({
        name: ESiriusEvents.ShowToastr,
        value: {
          type: 'error',
          title: 'Error',
          message: e,
        },
      });
      return [];
    })
  ), { dispatch: false });

  private loadMatterDetails<A extends { payload: any }, Command extends { matterId: string }>() {
    return pipe(
      withLatestFrom(this._store.pipe(select(selectCurrentMatter)), (action: A, currentMatter) => ({
        currentMatter,
        leapHostArgs: action.payload,
      })),
      map((data) => {
        const { currentMatter, leapHostArgs } = data;
        const { matterId } = leapHostArgs;
        const num = this.getMatterNum(currentMatter, matterId);
        // navigate to the matter details page
        this._appApiSvc.navigate({
          path: [
            {
              outlets: {
                primary: ['matters', num],
              },
            },
          ],
        });
        return { leapHostArgs, currentMatter };
      }),
      delayWhen((data: { leapHostArgs; currentMatter }) =>
        // ensure the matter details page has been navigate to
        this._store.pipe(
          select(selectDetailInfo),
          rxjsFilter((detailInfo) => !!detailInfo && detailInfo.matterId === data.leapHostArgs.matterId)
        )
      ),
      withLatestFrom(this._store.pipe(select(selectRoot)), (data, root) => ({
        currentMatter: data.currentMatter,
        leapHostArgs: data.leapHostArgs,
        root,
      }))
    );
  }

  private emailPdf(documents: IDoc[], actions: any[], currentMatter: any) {
    const pdfDocumentDtos = documents.map((d) => ({
        filename: d.latestVersion || d.id,
        name: d.name,
        type: d.ext,
      } as IPdfServiceDocumentDTO));
    const createPDFRequest: IDocCombinePDFHttpRequestParams = {
      Documents: pdfDocumentDtos,
      SaveToMatter: false,
      CombinedDocumentsName: undefined,
      Combine: false,
      Secure: true,
      SaveToSuperDiaryTempStorage: true,
    };
    actions = [
      new appActions.InitialiseEmail({
        data: { matterNumber: currentMatter.matterId },
        convertPdfParams: createPDFRequest,
      }),
      new createPDFActions.SetCreatePDFoptions(createPDFRequest),
      new createPDFActions.SelectCorrespondence(documents),
      new createPDFActions.CreatePDFnEmail(null),
    ];
    return actions;
  }

  private emailOriginal(documents: IDoc[], actions: any[], currentMatter: any) {
    const documentIconHelper = new DocumentIconHelper();
    const attachments = documents.map((d) => ({
        id: d.id,
        latestVersion: d.latestVersion,
        name: `${d.name}.${d.ext}`,
        iconClass: documentIconHelper.getIconInfo(d.ext).iconClass,
        type: AttachmentType.Correspondence,
      } as Attachment));
    actions = [
      new appActions.InitialiseEmail({ data: { matterNumber: currentMatter.matterId }, attachment: attachments }),
      new attachCorrespondenceActions.AttachCorrespondence(attachments),
    ];
    return actions;
  }

  /**
   * Only get a route where its outlet is 'primary'
   * */
  private getPrimaryRoute(route: any): any {
    let result: any = null;
    for (const childRoute of route) {
      if (childRoute.outlet === 'primary') {
        result = childRoute;
        break;
      }
    }

    return result;
  }

  constructor(
    private actions$: Actions,
    private _store: Store<any>,
    private _correspondenceService: CorrespondenceService,
    private _leapHostHandlerSvc: LeapHostHandlerService,
    private _eventBus: EventBusService,
    private _layoutRendererService: LayoutRendererService,
    private _offlineLauncherSvc: OfflineLauncherService,
    private _matterAddinSvc: MatterAddinService,
    private _appApiSvc: AppApiService,
    private _brandSvc: BrandService,
    private _leapAppSvc: LeapAppService,
    private _precedentService: PrecedentService
  ) {}

  /**
   * Get a matter number (either fileNumber or matterId) for navigation
   * */
  private getMatterNum(currentMatter: IMatterListEntry, leapHostMatterId: string): string {
    // check whether the current matter id is the same as the matterId from leapHost
    // if it is the same, we use the current matter's fileNumber, else we use the matterId from leapHost
    return currentMatter.matterId === leapHostMatterId
      ? currentMatter.fileNumber
        ? encodeURIComponent(currentMatter.fileNumber)
        : currentMatter.matterId
      : leapHostMatterId;
  }
}
