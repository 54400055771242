import { Injectable } from '@angular/core';
import { IBankAccount, IPaymentTypes } from '../../../+payments-debtors/models/payment-debtors.models';
import { IPreference } from 'app/features/+payments-debtors/models';
import { PaymentTypeId } from '../../constants';

// *********************//
export interface IInvestmentAccount {
  BankAccountGUID: string;
  InvestmentTransactionNumber: number;
  ControlledMoneyTransactionNumber: number;
  ControlledMoneyReceiptNumber: number;
  AccountNumber: string;
  AccountName: string;
  AccountUsage: number;
  AccountBSB: string;
}
export interface IInvoiceGlPreference {
  GSTAccountingBasis: number;
  GLAccountCodeDebtors: string;
  GLAccountCodeTimeFees: string;
  GLAccountCodeCostRecoveries: string;
  GLAccountCodePaymentItemsRec: string;
}
// *********************//

export interface ITransactionNumber {
  TransactionNumber: string;
  Auto: boolean;
}

@Injectable()
export class TransactionNumberService {
  constructor() {}

  getPaymentNumber(bankAccount: IBankAccount, paymentType: IPaymentTypes, preference: IPreference): ITransactionNumber {
    switch (paymentType.PaymentTypeID) {
      case PaymentTypeId.Cash: // cash
      case PaymentTypeId.CreditCard: // credit card
      case PaymentTypeId.International: // international
        return {
          Auto: false,
        } as ITransactionNumber;
      case PaymentTypeId.CashiersCheque: // bank cheque
      case PaymentTypeId.Cheque: // personal cheque
        return {
          Auto: paymentType.AutoChequeNumber || true,
          TransactionNumber: (bankAccount.ChequeNumber + 1).toString(),
        } as ITransactionNumber;
      case PaymentTypeId.Wire: // EFT
        return {
          Auto: paymentType.AutoEFTNumber || true,
          TransactionNumber: preference.EFTNumberPrefix + (bankAccount.EFTNumber + 1).toString(),
        } as ITransactionNumber;
      default:
        return {
          Auto: true,
          TransactionNumber: (bankAccount.ReceiptNumber + 1).toString(),
        } as ITransactionNumber;
    }
  }

  getInvestmentNumber(bankAccount: IInvestmentAccount, isReceipt: boolean): string {
    let transactionNumber: string;
    if (bankAccount.AccountUsage === 2 || bankAccount.AccountUsage === 5 || bankAccount.AccountUsage === 6) {
      if (isReceipt) {
        transactionNumber = (bankAccount.ControlledMoneyReceiptNumber + 1).toString();
      } else {
        transactionNumber = (bankAccount.ControlledMoneyTransactionNumber + 1).toString();
      }
    } else {
      transactionNumber = (bankAccount.InvestmentTransactionNumber + 1).toString();
    }
    return transactionNumber;
  }

  getReceiptNumber(bankAccount: IBankAccount): ITransactionNumber {
    return {
      Auto: true,
      TransactionNumber: (bankAccount.ReceiptNumber + 1).toString(),
    } as ITransactionNumber;
  }

  getJournalNumber(bankAccount: IBankAccount): ITransactionNumber {
    return {
      Auto: true,
      TransactionNumber: (bankAccount.JournalNumber + 1).toString(),
    } as ITransactionNumber;
  }

  getInvoiceNumber(preference: IInvoiceGlPreference): ITransactionNumber {
    let num = preference.GLAccountCodeDebtors;
    num = num.substr(num.lastIndexOf('-') + 1);
    return {
      Auto: true,
      TransactionNumber: (parseInt(num, 10) + 1).toString(),
    } as ITransactionNumber;
  }
}
