import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { BaseService } from '@app/shared/services/base/base.service';
import { LogService } from '@app/core/services/log/log.service';
import {
  CreateEmailRequest,
  CreateEmailResponse,
  DeleteEmailResponse,
  GetEmailsParams,
  IEmail,
  IEmailAddress,
  IEmailDTO,
  toUpdateEmailRequest,
  UpdateEmailRequest,
  UpdateEmailResponse,
} from '@app/features/+email/models';

import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { FEATURE_FLAG_USE_CALC_WEB_WORKER } from '@app/core/constants/features-flags.constant';
import { CalcEngineService } from '@app/features/calc-engine/services/calc-engine.service';
import { MAIN_OBJECT_TYPES } from '@leap/calc-core/types';
import { ClientTable, DebtorForAccounting } from '@app/core/constants';
import { environment } from '@env/environment';
import { IWebAddress } from '@app/core/models';

@Injectable()
export class EmailService extends BaseService {
  private _calcEngineSvc: CalcEngineService = inject(CalcEngineService);

  private path_: string;

  constructor(
    private _log: LogService,
    private http: HttpClient,
    private _featureFlagSvc: FeatureFlagService,
  ) {
    super();
    this._log.init('email-service');
    this.path_ = `${this.calendarPath}/emails`;
  }

  createEmail(request: CreateEmailRequest): Observable<CreateEmailResponse> {
    const url = `${this.path_}`;
    return this.http.post<CreateEmailResponse>(url, request);
  }

  deleteEmail(id: number): Observable<DeleteEmailResponse> {
    const url: string = this.urlJoin(this.path_, id.toString());
    return this.http.delete<DeleteEmailResponse>(url);
  }

  getEmail(id: number): Observable<IEmailDTO> {
    const url: string = this.urlJoin(this.path_, id.toString());
    return this.http.get<IEmailDTO>(url);
  }

  getEmails(params: GetEmailsParams): Observable<IEmailDTO[]> {
    // const data = {
    //   matterId: params.matterId,
    //   staffId: params.staffId
    // };
    return this.http.get<IEmailDTO[]>(this.path_);
  }

  getEmailSubject(matterId: string, recipients: IEmailAddress[]): Observable<string> {
    if (this._featureFlagSvc.isFeatureEnabled(FEATURE_FLAG_USE_CALC_WEB_WORKER)) {
      return from(
        (async () => {
          let sessionId: string;
          try {
            sessionId = (await this._calcEngineSvc.createSession({ matterGUID: matterId })).sessionId;

            await this.updateSubjectOnRecipientChange(sessionId, matterId, recipients);

            const emailSubject = await this._calcEngineSvc.evaluatePath(
              { sessionId: sessionId, matterGUID: matterId },
              'matter.reLine1',
            );

            if (emailSubject.status === 'ok') {
              return emailSubject.result;
            } else {
              throw new Error(emailSubject.error);
            }
          } catch (error) {
            this._log.error('Error while getting email subject:', error);
            throw error;
          } finally {
            if (sessionId) {
              await this._calcEngineSvc.destroySession(sessionId);
            }
          }
        })(),
      );
    } else {
      const leapcalcPath = `${this.siriusPath}/api/calc/emailsubject`;
      const Req = {
        matterId,
        recipients: recipients?.map((x) => ({ email: x.address })) || [],
      };
      return this.http.post(leapcalcPath, Req, { responseType: 'text' });
    }
  }

  sendEmail(id: number): Observable<void> {
    const url = this.urlJoin(this.path_, id.toString(), '/send');
    return this.http.post<void>(url, undefined);
  }

  updateEmail(email: IEmail): Observable<UpdateEmailResponse> {
    this._log.info('email: ', email);

    const request: UpdateEmailRequest = toUpdateEmailRequest(email);
    this._log.info('email model: ', request);

    return this.http.put<UpdateEmailResponse>(this.path_, request);
  }

  /**
   * This is copied from sirius server
   *
   * It takes a calc session, and finds the appropriate default card for the email subject.
   */
  private async updateSubjectOnRecipientChange(sessionId: string, matterId: string, recipients: Array<IEmailAddress>) {
    const matter = await this._calcEngineSvc.getExecutionContextObject(sessionId, MAIN_OBJECT_TYPES.MATTER);

    if (!matter || !Array.isArray(matter.matterCards) || matter.matterCards.length === 0) {
      this._log.debug('No matter cards found');
      return;
    }

    const cards = matter.matterCards.filter((matterCard) => {
      const isNotMatterDebtorCard =
        matterCard.__tableId !== DebtorForAccounting[environment.config.brand.region.toLowerCase()].id;

      return isNotMatterDebtorCard;
    });

    if (!recipients || recipients.length === 0) {
      const firstClientCard = cards[0];
      if (firstClientCard) {
        await this._calcEngineSvc.sessionSetDefaultFile(
          sessionId,
          [firstClientCard.__tableId, firstClientCard.__fileOrder].join(';'),
        );
        return;
      }
    }

    let recipientCounter = 0;

    for (const recipient of recipients) {
      recipientCounter++;

      for (const matterCard of cards) {
        const fullMatterCard = (await this._calcEngineSvc.sessionGetObjectById(sessionId, matterCard.__id)).result;

        // if recipient email matches one of the person in card persons
        const recipientPerson = fullMatterCard.personList?.find((p) => {
          let webAddress: IWebAddress;
          const person = matter.people?.find((x) => x.__id === p.__id);

          if (person) {
            webAddress = person.webAddressList?.find(
              (w) => w.address === (recipient as any).email || w.address === recipient.address,
            );
          }

          return webAddress != undefined;
        });

        if (recipientPerson) {
          const isNotMatterClientCard =
            matterCard.__tableId !== ClientTable[environment.config.brand.region.toLowerCase()].id;

          if (isNotMatterClientCard || recipientCounter === recipients.length) {
            this._calcEngineSvc.sessionSetDefaultFile(
              sessionId,
              [matterCard.__tableId, matterCard.__fileOrder].join(';'),
            );
          }

          if (isNotMatterClientCard) {
            break;
          }
        }
      }
    }
  }
}
