import { IPhoneNumber, IWebAddress } from '@app/core/models';
import { AddressType, ECardType, IAlternativeAddressSettings, ICardRelatedMatter } from '@app/features/+card/models';
import { CalcRendererInit } from '@app/features/+layout-renderer/models/layout-renderer.model';
import { SpecialRate } from '@app/features/+time-fee-ledger/models';
import { IBeneficiary, ICard, ICardDefinableTable, IPerson, ITrustee } from '@app/shared/models';
import { Action } from '@ngrx/store';

export enum CardDetailsActionTypes {
  MANAGE_TRUSTEES = '[Card Trustees] manage trustees',
  MANAGE_BENEFICIARIES = '[Card Beneficiaries] manage beneficiaries',
  ADD_TRUSTEE = '[Card Trustees] add a trustee',
  SET_TRUSTEE = '[Card Trustees] set a trustee',
  DELETE_TRUSTEES = '[Card Trustees] delete trustees',
  ADD_BENEFICIARY = '[Card Beneficiaries] add a beneficiary',
  SET_BENEFICIARY = '[Card Beneficiaries] set a beneficiary',
  DELETE_BENEFICIARIES = '[Card Beneficiaries] delete beneficiaries',
  LOAD_CARD_DETAILS_START = '[Card Details] load start',
  LOAD_CARD_DETAILS_SUCCEED = '[Card Details] load succeed',
  LOAD_CARD_DETAILS_FAIL = '[Card Details] load fail',
  LOAD_EXISTING_CARD = '[Card Details] load existing card',
  SELECT_EXISTING_PERSON = '[Card Details] select existing person',
  REQUEST_RESET = '[Card Details] request to reset card details',
  RESET = '[Card Details] reset',
  SAVE = '[Card Details] save',
  SAVE_SUCCEED = '[Card Details] save succeed',
  SAVE_FAIL = '[Card Details] save fail',
  REQUIRE_DELETE = '[Card Details] require to delete a card',
  DELETE = '[Card Details] delete',
  DELETE_SUCCEED = '[Card Details] delete succeed',
  DELETE_FAIL = '[Card Details] delete fail',
  REQUIRE_UNDELETE = '[Card Details] require to undelete a card',
  UNDELETE = '[Card Details] undelete',
  UNDELETE_SUCCEED = '[Card Details] undelete succeed',
  UNDELETE_FAIL = '[Card Details] undelete fail',
  SET_CARD_DETAILS_FORM_VALUE = '[Card Details] set card details form value',
  UPDATE_ALTERNATIVE_ADDRESS_SETTINGS = '[Card Details] update alternative address settings',
  SET_ADDRESS_VALUE = '[Card Details] set address value',
  UPDATE_PERSONAL_DETAILS = '[Card Details] Update Personal Details',
  ADD_PERSON = '[Card Details] add person',
  REMOVE_PERSON = '[Card Details] remove person',
  UPDATE_PERSON_LIST = '[Card Details] update person list',
  UPDATE_ACTIVE_PERSON_ID = '[Card Details] update active person id',
  CHANGE_CARD_TYPE = '[Card Details] change card type',
  UPDATE_CARD_TYPE = '[Card Details] update card type',
  GET_LETTER_LEAP_CALC_INFORM = '[Card Details] get letter leap calc inform',
  UPDATE_LETTER_LEAP_CALC_INFORM = '[Card Details] update letter leap calc inform',
  ADD_CARD_DETAILS_INVALID_CONTROLS = '[Card Details] add card details invalid controls',
  REMOVE_CARD_DETAILS_INVALID_CONTROLS = '[Card Details] remove card details invalid controls',
  CLEAR_CARD_DETAILS_INVALID_CONTROLS = '[Card Details] clear card details invalid controls',
  PERSON_WEB_VALUE_CHANGE = '[Card Details] person web value change',
  PERSON_PHONE_VALUE_CHANGE = '[Card Details] person phone value change',
  SET_CARD_PROCESSING_STATUS = '[Card Details] set the status of cardProcessing',
  ADD_CALC_RENDERER_INIT = '[Card Details] add calc renderer init',
  PREPARE_FORM_VALUES_FOR_SAVE = '[Card Details] prepare form values for save',
  PREPARE_FORM_VALUES_FOR_SAVE_SUCCESS = '[Card Details] prepare form values for save success',
}

export class ManageTrustees implements Action {
  readonly type = CardDetailsActionTypes.MANAGE_TRUSTEES;

  constructor(public payload: any) {}
}

export class ManageBeneficiaries implements Action {
  readonly type = CardDetailsActionTypes.MANAGE_BENEFICIARIES;

  constructor(public payload: any) {}
}

export class AddTrustee implements Action {
  readonly type = CardDetailsActionTypes.ADD_TRUSTEE;

  constructor(public payload: { trustee: ITrustee }) {}
}

export class AddBeneficiary implements Action {
  readonly type = CardDetailsActionTypes.ADD_BENEFICIARY;

  constructor(public payload: { beneficiary: IBeneficiary }) {}
}

export class SetTrustee implements Action {
  readonly type = CardDetailsActionTypes.SET_TRUSTEE;

  constructor(public payload: { trustee: ITrustee }) {}
}

export class SetBeneficiary implements Action {
  readonly type = CardDetailsActionTypes.SET_BENEFICIARY;

  constructor(public payload: { beneficiary: IBeneficiary; index: number }) {}
}

export class DeleteTrustees implements Action {
  readonly type = CardDetailsActionTypes.DELETE_TRUSTEES;

  constructor(public payload: { trustees: ITrustee[] }) {}
}

export class DeleteBeneficiaries implements Action {
  readonly type = CardDetailsActionTypes.DELETE_BENEFICIARIES;

  constructor(public payload: { indexes: number[] }) {}
}

export class LoadCardDetailsStart implements Action {
  readonly type = CardDetailsActionTypes.LOAD_CARD_DETAILS_START;

  constructor(public payload: { id: string; itemDetails?: ICard }) {}
}

export class LoadCardDetailsSucceed implements Action {
  readonly type = CardDetailsActionTypes.LOAD_CARD_DETAILS_SUCCEED;

  constructor(public payload: { data: ICard; specialRates: SpecialRate[]; relatedMatters: ICardRelatedMatter[] }) {}
}

export class LoadCardDetailsFail implements Action {
  readonly type = CardDetailsActionTypes.LOAD_CARD_DETAILS_FAIL;

  constructor(public payload: { error: any }) {}
}

export class LoadExistingCard implements Action {
  readonly type = CardDetailsActionTypes.LOAD_EXISTING_CARD;

  constructor(public payload: { id: string; itemDetails: ICard }) {}
}

export class SelectExistingPerson implements Action {
  readonly type = CardDetailsActionTypes.SELECT_EXISTING_PERSON;

  constructor(public payload: { id: string; itemDetails: IPerson }) {}
}

export class RequestReset implements Action {
  readonly type = CardDetailsActionTypes.REQUEST_RESET;

  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = CardDetailsActionTypes.RESET;

  constructor(public payload: any) {}
}

export class Save implements Action {
  readonly type = CardDetailsActionTypes.SAVE;

  constructor(public payload: any) {}
}

export class SaveSucceed implements Action {
  readonly type = CardDetailsActionTypes.SAVE_SUCCEED;

  constructor(public payload: { message?: string }) {}
}

export class SaveFail implements Action {
  readonly type = CardDetailsActionTypes.SAVE_FAIL;

  constructor(public payload: any) {}
}

export class SetCardDetailsFormValue implements Action {
  readonly type = CardDetailsActionTypes.SET_CARD_DETAILS_FORM_VALUE;

  constructor(public payload: { data: any }) {}
}

export class SetAddressValue implements Action {
  readonly type = CardDetailsActionTypes.SET_ADDRESS_VALUE;

  constructor(public payload: { data: any; index: number; addressType: AddressType; alternative: boolean }) {}
}

export class UpdateAlternativeAddressSettings implements Action {
  readonly type = CardDetailsActionTypes.UPDATE_ALTERNATIVE_ADDRESS_SETTINGS;

  constructor(public payload: { settings: IAlternativeAddressSettings }) {}
}

export class UpdatePersonalDetails implements Action {
  readonly type = CardDetailsActionTypes.UPDATE_PERSONAL_DETAILS;

  constructor(public payload: { data: any; id: string }) {}
}

export class AddPerson implements Action {
  readonly type = CardDetailsActionTypes.ADD_PERSON;

  constructor(public payload: { data: any }) {}
}

export class RemovePerson implements Action {
  readonly type = CardDetailsActionTypes.REMOVE_PERSON;

  constructor(public payload: { id: string; shouldRenewLeapCalcInform: boolean }) {}
}

export class UpdatePersonList implements Action {
  readonly type = CardDetailsActionTypes.UPDATE_PERSON_LIST;

  constructor(public payload: { list: IPerson[]; id: string }) {}
}

export class UpdateActivePersonId implements Action {
  readonly type = CardDetailsActionTypes.UPDATE_ACTIVE_PERSON_ID;

  constructor(public payload: { id: string }) {}
}

export class ChangeCardType implements Action {
  readonly type = CardDetailsActionTypes.CHANGE_CARD_TYPE;

  constructor(public payload: { cardType: ECardType }) {}
}

export class UpdateCardType implements Action {
  readonly type = CardDetailsActionTypes.UPDATE_CARD_TYPE;

  constructor(public payload: { type: ECardType }) {}
}

export class RequireDelete implements Action {
  readonly type = CardDetailsActionTypes.REQUIRE_DELETE;

  constructor(public payload: { card: any }) {}
}

export class Delete implements Action {
  readonly type = CardDetailsActionTypes.DELETE;

  constructor(public payload: { card: any }) {}
}

export class DeleteSucceed implements Action {
  readonly type = CardDetailsActionTypes.DELETE_SUCCEED;

  constructor(public payload: { message?: string }) {}
}

export class DeleteFail implements Action {
  readonly type = CardDetailsActionTypes.DELETE_FAIL;

  constructor(public payload: { error: any }) {}
}

export class RequireUndelete implements Action {
  readonly type = CardDetailsActionTypes.REQUIRE_UNDELETE;

  constructor(public payload: { cardId: string }) {}
}

export class Undelete implements Action {
  readonly type = CardDetailsActionTypes.UNDELETE;

  constructor(public payload: { cardId: string }) {}
}

export class UndeleteSucceed implements Action {
  readonly type = CardDetailsActionTypes.UNDELETE_SUCCEED;

  constructor(public payload: { message?: string }) {}
}

export class UndeleteFail implements Action {
  readonly type = CardDetailsActionTypes.UNDELETE_FAIL;

  constructor(public payload: { error: any }) {}
}

export class GetLetterLeapCalcInform implements Action {
  readonly type = CardDetailsActionTypes.GET_LETTER_LEAP_CALC_INFORM;

  constructor(public payload: any) {}
}

export class UpdateLetterLeapCalcInform implements Action {
  readonly type = CardDetailsActionTypes.UPDATE_LETTER_LEAP_CALC_INFORM;

  constructor(public payload: { inform: any }) {}
}

export class AddCardDetailsInvalidControls implements Action {
  readonly type = CardDetailsActionTypes.ADD_CARD_DETAILS_INVALID_CONTROLS;

  constructor(public payload: { names: string[] }) {}
}

export class RemoveCardDetailsInvalidControls implements Action {
  readonly type = CardDetailsActionTypes.REMOVE_CARD_DETAILS_INVALID_CONTROLS;

  constructor(public payload: { names: string[] }) {}
}

export class ClearCardDetailsInvalidControls implements Action {
  readonly type = CardDetailsActionTypes.CLEAR_CARD_DETAILS_INVALID_CONTROLS;

  constructor(public payload: any) {}
}

export class PersonWebValueChange implements Action {
  readonly type = CardDetailsActionTypes.PERSON_WEB_VALUE_CHANGE;

  constructor(public payload: { web: IWebAddress }) {}
}

export class PersonPhoneValueChange implements Action {
  readonly type = CardDetailsActionTypes.PERSON_PHONE_VALUE_CHANGE;

  constructor(public payload: { phone: IPhoneNumber }) {}
}

export class SetCardProcessingStatus implements Action {
  readonly type = CardDetailsActionTypes.SET_CARD_PROCESSING_STATUS;

  constructor(public payload: { processing: boolean }) {}
}

export class AddCalcRendererInit implements Action {
  readonly type = CardDetailsActionTypes.ADD_CALC_RENDERER_INIT;

  constructor(public payload: CalcRendererInit) {}
}

export class PrepareFormValuesForSave implements Action {
  readonly type = CardDetailsActionTypes.PREPARE_FORM_VALUES_FOR_SAVE;

  constructor() {}
}

export class PrepareFormValuesForSaveSuccess implements Action {
  readonly type = CardDetailsActionTypes.PREPARE_FORM_VALUES_FOR_SAVE_SUCCESS;

  constructor(public payload: ICardDefinableTable[]) {}
}

export type CardDetailsActions =
  | ManageTrustees
  | AddTrustee
  | AddBeneficiary
  | SetBeneficiary
  | SetTrustee
  | DeleteTrustees
  | DeleteBeneficiaries
  | LoadCardDetailsStart
  | LoadCardDetailsSucceed
  | LoadCardDetailsFail
  | LoadExistingCard
  | SelectExistingPerson
  | RequestReset
  | Reset
  | Save
  | SaveSucceed
  | SaveFail
  | RequireDelete
  | Delete
  | DeleteSucceed
  | DeleteFail
  | RequireUndelete
  | Undelete
  | UndeleteSucceed
  | UndeleteFail
  | SetAddressValue
  | UpdateAlternativeAddressSettings
  | UpdatePersonalDetails
  | AddPerson
  | RemovePerson
  | UpdatePersonList
  | UpdateActivePersonId
  | ChangeCardType
  | UpdateCardType
  | SetCardDetailsFormValue
  | GetLetterLeapCalcInform
  | UpdateLetterLeapCalcInform
  | AddCardDetailsInvalidControls
  | RemoveCardDetailsInvalidControls
  | ClearCardDetailsInvalidControls
  | PersonWebValueChange
  | PersonPhoneValueChange
  | SetCardProcessingStatus
  | AddCalcRendererInit
  | PrepareFormValuesForSave
  | PrepareFormValuesForSaveSuccess;
