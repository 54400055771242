import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppSlice } from '@app/core/store';
import { AccountingState } from '../reducers';

export const selectAccountingStateFeature = createFeatureSelector<AccountingState>(AppSlice.Accounting);

export const selectAccountingSuccess = createSelector(
  selectAccountingStateFeature,
  (state: AccountingState) => state.success,
);

export const selectAccountingSaving = createSelector(
  selectAccountingStateFeature,
  (state: AccountingState) => state.saving,
);

export const selectAccountingError = createSelector(
  selectAccountingStateFeature,
  (state: AccountingState) => state.error,
);

export const selectDehydratedAccountingForm = createSelector(
  selectAccountingStateFeature,
  (state: AccountingState) => state.dehydratedform,
);
