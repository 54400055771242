import {
  add,
  addBusinessDays,
  differenceInBusinessDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  format,
  formatISO,
  getDaysInMonth,
  intlFormat,
  isBefore,
  isValid,
  isWeekend,
  parse,
  parseISO,
  setDate,
  setMonth,
  setYear,
  startOfDay,
  startOfToday,
  sub,
  subBusinessDays,
} from 'date-fns';
import { FormatDateHttpOptions } from '@app/shared/models';
import { isValueInList } from '../../../../server/modules/shared/functions/common-util.functions';

export const OneDay = 1000 * 60 * 60 * 24;

const DATE_FORMAT_SEPARATORS = /[-/.\s]+/g;

export const DATE_SEPARATOR = '/';

export const regionToDateLocale: Record<string, string> = {
  AU: 'en-GB',
  UK: 'en-GB',
  US: 'en-US',
  CA: 'en-US',
  NL: 'en-GB',
};

export const regionTimeZone: Record<string, string> = {
  AU: 'Australia/Sydney',
  UK: 'Europe/London',
  US: 'America/New_York',
  CA: 'America/Los_Angeles',
  NL: 'Europe/Amsterdam',
};

export const getDateString = (date: Date): string => (date ? format(date, 'E dd MMM') : undefined);

/**
 * Ensures the provided input is a Date object.
 * If the input is a string, it attempts to parse it as an ISO date string.
 * Returns `undefined` if the input is neither a Date object nor a valid date string.
 * @param {Date|string} dateInput - The date input which can be a Date object or a string.
 * @return {Date|undefined} A Date object if the input is valid, otherwise undefined.
 */
export const getDate = (dateInput: Date | string): Date | undefined => {
  if (dateInput instanceof Date) {
    return dateInput; // Already a Date object
  } else if (typeof dateInput === 'string') {
    const parsedDate = parseISO(dateInput);
    return isValid(parsedDate) ? parsedDate : undefined;
  }
  return undefined; // Return undefined if input is neither string nor Date
};

export const getTimeStringAMPM = (date: Date): string => (date ? format(date, 'E dd MMM HH:mm a') : undefined);

export const isFutureDate = (date: Date, afterDate: Date = startOfToday()): boolean =>
  differenceInDays(date, afterDate) > 0;

export const mergeDatetime = (date: Date, time: Date): Date =>
  setDate(setMonth(setYear(time, date.getFullYear()), date.getMonth()), date.getDate());

export type Duration = number;
export enum DurationUnit {
  Years = 'years',
  Months = 'months',
  Weeks = 'weeks',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds',
  BusinessDays = 'business-days',
}

export const addDuration = (start: Date, duration: Duration, unit: DurationUnit): Date => {
  if (unit === DurationUnit.BusinessDays) {
    return addBusinessDays(start, duration);
  }
  return add(start, { [unit]: duration });
};

export const subtractDuration = (start: Date, duration: Duration, unit: DurationUnit): Date => {
  if (unit === DurationUnit.BusinessDays) {
    return subBusinessDays(start, duration);
  }
  return sub(start, { [unit]: duration });
};

export const getDuration = (start: Date, end: Date, unit: DurationUnit): number => {
  switch (unit) {
    case DurationUnit.Days:
      return differenceInDays(end, start);
    case DurationUnit.Hours:
      return differenceInHours(end, start);
    case DurationUnit.Minutes:
      return differenceInMinutes(end, start);
    case DurationUnit.Months:
      return differenceInMonths(end, start);
    case DurationUnit.Seconds:
      return differenceInSeconds(end, start);
    case DurationUnit.Weeks:
      return differenceInWeeks(end, start);
    case DurationUnit.Years:
      return differenceInYears(end, start);
    case DurationUnit.BusinessDays: {
      const businessDays = differenceInBusinessDays(start, end);
      // Add 1 day when start is on a weekend and end is after that on a weekday.
      return isWeekend(start) && !isWeekend(end) && isBefore(start, end) ? businessDays + 1 : businessDays;
    }
    default:
      break;
  }
};

export const parseUTC = (date) =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );

export const formatStringToDate = <T extends DateUtilsObject>(
  x: T,
  autoformat?: boolean,
  locale?: string,
  inclusion?: string[],
  exclusion?: string[],
): T => {
  if (!inclusion) {
    inclusion = [];
  }
  if (!exclusion) {
    exclusion = [];
  }
  return formatStringToDateStub(x, autoformat, locale, inclusion, exclusion);
};

export const formatDateToString = <T extends DateUtilsObject>(x: T, dateFormat?: string): T => {
  if (!dateFormat) {
    dateFormat = 'yyyy-MM-dd';
  }
  return formatDateToStringStub(x, dateFormat);
};

const formatStringToDateStub = <T extends DateUtilsObject | string>(
  x: T,
  autoformat: boolean,
  locale: string = 'en-AU',
  inclusion: string[],
  exclusion: string[],
): T => {
  if (x) {
    Object.keys(x).forEach((key: string): void => {
      const value = x[key];
      if (value !== undefined && value !== null) {
        const type: string = typeof value;
        if (type === 'string' && shouldConvertStringToDate(key, inclusion, exclusion)) {
          if (autoformat) {
            x[key] = intlFormat(value, { year: '2-digit', month: 'numeric', day: 'numeric' }, { locale });
          } else {
            x[key] = getDate(value);
          }
        } else if (type === 'object') {
          x[key] = formatStringToDateStub(value, autoformat, locale, inclusion, exclusion);
        }
      }
    });
  }
  return x;
};

const shouldConvertStringToDate = (key: string, inclusion: Array<string>, exclusion: string[]) => {
  if (key.indexOf('Date', key.length - 4) !== -1 && !isValueInList(exclusion, key)) {
    return true;
  } else if (isValueInList(inclusion, key)) {
    return true;
  }
  return false;
};

const formatDateToStringStub = <T extends DateUtilsObject | string>(x: T, dateFormat: string): T => {
  if (x) {
    Object.keys(x).forEach((key: string): void => {
      const value = x[key];
      if (value !== null) {
        if (value instanceof Date) {
          x[key] = format(value, dateFormat);
        } else if (typeof value === 'object') {
          x[key] = formatDateToStringStub(value, dateFormat);
        }
      }
    });
  }
  return x;
};

export const formatDateHttp = (date: Date | string, options?: FormatDateHttpOptions): string => {
  const DefaultOptions = {
    includeTimezoneOffset: true,
    preserveTime: true,
    dateFormats: `yyyy-MM-dd'T'HH:mm:ss`,
  };

  options = { ...DefaultOptions, ...options };

  let dateObj = typeof date === 'string' ? parseISO(date) : date;

  if (typeof date === 'string' && !isValid(dateObj)) {
    dateObj = parse(date, 'yyyy/MM/dd', new Date());
  }

  if (!!dateObj && !isValid(dateObj)) {
    throw new Error(`Invalid date value: ${date}`);
  }

  const d = options.preserveTime ? dateObj : startOfDay(dateObj);

  return options.includeTimezoneOffset ? formatISO(d) : format(d, options.dateFormats);
};

export const basicFormatDate = (date: Date, dateFormat?: string): string =>
  format ? format(date, dateFormat) : formatISO(date);

export const formatDate = (date: Date, dateFormat?: string): string => basicFormatDate(date, dateFormat);

interface DateUtilsObject {
  [key: string]: any;
}

export const getDateForRegion = (region: string, separator = DATE_SEPARATOR): string => {
  const dateTime = new Date().toLocaleString(regionToDateLocale[region.toUpperCase()], {
    timeZone: regionTimeZone[region.toUpperCase()],
  });
  const date = dateTime?.replace(DATE_FORMAT_SEPARATORS, '-')?.substring(0, dateTime.indexOf(','));
  const dateFormat = dateFormatByRegion(region, '-');
  return `${format(parse(date, dateFormat.format, new Date()), `yyyy${separator}MM${separator}dd`)}T00:00:00`;
};

export const isValidDateField = (value: string, region?: string) => {
  const date = value?.replace(DATE_FORMAT_SEPARATORS, '-');
  const dateFormat = dateFormatByRegion(region, '-');
  const parsedDate = parse(date, dateFormat.format, new Date());

  const day = parsedDate.getDate();
  const month = parsedDate.getMonth() + 1;
  const year = parsedDate.getFullYear();

  if (!isValid(parsedDate) || month > 12 || year.toString().length !== 4) {
    return false;
  }

  const daysInMonth = getDaysInMonth(parsedDate);

  if (day > daysInMonth) {
    return false;
  }

  return true;
};

export const getDateForRegionAsDate = (region: string): Date => {
  const dateTime = new Date().toLocaleString(regionToDateLocale[region.toUpperCase()], {
    timeZone: regionTimeZone[region.toUpperCase()],
  });
  const dateString = dateTime?.replace(DATE_FORMAT_SEPARATORS, '-')?.substring(0, dateTime.indexOf(','));
  const dateFormat = dateFormatByRegion(region, '-');
  return parse(dateString, dateFormat.format, new Date());
};

/**
 * Convert from a region specific date format to a format we can pass to the our API.
 */
export const dateFieldToDateValue = (inputValue: string | Date, region?: string, separator = DATE_SEPARATOR) => {
  if (inputValue instanceof Date) {
    return `${format(inputValue, `yyyy${separator}MM${separator}dd`)}T00:00:00`;
  } else {
    const date = inputValue?.replace(DATE_FORMAT_SEPARATORS, separator);
    const { format: dateFormat } = dateFormatByRegion(region, separator);
    const parsedDate = parse(date, dateFormat, new Date());
    //E.G. 2024-04-22T00:00:00
    return `${format(parsedDate, `yyyy${separator}MM${separator}dd`)}T00:00:00`;
  }
};

export const dateFormatByRegion = (
  region?: string,
  separator: string = DATE_SEPARATOR,
): { format: string; regex: RegExp } => {
  switch (region?.toUpperCase()) {
    case 'US':
      return { format: `MM${separator}dd${separator}yyyy`, regex: /^(\d{1,2}[/.-]?){1,2}[/.-]?\d{0,4}$/ };

    case 'CA':
      return { format: `yyyy${separator}MM${separator}dd`, regex: /^(\d{0,4}[/.-]?){1,2}[/.-]?\d{0,4}$/ };

    default:
      return { format: `dd${separator}MM${separator}yyyy`, regex: /^(\d{1,2}[/.-]?){1,2}[/.-]?\d{0,4}$/ };
  }
};

/**
 * Function to convert a user input date string to a valid date string, based on region.
 * @param date User Input Date String
 * @param region Region in which the user is logged within
 * @param separator The separator to use, if not the the default
 * @returns Attempts to return a VALID Date string based on the region
 */
export const convertToValidDateString = (date: string, region: string, separator = DATE_SEPARATOR): string => {
  const specialCharInStrings = date.match(/[^0-9]/g);
  const regionFormat = dateFormatByRegion(region, separator);
  const splitFormat = regionFormat.format.split(separator);

  //If the date does not contain any special characters and is 8 characters long, we can assume it is a date string without any separators e.g. 07032025 = 07-03-2025
  if (!specialCharInStrings && date.length === 8) {
    return splitFormat.reduce((acc, curr, index) => {
      const startIndex = splitFormat.reduce((a, c, i) => {
        return i >= index ? a : a + c.length;
      }, 0);

      return acc + date.substring(startIndex, startIndex + curr.length) + (index < 2 ? separator : '');
    }, '');
  }

  //Return the string value if it does not contain any special characters or if it does not match the regex. This will just return the user Input
  const formattedDate = date?.replace(new RegExp(/[^0-9]/g), separator);
  const dateFormat = dateFormatByRegion(region, separator);
  const parsedDate = new Date(parse(formattedDate, dateFormat.format, new Date()));

  if (parsedDate && parsedDate instanceof Date && !isNaN(parsedDate.getTime())) {
    const day = parsedDate.getDate().toString();
    const month = (parsedDate.getMonth() + 1).toString();
    const year = parsedDate.getFullYear().toString();

    if (year.length === 2 || year.length === 4) {
      return splitFormat.reduce((acc, curr, index) => {
        let value = '';
        switch (curr.toUpperCase()) {
          case 'DD':
            value = day;
            break;
          case 'MM':
            value = month;
            break;
          case 'YYYY':
            if (year.length === 2) {
              const currentYear = new Date().getFullYear().toString();
              value = currentYear.substring(0, 2) + year;
            } else {
              value = year;
            }
            break;
        }
        return acc + value + (index < 2 ? separator : '');
      }, '');
    }
  }

  return date;
};
