type CardType = 'Trust' | 'People' | 'Organisation' | 'Business' | 'Government' | 'Company';

export const AddressTypes = ['Street', 'DX', 'POBox', 'RegisteredOffice'] as const;

export const AddressTypesLabel: {
  [k in AddressTypeV2]: string;
} = {
  POBox: 'PO Box',
  RegisteredOffice: 'Registered Office',
  Street: 'Street',
  DX: 'DX',
};

type AddressTypeV2 = (typeof AddressTypes)[number];

type Field = { id: string; jsName: string; value: string };

type AddressDocsCore = {
  addressType: AddressTypeV2;
  propertyBuildingName: string;
  levelUnitShop: string;
  number: string; // eslint-disable-line
  street: string;
  suburb: string;
  state: string;
  county: string;
  municipality: string;
  postcode: string;
  stateType: string;
  streetDirection: string;
  country: string;
  specialInstructions: string;
  district: string;
  type: string;
  stationInfo: string;
  poBoxType?: string; //only available if addressType='POBox'
};

type AddressSettingDocsCore = {
  defaultAddress: string;
  startDate?: string;
  endDate?: string;
};

type PhoneNumberDocsCore = {
  numberType: string;
  value: string;
  areaCode: string;
};

type WebAddressDocsCore = {
  type: string;
  address: string;
};

type PersonDocsCore = {
  comments: string;
  countryOfBirth: string;
  custom10: string;
  custom11: string;
  custom12: string;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;
  custom6: string;
  custom7: string;
  custom8: string;
  custom9: string;
  dateOfBirth: string;
  dateOfDeath: string;
  deleteCode: 1 | 0;
  eMailAddressesText: string;
  fields: Field[];
  firmId: string;
  firstName: string;
  gender: string;
  id: string;
  interpreterLanguage: string;
  lastName: string;
  maidenName: string;
  maritalStatus: string;
  nationality: string;
  occupation: string;
  passportName: string;
  passportNumber: string;
  phoneNumberList: PhoneNumberDocsCore[];
  photoFileName: string;
  placeOfBirth: string;
  placeOfDeath: string;
  previousNames: string;
  salutation: string;
  specialNeeds: string;
  userId: string;
  version: number;
  webAddressList: WebAddressDocsCore[];
};

type RegOfficeDocsCore = {
  county: string;
  specialInstructions: string;
  levelUnitShop: string;
  propertyBuildingName: string;
  number: string; // eslint-disable-line
  postcode: string;
  state: string;
  street: string;
  suburb: string;
};

type TrusteeDocsCore = {
  id: string; // not provided
  trusteeName: string;
  trusteeIsACompany: boolean;
  company: string;
  trusteeCrn: string; // also contains ABN value.
  trusteeAcn: string;
};

type DocumentDocsCore = {
  id: string;
  name: string;
  type: string;
  created: string;
  added: string;
  deleteCode: 1 | 0;
  staffInitials: string;
};

enum SalutationTitleType {
  Custom = 'Custom',
  DefaultFriendly = 'DefaultFriendly',
  DefaultFormal = 'DefaultFormal',
}

enum TitleType {
  Custom = 'Custom',
  Default = 'Default',
}

type CardDocsCore = {
  abn: string;
  acn: string;
  acnType: string;
  addresses: AddressDocsCore[];
  addressSettings: AddressSettingDocsCore;
  asicKey: string;
  bankAccountBSB: string;
  bankAccountName: string;
  bankAccountNumber: string;
  businessType: string;
  comments: string;
  companyName: string;
  companyTitle: string;
  deleteCode: 1 | 0;
  documents: DocumentDocsCore[];
  fields: Field[];
  firmId: string;
  fullName: string;
  futureAddresses: AddressDocsCore[];
  futureAddressSettings: AddressSettingDocsCore;
  id: string;
  isSupplier: boolean;
  lastModifiedUserId: string;
  marketingConsent: boolean;
  personIds: string[];
  persons: PersonDocsCore[];
  phoneNumbers: PhoneNumberDocsCore[];
  photoFileName: string;
  regOffice: RegOfficeDocsCore;
  shortName: string;
  soleDirector: boolean;
  tradingName: string;
  tradingTitle: string;
  trustDate: string;
  trustees: TrusteeDocsCore[];
  trustStatus: string;
  type: CardType;
  version: number;
  webAddresses: WebAddressDocsCore[];
  salutationTitleType: SalutationTitleType;
  salutationTitle: string;
  titleType: TitleType;
  title: string;

  // below are pending from jason
  tin: string; // also known as ABN
  reference: string; // not provided.
  companyOwnerName: string; // also known as companyName
  acnArbnEtc: string; // also known as acnType
  acln: string;
  propertyBuildingName: string; // also known as RegOfficeDocsCore's propertyBuildingName
};

type CardRoleInMatter = {
  tables: string[];
  substitutionTables: string[];
};

type ListCardDocsCore = {
  cardId: string;
  deleteCode: 1 | 0;
  fullName: string;
  firmId: string;
  shortName: string;
  type: string;
  persons: string[];
  addresses: AddressDocsCore[];
  roles: CardRoleInMatter;
  cardRoles: string[];
  isSupplier: boolean;
  email?: WebAddressDocsCore;
};

type SyncDocsCore = {
  data: ListCardDocsCore[];
  lastRowVer: number;
  shardId: string;
  total: number;
  hasNextPage: boolean;
};

enum CardDetailsDocsListColumnIds {
  Type = 'type',
  Name = 'name',
  Created = 'created',
  Added = 'added',
}

enum ContactType {
  Email = 'Email',
  Phone = 'Phone',
  Web = 'Web',
}

type PersonDocsCoreVM = PersonDocsCore & {
  photo?: string; //base64 or https
};

export {
  AddressTypeV2,
  CardType,
  CardRoleInMatter,
  AddressDocsCore,
  AddressSettingDocsCore,
  CardDocsCore,
  Field,
  ListCardDocsCore,
  PersonDocsCore,
  PhoneNumberDocsCore,
  RegOfficeDocsCore,
  SyncDocsCore,
  TrusteeDocsCore,
  WebAddressDocsCore,
  DocumentDocsCore,
  CardDetailsDocsListColumnIds,
  ContactType,
  SalutationTitleType,
  TitleType,
  PersonDocsCoreVM,
};
