import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as notificationActions from '../actions';
import * as folderActions from '@app/features/+correspondence/store/actions/folder';
import { catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  selectCurrentMatterNotifications,
  selectNotificationGroupEntities,
  selectNotificationListLoaded,
  selectNotificationListMetaInform,
  selectNotificationListNotifications,
  selectNotificationListSelectedNotification,
  selectNotificationListShowAll,
  selectNotificationProcessing,
} from '@app/features/+notification/store/selectors';
import { selectCurrentMatter } from '@app/core/store/selectors/app-state.selectors';
import { forkJoin, from as observableFrom, of, timer } from 'rxjs';
import { NotificationApiService, NotificationStorageService } from '../../services';
import {
  ENotificationEntityType,
  INotification,
  INotificationMetaInfo,
} from '@app/features/+notification/models/notification.model';
import { AppApiService } from '@app/core/api';
import {
  selectCurrentRouteData,
  selectRouterPrimaryRouteOutletPageName,
  selectRouterUrl,
} from '@app/core/store/selectors/router-state.selectors';
import { EPageName } from '@app/core/models';
import { PlatformService } from '@app/core/services';
import { DialogService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DuplicatedFileNumber } from '@app/features/+matter-list/constants';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { MatterListStorageService } from '@app/features/+matter-list/services/storage/matter-list-storage.service';

const pollingTimeGap = 900000; // 15 minutes

@Injectable()
export class NotificationEffect {

  loadNotificationsFromDb$ = createEffect(() => this.actions$.pipe(
    ofType(notificationActions.NotificationDbActionTypes.LOAD_NOTIFICATIONS_DB_START),
    withLatestFrom(this.store.pipe(select(selectNotificationListLoaded)), (action, loaded) => loaded),
    filter((loaded) => !loaded && this.platformSvc.isBrowser),

    // Cancels any ongoing operation if a new action comes in
    switchMap(() =>

      // Emits an array containing the LAST emitted value from each input Observable
      forkJoin(
        [
          this.notificationStorageSvc.getAll().pipe(
            tap(
              // Just in case for debugging this piece of over-engineered code!
              //allNotifications => console.debug('---------> Result of noti.getAll():', allNotifications)
            )
          ),

          this.notificationStorageSvc.getMeta()
        ]
      )

        // Chains `mergeMap` with `catchError` to have a cleaner code
        .pipe(

          // Flatten the emitted output from
          mergeMap((data) => {

            //console.debug('############# loadNotificationsFromDb data', data);
            return [
              new notificationActions.StoreMetaInform({
                continuationToken: data[1].continuationToken,
                lastEvaluatedKey: data[1].lastEvaluatedKey,
                staffId: data[1].staffId,
                firmId: data[1].firmId,
              }),
              new notificationActions.GetNotificationBatch({
                lastBatch: data[0],
                getEvents: true,
              }),
              new notificationActions.PollingNotifications(null),
            ]
          }),
          catchError((error) => [
            new notificationActions.GetNotificationBatch({ continuationToken: '', lastBatch: [], getEvents: true }),
            new notificationActions.PollingNotifications(null),
          ])
        ))
  ));


  getNotificationBatch$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.GetNotificationBatch>(
      notificationActions.NotificationApiActionTypes.GET_NOTIFICATION_BATCH
    ),
    withLatestFrom(
      this.store.pipe(select(selectNotificationListMetaInform)),
      (action, metaInform) => {

        const continuationTokenCandidate = action.payload.continuationToken === undefined
          ? metaInform.continuationToken
          : action.payload.continuationToken
          ;

        // TODO: Could be a backend fault, which delivers contnuation tokens with future time-stamp
        //  But they insisted this is intentional!
        //  So, we detect and clear the token here, because passing such tokens would result in
        //    an empty notifications list in all the following get-batch-noti-list calls
        //  It happens when the user acknowledge notifications and then logout immediately
        const sanitizedContinuationToken = this.isContinuationTokenInFuture(continuationTokenCandidate)
          ? ''
          : continuationTokenCandidate
          ;

        return {
          continuationToken: sanitizedContinuationToken,
          lastBatch: action.payload.lastBatch,
          getEvents: action.payload.getEvents,
          metaInform,
        }
      }
    ),
    switchMap((data: any) =>
      this.notificationSvc.getNotifications(data.continuationToken).pipe(map((res) => ({ data, res })))
    ),
    mergeMap(({ data, res }) => {
      // if the return notifications array length equals to the maxResult,
      //  we need to make extra call using 'continuationToken' to ensure there is no more batch left
      const notiRes = res.body;

      console.debug('Check point: !!notiRes.notifications, len, max', !!notiRes.notifications, notiRes.notifications.length, notiRes.maxResults);

      if (!!notiRes.notifications && notiRes.notifications.length === notiRes.maxResults) {

        // We need to enque another call to fetch the next batch of the notifications
        //  since we got an array with the max designated size
        return [
          new notificationActions.GetNotificationBatch({
            continuationToken: notiRes.continuationToken,
            lastBatch: [...data.lastBatch, ...notiRes.notifications],
            getEvents: data.getEvents,
          }),
        ];
      } else {

        // if we got an empty or smaller than max array, it means we have had
        //  all the notifications from the api.
        const notifications = [...data.lastBatch, ...notiRes.notifications];
        const listSuccessData = {
          notifications,
          firmId: notiRes.firmId,
          staffId: notiRes.staffId,
          continuationToken: notiRes.continuationToken,
          lastEvaluatedKey: data.metaInform.lastEvaluatedKey,
        };

        //console.debug('We got small or empty array, so the last call, listSuccessData:', listSuccessData);

        const returnActions: any[] = [
          new notificationActions.StoreMetaInform({
            continuationToken: notiRes.continuationToken,
            staffId: notiRes.staffId,
            firmId: notiRes.firmId,
          }),
          new notificationActions.ListNotificationsSuccess({ data: listSuccessData }),
          new notificationActions.NotificationsSaveDbStart({ data: listSuccessData }),
        ];

        if (data.getEvents) {
          returnActions.push(new notificationActions.GetEventBatch({ lastBatch: [] }));
        }
        return returnActions;
      }
    })
  ));


  getEventBatch$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.GetEventBatch>(notificationActions.NotificationApiActionTypes.GET_EVENT_BATCH),
    withLatestFrom(
      this.store.pipe(select(selectNotificationListMetaInform)),
      (action, metaInform) => ({
        staffId: metaInform.staffId,
        lastEvaluatedKey:
          action.payload.lastEvaluatedKey === undefined ? metaInform.lastEvaluatedKey : action.payload.lastEvaluatedKey,
        lastBatch: action.payload.lastBatch,
      })
    ),
    switchMap((data: any) =>
      this.notificationSvc.getEvents(data.staffId, data.lastEvaluatedKey).pipe(map((res) => ({ data, res })))
    ),
    mergeMap(({ data, res }) => {
      // if the return event list is not empty, we need to make extra call using the lastEvaluatedKey value from the last event in the list to ensure there is no more batch left
      if (!!res && !!res.events && res.events.length > 0) {
        return [
          new notificationActions.GetEventBatch({
            lastEvaluatedKey: res.lastEvaluatedKey,
            lastBatch: [...data.lastBatch, ...res.events],
          }),
        ];
      } else {
        // after we ensuring we already got all notification events, we need to filter out all deleted notifications
        const deletedNotificationIds: number[] = data.lastBatch
          .filter((event) => event.actionType === 'Deleted')
          .map((notification) => notification.notificationId);
        const hasDeletedNotifications = !!deletedNotificationIds && deletedNotificationIds.length > 0;

        return [
          ...(hasDeletedNotifications
            ? [
              // if we have any deletedNotifications, we need to delete those notifications in our indexDB
              new notificationActions.StoreMetaInform({ lastEvaluatedKey: res.lastEvaluatedKey }),
              new notificationActions.DeleteNotifications({ ids: deletedNotificationIds }),
              new notificationActions.NotificationsDeleteDbStart({
                ids: deletedNotificationIds,
                lastEvaluatedKey: res.lastEvaluatedKey,
              }),
            ]
            : []),
        ];
      }
    })
  ));


  saveNotificationsToDb$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.NotificationsSaveDbStart>(
      notificationActions.NotificationDbActionTypes.NOTIFICATIONS_SAVE_DB_START
    ),
    filter(() => this.platformSvc.isBrowser),
    switchMap((action) =>
      this.notificationStorageSvc.upsertAll(action.payload.data)
        .then((res) => new notificationActions.NotificationsSaveDbSuccess(null))
        .catch((error) => new notificationActions.NotificationsSaveDbFailure(null))

    ),
    catchError((error) => of(new notificationActions.NotificationsSaveDbFailure(null)))
  ));


  deleteNotificationFromDb$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.NotificationsDeleteDbStart>(
      notificationActions.NotificationDbActionTypes.NOTIFICATIONS_DELETE_DB_START
    ),
    filter(() => this.platformSvc.isBrowser),
    switchMap((action) => this.notificationStorageSvc
      .deleteBatch(action.payload)
      .then((res) => new notificationActions.NotificationsDeleteDbSuccess(null))
      .catch((error) => new notificationActions.NotificationsDeleteDbFailure(null))),
    catchError((error) => of(new notificationActions.NotificationsDeleteDbFailure(null)))
  ));


  acknowledgeNotification$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.AcknowledgeNotification>(
      notificationActions.NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION
    ),
    switchMap((action) => {
      const { notification } = action.payload;
      return this.notificationSvc.acknowledgeNotification(notification.id).pipe(
        mergeMap((res) => [
          new notificationActions.AcknowledgeNotificationSuccess({ notification }),
          new notificationActions.ClearSelectedNotificationId(null),
        ]),
        catchError((error) => of(new notificationActions.AcknowledgeNotificationFail(null)))
      );
    })
  ));


  acknowledgeNotificationBatch$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.AcknowledgeNotificationBatch>(
      notificationActions.NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_BATCH
    ),
    switchMap((action) => this.notificationSvc.acknowledgeNotificationBatch(action.payload.ids).pipe(
      mergeMap((res) => [
        new notificationActions.AcknowledgeNotificationBatchSuccess({ ids: res }),
        new notificationActions.ClearSelectedNotificationId(null),
      ]),
      catchError((error) => of(new notificationActions.AcknowledgeNotificationBatchFail(null)))
    ))
  ));


  checkNotification$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.CheckNotification>(notificationActions.NotificationApiActionTypes.CHECK_NOTIFICATION),
    filter(() => this.platformSvc.isBrowser),
    switchMap((action) => {
      const { notification, relatedEntityRouteId } = action.payload;
      if (!!relatedEntityRouteId) {
        return of(action.payload);
      } else {
        return observableFrom(this.matterListStorageSvc.get(notification.entity.relatedEntityId)).pipe(
          map((matterEntry) => ({
            notification,
            relatedEntityRouteId: !!matterEntry
              ? matterEntry.fileNumber === DuplicatedFileNumber
                ? notification.entity.relatedEntityId
                : matterEntry.fileNumber
              : null,
          }))
        );
      }
    }),
    withLatestFrom(
      this.store.pipe(select(selectRouterPrimaryRouteOutletPageName)),
      this.store.pipe(select(selectRouterUrl)),
      (data, primaryOutletPageName, currentRouteUrl) => ({
        notification: data.notification,
        relatedEntityRouteId: data.relatedEntityRouteId,
        primaryOutletPageName,
        currentRouteUrl,
      })
    ),
    tap((data) => {
      const { notification, primaryOutletPageName, relatedEntityRouteId, currentRouteUrl } = data;

      // this flag = true uses to navigate to parent path first prior to CanActivated checks by LinkAccoutGuard return false.
      const navigateFirst = currentRouteUrl === '/matters';

      const notificationType = notification.entity.type;

      switch (notificationType) {
        // transient notification
        case ENotificationEntityType.CriticalDate:
          this.handleNotificationCriticalDate(notification, relatedEntityRouteId);
          return;

        // transient notification
        case ENotificationEntityType.SearchOrder:
          this.handleNotificationSearchOrder(notification, primaryOutletPageName, relatedEntityRouteId);
          return;

        // sticky notification
        case ENotificationEntityType.Appointment:
          this.handleNotificationAppointment(notification, relatedEntityRouteId, navigateFirst);
          return;

        // sticky notification
        case ENotificationEntityType.Task:
          this.handleNotificationTask(notification, relatedEntityRouteId, navigateFirst);
          return;

        // sticky notification
        case ENotificationEntityType.Document:
          this.handleNotificationDocument(notification, primaryOutletPageName, relatedEntityRouteId);
          return;

        // sticky notification
        case ENotificationEntityType.Comment:
          this.handleNotificationComment(notification, primaryOutletPageName, relatedEntityRouteId);
          return;

        // sticky notification
        case ENotificationEntityType.Email:
          this.handleNotificationEmail(notification, primaryOutletPageName, relatedEntityRouteId);
          return;

        // sticky notification
        case ENotificationEntityType.PaymentRequest:
          this.handleNotificationPaymentRequest(notification, relatedEntityRouteId);
          return;

        case ENotificationEntityType.Matter:
          this.handleNotificationMatter(notification);
          return;

        default:
          return;
      }
    })
  ), { dispatch: false });


  acknowledgeStickyNotification$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.AcknowledgeStickyNotification>(
      notificationActions.NotificationApiActionTypes.ACKNOWLEDGE_STICKY_NOTIFICATION
    ),
    withLatestFrom(
      this.store.pipe(select(selectNotificationListSelectedNotification)),
      (action: notificationActions.AcknowledgeStickyNotification, selectedNotification: INotification) =>
        selectedNotification
    ),
    filter((selectedNotification: INotification) => !!selectedNotification),
    switchMap((selectedNotification: INotification) => [new notificationActions.AcknowledgeNotification({ notification: selectedNotification })])
  ));


  pollingNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.PollingNotifications>(
      notificationActions.NotificationApiActionTypes.POLLING_NOTIFICATIONS
    ),
    filter(() => this.platformSvc.isBrowser),
    switchMap(() => timer(pollingTimeGap, pollingTimeGap).pipe(
      switchMap(() => [new notificationActions.GetNotificationBatch({ lastBatch: [] })])
    ))
  ));


  acknowledgeTransientNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.AcknowledgeTransientNotifications>(
      notificationActions.NotificationApiActionTypes.ACKNOWLEDGE_TRANSIENT_NOTIFICATIONS
    ),
    withLatestFrom(
      this.store.pipe(select(selectNotificationListSelectedNotification)),
      this.store.pipe(select(selectNotificationGroupEntities)),
      (action, selectedNotification, notificationGroupEntities) => ({
        entityType: action.payload.entityType,
        notifications:
          !!selectedNotification && !!notificationGroupEntities
            ? notificationGroupEntities[selectedNotification.entity.relatedEntityId]
            : [],
      })
    ),
    filter((data) => !!data.notifications && data.notifications.length > 0),
    map((data) => {
      const { entityType, notifications } = data;
      const transientNotifications = notifications.filter((notification) => notification.entity.type === entityType);
      return {
        transientNotifications,
      };
    }),
    filter((data) => !!data.transientNotifications && data.transientNotifications.length > 0),
    switchMap((data) => {
      const { transientNotifications } = data;
      const ids = transientNotifications.map((notification) => notification.id);
      return [new notificationActions.AcknowledgeNotificationBatch({ ids })];
    })
  ));


  acknowledgeCurrentMatterEntityNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.AcknowledgeCurrentMatterEntityNotifications>(
      notificationActions.NotificationApiActionTypes.ACKNOWLEDGE_CURRENT_MATTER_ENTITY_NOTIFICATIONS
    ),
    withLatestFrom(this.store.pipe(select(selectCurrentMatterNotifications)), (action, notifications) => {
      let notification: INotification;
      if (!!notifications && notifications.length > 0) {
        notification = notifications.find((nf) => nf.entity.id === action.payload.entityId);
      }
      return notification;
    }),
    filter((notification) => !!notification),
    switchMap((notification) => this.notificationSvc.acknowledgeEntityNotifications(notification.entity).pipe(
      mergeMap((res) => [
        new notificationActions.AcknowledgeNotificationBatchSuccess({ ids: [notification.id] }),
        new notificationActions.ClearSelectedNotificationId(null),
      ]),
      catchError((error) => of(new notificationActions.AcknowledgeNotificationBatchFail(null)))
    ))
  ));


  acknowledgeCurrentMatterTransientNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.AcknowledgeCurrentMatterTransientNotifications>(
      notificationActions.NotificationApiActionTypes.ACKNOWLEDGE_CURRENT_MATTER_TRANSIENT_NOTIFICATIONS
    ),
    withLatestFrom(this.store.pipe(select(selectCurrentMatterNotifications)), (action, notifications) => ({
      entityType: action.payload.entityType,
      notifications,
    })),
    filter((data) => !!data.notifications && data.notifications.length > 0),
    map((data) => {
      const { entityType, notifications } = data;
      const transientNotifications = notifications.filter((notification) => notification.entity.type === entityType);
      return {
        transientNotifications,
      };
    }),
    filter((data) => !!data.transientNotifications && data.transientNotifications.length > 0),
    switchMap((data) => {
      const { transientNotifications } = data;
      const ids = transientNotifications.map((notification) => notification.id);
      return [new notificationActions.AcknowledgeNotificationBatch({ ids })];
    })
  ));


  clearAllNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.ClearAllNotifications>(
      notificationActions.NotificationApiActionTypes.CLEAR_ALL_NOTIFICATIONS
    ),
    withLatestFrom(
      this.store.pipe(select(selectNotificationProcessing)),
      this.store.pipe(select(selectNotificationListNotifications)),
      this.store.pipe(select(selectCurrentMatter)),
      this.store.pipe(select(selectNotificationListShowAll)),
      (action, isProcessing, notifications, currentMatter, showAll) => ({
        ids: !!notifications ? notifications.map((notification) => notification.id) : [],
        isProcessing,
        isGlobal: !!currentMatter ? showAll : true,
      })
    ),
    filter((data) => !data.isProcessing && !!data.ids && data.ids.length > 0),
    tap((data) => {
      const { ids, isGlobal } = data;
      const title = isGlobal ? 'MatterNotification.Clear.Global.Title' : 'MatterNotification.Clear.CurrentMatter.Title';
      const message = isGlobal
        ? 'MatterNotification.Clear.Global.Message'
        : 'MatterNotification.Clear.CurrentMatter.Message';
      const actionText = isGlobal
        ? 'MatterNotification.Clear.Global.Action.Text'
        : 'MatterNotification.Clear.CurrentMatter.Action.Text';
      const closeText = isGlobal
        ? 'MatterNotification.Clear.Global.Close.Text'
        : 'MatterNotification.Clear.CurrentMatter.Close.Text';
      this.dialogSvc.confirm({
        title: this.translateSvc.instant(title),
        message: this.translateSvc.instant(message),
        showCancel: true,
        actionText: this.translateSvc.instant(actionText),
        closeText: this.translateSvc.instant(closeText),
        onClose: (acknowledgeConfirmed) => {
          if (acknowledgeConfirmed) {
            this.store.dispatch(new notificationActions.AcknowledgeNotificationBatch({ ids }));
          }
        },
      });
    })
  ), { dispatch: false });


  clearNotification$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.ClearNotification>(notificationActions.NotificationApiActionTypes.CLEAR_NOTIFICATION),
    withLatestFrom(this.store.pipe(select(selectNotificationProcessing)), (action, isProcessing) => ({
      notification: action.payload.notification,
      isProcessing,
    })),
    filter((data) => !data.isProcessing && !!data.notification),
    switchMap((data) => {
      const { notification } = data;
      return [new notificationActions.AcknowledgeNotification({ notification })];
    })
  ));


  pubnubNotificationsUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.PubnubNotificationsUpdate>(
      notificationActions.NotificationApiActionTypes.PUBNUB_NOTIFICATIONS_UPDATE
    ),
    withLatestFrom(
      this.store.pipe(select(selectNotificationListMetaInform)),
      this.store.pipe(select(selectCurrentRouteData)),
      (action, metaInform, routeData) => ({
        payload: action.payload,
        metaInform,
        routeData,
      })
    ),
    filter(
      (data) => {

        console.debug('%c pubnubNotificationsUpdate-filter data:', 'color:orange', data);
        return !data.routeData['newWin'] &&
          data.payload.firmId === data.metaInform.firmId &&
          !!data.payload.staffIds?.find((staffId) => staffId === data.metaInform.staffId)
      }
    ),
    // We should pass the `metaInform.continuationToken` to the BATCH action to have a valid one
    switchMap((data) => [new notificationActions.GetNotificationBatch({
      continuationToken: data.metaInform.continuationToken,
      lastBatch: [],
      getEvents: false
    })])
  ));


  pubnubEventsUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.PubnubEventsUpdate>(notificationActions.NotificationApiActionTypes.PUBNUB_EVENTS_UPDATE),
    withLatestFrom(
      this.store.pipe(select(selectNotificationListMetaInform)),
      this.store.pipe(select(selectCurrentRouteData)),
      (action, metaInform, routeData) => ({
        payload: action.payload,
        metaInform,
        routeData,
      })
    ),
    filter((data) => (
      !data.routeData['newWin'] &&
      data.payload.firmId === data.metaInform.firmId &&
      data.payload.staffId === data.metaInform.staffId
    )),
    switchMap(() => [new notificationActions.GetEventBatch({ lastBatch: [] })])
  ));


  openEntityFail$ = createEffect(() => this.actions$.pipe(
    ofType<notificationActions.OpenEntityFail>(notificationActions.NotificationApiActionTypes.OPEN_ENTITY_FAIL),
    tap((action) => {
      const { entityType } = action.payload;
      this.toastrSvc.show(
        this.translateSvc.instant(`MatterNotification.Error.Entity.${entityType}`),
        'Error',
        {},
        'error'
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private appApiSvc: AppApiService,
    private notificationSvc: NotificationApiService,
    private notificationStorageSvc: NotificationStorageService,
    private matterListStorageSvc: MatterListStorageService,
    private dialogSvc: DialogService,
    private toastrSvc: ToastrService,
    private translateSvc: TranslateService,
    private platformSvc: PlatformService,
    private router: Router
  ) { }

  private navigateTo = (
    navigateFirst: boolean,
    routingOptions: {
      path: any[];
      query?: any;
      extras?: any;
    }
  ) => {
    if (navigateFirst) {
      const primaryPath = routingOptions.path[0]?.outlets?.primary;
      this.appApiSvc.navigate({ path: primaryPath });

      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe(() => this.appApiSvc.navigate(routingOptions));

      return;
    }

    this.appApiSvc.navigate(routingOptions);
  };

  /**
   * Handle critical date notification
   *
   * @param notification
   * @param relatedEntityRouteId
   * */
  private handleNotificationCriticalDate(notification: INotification, relatedEntityRouteId: string): void {
    this.store.dispatch(new notificationActions.SetSelectedNotificationId({ id: notification.id }));
    const matterNumber = !!relatedEntityRouteId ? relatedEntityRouteId : notification.entity.relatedEntityId;

    this.appApiSvc.navigate({
      path: [{ outlets: { primary: ['matters', matterNumber, 'schedule'], popup: null } }],
    });
  }

  /**
   * Handle appointment notification
   *
   * @param notification
   * @param relatedEntityRouteId
   * */
  private handleNotificationAppointment(
    notification: INotification,
    relatedEntityRouteId: string,
    navigateFirst: boolean
  ): void {
    this.store.dispatch(new notificationActions.SetSelectedNotificationId({ id: notification.id }));
    const matterNumber = !!relatedEntityRouteId ? relatedEntityRouteId : notification.entity.relatedEntityId;

    this.navigateTo(navigateFirst, {
      path: [{ outlets: { primary: ['matters', matterNumber, 'schedule'], popup: ['appointment'] } }],
      query: { appointmentId: notification.entity.id },
    });
  }

  /**
   * Handle task notification
   *
   * @param notification
   * @param relatedEntityRouteId
   * */
  private handleNotificationTask(
    notification: INotification,
    relatedEntityRouteId: string,
    navigateFirst: boolean
  ): void {
    this.store.dispatch(new notificationActions.SetSelectedNotificationId({ id: notification.id }));
    const matterNumber = !!relatedEntityRouteId ? relatedEntityRouteId : notification.entity.relatedEntityId;

    this.navigateTo(navigateFirst, {
      path: [{ outlets: { primary: ['matters', matterNumber, 'schedule'], popup: ['task'] } }],
      query: { taskId: notification.entity.id },
    });
  }

  /**
   * Handle document notification
   *
   * @param notification
   * @param primaryOutletPageName
   * @param relatedEntityRouteId
   * */
  private handleNotificationDocument(
    notification: INotification,
    primaryOutletPageName: EPageName,
    relatedEntityRouteId: string
  ): void {
    this.store.dispatch(new notificationActions.SetSelectedNotificationId({ id: notification.id }));
    if (primaryOutletPageName === EPageName.MatterDetails) {
      this.store.dispatch(new folderActions.ShowNotificationCorrespondence(null));
    } else {
      const matterNumber = !!relatedEntityRouteId ? relatedEntityRouteId : notification.entity.relatedEntityId;
      this.appApiSvc.navigate({ path: ['matters', matterNumber] });
    }
  }

  /**
   * Handle SearchOrder notification
   *
   * @param notification
   * @param primaryOutletPageName
   * @param relatedEntityRouteId
   * */
  private handleNotificationSearchOrder(
    notification: INotification,
    primaryOutletPageName: EPageName,
    relatedEntityRouteId: string
  ): void {
    this.store.dispatch(new notificationActions.SetSelectedNotificationId({ id: notification.id }));
    if (primaryOutletPageName === EPageName.MatterDetails) {
      this.store.dispatch(
        new notificationActions.AcknowledgeCurrentMatterTransientNotifications({
          entityType: ENotificationEntityType.SearchOrder,
        })
      );
      this.appApiSvc.navigateClear();
    } else {
      const matterNumber = !!relatedEntityRouteId ? relatedEntityRouteId : notification.entity.relatedEntityId;
      this.appApiSvc.navigate({
        path: [{ outlets: { primary: ['matters', matterNumber], popup: null } }],
      });
    }
  }

  /**
   * Handle Comment notification
   *
   * @param notification
   * @param primaryOutletPageName
   * @param relatedEntityRouteId
   * */
  private handleNotificationComment(
    notification: INotification,
    primaryOutletPageName: EPageName,
    relatedEntityRouteId: string
  ): void {

    this.store.dispatch(new notificationActions.SetSelectedNotificationId({ id: notification.id }));
    const matterNumber = !!relatedEntityRouteId ? relatedEntityRouteId : notification.entity.relatedEntityId;
    this.appApiSvc.viewComment(notification.entity.id, false, matterNumber);
  }

  /**
   * Handle Matter notification
   *
   * @param notification
   * @param primaryOutletPageName
   * @param relatedEntityRouteId
   *
   */
  private handleNotificationMatter(
    notification: INotification,
  ): void {

    this.store.dispatch(new notificationActions.SetSelectedNotificationId({ id: notification.id }));
    const matterId = notification.entity.relatedEntityId ?? notification.entity.id;
    this.appApiSvc.navigate({
      path: [{ outlets: { primary: ['matters', matterId], popup: null } }],
    });
  }

  /**
   * Handle Email notification
   *
   * @param notification
   * @param primaryOutletPageName
   * @param relatedEntityRouteId
   * */
  private handleNotificationEmail(
    notification: INotification,
    primaryOutletPageName: EPageName,
    relatedEntityRouteId: string
  ): void {
    this.store.dispatch(new notificationActions.SetSelectedNotificationId({ id: notification.id }));
    if (primaryOutletPageName === EPageName.MatterDetails) {
      this.store.dispatch(new folderActions.ShowNotificationCorrespondence(null));
    } else {
      const matterNumber = !!relatedEntityRouteId ? relatedEntityRouteId : notification.entity.relatedEntityId;
      this.appApiSvc.navigate({ path: ['matters', matterNumber] });
    }
  }

  /**
   * Handle paymentRequest notification
   *
   * @param notification
   * @param relatedEntityRouteId
   * */
  private handleNotificationPaymentRequest(notification: INotification, relatedEntityRouteId: string) {
    this.store.dispatch(new notificationActions.SetSelectedNotificationId({ id: notification.id }));
    const matterNumber = !!relatedEntityRouteId ? relatedEntityRouteId : notification.entity.relatedEntityId;
    this.appApiSvc.navigate({
      path: [
        { outlets: { primary: ['matters', matterNumber, 'payments-debtors'], popup: ['payment-request', 'view'] } },
      ],
      query: { matterNumber: notification.entity.relatedEntityId, transactionId: notification.entity.id },
    });
  }


  /**
   * Check the sanity of the continuationToken
   * Its timestamp feild shouldn't be in the future!
   * @param tokenString
   * @returns
   */
  private isContinuationTokenInFuture(tokenString: string): boolean {

    let result = true;

    try {

      const token = JSON.parse(tokenString);
      if (token) {
        const tokenTimestamp = new Date(`${token.TimeStamp}Z`); // Mark it as UTC
        const currentTime = new Date();
        result = tokenTimestamp > currentTime;

      } else {
        result = false;
      }

    } catch (error) {

      console.error('Error parsing the continuation token:', error);
    }



    return result;
  }
}
