import { createSelector } from '@ngrx/store';
import { isObjEqual } from '@server/modules/shared/functions/common-util.functions';

import { selectCardDetailsState } from '../reducers';
import * as fromCardDetails from '../reducers/card-details.reducer';
import { selectSelectedCardId } from '../selectors/card-list.selectors';

export const selectCardDetailsLoading = createSelector(selectCardDetailsState, (state: fromCardDetails.State) =>
  fromCardDetails.getLoading(state),
);

export const selectCardProcessing = createSelector(selectCardDetailsState, (state: fromCardDetails.State) =>
  fromCardDetails.getCardProcessing(state),
);

export const selectCardDetails = createSelector(selectCardDetailsState, (state: fromCardDetails.State) =>
  fromCardDetails.getCardDetails(state),
);

export const selectFormValue = createSelector(selectCardDetailsState, (state: fromCardDetails.State) =>
  fromCardDetails.getFormValue(state),
);

export const selectActivePersonId = createSelector(selectCardDetailsState, (state: fromCardDetails.State) =>
  fromCardDetails.getActivePersonId(state),
);

export const selectPersonList = createSelector(selectFormValue, (value: any) => (value ? value.personList : []));

export const selectSpecialRatesForCard = createSelector(selectCardDetailsState, (state: fromCardDetails.State) =>
  fromCardDetails.getSpecialRatesForCard(state),
);

export const selectCardDetailsInvalidControls = createSelector(selectCardDetailsState, (state: fromCardDetails.State) =>
  fromCardDetails.getInvalidControls(state),
);

export const selectCardDetailsRelatedMatters = createSelector(selectCardDetailsState, (state: fromCardDetails.State) =>
  fromCardDetails.getRelatedMatters(state),
);

export const selectCardDetailsCalcRendererInits = createSelector(
  selectCardDetailsState,
  (state: fromCardDetails.State) => state.calcRendererInits,
);

export const selectIsCardDetailsModified = createSelector(
  selectCardDetailsLoading,
  selectFormValue,
  selectCardDetails,
  (cardDetailsLoading, formValue, cardDetails) =>
    !!(
      !cardDetailsLoading &&
      !!formValue &&
      !!cardDetails &&
      formValue.__cardId === cardDetails.__cardId &&
      !isObjEqual(formValue, cardDetails)
    ),
);

export const selectCardDetailsLoaded = createSelector(
  selectSelectedCardId,
  selectFormValue,
  (selectedCardId, formValue) => (!!selectedCardId && formValue) || !selectedCardId,
);
