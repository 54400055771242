import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { MenuItemDef } from '@ag-grid-community/core';
import { AddinActionType, AddinItem, LeapAppGridId, ViewLeapApp } from '@app/features/matter-addin/models';
import { OnlyVisibleLinks } from '@app/features/matter-addin/constants/matter-addin.constants';

interface ILeapAppContextItem {
  rowItem: any;
  selectedItems: any[];
  leapApp: ViewLeapApp;
  contextItem: AddinItem;
}
@Directive()
export abstract class GridWithLeapAppSupportBase {
  @Input() leapApps: ViewLeapApp[];

  @Output() onClickLeapAppContextItem = new EventEmitter<ILeapAppContextItem>();

  abstract leapAppGridId: LeapAppGridId;

  protected abstract leapAppContextItemVisibilityCheck(params: { rowItem?: any; addinItem: AddinItem }): boolean;

  protected configLeapAppForAgGridContextMenu(params: {
    rowItem: any;
    selectedItems: any[];
  }): Array<MenuItemDef | string> {
    // no leap apps, return empty array
    if (!this.leapApps || this.leapApps.length === 0) {
      return [];
    }

    const { rowItem, selectedItems } = params;
    let contextMenu: Array<MenuItemDef | string> = [];
  
    const supportedExtensions = ["msg", "docx", "pdf", "html", "xlsx", "png", "jpg", "tif", "txt", "doc",
     "xls", "rtf", "eml", "jpeg", "csv", "tiff", "mhtml", "xhtml", "json", "htm", "oft"]; 
  
    for (const app of this.leapApps) {
      const { view } = app;
      if (view?.grids?.length > 0) {
        const grid = view.grids.find((g) => g.gridId === this.leapAppGridId);
        const validContext = OnlyVisibleLinks(grid?.context);
  
        if (validContext.length > 0) {
          let menuItems: Array<MenuItemDef | string> = [];
  
          for (const c of grid.context) {
            if (this.leapAppContextItemVisibilityCheck({ addinItem: c, rowItem })) {
              // If the AddinItem's actionType is ContextHeader,
              // it means it has subItems and we need to build a submenu for it
              if (c.actionType === AddinActionType.ContextHeader) {
                // Filter and map the sub-items
                const subMenu = c.items
                  .filter((i) => this.leapAppContextItemVisibilityCheck({ addinItem: i, rowItem }))
                  .map((i) => ({
                    name: i.label,
                    action: () =>
                      this.handleClickActionOnLeapAppContextItem({
                        leapApp: app,
                        rowItem,
                        addinItem: i,
                        selectedItems,
                      }),
                  }));
  
                // Only add this context header if it has at least one valid sub-item
                if (subMenu && subMenu.length > 0) {
                  const prefix = (menuItems.length > 0) ? [...menuItems, 'separator'] : [...menuItems];
                  menuItems = [
                    ...prefix,
                    {
                      name: c.label,
                      cssClasses: ['dropdown-header', 'ag-dropdown-header'],
                      disabled: true,
                    },
                    ...subMenu,
                  ];
                }
              } else {
                // AddinActionType.Context
                const isCortoNewDocumentChat = (app.name === "Corto" && c.label === "Matter AI - New Document Chat");
                if (isCortoNewDocumentChat) {
                  let fileExt = (rowItem?.ext || "").toLowerCase();
                  if (fileExt.startsWith(".")) {
                    fileExt = fileExt.substring(1);
                  }
  
                  // If the extension is not supported, skip this item
                  if (!supportedExtensions.includes(fileExt)) {
                    continue;
                  }
                }
  
                const prefix = menuItems.length > 0 ? [...menuItems, 'separator'] : [...menuItems];
                menuItems = [
                  ...prefix,
                  {
                    name: c.label,
                    action: () =>
                      this.handleClickActionOnLeapAppContextItem({
                        leapApp: app,
                        rowItem,
                        addinItem: c,
                        selectedItems,
                      }),
                    disabled: c.hidden !== false,
                  },
                ];
              }
            }
          }
  
          // Remove trailing separator if present
          if (menuItems.length > 0 && menuItems[menuItems.length - 1] === 'separator') {
            menuItems = menuItems.slice(0, -1);
          }
  
          // If the app is Corto, rename it to LEAP AI
          if (app.name === "Corto") {
            if (menuItems.length > 0) {
              contextMenu.push({
                name: 'LEAP AI',
                subMenu: menuItems,
              });
            }
          } else {
            if (menuItems.length > 0) {
              contextMenu.push({
                name: app.name,
                subMenu: menuItems,
              });
            }
          }
        }
      }
    }

    return contextMenu;
  }

  protected handleClickActionOnLeapAppContextItem(params: {
    rowItem: any;
    selectedItems: any[];
    addinItem: AddinItem;
    leapApp: ViewLeapApp;
  }) {
    this.onClickLeapAppContextItem.emit({
      rowItem: params.rowItem,
      selectedItems: params.selectedItems,
      leapApp: params.leapApp,
      contextItem: params.addinItem,
    });
  }
}
