import { forkJoin, Observable } from 'rxjs';

import { filter, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';

import { BaseService } from '@app/shared/services';
import { BrandService, ConfigService, StartupService } from '@app/core/services';
import { AppState, selectTableMappings } from '@app/core/store';
import { IMatterType, IMatterTypeTable, ISchemaApiResponse, TableMapping } from '@app/shared/models';
import { getDisplayMatterType, renameIfNZ } from '@app/features/+matter-types/functions';
import { Regions } from '@app/shared/models/config.model';
import { getObjValue, setOrCreateObjValue } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class MatterTypesService extends BaseService {
  private readonly envRegion: string;

  constructor(
    private http: HttpClient,
    private _startupSvc: StartupService,
    private _store: Store<AppState>,
    private _configService: ConfigService,
    private _brandSvc: BrandService
  ) {
    super();
    this.envRegion = this._configService.getEnvironmentRegion();
  }

  getDefaultState(): string {
    switch (this.envRegion) {
      case Regions.US:
        return 'NY';

      case Regions.UK:
      case Regions.IE:
        return 'ENG';

      case Regions.CA:
        return 'ON';

      case Regions.NZ:
        return 'FED';

      case Regions.AU:
      default:
        return 'NSW';
    }
  }

  getLastMatterTypeId(): Observable<string> {
    const url = this.urlJoin(
      this.apiPath,
      '/api/v1/staffcache/item/',
      `docs-staff-${this._startupSvc.userDetails.staffId}-lastMatterType`
    );
    return this.http.get<string>(url);
  }

  updateLastMatterTypeId(matterTypeId: string): Observable<string> {
    const url = this.urlJoin(this.apiPath, '/api/v1/staffcache/item/');
    return this.http.post<string>(url, {
      [`docs-staff-${this._startupSvc.userDetails.staffId}-lastMatterType`]: matterTypeId,
    });
  }

  getMatterTypes(state: string): Observable<IMatterType[]> {
    const url = this.urlJoin(this.leapDesignPath, `/Content/mattertypes`, `?state=${state}`);
    const project = (response: ISchemaApiResponse<IMatterType>) => (response.data ? (response.data as any) : []).map((matterType) => {
        matterType.name = getDisplayMatterType(matterType, state);
        return renameIfNZ(matterType, this._brandSvc.isNZ());
      });
    return this.http.get<ISchemaApiResponse<IMatterType>>(url).pipe(map(project));
  }

  getMatterType(matterTypeId: string): Observable<IMatterType> {
    const url = this.urlJoin(this.leapDesignPath, `/Content/mattertypes/${matterTypeId}/scheme?includeAncestors=false`);

    const project = (data: [{ data: IMatterType; type: string }, TableMapping[]]) => {
      const [resp, tableMappings] = data;
        const matterType = resp.data;
      return transformMatterTypeTables(matterType, tableMappings, [
        'substitutionTables.client',
        'substitutionTables.otherSide',
        'substitutionTables.otherSideInsurer',
        'substitutionTables.otherSideSolicitor',
      ]);
    };
    return forkJoin([
      this.http.get<any>(url),
      this._store.pipe(
        select(selectTableMappings),
        filter((list) => list.length > 0),
        take(1)
      ),
    ]).pipe(map(project));
  }

  /**
   * Get matterTypeIds of ancestors of a matterType
   *
   * @param matterTypeId
   */
  getMatterTypeAncestors(matterTypeId = ''): Observable<string[]> {
    //todo: new endpoint - dont need to migrate as not supported in new api.
    const url = this.urlJoin(this.schemaPath, '/api/mattertypes/query', `?id=${matterTypeId}`);
    return this.http.get<any>(url).pipe(
      map((response) => {
        const matterType = response.data[0];
        const paths = `${matterType.path}${matterTypeId}`;
        return paths.split('/').filter(Boolean);
      })
    );
  }

  getMatterTypeIdsForState(state: string): Observable<string[]> {
    //todo: new endpoint - dont need to migrate as not supported in new api.
    const url = this.urlJoin(
      this.schemaPath,
      '/api/mattertypes/query',
      `?expand=states&states.name=${state.toUpperCase()}`
    );
    return this.http.get<any>(url).pipe(map((response) => response.data.map((d) => d.id)));
  }
}

const transformMatterTypeTables = (
  matterType: IMatterType,
  mappings: TableMapping[],
  properties: string[]
): IMatterType => {
  let es6Approach = {};

  properties.forEach((property: string) => {
    const substitutionTable = getObjValue(matterType, property);
    const result = {
      ...substitutionTable,
      tables: enhanceMatterTypeTable(mappings, getObjValue(substitutionTable, 'tables', [])),
    };

    es6Approach = setOrCreateObjValue(es6Approach, property, result);
  });

  return {
    ...matterType,
    ...es6Approach,
  } as IMatterType;
};

const enhanceMatterTypeTable = (tableMappings: TableMapping[], tables: IMatterTypeTable[]): IMatterTypeTable[] => (
    tables?.map(
      (table: IMatterTypeTable): IMatterTypeTable => {
        const mapping = tableMappings?.find((tableMapping: TableMapping): boolean => tableMapping.TableId === table.tableId);
        if (mapping) {
          return { ...table, tableName: mapping.TableName };
        }
        return table;
      }
    ) || []
  );
