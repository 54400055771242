import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map as pipeMap } from 'rxjs/operators';

import { BaseService } from '@app/shared/services/base/base.service';
import { ITrustLedgerBalance, ITrustLedgerResponse } from '@app/features/+trust/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TrustLedgerService extends BaseService {
  constructor(private http: HttpClient, private _translateSvc: TranslateService,) {
    super();
  }

  get(matterId: string, bankAccountId?: string): Observable<ITrustLedgerResponse> {
    const queryUrl = !!bankAccountId ? `?bankAccountGuid=${bankAccountId}` : '';
    const url = `${this.accountingPath}/api/cloud/matter/${matterId}/trustledger${queryUrl}`;
    return this.http.get<ITrustLedgerResponse>(url).pipe(
      pipeMap((response: ITrustLedgerResponse) => {
        const transactions = response || DefaultTrustLedgerResponse;
        if (transactions.TrustLedgerMatterList.length > 0) {
          transactions.TrustLedgerMatterList.forEach((transaction) => {
            transaction.TransactionNumber = transaction.TransactionType === -123 ?
              transaction.TransactionNumber.replace('Anticipated Deposit', this._translateSvc.instant('Trust.List.AnticipatedDeposit')) :
              transaction.TransactionNumber;
          });
        }
        return {
          ...transactions,
          TrustLedgerBalance: enrichedTrustLedgerBalance(matterId, response || DefaultTrustLedgerResponse),
        };
      })
    );
  }
}

const enrichedTrustLedgerBalance = (matterId: string, response: ITrustLedgerResponse): ITrustLedgerBalance => {
  const { TotalDeposits, TotalWithdrawals, TrustAntDepositAmount } = response;
  const TrustLedgerBalance = (response.TrustLedgerBalance || {}) as ITrustLedgerBalance;
  return {
    ...TrustLedgerBalance,
    MatterGUID: matterId,
    AvailableBalance:
      TotalDeposits - TotalWithdrawals - Math.max(TrustLedgerBalance.PTFAmount || 0, TrustLedgerBalance.Uncleared || 0),
    TotalDeposits,
    TotalWithdrawals,
    TrustAntDepositAmount,
  };
};

const DefaultTrustLedgerResponse: ITrustLedgerResponse = {
  TrustAntDepositAmount: 0,
  TotalWithdrawals: 0,
  TotalDeposits: 0,
  TrustLedgerBalance: null,
  TrustLedgerMatterList: [],
  TrustLedgerHistoryList: [],
  TrustReason: '',
};
