import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, from, Observable } from 'rxjs';
import { map as pipeMap, mergeMap, map } from 'rxjs/operators';

import { BaseService, LeapDesignService } from '@app/shared/services';
import { mapTableId } from '@app/shared/functions';
import {
  EMatterDetailType,
  IDefinableTable,
  IMatterDetailEntry,
  IMatterType,
  IMatterTypeCard,
  ITableSchema,
} from '@app/shared/models';
import { UserInfo } from '@leapdev/auth-agent/src/lib/types';
import { AuthService } from '@app/core/services';
import { AvailableRegions, ClientTable, DebtorForAccounting } from '@app/core/constants';
import { OrderCalculationPolicies } from '@app/features/+matter-details/constants';
import { isMatterCard, isRelatedLayout, recalculateFileOrder } from '@app/features/+matter-details/functions';
import { ISubstitution } from '@app/features/+matter-list/models';

import { IMatterTableTypeVM } from '../models';
import { calculateDetailEntriesDisplayOrder, orderInType } from '../functions';
import {
  differenceBetweenArrays,
  firstItemInArray,
  isEmptyValue,
  uniqBy,
} from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class MatterTablesService extends BaseService {
  constructor(private http: HttpClient, private _authSvc: AuthService, private _leapDesignSvc: LeapDesignService) {
    super();
  }

  updateMatterTables(matterId: string, detailEntries: IMatterDetailEntry[]): Observable<void> {
    const matterCards = detailEntries?.filter(isMatterCard) || [];
    const definableTables = differenceBetweenArrays(detailEntries, matterCards);
    const url: string = this.urlJoin(this.apiPath, `/api/v1/matters/${matterId}/cardroles-definabletables`);
    // return of(null);
    return this.http.put<void>(url, { matterCards, definableTables });
  }

  getMatterTableTypeVMs(entries: IMatterDetailEntry[]): Observable<IMatterTableTypeVM[]> {
    return combineLatest([this.getTableIdByRegion(DebtorForAccounting), this.getTableIdByRegion(ClientTable)]).pipe(
      pipeMap((source: [string, string]) => {
        const [debtorTableId, clientTableId] = source;
        const isHidden = (entry: IMatterDetailEntry) => [
            !isMatterCard(entry) && (entry as IDefinableTable).basedOnCard,
            entry.tableId === debtorTableId,
            orderInType(entry) > OrderCalculationPolicies.SameTypeRange,
          ].some((match) => !!match);
        const isRemovable = (entry: IMatterDetailEntry) => {
          if (entry.tableId === clientTableId) {
            return false;
          }
          const sameTableEntries: IMatterDetailEntry[] = entries?.filter((x) => x.tableId === entry.tableId) || [];

          const removable = sameTableEntries?.reduce((result: boolean, tableEntry: IMatterDetailEntry) => result && isEmptyValue(tableEntry.__description), true);

          return removable === undefined ? true : removable;
        };

        return (
          (entries?.map((entry) => ({
            ...entry,
            removable: isRemovable(entry),
            hidden: isHidden(entry),
          })) as IMatterTableTypeVM[]) || []
        );
      })
    );
  }

  getTableIdByRegion(constantObj: any): Observable<string> {
    const promise = this._authSvc.userDetails().then((user: UserInfo) => {
      const isValidRegion = [
        AvailableRegions.AU,
        AvailableRegions.UK,
        AvailableRegions.US,
        AvailableRegions.IE,
        AvailableRegions.CA,
        AvailableRegions.NZ,
      ].includes(user.region);
      return isValidRegion ? constantObj[user.region.toLowerCase()].id : constantObj[AvailableRegions.AU].id;
    });
    return from(promise);
  }

  getAllTableTypes(substitution: ISubstitution): Observable<IMatterTableTypeVM[]> {
    return this._leapDesignSvc.getTables().pipe(
      map((allTableTypes: ITableSchema[]) => {
        const activeTableTypes =
          allTableTypes?.filter(
            (tType) =>
              isEmptyValue(tType.id) ||
              firstItemInArray(tType.id.split('')) !== '-'
          ) || [];
  
        const idsAsSubstitution = Object.values(substitution);
  
        return (
          activeTableTypes.reduce((result: IMatterTableTypeVM[], tType: ITableSchema) => {
            if (idsAsSubstitution.includes(tType.id)) {
              return result;
            }
  
            const mappedTableType = getSubsTable(tType, substitution, allTableTypes);
  
            result.push({
              detailType: tType.basedOnCard
                ? EMatterDetailType.Card
                : EMatterDetailType.DefinableTable,
              tableId: tType.id,
              __tableId: tType.id,
              __displayOrder: OrderCalculationPolicies.SameTypeRange,
              __className: mappedTableType.className,
              __name: mappedTableType ? mappedTableType.tableName : tType.tableName,
              removable: true,
              hidden: false,
              isCard: tType.basedOnCard,
              subsTableId:
                !mappedTableType || mappedTableType.id === tType.id
                  ? null
                  : mappedTableType.id,
              default: false,
              order: -1
            } as IMatterTableTypeVM);
  
            return result;
          }, []) || []
        );
      })
    );
  }

  getTableTypesByMatterTypeId(
    matterTypeId: string,
    allTableTypes: IMatterTableTypeVM[],
    debtorId: string
  ): Observable<IMatterTableTypeVM[]> {
    const url = this.urlJoin(
      this.leapDesignPath,
      `/Content/mattertypes/${matterTypeId}/scheme?includeAncestors=false`
    );
  
    return this.http.get<{ data: IMatterType }>(url).pipe(
      map((resp) => {
        const matterType = resp.data;
        if (!matterType) {
          return [];
        }
  
        const cards = matterType.cards ?? [];
        const definableTables = matterType.definableTables ?? [];
  
        const toVMs = (entries: IMatterTypeCard[], isCard: boolean) => {
          return entries?.map((entry) => {
            const matchedTableType: IMatterTableTypeVM = allTableTypes?.find(
              (y) => y.tableId === entry.tableId
            );
            return {
              ...matchedTableType,
              detailType: isCard ? EMatterDetailType.Card : EMatterDetailType.DefinableTable,
              default: entry.default ?? false,
              __displayOrder: OrderCalculationPolicies.SameTypeRange,
              removable: true,
              hidden: entry.tableId === debtorId,
              isCard,
              order: parseInt((entry as any).order ?? '0', 10)
            };
          }) ?? [];
        };
  
        return [...toVMs(cards, true), ...toVMs(definableTables, false)];
      })
    );
  }

  updateDisplayOrderModels(
    details: IMatterDetailEntry[],
    retainCurrentSorting = true
  ): Observable<IMatterDetailEntry[]> {
    const entries = details ? [...details] : [];
    const refinedPayload = recalculateFileOrder(entries);
    return this.getMatterTableTypeVMs(refinedPayload).pipe(
      pipeMap((matterCards) => {
        const beforeReorderedEntries = matterCards.map((x) => ({
          ...x,
          tempName: x.context?.detailNumber ? `${x.__name}.${x.context.detailNumber}` : x.__name,
        }));

        const representatives = uniqBy(
          beforeReorderedEntries?.filter((e) => e.hidden === false),
          'tempName'
        );

        const dependents = differenceBetweenArrays(beforeReorderedEntries, representatives);
        const resultInOrder = calculateDetailEntriesDisplayOrder(
          representatives,
          dependents,
          retainCurrentSorting
        ).filter((entry) => {
          const isNotTransient = !entry.isTransient;
          const isNotRelatedLayout = !isRelatedLayout(entry, entries);
          return isNotTransient && isNotRelatedLayout;
        });

        return resultInOrder.map((payload) => {
          const { tempName, ...others } = payload as any;
          return { ...others } as IMatterDetailEntry;
        });
      })
    );
  }

  reOrderMatterDetails(matterId: string, details: IMatterDetailEntry[]): Observable<void> {
    return this.updateDisplayOrderModels(details).pipe(
      mergeMap((reOrderedDetails) => this.updateMatterTables(matterId, reOrderedDetails))
    );
  }
}

const getSubsTable = (table: ITableSchema, substitution: ISubstitution, allTables: ITableSchema[]): ITableSchema => {
  const mappedTableId: string = mapTableId(table.id, substitution);
  if (mappedTableId === table.id || isEmptyValue(mappedTableId)) {
    return table;
  } else {
    return allTables?.find((t) => t.id === mappedTableId);
  }
};
