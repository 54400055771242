// import localeAuExtra from '@angular/common/locales/extra/en-AU';
// import localeUsExtra from '@angular/common/locales/extra/en-US-POSIX';
// import localeCaExtra from '@angular/common/locales/extra/en-CA';
// import localeUs from '@angular/common/locales/en-US-POSIX';
// import localeCa from '@angular/common/locales/en-CA';
// import localeAu from '@angular/common/locales/en-AU';
// import localeUkExtra from '@angular/common/locales/extra/en-GB';
// import localeUk from '@angular/common/locales/en-GB';
// import localeIeExtra from '@angular/common/locales/extra/en-IE';
// import localeIe from '@angular/common/locales/en-IE';
// import localeNz from '@angular/common/locales/en-NZ';
// import localeNzExtra from '@angular/common/locales/extra/en-NZ';

import { ILocaleInfo } from '@app/core/models';

export * from './router.constant';
export * from './preferences.constant';

export const AvailableRegions = {
  AU: 'au',
  UK: 'uk',
  US: 'us',
  IE: 'ie',
  CA: 'ca',
  NZ: 'nz',
  NL: 'nl',
};

// Hard code - Exception for DebtorForAccounting Table
export const DebtorForAccounting = {
  au: {
    id: '991',
  },
  uk: {
    id: '1080',
  },
  ie: {
    id: '1080',
  },
  us: {
    id: '733',
  },
  ca: {
    id: '733',
  },
  nz: {
    id: '991',
  },
};

// Hard code - Client tableId
export const ClientTable = {
  au: {
    id: '3',
  },
  uk: {
    id: '3',
  },
  us: {
    id: '3',
  },
  ca: {
    id: '3',
  },
  nz: {
    id: '3',
  },
};

export enum EPubNubMessageType {
  TimeFees = 1,
  CostRecoveries = 2,
  TrustLedger = 3,
  Office = 4,
  DocReg = 5,
  AntDisb = 6,
  PowerMoney = 7,
  TransitMoney = 8,
  PowerEstateReg = 9,
  TrustInvestments = 10,
  UpdateDocument = 11,
  MatterListEntry = 12,
  PersonListEntry = 13,
  CardListEntry = 14,
  UpdateStaff = 15,
  UpdateFirm = 16,
  UpdateFolder = 17,
  UpdateTask = 18,
  UpdateCalendar = 19,
  UpdateBranchInfo = 20,
  MatterIndexSucceeded = 21,
}

export const EPermissionType = {
  LeapUser: 'LeapUser',
  LawConnectUser: 'LawConnectUser',
  MatterRead: 'MatterRead',
  MatterWrite: 'MatterWrite',
  CardRead: 'CardRead',
  CardWrite: 'CardWrite',
  ContentRead: 'ContentRead',
  ContentWrite: 'ContentWrite',
  FeeRead: 'FeeRead',
  FeeWrite: 'FeeWrite',
  All: 'All',
};

export const FirmDetailsFetchMode = {
  Cacheable: 'cacheable',
  Force: 'force',
};

export const FirmDetailsFetchFields = [
  'id',
  'firmName',
  'gstRate',
  'externalPlatform',
  'autoNumbering',
  'byLawyers',
  'currency',
  'defaultSearchProvider',
  'region',
  'superDiary',
  'state',
  'personLabels',
  'forceEnableAutoTime'
];

export const LocaleInfoMap: { [key: string]: ILocaleInfo } = {
  au: { localeId: 'en-AU', translateId: 'locale-en-au' },
  us: { localeId: 'en', translateId: 'locale-en-us' },
  ca: { localeId: 'en-CA', translateId: 'locale-en-ca' },
  uk: { localeId: 'en-GB', translateId: 'locale-en-gb' },
  ie: { localeId: 'en-IE', translateId: 'locale-en-ie' },
  nz: { localeId: 'en-NZ', translateId: 'locale-en-nz' },
  nl: { localeId: 'nl-NL', translateId: 'locale-nl-nl' },
};

export const Sentry_DSN = 'https://481574389e584f74b5d8db0f76340379@sentry.io/274255';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
