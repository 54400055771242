import { inject } from "@angular/core";
import { FeatureFlagService } from "@app/core/services";
import { PersonV2Service } from "./person-v2.service";
import { PersonService } from "./person.service";
import { HttpClient } from "@angular/common/http";

const FLAG_USE_NEW_DOCS_CORE_API_SERVICE = 'can-use-docs-core-api';
export function PersonServiceFactory() {
    const featureFlagSvc = inject(FeatureFlagService);
    const useNewDocsCoreAAPIService = featureFlagSvc.isFeatureEnabled(FLAG_USE_NEW_DOCS_CORE_API_SERVICE);
  
    if (useNewDocsCoreAAPIService) {
      return new PersonV2Service(
        inject(HttpClient),
      );
    } else {
      return new PersonService(
        inject(HttpClient),
      );
    }
  }