import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppApiService } from '@app/core/api';
import { EPermissionType } from '@app/core/constants';
import { ESiriusEvents, Feature } from '@app/core/models';
import {
  AuthService,
  BrandService,
  EventBusService,
  FeatureControlService,
  LogService,
  PlatformService,
} from '@app/core/services';
import { xAnimationScaleIn, xAnimationScaleOut } from '@app/shared/animations/animations';
import { DialogService } from '@app/shared/services/dialog/dialog.service';
import { selectFeeTimerCurrentTimeElapsedState, selectFeeTimerVisible } from '@app/features/fee-timer/store/selectors';
import { selectNotificationBellTotal } from '@app/features/+notification/store/selectors';
import { AnalyticsCategories } from '@app/core/constants/analytics.constant';
import { environment } from '@env/environment';
import { UserInfo } from '@leapdev/auth-agent/src/lib/types';
import { TimerRecordingAction } from '@app/features/+time-fee-ledger/models';
import { LeapAppService } from '@app/features/matter-addin/services';

declare const WalkMePlayerAPI;

@Component({
  selector: 'sc-main-header',
  templateUrl: './main-header.component.html',
  animations: [
    trigger('xAnimation', [
      transition(':enter', [useAnimation(xAnimationScaleIn)]),
      transition(':leave', [useAnimation(xAnimationScaleOut)]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  @Input()
  walkmeReady: boolean;

  @Input()
  mainTitle: string;

  firstname: string;
  surname: string;
  email: string;

  brand: string;
  brandLogo: string;
  isTitleX: boolean;
  timerMenuOpened: boolean;
  dropdownMenuOpened: boolean;
  feeTimerClockHidden$: Observable<boolean>;
  insideTimer = false;
  permissionEnum: typeof EPermissionType = EPermissionType;

  // Timesheet App Menu
  timesheetMenu = undefined;
  timeSheetMenuAvailable = signal(false);

  public timerRunning$: Observable<boolean>;
  public isLiveChatEnabled$: Observable<boolean>;
  public isSmallScreen$: Observable<boolean>;
  public notificationTotal$: Observable<number>;
  private _unsub = new Subject<void>();
  private _setCurrentUserDetail$: Subscription = Subscription.EMPTY;

  private _userdetails: UserInfo;

  get getGlobalAnalyticsCategory(): string {
    return AnalyticsCategories.Global;
  }

  get getGlobalSignoutAnalyticsCategory(): string {
    return AnalyticsCategories.GlobalSignout;
  }

  constructor(
    private _appApiSvc: AppApiService,
    private _breakpointObserver: BreakpointObserver,
    private _dialogSvc: DialogService,
    private _featureControlSvc: FeatureControlService,
    private _platformSvc: PlatformService,
    private authSvc: AuthService,
    private log: LogService,
    private titleService: Title,
    private _brandSvc: BrandService,
    private cdRef: ChangeDetectorRef,
    private store: Store<any>,
    private _eventBus: EventBusService,
    private _leapAppService: LeapAppService,
  ) {
    log.init('header-component');
    this.brand = this._brandSvc.brandName;
    this.brandLogo = `/assets/images/${this.brand.toLowerCase()}/header-logo.svg`;
    this.titleService.setTitle(this.brand.toLowerCase() === 'leap' ? this.brand.toUpperCase() : this.brand);
    this.feeTimerClockHidden$ = this.store.pipe(select(selectFeeTimerVisible));
    this.isTitleX = this._brandSvc.isTitleX;

    this._setCurrentUserDetail$ = this._eventBus.on(ESiriusEvents.SetCurrentUserDetail, (value: any) => {
      if (value) {
        let hasChanged = false;
        const { lastName, firstName } = value;

        if (lastName !== this.surname) {
          this.surname = lastName;
          hasChanged = true;
        }

        if (firstName !== this.firstname) {
          this.firstname = firstName;
          hasChanged = true;
        }

        if (hasChanged) {
          this.cdRef.markForCheck();
        }
      }
    });

    this.checkForTimesheets();
  }

  /**
   * Similar to LEAP Desktop functionality, this function checks for the presence of the timesheet app and shows the
   * timesheet in the menubar.
   */
  private checkForTimesheets() {
    if (environment.config.apps.appIds.timesheet && environment.config.apps.appFunctions?.timesheet) {
      this._leapAppService
        .getGlobalLeapApps()
        .pipe(takeUntil(this._unsub))
        .subscribe((apps) => {
          if (apps.length > 0) {
            const timesheetApp = apps.find((app) => {
              return environment.config.apps.appIds.timesheet.includes(app.groupId);
            });

            if (timesheetApp) {
              for (const menu of timesheetApp.menus) {
                const menuItem = menu.items.find((item) => {
                  return environment.config.apps.appFunctions?.timesheet.includes(item.functionGroupId);
                });

                if (menuItem) {
                  this.timesheetMenu = {
                    functionGroupId: menuItem.functionGroupId,
                    appId: timesheetApp.id,
                    url: menuItem.url,
                    windowType: menuItem.windowType,
                  };

                  this.timeSheetMenuAvailable.set(true);

                  break;
                }
              }
            }
          }
        });
    }
  }

  async ngOnInit() {
    this._userdetails = await this.authSvc.userDetails();

    if (this._userdetails) {
      this.firstname = this._userdetails.firstName;
      this.surname = this._userdetails.lastName;
      this.email = this._userdetails.email;
      this.cdRef.markForCheck();
    }

    this.isLiveChatEnabled$ = this._featureControlSvc.isEnabled(Feature.LiveChat);
    this.isSmallScreen$ = this._breakpointObserver
      .observe(['(max-width: 1380px)'])
      .pipe(map((state: BreakpointState) => state.matches));
    this.notificationTotal$ = this.store.pipe(select(selectNotificationBellTotal));
    this.timerRunning$ = this.store.pipe(
      select(selectFeeTimerCurrentTimeElapsedState),
      map((state) => state === TimerRecordingAction.Start),
    );
  }

  ngOnDestroy(): void {
    this._unsub.next();
    this._unsub.complete();

    if (this._setCurrentUserDetail$?.closed === false) {
      this._setCurrentUserDetail$.unsubscribe();
    }
  }

  refresh(event: any) {
    this.authSvc.userDetails().then((user) => {
      console.log(user);
      this._userdetails = user;
      if (this._userdetails) {
        this.firstname = this._userdetails.firstName;
        this.surname = this._userdetails.lastName;
        this.email = this._userdetails.email;
      }
    });
  }

  logout() {
    this._dialogSvc.confirm({
      title: 'SIGN OUT',
      message: 'Are you sure you want to sign out?',
      actionText: 'Sign Out',
      closeText: 'Cancel',
      showCancel: true,
      onClose: (confirmed: boolean) => {
        if (confirmed) {
          this.authSvc.logout();
        }
      },
    });
  }

  about() {
    this._appApiSvc.navigate({ path: [{ outlets: { popup: ['about'] } }] });
  }

  openDevToolbox() {
    this._appApiSvc.navigate({ path: [{ outlets: { popup: ['dev-toolbox'] } }] });
  }

  isDevToolboxEnabled() {
    return localStorage.getItem('leapDevTools') === 'true';
  }

  feedback() {
    this._appApiSvc.navigate({ path: [{ outlets: { popup: ['feedback'] } }] });
  }

  onClickTimesheet() {
    this._leapAppService.openGlobalLeapAppAddin(this.timesheetMenu);
  }

  profile() {
    this._appApiSvc.navigate({ path: [{ outlets: { popup: ['user-profile'] } }] });
  }

  appMenu() {
    this._appApiSvc.navigate({ path: [{ outlets: { popup: ['appmenu'] } }] });
  }

  supportCommunity() {
    window.open(environment.config.support.communityUrl);
  }

  walkMe() {
    WalkMePlayerAPI.toggleMenu();
  }

  liveChat() {
    if (this.isTitleX) {
      this._appApiSvc.navigate({ path: [{ outlets: { popup: ['live-chat'] } }] });
    }
  }

  notifications(): void {
    this._appApiSvc.navigate({ path: [{ outlets: { popup: ['notification'] } }] });
  }

  onTimerOpenChange(event: boolean) {
    return (this.timerMenuOpened = !this.timerMenuOpened);
  }

  onDropdownOpenChange(event: boolean) {
    return (this.dropdownMenuOpened = !this.dropdownMenuOpened);
  }

  timerMouseover(event: any) {
    this.insideTimer = true;
  }

  timerMouseout(event: any) {
    this.insideTimer = false;
  }
}
