import { RouterEffects } from './router.effect';
import { NotificationEffect } from '@app/features/+notification/store/effects/notification.effect';

export const effects = [RouterEffects, NotificationEffect];

export * from './app.effect';
export * from './firm-details.effect';
export * from './pubnub.effect';
export * from './router.effect';
export * from './staff.effect';
export * from './toastr.effect';
export * from './support-request.effect';
export * from './automation.effect';
export * from './table-content.effect';
