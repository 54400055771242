import { map } from 'rxjs/operators';
// core
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { StartupService } from '@app/core/services';
import { AccessControlStaffRole, EMatterDetailType, ICard, IMatterDetailEntry, IMatterType } from '@app/shared/models';
import { BaseService } from '@app/shared/services/base/base.service';

import { TableConfigIds } from '@app/features/+matter-details/constants';
import { hasProp } from '@server/modules/shared/functions/common-util.functions';
import {
  CreateMatterSuccessPayload,
  IMatterAccounting,
  IMatterListEntry,
  IMatterListPublicResponseSchema,
  IMatterListResponseSchema,
  IMatterRequestInfo,
  INewMatterParams,
  ISubstitution,
  SubstitutionTables,
} from '../../models';
import { MatterSearchService } from '../matter-search/matter-search.service';

@Injectable()
export class MatterListService extends BaseService {
  // public
  matters$: Observable<IMatterListEntry[]>;

  // internal
  private matters_ = new BehaviorSubject<IMatterListEntry[]>(null);
  private _path: string;

  constructor(
    private http: HttpClient,
    private matterSearchSvc: MatterSearchService,
    private startupSvc: StartupService
  ) {
    super();
    this._path = `${this.apiPath}/api/v1/matters`;
    this.matters$ = this.matters_.asObservable();
  }

  getAll(lastRowVer = 0): Observable<IMatterListResponseSchema> {
    console.log(`lastRowVer: ${lastRowVer}`);
    const url = this._path + `?lastRowVer=${lastRowVer}`;
    const docsPublicUrl = this.docsPublicPath + `/api/v1/matters?lastRowVer=${lastRowVer}`;

    return combineLatest([this.http.get<IMatterListResponseSchema>(url), this.http.get<IMatterListPublicResponseSchema>(docsPublicUrl)]).pipe(
      map(([matterListResponse, docsPublicResponse]: [IMatterListResponseSchema, IMatterListPublicResponseSchema]) => {
        const newMatterListData = matterListResponse.data.map((matterListEntry) => {
          const matterListPublicEntry = docsPublicResponse.matterList.find((m) => m.matterId === matterListEntry.matterId);
          return {
            ...matterListEntry,
            ...matterListPublicEntry,
            instructionDate: matterListEntry.instructionDate
          }
        })

        return {
          ...matterListResponse,
          data: newMatterListData
        }
      })
    )
  }

  canDelete(matterId: string): Observable<boolean> {
    const url = `${this.accountingv2Path}/api/${matterId}/mattertest`;
    return this.http.get(url).pipe(map((res: any) => res.MatterTestOk));
  }

  delete(matterId: string): Observable<void> {
    const url = this.urlJoin(this._path, matterId);
    return this.http.delete<void>(url);
  }

  create(params: INewMatterParams): Observable<CreateMatterSuccessPayload> {
    const { matterType, card, options } = params;
    const id: string = hasProp(options, 'id') ? options.id : uuidv4();
    const staffId = this.startupSvc.userDetails.staffId;
    const url = `${this.siriusPath}/api/matter`;
    const matterRequest: IMatterRequestInfo = {
      matter: createRequest(id, matterType, card, staffId, options),
      card,
    };

    return this.http.post<CreateMatterSuccessPayload>(url, matterRequest);
  }

  private updateMatterCardPropsWhichEqualsToPartyTableId = (
    previousEntries: IMatterDetailEntry[],
    partyTableId: string,
    partySubsTableId: string,
    detailEntriesExcludedTableOccupiedByParties: IMatterDetailEntry[]
  ) => {
    const anyMatterCardIdWithSimilarToPartySubsTableIdBefore = partySubsTableId
      ? Array.from(
        new Set<string>(
          previousEntries
            .filter((d) => d.detailType === EMatterDetailType.Card && d.tableId === partySubsTableId)
            .map((d) => d.__id)
        )
      )
      : [];

    const party = detailEntriesExcludedTableOccupiedByParties.find((x) => x && x.tableId === partyTableId);
    if (anyMatterCardIdWithSimilarToPartySubsTableIdBefore?.length > 0) {
      // update the key props with new substituted table
      return detailEntriesExcludedTableOccupiedByParties.map((de) => {
        if (!de) {
          return null;
        }

        if (anyMatterCardIdWithSimilarToPartySubsTableIdBefore.includes(de.__id)) {
          if (de.detailType === EMatterDetailType.DefinableTable) {
            return null;
          }

          const previousDetailNumber = previousEntries.find(
            (x) => x.__id === de.__id && x.detailType === EMatterDetailType.Card
          )?.context?.detailNumber;

          return {
            ...de,
            default: party.default,
            subsTableId: party.subsTableId,
            tableId: party.tableId,
            __tableId: party.__tableId,
            __className: party.__className,
            __name: party.__name,
            __classes: party.__classes,
            context: {
              ...de?.context,
              detailNumber: previousDetailNumber,
            },
            hidden: false,
          };
        }

        return de;
      });
    }

    return [...detailEntriesExcludedTableOccupiedByParties];
  };

  getMatterDetailEntriesWithPartySubstitutionTableScenario = (
    parties: ISubstitution,
    matterCardEntries: IMatterDetailEntry[],
    previousDetailEntries: IMatterDetailEntry[],
    isSwitchToAnotherMatterType: boolean
  ) => {
    const partiesTableId = [
      TableConfigIds.CLIENT,
      TableConfigIds.OTHER_SIDE,
      TableConfigIds.OTHER_SIDE_INSURER,
      TableConfigIds.OTHER_SIDE_SOLICITOR,
    ];
    const partiesSubstitutionTableId = [
      parties.client,
      parties.otherSide,
      parties.otherSideInsurer,
      parties.otherSideSolicitor,
    ].filter(Boolean);

    let alreadyInExcludedTableIds = [];
    let detailEntriesExcludedTableOccupiedByParties = matterCardEntries.map((entry) => {
      const entryTableId = entry.__tableId;
      if (
        !partiesTableId.includes(entryTableId) &&
        partiesSubstitutionTableId.includes(entryTableId) &&
        !alreadyInExcludedTableIds.includes(entryTableId) &&
        !!!entry.subsTableId
      ) {
        alreadyInExcludedTableIds = [...alreadyInExcludedTableIds, entryTableId];
        return null;
      }
      return entry;
    });

    // new matter will not have prvious detail entries.
    if (previousDetailEntries) {
      const latest = detailEntriesExcludedTableOccupiedByParties.filter(
        (m) => m && m.detailType === EMatterDetailType.Card
      );

      // any matter card with value should be retained when user switch to new matter type.
      const previousEntries = !isSwitchToAnotherMatterType
        ? [...previousDetailEntries]
        : previousDetailEntries.filter((m) => m.__id);

      const previous = previousEntries.filter((m) => m.detailType === EMatterDetailType.Card);

      const isMatterDetailEntriesChanged =
        (previous && JSON.stringify(previous)) !== (latest && JSON.stringify(latest));

      if (isMatterDetailEntriesChanged) {
        partiesTableId.forEach((partyTableId) => {
          // update any table id similar to previous client substituted table id to newly selected table id.
          const previousPartySubsTableId = previousEntries.find((d) => d.tableId === partyTableId && d.__id)
            ?.subsTableId;
          detailEntriesExcludedTableOccupiedByParties = this.updateMatterCardPropsWhichEqualsToPartyTableId(
            previousEntries,
            partyTableId,
            previousPartySubsTableId,
            detailEntriesExcludedTableOccupiedByParties
          );

          if (isSwitchToAnotherMatterType) {
            const newPartySubsTableId = latest.find((d) => d.tableId === partyTableId && d.__id)?.subsTableId;
            detailEntriesExcludedTableOccupiedByParties = this.updateMatterCardPropsWhichEqualsToPartyTableId(
              previousEntries,
              partyTableId,
              newPartySubsTableId,
              detailEntriesExcludedTableOccupiedByParties
            );
          }

          // exclude the party if its subs table yet assigned and others similar table is/are available.
          const party = detailEntriesExcludedTableOccupiedByParties.find((x) => x && x.tableId === partyTableId);
          if (
            party &&
            !party.cardId &&
            detailEntriesExcludedTableOccupiedByParties.filter((r) => r && r.tableId === party.subsTableId).length > 1
          ) {
            detailEntriesExcludedTableOccupiedByParties = detailEntriesExcludedTableOccupiedByParties.map((de) => {
              if (!de) {
                return null;
              }

              // this is the empty party that need to be replaced by other matter card which has already created in the list.
              if (de.tableId === party.tableId && !party.__id) {
                return null;
              }

              if (party.subsTableId === de.tableId) {
                const displayOrderInArray = `${party.__displayOrder}`.split('');
                const sliceUpTo = displayOrderInArray.length > 0 ? displayOrderInArray.length : undefined;
                const remaininingDigits = displayOrderInArray.slice(1, sliceUpTo).reduce((a, b) => `${a}${b}`);
                const displayOrder = +`${displayOrderInArray[0]}${remaininingDigits}`;

                return {
                  ...de,
                  default: party.default,
                  subsTableId: party.subsTableId,
                  tableId: party.tableId,
                  __tableId: party.__tableId,
                  __className: party.__className,
                  __name: party.__name,
                  __classes: party.__classes,
                  hidden: false,
                  __displayOrder: displayOrder,
                };
              }

              return de;
            });
          }
        });
      }
    }

    return {
      detailEntriesExcludedTableOccupiedByParties,
      partiesTableId,
      partiesSubstitutionTableId,
    };
  };
}

const createRequest = (id: string, matterType: IMatterType, card: ICard, staffId: string, options: any = {}): any => {
  const Role: typeof AccessControlStaffRole = AccessControlStaffRole;
  const clientCardTemplate = matterType.cards?.find((c): boolean => c.tableId === SubstitutionTables.CLIENT);

  return {
    matter: {
      __id: id,
      __className: 'Matter',
      type: matterType.name,
      matterTypeId: matterType.id,
      fileNumber: options.fileNumber || '-1',
      otherRefs: options.otherRefs || '',
      credit: options.credit || staffId,
      personResponsible: options.personResponsible || staffId,
      personActing: options.personActing || staffId,
      personAssisting: options.personAssisting || staffId,
      workObtained: options.workObtained || null,
      referrerCardId: options.referrerCardId || null,
      referrerPersonId: options.referrerPersonId || null,
      acl: options.acl || {
        enabled: false,
        staffList: [
          {
            id: options.credit || staffId,
            role: Role.Credit,
          },
          {
            id: options.personResponsible || staffId,
            role: Role.Responsible,
          },
          {
            id: options.personActing || staffId,
            role: Role.Acting,
          },
          {
            id: options.personAssisting || staffId,
            role: Role.Assisting,
          },
        ],
      },
      accounting:
        options.accounting ||
        ({
          feeEstimate: 0,
          feeEstimateGST: 0,
          disbursementsEstimate: 0,
          disbursementsEstimateGST: 0,
          showEstimateWarning: false,
          invoiceSettings: {
            fixed: false,
            fixedAmount: 0,
          },
          costAgreement: {
            required: false,
            receivedDate: null,
          },
          taxFree: false,
          debtorNote: null,
        } as IMatterAccounting),
      __substitution: {
        client: options.clientId || null,
        otherSide: options.otherSideId || null,
        otherSideSolicitor: options.otherSideSolicitorId || null,
        otherSideInsurer: options.otherSideInsurerId || null,
      },
      category: options.category || 'Dummy',
      codeUnique: hasProp(options, 'codeUnique') === true ? options.codeUnique : 0,

      titleClient: matterType.title.client,
      titleClientClientAsPrefix: matterType.title.clientPrefix,
      titleClientOtherSideAsSuffix: matterType.title.clientSuffix,
      titleClientUseDefault: hasProp(options, 'titleClientUseDefault') === true ? options.titleClientUseDefault : true,
      title: matterType.title.other,
      titleClientAsPrefix: matterType.title.otherPrefix,
      titleOtherSideAsSuffix: matterType.title.otherSuffix,
      titleUseDefault: hasProp(options, 'titleUseDefault') === true ? options.titleUseDefault : true,
      state: options.state || null,
      // AU Test has field for description being "matterDescription", while UK (and US?) have it as "description"
      description: options.description || null,
      matterDescription: options.description || null,
      autoCustomDescription: options.autoCustomDescription || false,
      criticalDateSchemeId: matterType.criticalDateSchemeId,
    },
    matterCards: [
      {
        __inherited: card.__id,
        __tableId: SubstitutionTables.CLIENT,
        reference: card.reference || null,
        __fileOrder: 1,
        __displayOrder: !!clientCardTemplate ? clientCardTemplate.order * 1000000 + 1000 : null,
        cardPersonList: [],
      },
    ],
    // to be added on sirius server
    // extra: {
    //   firstDescription: (card as any)._ShortName() // 5256: use short name for matter description all the time
    // }
  };
};
