import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  delayWhen,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { environment } from '@env/environment';
import { IMatterCard, IntegrationProvider } from '@app/shared/models';
import * as actions from '../actions/current-matter';
import * as appActions from '@app/core/store';
import { selectCurrentMatter, selectCurrentMatterId, selectCurrentStaff, selectFirmDetails } from '@app/core/store';
import { InfoTrackService, MatterDetailsService } from '../../services';
import { State } from '../reducers';
import { DialogService } from '@app/shared/services';

import { IMatterListEntry } from '@app/features/+matter-list/models';
import {
  selectCurrentMatterLoading,
  selectDetailInfo,
  selectMatterCardRelationships,
} from '../selectors/current-matter.selectors';
import { AuthService, FeatureFlagService, PlatformService } from '@app/core/services';
import { AppApiService } from '@app/core/api';
import { IMatterDetailsApiResponse } from '../../models/matter-details-api.model';
import { MatterListStorageService } from '@app/features/+matter-list/services';
import { EInfoTrackType } from '@app/core/models';
import { MatterTablesService } from '@app/features/+matter-table-types/services';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import * as currentMatterActions from '@app/features/+matter-details/store/actions/current-matter';
import { Regions } from '@app/shared/models/config.model';
import { FEATURE_FLAG_USE_INFO_TRACK_SSO_FLOW } from '@app/core/constants/features-flags.constant';

@Injectable()
export class CurrentMatterEffects {

  getCurrentMatter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_CURRENT_MATTER),
    filter(() => !!this._platformService.isBrowser),
    withLatestFrom(this._matterListStorageSvc.getAllStream()),
    switchMap(([action, matters]: [actions.GetCurrentMatter, IMatterListEntry[]]) => this._matterDetailsSvc.get(action.payload.matterId, matters).pipe(
      filter((matter) => !!matter),
      mergeMap((matter) => [new actions.CurrentMatterSuccess(matter)]),
      catchError((error) => {
        this._store.dispatch(new actions.CurrentMatterFailure(error));
        // re-throw error and let error-handler.service to handle this type of error
        return throwError(() =>
          new SiriusError({
            type: 'error',
            title: 'Failure',
            message: 'Unable to get matter entry.',
          })
        );
      })
    ))
  ));


  currentMatterSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.CurrentMatterSuccess>(actions.CURRENT_MATTER_SUCCESS),
    filter(() => !!this._platformService.isBrowser),
    switchMap((action) =>
      // Not saving to db at the moment
      [
        new appActions.SaveMatter(action.payload),
        new appActions.GetMatterCore({ matterId: action.payload.matterId }),
      ]
    )
  ));


  getMatterDetailsStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_MATTER_DETAILS_START),
    switchMap((action: actions.GetMatterDetailsStart) => this._matterDetailsSvc.getMatterDetails(action.payload).pipe(
      mergeMap((resp: IMatterDetailsApiResponse) => [new actions.GetMatterDetailsSuccess(resp)]),
      catchError((error) => {
        this._store.dispatch(new actions.GetMatterDetailsFailure(error));
        // re-throw error and let error-handler.service to handle this type of error
        return throwError(() =>
          new SiriusError({
            type: 'error',
            title: 'Failure',
            message: 'Unable to load matter details.',
          })
        );
      })
    ))
  ));


  getMatterCardsStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.GET_MATTER_CARDS_START),
    switchMap((action: actions.GetMatterCardsStart) => this._matterDetailsSvc.getMatterCards(action.payload).pipe(
      map((resp: IMatterCard[]) => new actions.GetMatterCardsSuccess(resp)),
      catchError((error) => of(new actions.GetMatterCardsFailure(error)))
    ))
  ));


  reorderMatterDetailEntriesStart$: any = createEffect(() => this.actions$.pipe(
    ofType<actions.ReorderMatterDetailEntriesStart>(actions.REORDER_MATTER_DETAIL_ENTRIES_START),
    withLatestFrom(this._store.pipe(select(selectCurrentMatterId))),
    mergeMap((data: any) => {
      const [action, matterId] = data;
      return this._matterTablesSvc.reOrderMatterDetails(matterId, action.payload).pipe(
        map(() => new actions.ReorderMatterDetailEntriesSuccess(null)),
        catchError(() => [new actions.ReorderMatterDetailEntriesFail(null)])
      );
    })
  ));


  reorderMatterDetailEntriesFail$: any = createEffect(() => this.actions$.pipe(
    ofType(actions.REORDER_MATTER_DETAIL_ENTRIES_Fail),
    tap(() => {
      this._dialogSvc.error({ message: this._translateSvc.instant('Matter.Card.Reorder.Error.Message') });
    })
  ), { dispatch: false });


  addActingPersonStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.ADD_ACTING_PERSON_START),
    mergeMap((action: actions.AddActingPersonStart) => {
      const { matterId, matterCard, person } = action.payload;
      return this._matterDetailsSvc.addActingPerson(matterId, matterCard, person).pipe(
        map(() => new actions.PersonActingSuccess(null)),
        catchError(() => {
          this._store.dispatch(new actions.PersonActingFailure(null));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(() =>
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to add acting person.',
            })
          );
        })
      );
    })
  ));


  removeActingPersonStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.REMOVE_ACTING_PERSON_START),
    mergeMap((action: actions.RemoveActingPersonStart) => {
      const { matterId, matterCard, person } = action.payload;
      return this._matterDetailsSvc.removeActingPerson(matterId, matterCard, person).pipe(
        map(() => new actions.PersonActingSuccess(null)),
        catchError(() => {
          this._store.dispatch(new actions.PersonActingFailure(null));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(() =>
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to remove acting person.',
            })
          );
        })
      );
    })
  ));


  updateMatterDetailEntries$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.UPDATE_MATTER_DETAIL_ENTRIES),
    mergeMap((action: actions.UpdateMatterDetailEntries) => {
      const { matterId, entries } = action.payload;
      return this._matterTablesSvc.updateMatterTables(matterId, entries).pipe(
        mergeMap(() => [new currentMatterActions.SetDetailEntries({ detailEntries: entries })]),
        catchError(() =>
          // re-throw error and let error-handler.service to handle this type of error
          throwError(() =>
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to update matter detail entries.',
            })
          )
        )
      );
    })
  ));


  reorderMatterDetailsWithRelationships$: Observable<void> = createEffect(() => this.actions$.pipe(
    ofType<actions.ReorderRelationsMatterDetailsStart>(actions.REORDER_RELATIONS_MATTER_DETAIL_ENTRIES_START),
    withLatestFrom(
      this._store.pipe(select(selectCurrentMatterId)),
      this._store.pipe(select(selectMatterCardRelationships))
    ),
    mergeMap((data) => {
      const [action, matterId, relationships] = data;
      const entries = this._matterDetailsSvc.reOrderDetailsWithRelationships(action.payload, relationships);
      return this._matterTablesSvc.updateMatterTables(matterId, entries);
    })
  ), { dispatch: false });


  goToFormsPrecedents$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GoToFormsPrecedents>(actions.GO_TO_FORMS_PRECEDENTS),
    delayWhen(() =>
      // we need to delay the navigation until we have both matterState and matterTypeId from matterDetails
      this._store.pipe(
        select(selectDetailInfo),
        filter((details) => !!details && !!details.matterState && !!details.matterTypeId),
        take(1)
      )
    ),
    withLatestFrom(this._store.pipe(select(selectDetailInfo)), (action, detailInfo) => detailInfo),
    tap((detailInfo) => {
      const { matterTypeId, matterState } = detailInfo;
      this._appApiSvc.formsAndPrecedents({ matterTypeId, state: matterState });
    })
  ), { dispatch: false });


  goToMatterOptions$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GoToMatterOptionsModalPage>(actions.GO_TO_MATTER_OPTIONS_MODAL_PAGE),
    delayWhen(() =>
      this._store.pipe(
        select(selectCurrentMatterLoading),
        filter((currentMatterLoading) => !currentMatterLoading)
      )
    ),
    tap(() => this._appApiSvc.openMatterOptions())
  ), { dispatch: false });


  /**
   * This is the new SSO Flow for opening up InfoTrack from correspondence
   */
  infotrackNewWin$ = createEffect(() => this.actions$.pipe(
    ofType<actions.InfotrackNewWin>(actions.INFOTRACK_NEW_WIN),
    filter(() => this._platformService.isBrowser),
    filter(() => this._featureFlagSvc.isFeatureEnabled(FEATURE_FLAG_USE_INFO_TRACK_SSO_FLOW)),
    delayWhen(() =>
      this._store.pipe(
        select((state) => ({
          currentMatter: selectCurrentMatter(state),
          firmDetails: selectFirmDetails(state),
          currentStaff: selectCurrentStaff(state),
        })),
        filter(({ currentMatter, firmDetails, currentStaff }) =>
          !!currentMatter && !!firmDetails && !!currentStaff
        ),
        take(1)
      )
    ),
    withLatestFrom(
      this._store.pipe(select(selectCurrentMatter)),
      this._store.pipe(select(selectFirmDetails)),
      this._store.pipe(select(selectCurrentStaff)),
      (action, currentMatter, firmDetails, currentStaff) => {
        return ({
          action,
          currentMatter,
          firmDetails,
          currentStaff,
        })
      }
    ),
    exhaustMap(({ action, currentMatter, firmDetails, currentStaff }) => {
      const url = new URL(decodeURIComponent(action.payload.url))
      if (!url) {
        return;
      }

      this._store.dispatch(new appActions.AppDisplayLoading(true));

      // Add the initial required query params to the URL
      url.searchParams.set('host', 'web');
      url.searchParams.set('isWeb', 'true');

      return this._authService.getToken().then(async (authToken) => {
        const matterContextDocuments = [];

        // if we're opening from correspondence, we will include the documents payload in the message bus data
        if (action.payload.documentId) {
          matterContextDocuments.push({
            docType: "Search", // InfoTrack shows as "Search" for docType
            documentId: action.payload.documentId,
            name: action.payload.documentName,
            type: "PDF", // InfoTrack shows as "PDF" for type
          })
        }

        const context = {
          matterId: currentMatter.matterId,
          matterTypeId: currentMatter.matterType,
          matterFileNumber: currentMatter.fileNumber,
          // isTimeRecordedMatter: null, // unused by infotrack
          // isActivityBilling: null, // unused by infotrack
          // tables: [], // unused by infotrack, they only open in this method from the correspondence space
          documents: matterContextDocuments,
        };

        const messageBusPayload = btoa(JSON.stringify({
          authtoken: authToken,
          matterContext: context,
          context,
          hostInfo: {
            hostType: 'web',
          },
          firmId: firmDetails.id,
          userId: currentStaff.userId,
        }));

        const messageBusRequest = {
          addinURL: url.toString(),
          base64EncodedData: messageBusPayload,
        };

        // Even though this is happening "outside" of the app ecosystem, InfoTrack requires a post to the message bus used by addins
        // so we push this payload to the message bus, and the returned requestId is appended to the URL we give to InfoTrack

        try {
          const response = await fetch(`${environment.config.endpoint.leapadin}/api/v1/addins`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify(messageBusRequest)
          });

          const requestId = await response.json();

          // Append the requestID
          url.searchParams.set('requestId', requestId);

          console.debug('InfoTrack', 'Navigating to', url.toString());

          window.open(url.toString(), 'InfoTrack');
          this._store.dispatch(new appActions.AppDisplayLoading(false));

        } catch (err) {
          console.log('Addins API Error', err);

          this._store.dispatch(new appActions.AppDisplayLoading(false));

          this._infoTrackSvc.handleIntegrationError({
            provider: IntegrationProvider.InfoTrack,
          });
        }

      });

    })
  ), { dispatch: false });

  /**
   * This is the legacy effect for opening the Infotrack window
   * It does not use the SSO flow
   */
  legacyInfotrackNewWin$ = createEffect(() => this.actions$.pipe(
    ofType<actions.InfotrackNewWin>(actions.INFOTRACK_NEW_WIN),
    filter(() => this._platformService.isBrowser),
    filter(() => this._featureFlagSvc.isFeatureDisabled(FEATURE_FLAG_USE_INFO_TRACK_SSO_FLOW)),
    delayWhen(() =>
      combineLatest([
        this._store.pipe(select(selectFirmDetails)),
        this._store.pipe(select(selectCurrentMatter)),
        this._store.pipe(select(selectCurrentStaff)),
      ]).pipe(
        filter((data) => {
          const [firmDetails, currentMatter, currentStaff] = data;
          return !!firmDetails && !!currentMatter && !!currentStaff;
        }),
        take(1)
      )
    ),
    withLatestFrom(
      this._store.pipe(select(selectCurrentMatter)),
      this._store.pipe(select(selectFirmDetails)),
      this._store.pipe(select(selectCurrentStaff)),
      (action, currentMatter, firmDetails, currentStaff) => {
        return ({
          matterId: currentMatter.matterId,
          userDetails: {
            firmId: firmDetails.id,
            staffId: currentStaff.__id,
            firstName: currentStaff.firstName,
            lastName: currentStaff.lastName,
            fullName: currentStaff.fullName,
            userId: currentStaff.userId,
            email: currentStaff.email,
          },
          provider: firmDetails ? firmDetails.defaultSearchProvider : IntegrationProvider.InfoTrack,
          region: !!firmDetails && !!firmDetails.region ? firmDetails.region : Regions.AU,
          env: environment.config.brand.env,
          type: action.payload.type,
          url: action.payload.url,
          documentId: action.payload.documentId,
          documentName: action.payload.documentName,
          matter: {
            fileNumber: currentMatter.fileNumber,
            deleteCode: currentMatter.deleteCode,
            accessible: currentMatter.accessible,
            matterType: currentMatter.matterType,
          },
        })
      }
    ),
    exhaustMap((data) => {
      this._store.dispatch(new appActions.AppDisplayLoading(true));

      const isMatterValid = data?.matter?.deleteCode !== 1 && data.matter.accessible;
      if (!isMatterValid) {
        this._store.dispatch(new appActions.AppDisplayLoading(false));

        this._infoTrackSvc.handleIntegrationError({
          message: `Unfortunately, inactive matter is not supported by ${data.provider}`,
        });
        return [];
      }

      /**
       * Per LS-5796 they require adding systemType=web to InfoTrack URLs
       * Per meeting after, we have decided to follow the app standard and use host=web&isWeb=true
       */
      const addExtraParamToInfoTrackUrl = (url: string) => {
        if (url.includes('?')) {
          return `${url}&host=web&isWeb=true`;
        }

        return `${url}?host=web&isWeb=true`;
      }

      return this._infoTrackSvc.getInfotrackBase(data).pipe(
        mergeMap((res) => {


          const { mappingId, token, provider, success, settingsUrl } = res;
          if (!success) {
            this._store.dispatch(new appActions.AppDisplayLoading(false));

            if (!!settingsUrl) {
              this._infoTrackSvc.handleIntegrationErrorWithSettingUrl({
                settingsUrl: addExtraParamToInfoTrackUrl(settingsUrl),
                provider,
              });

              return;
            }

            this._infoTrackSvc.handleIntegrationError({ provider });
            return;
          }

          if (data.type === EInfoTrackType.Url) {
            this._authService.getToken().then(async (authToken) => {
              const matterContextDocuments = [];

              // if we're opening from correspondence, we will include the documents payload in the message bus data
              if (data.documentId) {
                matterContextDocuments.push({
                  docType: "Search", // InfoTrack shows as "Search" for docType
                  documentId: data.documentId,
                  name: data.documentName,
                  type: "PDF", // InfoTrack shows as "PDF" for type
                })
              }

              const infotrackUrl = addExtraParamToInfoTrackUrl(this._infoTrackSvc.getInfoTrackUrl({ baseUrl: data.url, mappingId, token }));

              const context = {
                matterId: data.matterId,
                matterTypeId: data.matter.matterType,
                matterFileNumber: data.matter.fileNumber,
                // isTimeRecordedMatter: null, // unused by infotrack
                // isActivityBilling: null, // unused by infotrack
                // tables: [], // unused by infotrack, they only open in this method from the correspondence space
                documents: matterContextDocuments,
              };

              const messageBusPayload = btoa(JSON.stringify({
                authtoken: authToken,
                matterContext: context,
                context,
                hostInfo: {
                  hostType: 'web',
                },
                firmId: data.userDetails.firmId,
                userId: data.userDetails.userId,
              }));

              const messageBusRequest = {
                addinURL: infotrackUrl,
                base64EncodedData: messageBusPayload,
              };

              // Even though this is happening "outside" of the app ecosystem, InfoTrack requires a post to the message bus used by addins
              // so we push this payload to the message bus, and the returned requestId is appended to the URL we give to InfoTrack

              try {
                const response = await fetch(`${environment.config.endpoint.leapadin}/api/v1/addins`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                  },
                  body: JSON.stringify(messageBusRequest)
                });

                const requestId = await response.json();

                window.open(`${infotrackUrl}&requestId=${requestId}`, 'InfoTrack');
                this._store.dispatch(new appActions.AppDisplayLoading(false));

              } catch (err) {
                console.log('Addins API Error', err);

                this._store.dispatch(new appActions.AppDisplayLoading(false));

                this._infoTrackSvc.handleIntegrationError({
                  provider,
                });
              }
            })




            return [];
          }

          // Legacy integration methods from here on (hardcoded buttons)

          this._store.dispatch(new appActions.AppDisplayLoading(false));

          const {
            matter: { fileNumber },
            region,
          } = data;

          let url = '';

          switch (data.type) {
            case EInfoTrackType.Search:
              url = res.url;
              break;
            case EInfoTrackType.Fees:
              url = this._infoTrackSvc.getInfoTrackFeesUrl({ fileNumber, mappingId, token, provider });
              break;
            case EInfoTrackType.Support:
              url = this._infoTrackSvc.getInfoTrackSupportUrl({ mappingId, token, region, provider });
              break;
          }

          if (url) {
            window.open(addExtraParamToInfoTrackUrl(url), 'InfoTrack');
          }

          return [];
        }),
        catchError((err) => {
          const { settingsUrl, provider } = err;
          if (!!settingsUrl) {
            this._infoTrackSvc.handleIntegrationErrorWithSettingUrl({
              settingsUrl: addExtraParamToInfoTrackUrl(settingsUrl),
              provider,
            });
          } else {
            this._infoTrackSvc.handleIntegrationError({
              provider,
            });
          }
          return [];
        })
      );
    })
  ), { dispatch: false });


  updateLayoutRendererCriticalDates$ = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateLayoutRendererCriticalDatesStart>(actions.UPDATE_LAYOUT_RENDERER_CRITICAL_DATES_START),
    mergeMap((action) => {
      const { matterId, criticalDates } = action.payload;
      return this._matterDetailsSvc.updateLayoutRendererCriticalDates(matterId, criticalDates).pipe(
        map((res) => new actions.UpdateLayoutRendererCriticalDatesSuccess(null)),
        catchError((err) => [new actions.UpdateLayoutRendererCriticalDatesFailure(err)])
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private _appApiSvc: AppApiService,
    private _store: Store<State>,
    private _platformService: PlatformService,
    private _matterListStorageSvc: MatterListStorageService,
    private _matterDetailsSvc: MatterDetailsService,
    private _matterTablesSvc: MatterTablesService,
    private _dialogSvc: DialogService,
    private _infoTrackSvc: InfoTrackService,
    private _translateSvc: TranslateService,
    private _authService: AuthService,
    private _featureFlagSvc: FeatureFlagService,
  ) { }
}
