import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { StartupService } from '@app/core/services';
import { BaseService, PersonUtilsService } from '@app/shared/services';
import { ICard, ICardDefinableTable } from '@app/shared/models';
import { CardAddressService } from './card-address.service';
import { CardPersonDetailsService } from '@app/features/+card/services/card-person-details.service';
import { EPrintSource, PrintService } from '@app/shared/services/print/print.service';
import { ECardType, INamedList } from '../models';
import { formatDateHttp } from '@app/shared/utils/date.helper';
import {
  isBoolean,
  isEmptyObj,
  isEmptyValue,
  isNumber,
  uniqBy,
} from '@server/modules/shared/functions/common-util.functions';
import { CUSTOMISE_LIST_OPTION_VALUE } from '@app/shared/components/form/select/select.component';
import { AppApiService } from '@app/core/api/app-api.service';
import { EAddressType } from '../models';
import { GetLayoutSaveResponse } from '../../+layout-renderer/models/layout-renderer.model';
@Injectable()
export class CardDetailsService extends BaseService {
  constructor(
    private http: HttpClient,
    private startupSvc: StartupService,
    private addressSvc: CardAddressService,
    private printSvc: PrintService,
    private appApiSvc: AppApiService,
    private personUtilsSvc: PersonUtilsService,
    private personDetailsSvc: CardPersonDetailsService,
  ) {
    super();
  }

  // TODO: Modify after finalising jsEngine implementation. (Nguyen 19/12/2017)
  public loadCard(id: string): Observable<ICard> {
    const url = this.urlJoin(this.siriusPath, `api/card/${id}/details`);
    return this.http.get<ICard>(url);
  }

  /**
   * refactor the data from API
   * */
  public createFormValue(options: any = {}): any {
    const id: string = isEmptyValue(options.__id) === false ? options.__id : uuidv4();

    let originalAddressList = options.originalAddressList;
    let alternativeAddressList = options.alternativeAddressList;
    const defaultOriginalAddressList = [
      this.addressSvc.create('Street'),
      this.addressSvc.create('POBox'),
      this.addressSvc.create('DX'),
    ];
    if (originalAddressList !== undefined && originalAddressList.length > 0) {
      originalAddressList = uniqBy([...originalAddressList, ...defaultOriginalAddressList], 'addressType');
      originalAddressList = this.addressSvc.sort(originalAddressList);
    } else {
      originalAddressList = [...defaultOriginalAddressList];
    }

    if (alternativeAddressList !== undefined && alternativeAddressList.length > 0) {
      alternativeAddressList = uniqBy([...alternativeAddressList, ...defaultOriginalAddressList], 'addressType');
      alternativeAddressList = this.addressSvc.sort(alternativeAddressList);
    } else {
      alternativeAddressList = [...defaultOriginalAddressList];
    }

    const useDefaultTitle = isBoolean(options.useDefaultTitle) === true ? options.useDefaultTitle : true;
    const useDefaultDear = isBoolean(options.useDefaultDear) === true ? options.useDefaultDear : true;
    const useFriendlyDear = isBoolean(options.useFriendlyDear) === true ? options.useFriendlyDear : true;
    const title = useDefaultTitle ? options.autoTitle || '' : options.title || '';
    const dear = useDefaultDear
      ? useFriendlyDear
        ? options.autoDearInformal || ''
        : options.autoDearFormal || ''
      : options.dear || '';

    const personList = options.personList
      ? options.personList.map((person) => this.personUtilsSvc.createPerson(person))
      : [];
    const phoneNumberList =
      options.phoneNumberList && options.phoneNumberList.length > 0
        ? options.phoneNumberList
            .filter((phone) => !!phone.number)
            .map((phone) => this.personUtilsSvc.createPhone(phone.numberType, phone))
        : [];
    const web =
      options.webAddressList && options.webAddressList.length > 0
        ? options.webAddressList
            .filter((w) => (w.type === 'Web' || w.type === 'Web2') && !!w.address)
            .map((w) => this.personUtilsSvc.createWebAddress(w.type, w)) || []
        : [];
    const emails =
      options.webAddressList && options.webAddressList.length > 0
        ? options.webAddressList
            .filter((w) => (w.type === 'Email' || w.type === 'Email2') && !!w.address)
            .map((w) => this.personUtilsSvc.createWebAddress(w.type, w)) || []
        : [];
    const webAddressList = [...emails, ...web];
    return {
      isNew: !options.__id,
      __id: id,
      __cardId: id,
      __className: 'Card',
      __firmId: options.firmId || this.startupSvc.userDetails.firmId,
      reference: options.reference || '',
      cardType: options.type || ECardType.People,
      type: options.type || ECardType.People,

      asicKey: options.asicKey || '',
      bankAccount: options.bankAccount,

      isSupplier: !!options.isSupplier,
      marketingConsent: !!options.marketingConsent,
      useDefaultTitle,
      useFriendlyDear,
      useDefaultDear,
      comments: options.comments || '',
      title,
      dear,

      personList,
      originalAddressList: originalAddressList || [],
      originalAddressSettings: {
        defaultAddress: options.originalAddressSettings?.defaultAddress || EAddressType.STREET,
      },
      alternativeAddressList: alternativeAddressList || [],
      alternativeAddressSettings: {
        defaultAddress:
          options.alternativeAddressSettings && options.alternativeAddressSettings.defaultAddress
            ? options.alternativeAddressSettings.defaultAddress
            : null,
        startDate:
          options.alternativeAddressSettings && options.alternativeAddressSettings.startDate
            ? new Date(options.alternativeAddressSettings.startDate)
            : null,
        endDate:
          options.alternativeAddressSettings && options.alternativeAddressSettings.endDate
            ? new Date(options.alternativeAddressSettings.endDate)
            : null,
      },
      phoneNumberList,
      webAddressList,
      deleteCode: isNumber(options.deleteCode) === true ? options.deleteCode : 0,
      version: isNumber(options.version) === true ? options.version : 0,
      abn: options.abn || '',
      tin: options.tin || '',

      tradingNameTrustName: options.tradingNameTrustName || '',
      propertyBuildingName: options.propertyBuildingName || '',

      acn: options.acn || '',
      acnArbnEtc: options.acnArbnEtc || '',
      acln: options.acln || '',

      soleDirectorCompany: isBoolean(options.soleDirectorCompany) === true ? options.soleDirectorCompany : false,

      companyOwnerName: options.companyOwnerName || '',
      companyTitle: options.companyTitle || '',
      tradingTitle: options.tradingTitle || '',
      businessType: options.businessType || '',
      dateOfTrust: options.dateOfTrust ? new Date(options.dateOfTrust) : new Date(),
      trustStatus: options.trustStatus || '',
      trusteeList:
        options.trusteeList && options.trusteeList.length > 0
          ? options.trusteeList.map((trustee) => ({ ...trustee, id: uuidv4() }))
          : [],

      autoTitle: options.autoTitle || '',
      autoDearFormal: options.autoDearFormal || '',
      autoDearInformal: options.autoDearInformal || '',

      roles: options.roles || {
        tables: [],
        subsTables: [],
      },

      relatedTables: options.relatedTables || [],
      documents: options.documents || [],
      matterCardReference: isEmptyValue(options.matterCardReference) ? null : options.matterCardReference,
      taxCountry: options.taxCountry,
      irdNumber: options.irdNumber || '',
      trust: options.trust,
      beneficiaries: options.beneficiaries || [],
    };
  }

  public formatFormValue(formValue: any, userId: string): any {
    if (formValue.dateOfTrust) {
      formValue = {
        ...formValue,
        dateOfTrust: formatDateHttp(new Date(formValue.dateOfTrust), {
          includeTimezoneOffset: false,
          preserveTime: false,
        }),
      };
    }

    if (formValue.alternativeAddressSettings) {
      formValue = {
        ...formValue,
        alternativeAddressSettings: {
          ...formValue.alternativeAddressSettings,
          startDate: formValue.alternativeAddressSettings.startDate
            ? formatDateHttp(new Date(formValue.alternativeAddressSettings.startDate), {
                includeTimezoneOffset: false,
                preserveTime: false,
              })
            : null,
          endDate: formValue.alternativeAddressSettings.endDate
            ? formatDateHttp(new Date(formValue.alternativeAddressSettings.endDate), {
                includeTimezoneOffset: false,
                preserveTime: false,
              })
            : null,
        },
      };
    }

    return {
      ...formValue,
      personList: formValue.personList.map((person) => ({
        ...person,
        dateOfBirth: person.dateOfBirth
          ? formatDateHttp(new Date(person.dateOfBirth), { includeTimezoneOffset: false, preserveTime: false })
          : '',
        dateOfDeath: person.dateOfDeath
          ? formatDateHttp(new Date(person.dateOfDeath), { includeTimezoneOffset: false, preserveTime: false })
          : '',
        emails: person.webAddressList.filter((address) => address.type === 'Email'),
        web: person.webAddressList.find((address) => address.type === 'Web') || null,
        userId,
      })),
      trusteeList: formValue.trusteeList.filter((trustee) => !!trustee.trusteeName),
    };
  }

  /**
   * Handle option being selected in named list
   */
  public handleOptionSelect(params: { option: string; namedList: INamedList; formControlName: string }) {
    const { option, namedList, formControlName } = params;
    if (option === CUSTOMISE_LIST_OPTION_VALUE) {
      this.appApiSvc.navigate({
        path: [{ outlets: { overlay: ['custom-list'] } }],
        query: { listId: namedList.baseId || namedList.id, fieldId: formControlName },
        extras: { skipLocationChange: true },
      });
    }
  }

  public save(card: any): Observable<any> {
    const url = `${this.siriusPath}/api/card`;

    return this.http.post<any>(url, card);
  }

  public delete(card: any): Observable<any> {
    const url = `${this.apiPath}/api/v1/cards/${card.cardId}`;
    return this.http.delete(url);
  }

  public undelete(cardId: string): Observable<any> {
    const url = `${this.apiPath}/api/v1/cards/${cardId}/undelete`;
    return this.http.post(url, {});
  }

  public hasValidPerson(card: ICard): boolean {
    return isEmptyObj(card) === true
      ? false
      : card.cardType === ECardType.People
        ? !this.personDetailsSvc.isEmpty(card.personList)
        : true;
  }

  public statementOfAccountPrint(cardId: string) {
    this.printSvc.print(
      'APP_Office_Statement_Client_R1178',
      { Client: cardId, FirmGUID: this.startupSvc.userDetails.firmId },
      EPrintSource.Custom,
    );
  }

  /**
   * Get all matters that have a specific card on them
   *
   * @param cardId
   * */
  public getMatters(cardId: string): Observable<any> {
    const url = `${this.apiPath}/api/v1/cards/${cardId}/matters`;
    return this.http.get(url);
  }

  public getDefinableTables(layoutSaveResponses: GetLayoutSaveResponse[]): ICardDefinableTable[] {
    const definableTables = [];

    for (const response of layoutSaveResponses) {
      if (!response.tableObject) continue;
      definableTables.push(response.tableObject);
    }
    return definableTables;
  }
}
