import { Action } from '@ngrx/store';
import { IBeneficiary, ICard, IPerson, ITrustee } from '@app/shared/models';
import { SpecialRate } from '@app/features/+time-fee-ledger/models';
import { AddressType, ECardType, IAlternativeAddressSettings, ICardRelatedMatter } from '@app/features/+card/models';
import { IPhoneNumber, IWebAddress } from '@app/core/models';

export enum CardModalActionTypes {
  GO_NEW_CARD = '[Card Modal] go to new card page',
  CLOSE = '[Card Modal] close modal',
  DESTROY = '[Card Modal] destroy modal',
  REQUEST_RESET = '[Card Modal] request to reset card details',
  RESET = '[Card Modal] reset',
  SAVE = '[Card Modal] save new card',
  SAVE_SUCCEED = '[Card Modal] save new card succeed',
  SAVE_FAIL = '[Card Modal] save new card fail',
  START_NEW_CARD = '[Card Modal] start to create a new card',
  UPDATE_FORM_VALUE = '[Card Modal] update new card form value',
  UPDATE_CARD_DETAILS_AS_FORM_VALUE = '[Card Modal] update card details as form value',
  UPDATE_PERSONAL_DETAILS = '[Card Modal] Update Personal Details',
  SET_CARD_DETAILS_FORM_VALUE = '[Card Modal] set card details form value',
  SELECT_EXISTING_PERSON = '[Card Modal] select existing person',
  LOAD_CARD_DETAILS_START = '[Card Modal] load start',
  LOAD_CARD_DETAILS_SUCCEED = '[Card Modal] load succeed',
  LOAD_CARD_DETAILS_FAIL = '[Card Modal] load fail',
  UPDATE_ALTERNATIVE_ADDRESS_SETTINGS = '[Card Details] update alternative address settings',
  SET_ADDRESS_VALUE = '[Card Modal] set address value',
  MANAGE_BENEFICIARIES = '[Card Beneficiaries] manage beneficiaries in Card Modal',
  ADD_PERSON = '[Card Modal] add person',
  REMOVE_PERSON = '[Card Modal] remove person',
  UPDATE_PERSON_LIST = '[Card Modal] update person list',
  UPDATE_ACTIVE_PERSON_ID = '[Card Modal] update active person id',
  CHANGE_CARD_TYPE = '[Card Modal] change card type',
  UPDATE_CARD_TYPE = '[Card Modal] update card type',
  GET_LETTER_LEAP_CALC_INFORM = '[Card Modal] get letter leap calc inform',
  UPDATE_LETTER_LEAP_CALC_INFORM = '[Card Modal] update letter leap calc inform',
  ADD_CARD_DETAILS_INVALID_CONTROLS = '[Card Modal] add card details invalid controls',
  REMOVE_CARD_DETAILS_INVALID_CONTROLS = '[Card Modal] remove card details invalid controls',
  PERSON_WEB_VALUE_CHANGE = '[Card Modal] person web value change',
  PERSON_PHONE_VALUE_CHANGE = '[Card Modal] person phone value change',
  MANAGE_TRUSTEES = '[Card Trustees] manage trustees in Card Modal',
  ADD_TRUSTEE = '[Card Trustees] add a trustee in Card Modal',
  SET_TRUSTEE = '[Card Trustees] set a trustee in Card Modal',
  DELETE_TRUSTEES = '[Card Trustees] delete trustees in Card Modal',
  ADD_BENEFICIARY = '[Card Beneficiaries] add a beneficiary in Card Modal',
  SET_BENEFICIARY = '[Card Beneficiaries] set a beneficiary in Card Modal',
  DELETE_BENEFICIARIES = '[Card Beneficiaries] delete beneficiaries in Card Modal',
}

export class GoNewCard implements Action {
  readonly type = CardModalActionTypes.GO_NEW_CARD;

  constructor(public payload: any) {}
}

export class CloseModal implements Action {
  readonly type = CardModalActionTypes.CLOSE;

  constructor(public payload: any) {}
}

export class DestroyModal implements Action {
  readonly type = CardModalActionTypes.DESTROY;

  constructor(public payload: any) {}
}

export class Save implements Action {
  readonly type = CardModalActionTypes.SAVE;

  constructor(public payload: { isNewCard: boolean }) {}
}

export class SaveSucceed implements Action {
  readonly type = CardModalActionTypes.SAVE_SUCCEED;

  constructor(public payload: { message?: string }) {}
}

export class SaveFail implements Action {
  readonly type = CardModalActionTypes.SAVE_FAIL;

  constructor(public payload: any) {}
}

export class StartNewCard implements Action {
  readonly type = CardModalActionTypes.START_NEW_CARD;

  constructor(public payload: { type: string; isMatterCard?: boolean }) {}
}

export class UpdateFormValue implements Action {
  readonly type = CardModalActionTypes.UPDATE_FORM_VALUE;

  constructor(public payload: { data: any }) {}
}

export class UpdateCardDetailsAsFormValue implements Action {
  readonly type = CardModalActionTypes.UPDATE_CARD_DETAILS_AS_FORM_VALUE;

  constructor(public payload: any) {}
}

export class LoadCardDetailsStart implements Action {
  readonly type = CardModalActionTypes.LOAD_CARD_DETAILS_START;

  constructor(public payload: { id: string; reset: boolean; isMatterCard?: boolean; itemDetails?: ICard }) {}
}

export class SelectExistingPerson implements Action {
  readonly type = CardModalActionTypes.SELECT_EXISTING_PERSON;

  constructor(public payload: { id: string; itemDetails: IPerson }) {}
}

export class LoadCardDetailsSucceed implements Action {
  readonly type = CardModalActionTypes.LOAD_CARD_DETAILS_SUCCEED;

  constructor(
    public payload: { data: ICard; specialRates: SpecialRate[]; relatedMatters: ICardRelatedMatter[]; reset: boolean },
  ) {}
}

export class LoadCardDetailsFail implements Action {
  readonly type = CardModalActionTypes.LOAD_CARD_DETAILS_FAIL;

  constructor(public payload: { error: any }) {}
}

export class UpdatePersonalDetails implements Action {
  readonly type = CardModalActionTypes.UPDATE_PERSONAL_DETAILS;

  constructor(public payload: { data: any; id: string }) {}
}

export class SetCardDetailsFormValue implements Action {
  readonly type = CardModalActionTypes.SET_CARD_DETAILS_FORM_VALUE;

  constructor(public payload: { data: any }) {}
}

export class SetAddressValue implements Action {
  readonly type = CardModalActionTypes.SET_ADDRESS_VALUE;

  constructor(public payload: { data: any; index: number; addressType: AddressType; alternative: boolean }) {}
}

export class ManageBeneficiaries implements Action {
  readonly type = CardModalActionTypes.MANAGE_BENEFICIARIES;

  constructor(public payload: any) {}
}

export class AddBeneficiary implements Action {
  readonly type = CardModalActionTypes.ADD_BENEFICIARY;

  constructor(public payload: { beneficiary: IBeneficiary }) {}
}

export class SetBeneficiary implements Action {
  readonly type = CardModalActionTypes.SET_BENEFICIARY;

  constructor(public payload: { beneficiary: IBeneficiary; index: number }) {}
}

export class DeleteBeneficiaries implements Action {
  readonly type = CardModalActionTypes.DELETE_BENEFICIARIES;

  constructor(public payload: { indexes: number[] }) {}
}

export class UpdateAlternativeAddressSettings implements Action {
  readonly type = CardModalActionTypes.UPDATE_ALTERNATIVE_ADDRESS_SETTINGS;

  constructor(public payload: { settings: IAlternativeAddressSettings }) {}
}

export class AddPerson implements Action {
  readonly type = CardModalActionTypes.ADD_PERSON;

  constructor(public payload: { data: any }) {}
}

export class RemovePerson implements Action {
  readonly type = CardModalActionTypes.REMOVE_PERSON;

  constructor(public payload: { id: string; shouldRenewLeapCalcInform: boolean }) {}
}

export class UpdatePersonList implements Action {
  readonly type = CardModalActionTypes.UPDATE_PERSON_LIST;

  constructor(public payload: { list: IPerson[]; id: string }) {}
}

export class UpdateActivePersonId implements Action {
  readonly type = CardModalActionTypes.UPDATE_ACTIVE_PERSON_ID;

  constructor(public payload: { id: string }) {}
}

export class ChangeCardType implements Action {
  readonly type = CardModalActionTypes.CHANGE_CARD_TYPE;

  constructor(public payload: { cardType: ECardType }) {}
}

export class UpdateCardType implements Action {
  readonly type = CardModalActionTypes.UPDATE_CARD_TYPE;

  constructor(public payload: { type: ECardType }) {}
}

export class GetLetterLeapCalcInform implements Action {
  readonly type = CardModalActionTypes.GET_LETTER_LEAP_CALC_INFORM;

  constructor(public payload: any) {}
}

export class UpdateLetterLeapCalcInform implements Action {
  readonly type = CardModalActionTypes.UPDATE_LETTER_LEAP_CALC_INFORM;

  constructor(public payload: { inform: any }) {}
}

export class ManageTrustees implements Action {
  readonly type = CardModalActionTypes.MANAGE_TRUSTEES;

  constructor(public payload: any) {}
}

export class AddTrustee implements Action {
  readonly type = CardModalActionTypes.ADD_TRUSTEE;

  constructor(public payload: { trustee: ITrustee }) {}
}

export class SetTrustee implements Action {
  readonly type = CardModalActionTypes.SET_TRUSTEE;

  constructor(public payload: { trustee: ITrustee }) {}
}

export class DeleteTrustees implements Action {
  readonly type = CardModalActionTypes.DELETE_TRUSTEES;

  constructor(public payload: { trustees: ITrustee[] }) {}
}

export class AddCardDetailsInvalidControls implements Action {
  readonly type = CardModalActionTypes.ADD_CARD_DETAILS_INVALID_CONTROLS;

  constructor(public payload: { names: string[] }) {}
}

export class RemoveCardDetailsInvalidControls implements Action {
  readonly type = CardModalActionTypes.REMOVE_CARD_DETAILS_INVALID_CONTROLS;

  constructor(public payload: { names: string[] }) {}
}

export class RequestReset implements Action {
  readonly type = CardModalActionTypes.REQUEST_RESET;

  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = CardModalActionTypes.RESET;

  constructor(public payload: any) {}
}

export class PersonWebValueChange implements Action {
  readonly type = CardModalActionTypes.PERSON_WEB_VALUE_CHANGE;

  constructor(public payload: { web: IWebAddress }) {}
}

export class PersonPhoneValueChange implements Action {
  readonly type = CardModalActionTypes.PERSON_PHONE_VALUE_CHANGE;

  constructor(public payload: { phone: IPhoneNumber }) {}
}

export type CardModalActions =
  | GoNewCard
  | CloseModal
  | DestroyModal
  | RequestReset
  | Reset
  | Save
  | SaveSucceed
  | SaveFail
  | StartNewCard
  | SetCardDetailsFormValue
  | SelectExistingPerson
  | LoadCardDetailsStart
  | LoadCardDetailsSucceed
  | LoadCardDetailsFail
  | SetAddressValue
  | UpdateAlternativeAddressSettings
  | UpdatePersonalDetails
  | AddPerson
  | RemovePerson
  | UpdatePersonList
  | UpdateActivePersonId
  | ChangeCardType
  | UpdateCardType
  | UpdateFormValue
  | UpdateCardDetailsAsFormValue
  | GetLetterLeapCalcInform
  | UpdateLetterLeapCalcInform
  | AddCardDetailsInvalidControls
  | RemoveCardDetailsInvalidControls
  | ManageTrustees
  | AddTrustee
  | SetTrustee
  | DeleteTrustees
  | ManageBeneficiaries
  | AddBeneficiary
  | SetBeneficiary
  | DeleteBeneficiaries
  | PersonWebValueChange
  | PersonPhoneValueChange;
