import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import * as automationActions from '@app/core/store/actions/automation.actions';
import { delay, filter, map, mergeMap, switchMap, tap, throttleTime, withLatestFrom } from 'rxjs/operators';
import {
  selectAutomationLocalTicketId,
  selectAutomationResponded,
  selectAutomationWorkflowParams,
} from '@app/core/store/selectors';
import { AppState } from '../reducers';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BrandService, LogService } from '@app/core/services';
import { DialogService, UiUtilsService } from '@app/shared/services';
import { AppApiService } from '@app/core/api';

@Injectable()
export class AutomationEffect {
  notifyAutomationWordBusy$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(automationActions.AutomationActionTypes.NOTIFY_AUTOMATION_WORD_BUSY),
      map((action: automationActions.NotifyAutomationWordBusy) => action.payload),
      withLatestFrom(this._store.pipe(select(selectAutomationLocalTicketId)), (notification, localTicketId) => ({
        notification,
        localTicketId,
      })),
      switchMap(({ notification, localTicketId }) => {
        if (notification.ticketId === localTicketId) {
          this._dialogSvc.warning({
            title: `${this._brandSvc.brandName} Word Automation`,
            message:
              notification.message || 'Microsoft Word is busy. Please close any open dialog boxes and try again.',
          });
        }
        return [];
      }),
    ),
  );

  launchAutomationStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<automationActions.LaunchAutomationDialogStart>(
        automationActions.AutomationActionTypes.LAUNCH_AUTOMATION_DIALOG_START,
      ),
      withLatestFrom(this._store.pipe(select(selectAutomationLocalTicketId)), (action, id) => id),
      delay(6000),
      withLatestFrom(
        this._store.pipe(select(selectAutomationLocalTicketId)),
        this._store.pipe(select(selectAutomationResponded)),
        (originTicketId, localTicketId, automationResponded) => ({
          originTicketId,
          localTicketId,
          automationResponded,
        }),
      ),
      switchMap((data) =>
        // after 6 sec,
        // firstly, we reset the automationLoading value to false, so the create btn would be enable again,
        // then we check whether we still have localTicketId in the store, if we do, we open the automationDialog
        !data.automationResponded && !!data.localTicketId
          ? [
              new automationActions.ResetAutomationLoading(null),
              new automationActions.ResetAutomationResponded(null),
              new automationActions.LaunchAutomationDialog(null),
              new automationActions.ClearAutomationTickets(null),
            ]
          : [
              new automationActions.ResetAutomationLoading(null),
              new automationActions.ResetAutomationResponded(null),
              new automationActions.DeleteAutomationTicket({ ticketId: data.originTicketId }),
            ],
      ),
    ),
  );

  launchAutomationDialog$: Observable<unknown | Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<automationActions.LaunchAutomationDialog>(
          automationActions.AutomationActionTypes.LAUNCH_AUTOMATION_DIALOG,
        ),
        // throttleTime would ensure we only have one automation download dialog in 6000ms time span
        throttleTime(6000),
        // filter would ensure we only show one automation download dialog
        filter(() => !this.automationDownloadModalRef),
        tap(() => {
          this.automationDownloadModalRef = this._uiUtilsSvc.handleUriNotSupported();
        }),
      ),
    { dispatch: false },
  );

  closeAutomationDialog$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(automationActions.AutomationActionTypes.CLOSE_AUTOMATION_DIALOG),
        tap(() => {
          this.closeAutomationDownloadModal();
        }),
      ),
    { dispatch: false },
  );

  automationReady$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<automationActions.AutomationReady>(automationActions.AutomationActionTypes.AUTOMATION_READY),
      withLatestFrom(
        this._store.pipe(select(selectAutomationLocalTicketId)),
        this._store.pipe(select(selectAutomationWorkflowParams)),
        (action, localTicketId, workflowParams) => ({
          readyTicketId: action.payload,
          localTicketId,
          workflowParams,
        }),
      ),
      filter((data) => data.readyTicketId === data.localTicketId),
      mergeMap((data) => {
        const { workflowParams } = data;
        if (!!workflowParams && workflowParams.navigateClear) {
          this._appApiSvc.clearCurrentModal();
        }
        return [new automationActions.CloseAutomationDialog(null), new automationActions.AutomationClearParams(null)];
      }),
    ),
  );

  automationError$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<automationActions.AutomationError>(automationActions.AutomationActionTypes.AUTOMATION_ERROR),
      withLatestFrom(
        this._store.pipe(select(selectAutomationLocalTicketId)),
        this._store.pipe(select(selectAutomationWorkflowParams)),
      ),
      mergeMap((data) => {
        const [action, localTicketId, workflowParams] = data;
        if (action.payload.RecordId === localTicketId) {
          if (!!workflowParams && workflowParams.navigateClear) {
            this._appApiSvc.navigateClear();
          }

          this._dialogSvc.error({
            title: 'Error',
            message: action.payload.Message !== '' ? action.payload.Message : 'Server Error',
            actionText: 'Close',
          });
        }
        return [new automationActions.AutomationClearParams(null)];
      }),
    ),
  );

  private automationDownloadModalRef: BsModalRef;

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _log: LogService,
    private _dialogSvc: DialogService,
    private _brandSvc: BrandService,
    private _uiUtilsSvc: UiUtilsService,
    private _appApiSvc: AppApiService,
  ) {
    this._log.init('automation.effects');
  }

  private closeAutomationDownloadModal() {
    if (this.automationDownloadModalRef) {
      this.automationDownloadModalRef.hide();
      this.automationDownloadModalRef = null;
    }
  }
}
