import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppSlice } from '@app/core/store';
import { MatterAddinService, LeapAppService } from '@app/features/matter-addin/services';
import { LeapAppEffect, MatterAddinEffect } from '@app/features/matter-addin/store/effect';
import { reducers } from '@app/features/matter-addin/store/reducer';
import { leapAppReducer } from '@app/features/matter-addin/store/reducer';
import { TrustLedgerStateModule } from '@app/features/+trust/trust-ledger-state.module';
import { PrecedentService } from '@app/features/+precedent/services';

@NgModule({
  imports: [
    CommonModule,
    TrustLedgerStateModule,
    StoreModule.forFeature(AppSlice.MatterAddin, reducers),
    StoreModule.forFeature(AppSlice.LeapApp, leapAppReducer),
    EffectsModule.forFeature([MatterAddinEffect, LeapAppEffect]),
  ],
  declarations: [],
  providers: [MatterAddinService, PrecedentService, LeapAppService],
})
export class MatterAddinStateModule {}
