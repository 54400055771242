import { Action } from '@ngrx/store';
import { ILeapDesignTableContent } from '../../../shared/models';

export enum TableContentActions {
  LOAD_TABLE_CONTENT = '[Table Content] Load Table Content',
  LOAD_TABLE_CONTENT_SUCCESS = '[Table Content] Load Table Content Success',
  LOAD_TABLE_CONTENT_FAILURE = '[Table Content] Load Table Content Failure',
}

export class LoadTableContent implements Action {
  readonly type = TableContentActions.LOAD_TABLE_CONTENT;

  constructor(public payload: { tableId: string }) {}
}

export class LoadTableContentSuccess implements Action {
  readonly type = TableContentActions.LOAD_TABLE_CONTENT_SUCCESS;

  constructor(public payload: { tableContent: ILeapDesignTableContent }) {}
}

export class LoadTableContentFailure implements Action {
  readonly type = TableContentActions.LOAD_TABLE_CONTENT_FAILURE;

  constructor(public payload: any) {}
}

export type TableContentAction = LoadTableContent | LoadTableContentSuccess | LoadTableContentFailure;
