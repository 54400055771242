import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  public readonly isDeno: boolean;

  constructor() {
    this.isDeno = typeof (globalThis as any).Deno !== 'undefined' && 
                  typeof (globalThis as any).Deno.version !== 'undefined';
  }
}
