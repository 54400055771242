import { SortModelItem } from '@ag-grid-community/core';
import { ViewLeapAppLocationId } from '../../matter-addin/models';

export interface IPersonListEntry {
  personId: string;
  groupName?: string;
  salutation?: string;
  forenames?: string;
  surname?: string;
  recVer?: number;
  deleteCode?: number;
}

export interface IPersonListPublicEntry {
  rowVersion: number;
  previousName: string | null;
  birthDate: string | null;
  driverLicense: string | null;
  personId: string;
  firstName: string;
  lastName: string;
  salutation: string;
  deleteCode: boolean;
  emails: string[];
  contactNumbers: string[];
}

export interface IPersonListRow {
  isGroup?: boolean;
  groupName?: string;
  data?: IPersonListEntry;
  children?: IPersonListRow[];
}

export interface IPersonListResponseSchema {
  data: IPersonListEntry[];
  lastRowVer: number;
  total: number;
}

export interface IPersonListPublicResponseSchema {
  personList: IPersonListPublicEntry[];
  lastRowVersion: number | null;
}

export interface IPersonListMetaInfo {
  id: string;
  lastRowVer?: number;
  personSearchText?: string;
  groupInfo?: EPersonListGroupInfo;
  selectedPersonId?: string;
}

export enum EPersonListGroupInfo {
  UNGROUP = 1,
  FORENNAMES = 2,
  SURNAME = 3,
}


export enum EPersonDetailsTabType {
  Basic,
  Other,
  AdditionalFields,
  Cards,
  Matters,
}

export interface IPersonGroupListQuery {
  showDeleted: boolean;
  startRow: number;
  endRow: number;
  sortModel: SortModelItem[];
  searchText: string;
  rowGroupCols: string[];
  groupKeys: string[];
}

export const PersonErrors = {
  firstNames: {
    invalidTab: EPersonDetailsTabType.Basic,
    message: 'This Person cannot be saved. Please enter person\'s name.',
  },
  lastName: {
    firstNames: {
      invalidTab: EPersonDetailsTabType.Basic,
      message: 'This Person cannot be saved. Please enter person\'s name.',
    },
  },
  emailList: {
    inValidTab: EPersonDetailsTabType.Basic,
    message: 'This Person cannot be saved. Please enter a valid email address.',
  },
  default: {
    inValidTab: null,
    message: 'This Card cannot be saved.',
  },
};

export const PersonDetailsTabViewLeapAppLocationId = {
  [EPersonDetailsTabType.Basic]: ViewLeapAppLocationId.PeopleBasic,
  [EPersonDetailsTabType.Other]: ViewLeapAppLocationId.PeopleOther,
  [EPersonDetailsTabType.Cards]: ViewLeapAppLocationId.PeopleCardList,
  [EPersonDetailsTabType.Matters]: ViewLeapAppLocationId.PeopleMatterList,
};

export interface IDropdownIconOption {
  header?: boolean;
  divider?: boolean;
  buttonText?: string;
  action?: boolean;
}

export interface IPersonListGroupOption extends IDropdownIconOption {
  value: number;
  name: string;
}

export interface IPeopleResponseNextLink {
  rel: string;
  method: string;
  href: string;
}

export interface IPeopleResponseByDocAPI extends IPersonListResponseSchema {
  shardId: string;
  link: IPeopleResponseNextLink;
}

export enum PersonListColumnId {
  person = 'person',
  context = 'context',
}
