import { Action } from '@ngrx/store';
import { EInfoTrackType } from '@app/core/models';

export const CLEAR_MATTER_DETAILS = '[Current matter] clear';
export const INFOTRACK_NEW_WIN = '[Infotrack] new infotrack win';

export class ClearMatterDetails implements Action {
  readonly type = CLEAR_MATTER_DETAILS;
  constructor(public payload: any) {}
}

export class InfotrackNewWin implements Action {
  readonly type = INFOTRACK_NEW_WIN;

  constructor(public payload: { type: EInfoTrackType; url?: string, documentId?: string, documentName?: string }) {}
}

export type CurrentMatterDbActions = InfotrackNewWin | ClearMatterDetails;
