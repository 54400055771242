import { Injectable } from '@angular/core';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions/app.action';
import { FirmDetails } from '@app/shared/models';
import { AuthService, FirmService, LogService, StartupService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';

import { LocaleInfoMap } from '@app/core/constants';
import * as browserTitleActions from '@app/core/store/actions/browser-title.action';
import { combineLatest, from, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectAppRegion } from '@app/core/store/selectors/app-state.selectors';
import { getRegionFromCode, Regions } from '@app/shared/models/config.model';
import { AccountingService } from '@app/features/accounting/services/accounting/accounting.service';

@Injectable()
export class FirmDetailsEffects {
  constructor(
    private actions$: Actions,
    private _store: Store<any>,
    private _log: LogService,
    private _firmService: FirmService,
    private _translateSvc: TranslateService,
    private _accountingSvc: AccountingService,
    private _startUpSvc: StartupService,
    private _authService: AuthService
  ) {
    this._log.init('firm-details-effects');
  }

  addFirmDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AddFirmDetails>(actions.ADD_FIRM_DETAILS),
      withLatestFrom(this._store.pipe(select(selectAppRegion)), (action, region) => ({ action, region })),
      mergeMap(({ action, region }) => {
        const { fetchMode, fields } = action.payload;

        return this._firmService.getFirmDetails({ fetchMode, fields }).pipe(
          switchMap((firmDetails: FirmDetails) =>
            combineLatest([of(firmDetails), this.updateRegionSettings(firmDetails)])
          ),
          mergeMap(([firmDetails]) => {
            this._accountingSvc.configAccounting({ firmDetails, userDetails: this._startUpSvc.userDetails });
            // we need to reset the browser title if the region changes
            return !!region && region !== firmDetails.region
              ? [new actions.AddFirmDetailsSuccess(firmDetails), new browserTitleActions.PrimaryPageTitleInit()]
              : [new actions.AddFirmDetailsSuccess(firmDetails)];
          })
        );
      }),
      catchError((error) => [new actions.AddFirmDetailsFailure(error)])
    )
  );

  /*
      Calculation of region includes special case of Ireland hosted on the same environment as UK
      but using different currency symbol

      See [LO-1798] and jsengine.service.ts for more information on LEO implementation,
      this takes its implementation from that.
      More special cases can be added to the switch
  */
  private updateRegionSettings(firmDetails: FirmDetails): Observable<any> {
    const payloadCurrency = firmDetails.currency;

    let region = getRegionFromCode(this._authService.countryCode) || firmDetails.region;

    if (payloadCurrency?.code === 'EUR' && region === Regions.UK) {
      region = Regions.IE;
    }

    this._startUpSvc.updateUserRegion(region);

    const { translateId, localeId } = LocaleInfoMap[region];
    this._translateSvc.use(translateId);

    return from(
      new Promise((resolve) => {
        import(
          /* webpackInclude: /(en-AU|en|en-CA|en-GB|en-IE|en-NZ)\.mjs$/ */
          `/node_modules/@angular/common/locales/${localeId}.mjs`
        ).then((module) => {
          registerLocaleData(module.default);
          resolve([]);
        });
      })
    );
  }
}
