import * as actions from '../actions/card-modal';
import * as matterCardActions from '../actions/matter-card';
import { SpecialFeeListEntry } from '@app/features/+time-fee-ledger/models';
import { ECardType, ICardRelatedMatter } from '@app/features/+card/models';
import { uniqString } from '@server/modules/shared/functions';
import { IBeneficiary } from '@app/shared/models';

export interface State {
  cardProcessing: boolean;
  cardDetails: any;
  specialRatesForCard: SpecialFeeListEntry[];
  formValue: any;
  activePersonId: string;
  selectedSpecialFees: string[];
  invalidControls: string[];
  relatedMatters: ICardRelatedMatter[];
  beneficiaries: IBeneficiary[];
}

export const INITIAL_STATE: State = {
  cardProcessing: false,
  cardDetails: null,
  formValue: null,
  activePersonId: null,
  selectedSpecialFees: [],
  specialRatesForCard: [],
  invalidControls: [],
  relatedMatters: [],
  beneficiaries: [],
};

export const reducer = (
  state = INITIAL_STATE,
  action: actions.CardModalActions | matterCardActions.MatterCardActions,
) => {
  switch (action.type) {
    case actions.CardModalActionTypes.UPDATE_FORM_VALUE:
      return {
        ...state,
        formValue: action.payload.data,
      };

    case actions.CardModalActionTypes.UPDATE_CARD_DETAILS_AS_FORM_VALUE:
      return {
        ...state,
        cardDetails: state.formValue,
      };

    case actions.CardModalActionTypes.LOAD_CARD_DETAILS_SUCCEED:
      return {
        ...state,
        cardDetails: action.payload.reset ? action.payload.data : state.cardDetails,
        formValue: action.payload.data,
        activePersonId:
          action.payload.data.cardType === ECardType.People && action.payload.data.personList.length > 0
            ? action.payload.data.personList[0].__id
            : null,
        selectedSpecialFees: action.payload.specialRates.map((fee) => fee.SpecialFeeGUID),
        specialRatesForCard: action.payload.specialRates,
        invalidControls: [],
        relatedMatters: action.payload.relatedMatters,
      };

    case actions.CardModalActionTypes.SET_CARD_DETAILS_FORM_VALUE:
      return {
        ...state,
        formValue: { ...state.formValue, ...action.payload.data },
      };

    case actions.CardModalActionTypes.SET_ADDRESS_VALUE: {
      let addressList = action.payload.alternative
        ? state.formValue.alternativeAddressList
        : state.formValue.originalAddressList;
      addressList = addressList.map((address, index) =>
        index === action.payload.index ? { ...address, ...action.payload.data } : address,
      );
      const obj = action.payload.alternative
        ? { alternativeAddressList: addressList }
        : { originalAddressList: addressList };
      return {
        ...state,
        formValue: { ...state.formValue, ...obj },
      };
    }

    case actions.CardModalActionTypes.UPDATE_ALTERNATIVE_ADDRESS_SETTINGS:
      return {
        ...state,
        formValue: { ...state.formValue, alternativeAddressSettings: action.payload.settings },
      };

    case actions.CardModalActionTypes.UPDATE_CARD_TYPE:
      return {
        ...state,
        formValue: { ...state.formValue, cardType: action.payload.type, type: action.payload.type },
        activePersonId: state.formValue.personList.length > 0 ? state.formValue.personList[0].__id : null,
        invalidControls: [],
      };

    case actions.CardModalActionTypes.UPDATE_PERSONAL_DETAILS: {
      const personList = state.formValue.personList.map((person) =>
        person.__id === action.payload.id ? { ...person, ...action.payload.data } : person,
      );
      return {
        ...state,
        formValue: { ...state.formValue, personList },
      };
    }

    case actions.CardModalActionTypes.UPDATE_PERSON_LIST:
      return {
        ...state,
        formValue: { ...state.formValue, personList: action.payload.list },
        activePersonId: action.payload.id,
      };

    case actions.CardModalActionTypes.UPDATE_ACTIVE_PERSON_ID:
      return {
        ...state,
        activePersonId: action.payload.id,
      };

    case actions.CardModalActionTypes.SAVE:
    case matterCardActions.MatterCardActionTypes.SAVE_MATTER_CARD:
    case matterCardActions.MatterCardActionTypes.ADD_DEBTOR_CARD:
      return {
        ...state,
        cardProcessing: true,
      };

    case actions.CardModalActionTypes.SAVE_SUCCEED:
      return {
        ...state,
        cardProcessing: false,
        cardDetails: action.payload ? state.formValue : state.cardDetails,
      };

    case actions.CardModalActionTypes.SAVE_FAIL:
      return {
        ...state,
        cardProcessing: false,
      };

    case actions.CardModalActionTypes.DESTROY:
      return INITIAL_STATE;

    case actions.CardModalActionTypes.UPDATE_LETTER_LEAP_CALC_INFORM:
      return {
        ...state,
        formValue: {
          ...state.formValue,
          ...action.payload.inform,
        },
      };

    case actions.CardModalActionTypes.SET_TRUSTEE:
      return {
        ...state,
        formValue: {
          ...state.formValue,
          trusteeList: state.formValue.trusteeList.map((t) =>
            t.id === action.payload.trustee.id ? action.payload.trustee : t,
          ),
        },
      };

    case actions.CardModalActionTypes.ADD_TRUSTEE:
      return {
        ...state,
        formValue: {
          ...state.formValue,
          trusteeList:
            state.formValue.trusteeList && state.formValue.trusteeList.length > 0
              ? [...state.formValue.trusteeList, action.payload.trustee]
              : [action.payload.trustee],
        },
      };

    case actions.CardModalActionTypes.SET_BENEFICIARY:
      return {
        ...state,
        formValue: {
          ...state.formValue,
          beneficiaries: state.formValue.beneficiaries.map((b, i) =>
            i === action.payload.index ? action.payload.beneficiary : b,
          ),
        },
      };
    case actions.CardModalActionTypes.ADD_BENEFICIARY:
      return {
        ...state,
        formValue: {
          ...state.formValue,
          beneficiaries:
            state.formValue?.beneficiaries && state.formValue?.beneficiaries.length > 0
              ? [...state.formValue.beneficiaries, action.payload.beneficiary]
              : [action.payload.beneficiary],
        },
      };
    case actions.CardModalActionTypes.DELETE_BENEFICIARIES:
      return {
        ...state,
        formValue: {
          ...state.formValue,
          beneficiaries: state.formValue.beneficiaries?.filter((_, i) => !action.payload.indexes.includes(i)),
        },
      };

    case actions.CardModalActionTypes.DELETE_TRUSTEES: {
      const deleteTrusteeIds = action.payload.trustees?.map((c) => c.id) || [];
      return {
        ...state,
        formValue: {
          ...state.formValue,
          trusteeList: state.formValue.trusteeList?.filter((t) => !deleteTrusteeIds.includes(t.id)) || [],
        },
      };
    }

    case actions.CardModalActionTypes.ADD_CARD_DETAILS_INVALID_CONTROLS:
      return {
        ...state,
        invalidControls: uniqString([...state.invalidControls, ...action.payload.names]),
      };

    case actions.CardModalActionTypes.REMOVE_CARD_DETAILS_INVALID_CONTROLS:
      return {
        ...state,
        invalidControls: state.invalidControls?.filter((control) => !action.payload.names.includes(control)) || [],
      };

    case actions.CardModalActionTypes.RESET:
      return {
        ...state,
        formValue: state.cardDetails,
        activePersonId: state.cardDetails.personList.length > 0 ? state.cardDetails.personList[0].__id : null,
        invalidControls: [],
      };

    case actions.CardModalActionTypes.PERSON_WEB_VALUE_CHANGE: {
      const webType = action.payload.web.type;
      const hasWebTypeInCardLevel = state.formValue.webAddressList.find((web) => web.type === webType && !!web.address);
      return {
        ...state,
        formValue: {
          ...state.formValue,
          webAddressList: hasWebTypeInCardLevel
            ? state.formValue.webAddressList
            : [...state.formValue.webAddressList, action.payload.web],
        },
      };
    }

    case actions.CardModalActionTypes.PERSON_PHONE_VALUE_CHANGE: {
      const phoneType = action.payload.phone.numberType;
      const hasPhoneTypeInCardLevel = state.formValue.phoneNumberList.find(
        (phone) => phone.numberType === phoneType && !!phone.number,
      );
      return {
        ...state,
        formValue: {
          ...state.formValue,
          phoneNumberList: hasPhoneTypeInCardLevel
            ? state.formValue.phoneNumberList
            : [...state.formValue.phoneNumberList, action.payload.phone],
        },
      };
    }

    default:
      return state;
  }
};

export const getCardProcessing = (state: State) => state.cardProcessing;
export const getCardDetails = (state: State) => state.cardDetails;
export const getFormValue = (state: State) => state.formValue;
export const getActivePersonId = (state: State) => state.activePersonId;
export const getInvalidControls = (state: State) => state.invalidControls;
export const getSpecialRatesForCard = (state: State) => state.specialRatesForCard;
export const getRelatedMatters = (state: State) => state.relatedMatters;
