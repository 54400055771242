import * as fromApp from './app.reducer';
import * as fromStaff from './staff.reducer';
import * as fromToastr from './toastr.reducer';
import * as fromBrowserTitle from './browser-title.reducer';
import * as fromSupportRequest from './support-request.reducer';
import * as fromCardFilters from './card-filters.reducer';
import * as fromAutomation from './automation.reducer';
import * as fromTableContent from './table-content.reducer';

import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  core: fromApp.CoreState;
  staff: fromStaff.StaffState;
  toastr: fromToastr.State;
  browserTitle: fromBrowserTitle.State;
  supportRequest: fromSupportRequest.State;
  cardFilters: fromCardFilters.State;
  automation: fromAutomation.State;
  tableContent: fromTableContent.TableContent;
}

// https://medium.com/youngers-consulting/ngrx-tips-part-1-module-setup-with-lazy-loading-5dc8994b5a2d
export const reducers: ActionReducerMap<AppState> = {
  core: fromApp.reducer,
  staff: fromStaff.reducer,
  toastr: fromToastr.reducer,
  browserTitle: fromBrowserTitle.reducer,
  cardFilters: fromCardFilters.reducer,
  automation: fromAutomation.reducer,
  supportRequest: fromSupportRequest.reducer,
  tableContent: fromTableContent.reducer,
};

export const getInitialState = () =>
  ({
    core: fromApp.INIT_STATE,
    staff: fromStaff.initialState,
    toastr: fromToastr.INITIAL_STATE,
    browserTitle: fromBrowserTitle.INITIAL_STATE,
    cardFilters: fromCardFilters.INITIAL_STATE,
    automation: fromAutomation.INIT_STATE,
    supportRequest: fromSupportRequest.INITIAL_STATE,
    tableContent: fromTableContent.INITIAL_STATE,
  }) as AppState;

export * from './staff.reducer';
