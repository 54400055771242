import { ISubstitution } from '../../features/+matter-list/models';
import { TableConfigIds } from '../../features/+matter-details/constants';
import { PersonDocsCore, PhoneNumberDocsCore, WebAddressDocsCore } from '@app/features/+card/models';
import { IPhoneNumber, IWebAddress } from '@app/core/models';
import { IPerson } from '../models';

export const mapPhoneNumberFromDocsCore = (phone: PhoneNumberDocsCore): IPhoneNumber => {
  return {
    __className: 'PhoneNumber',
    numberType: phone.numberType,
    number: phone.value,      
    areaCode: phone.areaCode ?? null,
  };
};

export const mapWebAddressFromDocsCore = (web: WebAddressDocsCore): IWebAddress => {
  return {
    __className: 'WebAddress',
    type: web.type,
    address: web.address,
  };
};

export const mapPersonFromDocsCore = (personV2: PersonDocsCore): IPerson => {
  return {
    __id: personV2.id,
    __className: 'Person',         
    __firmId: personV2.firmId,
    userId: personV2.userId,

    __personId: personV2.id,       
    salutation: personV2.salutation,
    firstNames: personV2.firstName,
    lastName: personV2.lastName,
    previousNames: personV2.previousNames,
    maidenName: personV2.maidenName,
    gender: personV2.gender,
    nationality: personV2.nationality,
    passportNumber: personV2.passportNumber,
    passportName: personV2.passportName,
    dateOfBirth: personV2.dateOfBirth,
    placeOfBirth: personV2.placeOfBirth,
    countryOfBirth: personV2.countryOfBirth,
    dateOfDeath: personV2.dateOfDeath,
    placeOfDeath: personV2.placeOfDeath,

    primary: false,

    phoneNumberList: personV2.phoneNumberList
      ? personV2.phoneNumberList.map(mapPhoneNumberFromDocsCore)
      : [],
    webAddressList: personV2.webAddressList
      ? personV2.webAddressList.map(mapWebAddressFromDocsCore)
      : [],

    cardRoles: [],
    cards: [],
    primaryCards: [],

    dirty: false,
    version: personV2.version,
    deleteCode: personV2.deleteCode,
    comments: personV2.comments,
    occupation: personV2.occupation,
    eMailAddressesText: personV2.eMailAddressesText,
    interpreterLanguage: personV2.interpreterLanguage,
    specialNeeds: personV2.specialNeeds,
    maritalStatus: personV2.maritalStatus,
    custom1: personV2.custom1,
    custom2: personV2.custom2,
    custom3: personV2.custom3,
    custom4: personV2.custom4,
    custom5: personV2.custom5,
    custom6: personV2.custom6,
    custom7: personV2.custom7,
    custom8: personV2.custom8,
    custom9: personV2.custom9,
    custom10: personV2.custom10,
    custom11: personV2.custom11,
    custom12: personV2.custom12,
  };
};

export const mapPersonToDocsCore = (oldPerson: IPerson): PersonDocsCore => {
  return {
    id: oldPerson.__id,
    firmId: oldPerson.__firmId,
    userId: oldPerson.userId,
    salutation: oldPerson.salutation,
    firstName: oldPerson.firstNames,
    lastName: oldPerson.lastName,
    previousNames: oldPerson.previousNames,
    maidenName: oldPerson.maidenName,
    gender: oldPerson.gender,
    nationality: oldPerson.nationality,
    passportNumber: oldPerson.passportNumber,
    passportName: oldPerson.passportName,
    dateOfBirth: oldPerson.dateOfBirth?.toString() ?? null,
    placeOfBirth: oldPerson.placeOfBirth,
    countryOfBirth: oldPerson.countryOfBirth,
    dateOfDeath: oldPerson.dateOfDeath?.toString() ?? null,
    placeOfDeath: oldPerson.placeOfDeath,
    deleteCode: oldPerson.deleteCode as 0 | 1, 
    version: oldPerson.version,
    comments: oldPerson.comments,
    occupation: oldPerson.occupation,
    eMailAddressesText: oldPerson.eMailAddressesText,
    interpreterLanguage: oldPerson.interpreterLanguage,
    specialNeeds: oldPerson.specialNeeds,
    maritalStatus: oldPerson.maritalStatus,
    custom1: oldPerson.custom1,
    custom2: oldPerson.custom2,
    custom3: oldPerson.custom3,
    custom4: oldPerson.custom4,
    custom5: oldPerson.custom5,
    custom6: oldPerson.custom6,
    custom7: oldPerson.custom7,
    custom8: oldPerson.custom8,
    custom9: oldPerson.custom9,
    custom10: oldPerson.custom10,
    custom11: oldPerson.custom11,
    custom12: oldPerson.custom12,
    phoneNumberList: oldPerson.phoneNumberList?.map(p => ({
      numberType: p.numberType,
      value: p.number,
      areaCode: p.areaCode,
    })) || [],
    webAddressList: oldPerson.webAddressList?.map(w => ({
      type: w.type,
      address: w.address,
    })) || [],
    fields: [],
    photoFileName: null,
  };
};
