import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SyncDocsCore } from '@app/features/+card/models';
import { BaseService } from '@app/shared/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class CardListV2Service extends BaseService {
  private readonly path: string;

  constructor(private _http: HttpClient) {
    super();
  }

  public getAll(lastRowVer = 0, shardId: string): Observable<SyncDocsCore> {
    const url = `${this.docsV2Path}/api/v1/cards?lastrowversion=${lastRowVer}&shardId=${shardId}`;
    return this._http.get<SyncDocsCore>(url).pipe(
      map((res: SyncDocsCore) => ({
          ...res,
          data: res.data || [],
        }))
    );
  }
}
