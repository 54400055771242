import { LogService } from 'app/core/services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { selectCurrentMatterId } from '@app/core/store';
import { DialogService } from '@app/shared/services';
import { PrintService } from '@app/shared/services/print/print.service';
import { BankAccountService } from '@app/features/accounting/services';
import { EAccountUsage, IBankAccount } from '@app/features/accounting/models';

import { State } from '../reducers';
import { selectTrustSelectedBankAccount } from '../selectors';
import * as actions from '../actions';
import { TrustLedgerService } from '@app/features/+trust/services';
import { ITrustLedgerResponse } from '@app/features/+trust/models';
import { AppApiService } from '@app/core/api';

@Injectable()
export class TrustLedgerEffect {

  listTrustFundsStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.ListTrustFundsStart>(actions.LIST_TRUST_FUNDS_START),
    withLatestFrom(
      this._store.pipe(select(selectCurrentMatterId)),
      this._store.pipe(select(selectTrustSelectedBankAccount))
    ),
    switchMap((data) => {
      const [action, matterId, trustSelectedBankAccount] = data;
      const bankAccountId = !!trustSelectedBankAccount ? trustSelectedBankAccount.BankAccountGUID : action.payload;

      return this._trustLedgerSvc.get(matterId, bankAccountId).pipe(
        map((res: ITrustLedgerResponse) => new actions.ListTrustFundsSuccess(res)),
        catchError((error) => of(new actions.ListTrustFundsFailure(error)))
      );
    })
  ));


  getTrustBankAccountsStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetTrustBankAccountsStart>(actions.GET_TRUST_BANK_ACCOUNTS_START),
    switchMap(() => this._bankAccountSvc.getBankAccounts(EAccountUsage.Trust).pipe(
      map(
        (data: IBankAccount[]) =>
          new actions.GetTrustBankAccountsSuccess(data?.filter((d) => d.Deleted === false) || [])
      ),
      catchError((error) => of(new actions.GetTrustBankAccountsFailure(error)))
    ))
  ));


  selectTrustBankAccount$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.SelectTrustBankAccount>(actions.SELECT_TRUST_BANK_ACCOUNT),
    switchMap((action: actions.SelectTrustBankAccount) => {
      const bankAccountId = !!action.payload ? action.payload : null;
      return of(new actions.ListTrustFundsStart(bankAccountId));
    })
  ));


  printTrustReport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.PrintTrustReport>(actions.PRINT_TRUST_REPORT),
    withLatestFrom(
      this._store.pipe(select(selectCurrentMatterId)),
      this._store.pipe(select(selectTrustSelectedBankAccount)),
      (action, matterId, bankAccount) => ({ name: action.payload, matterId, bankAccount })
    ),
    switchMap((stateData: { name: string; matterId: string; bankAccount: IBankAccount }) => {
      const { name, matterId, bankAccount } = stateData;
      if (!bankAccount) {
        this._dialogSvc.confirm({
          title: 'Alert',
          message: this._translateSvc.instant('Trust.General.NoAccountSelected.Error'),
          showCancel: false,
          actionText: 'OK',
        });
      } else {
        this._printSvc.print(name, {
          BankAccGUID: bankAccount.BankAccountGUID,
          App: 1,
          MatterGUID: matterId,
        });
      }
      return from([]);
    })
  ));


  newTrustReceipt$ = createEffect(() => this.actions$.pipe(
    ofType<actions.NewTrustReceipt>(actions.NEW_TRUST_RECEIPT),
    withLatestFrom(this._store.pipe(select(selectTrustSelectedBankAccount)), (action, trustAccount) => trustAccount),
    tap((trustAccount) => {
      const query = !!trustAccount ? { trustAccountGuid: trustAccount.BankAccountGUID } : null;
      this._appApiSvc.newTrustReceipt(query);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private _log: LogService,
    private _store: Store<State>,
    private _appApiSvc: AppApiService,
    private _trustLedgerSvc: TrustLedgerService,
    private _bankAccountSvc: BankAccountService,
    private _printSvc: PrintService,
    private _dialogSvc: DialogService,
    private _translateSvc: TranslateService
  ) {}
}
