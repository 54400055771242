import { Injectable } from '@angular/core';

import { IPerson } from '@app/shared/models';
import { StartupService } from '@app/core/services';
import { Observable } from 'rxjs';
import { BaseService } from '@app/shared/services';
import { HttpClient } from '@angular/common/http';
import { IPhoneNumber, IWebAddress } from '@app/core/models';
import { isEmptyObj, isEmptyValue } from '@server/modules/shared/functions/common-util.functions';
import { PersonDocsCore } from '../models';

@Injectable()
export class CardPersonDetailsService extends BaseService {
  constructor(private startupSvc: StartupService, private http: HttpClient) {
    super();
  }

  getPersons(ids: string[]): Observable<IPerson[]> {
    const url = `${this.siriusPath}/api/persons/list?personIds=${ids.join(',')}`;
    const persons = this.http.get<IPerson[]>(url);
    return persons;
  }

  getBulkEmails(cardIds: string[]): Observable<any[]> {
    const url = `${this.siriusPath}/api/card/cardsemails`;
    return this.http.post<any[]>(url, cardIds);
  }

  getPhone(person: IPerson): string {
    const phones: IPhoneNumber[] = person.phoneNumberList;
    const phone: IPhoneNumber = phones?.find((p: IPhoneNumber): boolean => !!p.number);
    return phone ? phone.number : undefined;
  }

  getEmail(person: IPerson): string {
    const emails: IWebAddress[] = person.webAddressList;
    const email: IWebAddress = emails?.find((e: IWebAddress): boolean => !!e.address);
    return email ? email.address : undefined;
  }

  isEmpty(person: IPerson | IPerson[]): boolean {
    if (Array.isArray(person)) {
      const people = person;
      return people.every((per: IPerson): boolean => this.isEmpty(per));
    }

    if (isEmptyObj(person)) {
      return true;
    }

    const p: IPerson = person as IPerson;
    return isEmptyValue(p.firstNames) === true && isEmptyValue(p.lastName) === true;
  }

  isEmptyPerson(person: PersonDocsCore | PersonDocsCore[]): boolean {
    if (Array.isArray(person)) {
      const people = person;
      return people.every((per: PersonDocsCore): boolean => this.isEmptyPerson(per));
    }

    if (isEmptyObj(person)) {
      return true;
    }

    const p = person as PersonDocsCore;
    return isEmptyValue(p.firstName) === true && isEmptyValue(p.lastName) === true;
  }
}
