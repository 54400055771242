import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ITrustLedgerBalance, ITrustLedgerItem } from '@app/features/+trust/models';
import * as actions from '@app/features/+trust/store/actions';
import { IBankAccount } from '@app/features/accounting/models';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import { getDate } from '@app/shared/utils/date.helper';

export interface State extends EntityState<ITrustLedgerItem> {
  loading: boolean;
  error: any;
  history: ITrustLedgerItem[];
  totalDeposits: number;
  totalWithdrawals: number;
  antDepositAmount: number;
  balance: ITrustLedgerBalance;
  reason: string;

  bankAccounts: IBankAccount[];
  selectedBankAccountGuid: string;
}

export const selectId = (item: ITrustLedgerItem): string => `${item.TransactionHeaderGUID}_${item.SeqNum}`;

const sortFun = (a: ITrustLedgerItem, b: ITrustLedgerItem) => differenceInMilliseconds(getDate(a.TransactionDate), getDate(b.TransactionDate));

export const adapter: EntityAdapter<any> = createEntityAdapter<ITrustLedgerItem>({
  selectId,
  sortComparer: sortFun,
});

export const INITIAL_STATE: State = adapter.getInitialState({
  loading: false,
  error: null,
  history: [],
  totalDeposits: 0,
  totalWithdrawals: 0,
  antDepositAmount: 0,
  balance: null,
  reason: '',
  bankAccounts: undefined,
  selectedBankAccountGuid: null,
});

export const reducer = (state: State = INITIAL_STATE, { type, payload }: actions.TrustLedgerActions): State => {
  switch (type) {
    case actions.LIST_TRUST_FUNDS_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.LIST_TRUST_FUNDS_SUCCESS: {
      return {
        ...adapter.setAll(payload.TrustLedgerMatterList, adapter.removeAll(state)),
        loading: false,
        balance: payload.TrustLedgerBalance,
        totalDeposits: payload.TotalDeposits,
        totalWithdrawals: payload.TotalWithdrawals,
        antDepositAmount: payload.TrustAntDepositAmount,
        reason: payload.TrustReason,
        history: payload.TrustLedgerHistoryList,
      };
    }

    case actions.GET_TRUST_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        bankAccounts: payload,
      };
    }

    case actions.SELECT_TRUST_BANK_ACCOUNT: {
      return {
        ...state,
        selectedBankAccountGuid: payload,
      };
    }

    case actions.GET_TRUST_BANK_ACCOUNTS_FAILURE:
    case actions.LIST_TRUST_FUNDS_FAILURE: {
      return {
        ...state,
        error: payload,
      };
    }

    case actions.CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const selectError = (state: State) => state.error;
export const selectLoading = (state: State) => state.loading;
export const selectBalance = (state: State) => state.balance;
export const selectTotalDeposits = (state: State) => state.totalDeposits;
export const selectTotalWithdrawals = (state: State) => state.totalWithdrawals;
export const selectAntDepositAmount = (state: State) => state.antDepositAmount;
export const selectReason = (state: State) => state.reason;
export const selectHistory = (state: State) => state.history;
export const selectBankAccounts = (state: State) => state.bankAccounts;
export const selectSelectedBankAccountGuid = (state: State) => state.selectedBankAccountGuid;
