import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatterListStorageService } from '@app/features/+matter-list/services';
import { MatterAddinStateModule } from '@app/features/matter-addin/matter-addin-state.module';
import { SharedModule } from '@app/shared';

import { MatterAddinPageComponent } from './pages';
import { LeapHostHandlerService } from './services/leap-host-handler.service';
import { LeapAppBtnGrpComponent } from '@app/features/matter-addin/components';
import { LeapAppService } from '@app/features/matter-addin/services';
import { LeapAppSlimBtnGrpComponent } from '@app/features/matter-addin/components/leap-app-slim-btn-grp/leap-app-slim-btn-grp.component';

@NgModule({
  declarations: [MatterAddinPageComponent, LeapAppBtnGrpComponent, LeapAppSlimBtnGrpComponent],
  imports: [CommonModule, SharedModule, MatterAddinStateModule],
  providers: [MatterListStorageService, LeapHostHandlerService, LeapAppService],
  exports: [LeapAppBtnGrpComponent, LeapAppSlimBtnGrpComponent],
})
export class MatterAddinModule {}
