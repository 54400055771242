import { inject, Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { countries as countriesList } from 'countries-list';
import { Country } from '@app/core/models';
import { StartupService } from '@app/core/services';

import { PhoneNumberDocsCore, PersonDocsCore, WebAddressDocsCore } from '@app/features/+card/models';
import { isDocsCorePersonTyped, isPersonListEntryTyped, isPersonTyped, sortByAsc } from '@app/shared/functions';
import { v4 as uuidv4 } from 'uuid';
import { IPerson } from '@app/shared/models';
import { IPersonListEntry } from '@app/features/+person/models';

@Injectable()
export class PersonUtilsV2Service {
  private startupSvc = inject(StartupService);

  get countries() {
    const countries: Country[] = Object.entries(countriesList).map((entry) => ({
      code: entry[0],
      name: entry[1].name,
    }));
    return sortByAsc(countries, [(c: Country) => c.name]);
  }

  createPhone(type: string = 'Phone', options: Partial<PhoneNumberDocsCore>): PhoneNumberDocsCore {
    return {
      numberType: type,
      value: options.value ?? '',
      areaCode: options.areaCode ?? '',
    };
  }
  createWebAddress(type: string = 'Email', options: Partial<WebAddressDocsCore>): WebAddressDocsCore {
    return {
      type,
      address: options.address ?? '',
    };
  }

  // only if email is entered should it be validated
  // email can be empty as it is not required
  emailValidator(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      const type = group.controls['type'];
      const address = group.controls['address'];
      if (type.value?.includes('Email') && address.value !== '' && address.dirty && Validators.email(address)) {
        return { email: true };
      } else {
        return null;
      }
    };
  }

  createPerson(options: PersonDocsCore): PersonDocsCore {
    const web =
      options.webAddressList
        ?.filter((w) => (w.type === 'Web' || w.type === 'Web2') && !!w.address)
        .map((w) => this.createWebAddress(w.type, w)) ?? [];

    const emails =
      options.webAddressList
        ?.filter((w) => (w.type === 'Email' || w.type === 'Email2') && !!w.address)
        .map((w) => this.createWebAddress(w.type, w)) ?? [];

    const webAddressList = [...emails, ...web];

    const person = {
      comments: options.comments ?? '',
      countryOfBirth: options.countryOfBirth,
      custom10: options.custom10 ?? null,
      custom11: options.custom11 ?? null,
      custom12: options.custom12 ?? null,
      custom1: options.custom1 ?? null,
      custom2: options.custom2 ?? null,
      custom3: options.custom3 ?? null,
      custom4: options.custom4 ?? null,
      custom5: options.custom5 ?? null,
      custom6: options.custom6 ?? null,
      custom7: options.custom7 ?? null,
      custom8: options.custom8 ?? null,
      custom9: options.custom9 ?? null,
      dateOfBirth: options.dateOfBirth ?? null,
      dateOfDeath: options.dateOfDeath ?? null,
      deleteCode: options.deleteCode ?? 0,
      eMailAddressesText: options.eMailAddressesText ?? '',
      fields: options.fields ?? [],
      firmId: options.firmId ?? this.startupSvc.userDetails.firmId,
      firstName: options.firstName ?? '',
      gender: options.gender ?? null,
      id: options.id ?? uuidv4(),
      interpreterLanguage: options.interpreterLanguage ?? '',
      lastName: options.lastName ?? '',
      maidenName: options.maidenName ?? '',
      maritalStatus: options.maritalStatus ?? '',
      nationality: options.nationality,
      occupation: options.occupation ?? '',
      passportName: options.passportName ?? '',
      passportNumber: options.passportNumber ?? '',
      phoneNumberList: options.phoneNumberList?.filter((phone) => !!phone.value).map((phone) => this.createPhone(phone.numberType, phone)) ?? [],
      photoFileName: options.photoFileName ?? '',
      placeOfBirth: options.placeOfBirth,
      placeOfDeath: options.placeOfDeath,
      previousNames: options.previousNames ?? '',
      salutation: options.salutation,
      specialNeeds: options.specialNeeds ?? '',
      userId: options.userId ?? this.startupSvc.userDetails.userId,
      version: options.version ?? 1,
      webAddressList,
    };

    return person;
  }
}

export const getPersonsNamesStringV2 = (personList: PersonDocsCore[]): string => {
  let personNamesText = '';
  if (!!personList && personList.length > 0) {
    personList
      .filter((p) => !!p.firstName || !!p.lastName)
      .forEach((p, i, arr) => {
        let chars = '';
        if (i !== 0) {
          chars = i === arr.length - 1 ? ' & ' : ', ';
        }
        personNamesText += chars + getPersonFullNameV2(p);
      });
  }
  return personNamesText.trim();
};

export const getPersonFullNameV2 = (person: unknown, withSalutation = true): string => {
  if (!person) {
    return '';
  }

  let salutation = '';
  let firstName = '';
  let lastName = '';

  if (isDocsCorePersonTyped(person)) {
    const p = person as PersonDocsCore;
    salutation = p.salutation?.trim();
    firstName = p.firstName?.trim();
    lastName = p.lastName?.trim();
  } else if (isPersonTyped(person)) {
    const p = person as IPerson;
    salutation = p.salutation?.trim();
    firstName = p.firstNames?.trim();
    lastName = p.lastName?.trim();
  } else if (isPersonListEntryTyped(person)) {
    const p = person as IPersonListEntry;
    salutation = p.salutation?.trim();
    firstName = p.forenames?.trim();
    lastName = p.surname?.trim();
  }

  return [withSalutation ? salutation : '', firstName, lastName].join(' ').trim();
};
