import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, exhaustMap, filter, map as rxjsMap, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { IMatterType } from '@app/shared/models';

import { State } from '../reducers';
import * as actions from '../actions';
import {
  selectMatterTypes,
  selectSelectedMatterTypeDetails,
  selectSelectedMatterTypeId,
  selectSelectedState,
} from '../selectors';
import { MatterTypesService } from '../../services';
import { LeapDesignService, SchemaService } from '@app/shared/services';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';

@Injectable()
export class MatterTypesEffects {

  lastMatterTypeId$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.LAST_MATTER_TYPE_ID),
    switchMap(() =>
      this._matterTypeSvc.getLastMatterTypeId().pipe(
        mergeMap((matterTypeId) => [new actions.MatterTypeStart({ id: matterTypeId || '' })]),
        catchError((error) => [new actions.MatterTypeFailure(error)])
      )
    )
  ));


  updateLastMatterTypeId$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateLastMatterTypeId>(actions.UPDATE_LAST_MATTER_TYPE_ID),
    switchMap((action: actions.UpdateLastMatterTypeId) =>
      this._matterTypeSvc.updateLastMatterTypeId(action.payload).pipe(
        mergeMap(() => []),
        catchError(() => [])
      )
    )
  ), { dispatch: false });


  matterTypeStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.MatterTypeStart>(actions.MATTER_TYPE_START),
    withLatestFrom(
      this._store.pipe(select(selectSelectedState)),
      this._store.pipe(select(selectSelectedMatterTypeDetails)),
      (action, selectedState, selectedMatterTypeDetails) => ({
        matterTypeId: action.payload?.id,
        selectedState,
        selectedMatterTypeDetails,
      })
    ),
    filter((data) => !data.selectedMatterTypeDetails || data.matterTypeId !== data.selectedMatterTypeDetails.id),
    switchMap((data) => {
      const { matterTypeId, selectedState } = data;
      return !!matterTypeId
        ? this._matterTypeSvc.getMatterType(matterTypeId).pipe(
            mergeMap((matterType: IMatterType) => {
              if (!matterType) {
                return [new actions.MatterTypeSuccess(null)];
              }

              // check the state value from the response is the same as the selectedState value from the store
              // if not, we need to set the selectedState value as the response's state value
              const { states } = matterType;
              if (
                !!states &&
                states[0] &&
                states[0].name &&
                !states.find((s) => s.name?.toLowerCase() === 'all') &&
                (!selectedState || (!!selectedState && !states.find((s) => s.name === selectedState)))
              ) {
                return [
                  new actions.ChangeState({ state: states[0].name, matterTypeId }),
                  new actions.MatterTypeSuccess(matterType),
                ];
              }

              return [new actions.MatterTypeSuccess(matterType)];
            }),
            catchError((error) => {
              this._store.dispatch(new actions.MatterTypeFailure(error));
              return throwError(() =>
                new SiriusError({
                  type: 'error',
                  title: 'Failure',
                  message: 'Unable to load matter type details.',
                })
              );
            })
          )
        : [new actions.MatterTypeSuccess(null)];
    })
  ));


  listMatterTypesStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.LIST_MATTER_TYPES_START),
    withLatestFrom(this._store.pipe(select(selectSelectedState)), (action, state) => ({ state })),
    switchMap((stateData: { state: string }) => {
      const { state } = stateData;
      return this._matterTypeSvc.getMatterTypes(state).pipe(
        mergeMap((data) => [
          new actions.ListMatterTypesSuccess({
            state,
            matterTypes: data,
          }),
        ]),
        catchError((error) => {
          this._store.dispatch(new actions.ListMatterTypesFailure(error));
          return throwError(() =>
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to load matter type list.',
            })
          );
        })
      );
    })
  ));


  changeState$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.ChangeState>(actions.CHANGE_STATE),
    withLatestFrom(
      this._store.pipe(select(selectSelectedState)),
      this._store.pipe(select(selectMatterTypes)),
      (action, selectedState, matterTypes) => ({ selectedState, matterTypes })
    ),
    switchMap((stateData: { selectedState: string; matterTypes: IMatterType[] }) => {
      const { selectedState, matterTypes } = stateData;
      return matterTypes?.length === 0 ? [new actions.ListMatterTypesStart({ state: selectedState })] : [];
    })
  ));


  listStatesStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.ListStatesStart>(actions.LIST_STATES_START),
    exhaustMap(() => this._leapDesignSvc.getStates().pipe(
        rxjsMap((states) => new actions.ListStatesSuccess(states)),
        catchError(() => of(new actions.ListStatesFailure(null)))
      ))
  ));

  constructor(
    private actions$: Actions,
    private _store: Store<State>,
    private _matterTypeSvc: MatterTypesService,
    private _leapDesignSvc: LeapDesignService
  ) {}
}
