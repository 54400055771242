import { parse, parseISO } from 'date-fns';
import { formatDateHttp } from '../../../shared/utils';
export const formatDateForAccounting = (date: any) => {
  if (!date) {
    return '';
  }
  try {
    let parsedDate;
    if (typeof date === 'string') {
      parsedDate = parseISO(date);
      if (isNaN(parsedDate.getTime())) {
        parsedDate = parse(date, 'yyyy/MM/dd', new Date());
      }
    } else if (date instanceof Date) {
      parsedDate = date;
    }

    if (!parsedDate || isNaN(parsedDate.getTime())) {
      throw new Error(`Invalid date: ${date}`);
    }

    return formatDateHttp(parsedDate, {
      preserveTime: false,
      includeTimezoneOffset: false,
      dateFormats: 'yyyy-MM-dd',
    });
  } catch (error) {
    console.error('Date formatting error:', error);
    throw error;
  }
};
