import { Action } from '@ngrx/store';
import { ECardFilterType, ICardListEntry } from '@app/features/+card/models';

export enum CardListModalActionTypes {
  SET_UP_CARD_ENTRIES = '[Card List Modal] set up card entries',
  STORE_CARD_ENTRIES = '[Card List Modal] store card entries into state',
  SET_INITIAL_SELECTED = '[Card List Modal] set the initial selected card entries',
  SAVE_MATTER_CARD = '[Card list Modal] save matter card',
  SELECTOR_DEBTOR_CARD = '[Card list modal] confirm debtor card selection',
  SET_CARD_FILTER_TYPE = '[Card List Modal] set card filter type',
  SET_CARD_GUIDS_FOR_FILTERING = '[Card List Modal] set the value of card guids for filtering purpose',
  SET_SELECTED_CARD_FILTER_TYPE = '[Card List Modal] set selected card filter type',

  CLOSE = '[Card List Modal] close card list modal',
  DESTROY = '[Card List Modal] destroy card list modal',
}

export class SetUpCardEntries implements Action {
  readonly type = CardListModalActionTypes.SET_UP_CARD_ENTRIES;

  constructor(public payload: any) {}
}

export class StoreCardEntries implements Action {
  readonly type = CardListModalActionTypes.STORE_CARD_ENTRIES;

  constructor(public payload: { cardEntries: ICardListEntry[] }) {}
}

export class SetSelectedCardEntries implements Action {
  readonly type = CardListModalActionTypes.SET_INITIAL_SELECTED;

  constructor(public payload: { cardEntries: ICardListEntry[] }) {}
}

export class SaveMatterCard implements Action {
  readonly type = CardListModalActionTypes.SAVE_MATTER_CARD;

  constructor(public payload: { isNewMatterCard: boolean; cardEntry: ICardListEntry }) {}
}

export class SelectorDebtorCard implements Action {
  readonly type = CardListModalActionTypes.SELECTOR_DEBTOR_CARD;

  constructor(public payload: { cardEntry: ICardListEntry }) {}
}

export class SetCardFilterType implements Action {
  readonly type = CardListModalActionTypes.SET_CARD_FILTER_TYPE;

  constructor(public payload: { type: ECardFilterType }) {}
}

export class SetCardGuidsForFiltering implements Action {
  readonly type = CardListModalActionTypes.SET_CARD_GUIDS_FOR_FILTERING;

  constructor(public payload: { ids: string[]; includeAllCardOption?: boolean }) {}
}

export class SetSelectedCardFilterType implements Action {
  readonly type = CardListModalActionTypes.SET_SELECTED_CARD_FILTER_TYPE;

  constructor(public payload: { type: string }) {}
}

export class CloseCardListModal implements Action {
  readonly type = CardListModalActionTypes.CLOSE;

  constructor(public payload: any) {}
}

export class DestroyCardListModal implements Action {
  readonly type = CardListModalActionTypes.DESTROY;

  constructor(public payload: any) {}
}

export type CardListModalActions =
  | SetUpCardEntries
  | StoreCardEntries
  | SetSelectedCardEntries
  | SaveMatterCard
  | SetCardFilterType
  | SetCardGuidsForFiltering
  | SetSelectedCardFilterType
  | SelectorDebtorCard
  | CloseCardListModal
  | DestroyCardListModal;
