import { ICard } from '@app/shared/models';
import * as actions from '../actions/card-details';
import { SpecialRate } from '@app/features/+time-fee-ledger/models';
import { ECardType, ICardRelatedMatter } from '@app/features/+card/models';
import { uniqString } from '@server/modules/shared/functions/common-util.functions';

export interface State {
  loading: boolean;
  cardProcessing: boolean;
  cardDetails: ICard;
  specialRatesForCard: SpecialRate[];
  formValue: any;
  selectedSpecialFees: string[];
  activePersonId: string;
  invalidControls: string[];
  relatedMatters: ICardRelatedMatter[];
}

export const INITIAL_STATE: State = {
  loading: false,
  cardProcessing: false,
  cardDetails: null,
  specialRatesForCard: [],
  formValue: null,
  selectedSpecialFees: [],
  activePersonId: null,
  invalidControls: [],
  relatedMatters: [],
};

export const reducer = (state = INITIAL_STATE, action: actions.CardDetailsActions) => {
  switch (action.type) {
    case actions.CardDetailsActionTypes.LOAD_CARD_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.CardDetailsActionTypes.LOAD_CARD_DETAILS_SUCCEED: {
      return {
        ...state,
        loading: false,
        cardDetails: action.payload.data,
        specialRatesForCard: action.payload.specialRates,
        formValue: action.payload.data,
        activePersonId:
          action.payload.data.cardType === ECardType.People && action.payload.data.personList.length > 0
            ? action.payload.data.personList[0].__id
            : null,
        selectedSpecialFees: action.payload.specialRates.map((fee) => fee.SpecialFeeGUID),
        invalidControls: [],
        relatedMatters: action.payload.relatedMatters,
      };
    }

    case actions.CardDetailsActionTypes.RESET: {
      return {
        ...state,
        formValue: state.cardDetails,
        activePersonId: state.cardDetails.personList.length > 0 ? state.cardDetails.personList[0].__id : null,
        invalidControls: [],
      };
    }

    case actions.CardDetailsActionTypes.SET_CARD_DETAILS_FORM_VALUE: {
      return {
        ...state,
        formValue: { ...state.formValue, ...action.payload.data },
      };
    }

    case actions.CardDetailsActionTypes.SET_ADDRESS_VALUE: {
      let addressList = action.payload.alternative
        ? state.formValue.alternativeAddressList
        : state.formValue.originalAddressList;
      addressList = addressList.map((address, index) =>
        index === action.payload.index ? { ...address, ...action.payload.data } : address,
      );
      const addressSettings = action.payload.alternative
        ? { ...state.formValue.alternativeAddressSettings, defaultAddress: action.payload.addressType }
        : { ...state.formValue.originalAddressSettings, defaultAddress: action.payload.addressType };
      const obj = action.payload.alternative
        ? { alternativeAddressList: addressList, alternativeAddressSettings: addressSettings }
        : { originalAddressList: addressList, originalAddressSettings: addressSettings };
      return {
        ...state,
        formValue: { ...state.formValue, ...obj },
      };
    }

    case actions.CardDetailsActionTypes.UPDATE_ALTERNATIVE_ADDRESS_SETTINGS: {
      return {
        ...state,
        formValue: { ...state.formValue, alternativeAddressSettings: action.payload.settings },
      };
    }

    case actions.CardDetailsActionTypes.UPDATE_PERSONAL_DETAILS: {
      const newFormValue = { ...state.formValue };
      const personList = state.formValue.personList.map((person, index) => {
        if (person.__id === action.payload.id) {
          return { ...person, ...action.payload.data };
        } else {
          return person;
        }
      });

      return {
        ...state,
        formValue: { ...newFormValue, personList },
      };
    }

    case actions.CardDetailsActionTypes.UPDATE_PERSON_LIST: {
      return {
        ...state,
        formValue: { ...state.formValue, personList: action.payload.list },
        activePersonId: action.payload.id,
      };
    }

    case actions.CardDetailsActionTypes.UPDATE_ACTIVE_PERSON_ID: {
      return {
        ...state,
        activePersonId: action.payload.id,
      };
    }

    case actions.CardDetailsActionTypes.UPDATE_CARD_TYPE: {
      return {
        ...state,
        formValue: { ...state.formValue, cardType: action.payload.type, type: action.payload.type },
        activePersonId: state.formValue.personList.length > 0 ? state.formValue.personList[0].__id : null,
        invalidControls: [],
      };
    }

    case actions.CardDetailsActionTypes.SAVE:
    case actions.CardDetailsActionTypes.DELETE:
    case actions.CardDetailsActionTypes.UNDELETE:
      return {
        ...state,
        cardProcessing: true,
      };

    case actions.CardDetailsActionTypes.DELETE_SUCCEED:
      return {
        ...state,
        cardProcessing: false,
        cardDetails: { ...state.cardDetails, deleteCode: 1 },
        formValue: { ...state.formValue, deleteCode: 1 },
      };

    case actions.CardDetailsActionTypes.UNDELETE_SUCCEED:
      return {
        ...state,
        cardProcessing: false,
        cardDetails: { ...state.cardDetails, deleteCode: 0 },
        formValue: { ...state.formValue, deleteCode: 0 },
      };

    case actions.CardDetailsActionTypes.SAVE_SUCCEED:
      return {
        ...state,
        cardProcessing: false,
      };

    case actions.CardDetailsActionTypes.SET_CARD_PROCESSING_STATUS:
      return {
        ...state,
        cardProcessing: action.payload.processing,
      };

    case actions.CardDetailsActionTypes.SAVE_FAIL:
    case actions.CardDetailsActionTypes.DELETE_FAIL:
    case actions.CardDetailsActionTypes.UNDELETE_FAIL:
      return {
        ...state,
        cardProcessing: false,
      };

    case actions.CardDetailsActionTypes.UPDATE_LETTER_LEAP_CALC_INFORM:
      return {
        ...state,
        formValue: {
          ...state.formValue,
          ...action.payload.inform,
        },
      };

    case actions.CardDetailsActionTypes.SET_TRUSTEE:
      return {
        ...state,
        formValue: {
          ...state.formValue,
          trusteeList: state.formValue.trusteeList.map((t) =>
            t.id === action.payload.trustee.id ? action.payload.trustee : t,
          ),
        },
      };

    case actions.CardDetailsActionTypes.ADD_TRUSTEE:
      return {
        ...state,
        formValue: {
          ...state.formValue,
          trusteeList:
            state.formValue.trusteeList && state.formValue.trusteeList.length > 0
              ? [...state.formValue.trusteeList, action.payload.trustee]
              : [action.payload.trustee],
        },
      };

    case actions.CardDetailsActionTypes.DELETE_TRUSTEES: {
      const deleteTrusteeIds = action.payload.trustees?.map((x) => x.id) || [];

      return {
        ...state,
        formValue: {
          ...state.formValue,
          trusteeList: state.formValue.trusteeList?.filter((t) => !deleteTrusteeIds.includes(t.id)),
        },
      };
    }

    case actions.CardDetailsActionTypes.SET_BENEFICIARY: {
      return {
        ...state,
        formValue: {
          ...state.formValue,
          beneficiaries: state.formValue.beneficiaries.map((b, i) =>
            i === action.payload.index ? action.payload.beneficiary : b,
          ),
        },
      };
    }

    case actions.CardDetailsActionTypes.ADD_BENEFICIARY: {
      return {
        ...state,
        formValue: {
          ...state.formValue,
          beneficiaries:
            state.formValue?.beneficiaries && state.formValue?.beneficiaries.length > 0
              ? [...state.formValue.beneficiaries, action.payload.beneficiary]
              : [action.payload.beneficiary],
        },
      };
    }

    case actions.CardDetailsActionTypes.DELETE_BENEFICIARIES: {
      return {
        ...state,
        formValue: {
          ...state.formValue,
          beneficiaries: state.formValue.beneficiaries?.filter((_, i) => !action.payload.indexes.includes(i)),
        },
      };
    }

    case actions.CardDetailsActionTypes.ADD_CARD_DETAILS_INVALID_CONTROLS: {
      return {
        ...state,
        invalidControls: uniqString([...state.invalidControls, ...action.payload.names]),
      };
    }

    case actions.CardDetailsActionTypes.REMOVE_CARD_DETAILS_INVALID_CONTROLS: {
      return {
        ...state,
        invalidControls: state.invalidControls.filter((control) => !action.payload.names.includes(control)),
      };
    }

    case actions.CardDetailsActionTypes.CLEAR_CARD_DETAILS_INVALID_CONTROLS: {
      return {
        ...state,
        invalidControls: [],
      };
    }
    case actions.CardDetailsActionTypes.PERSON_WEB_VALUE_CHANGE: {
      const webType = action.payload.web.type;
      const hasWebTypeInCardLevel = state.formValue.webAddressList.find((web) => web.type === webType && !!web.address);
      return {
        ...state,
        formValue: {
          ...state.formValue,
          webAddressList: hasWebTypeInCardLevel
            ? state.formValue.webAddressList
            : [...state.formValue.webAddressList, action.payload.web],
        },
      };
    }

    case actions.CardDetailsActionTypes.PERSON_PHONE_VALUE_CHANGE: {
      const phoneType = action.payload.phone.numberType;
      const hasPhoneTypeInCardLevel = state.formValue.phoneNumberList.find(
        (phone) => phone.numberType === phoneType && !!phone.number,
      );
      return {
        ...state,
        formValue: {
          ...state.formValue,
          phoneNumberList: hasPhoneTypeInCardLevel
            ? state.formValue.phoneNumberList
            : [...state.formValue.phoneNumberList, action.payload.phone],
        },
      };
    }

    default:
      return state;
  }
};

export const getLoading = (state: State) => state.loading;
export const getCardDetails = (state: State) => state.cardDetails;
export const getFormValue = (state: State) => state.formValue;
export const getActivePersonId = (state: State) => state.activePersonId;
export const getCardProcessing = (state: State) => state.cardProcessing;
export const getSpecialRatesForCard = (state: State) => state.specialRatesForCard;
export const getInvalidControls = (state: State) => state.invalidControls;
export const getRelatedMatters = (state: State) => state.relatedMatters;
