import { Injectable } from '@angular/core';
import { AppApiService } from '@app/core/api';
import { PlatformService } from '@app/core/services';
import { CorrespondenceService } from '@app/features/+correspondence/services';
import { DuplicatedFileNumber } from '@app/features/+matter-list/constants';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { MatterListStorageService } from '@app/features/+matter-list/services';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { AutomationActionType, IAutomationOptions, IDoc, IDocumentTicketParams } from '@app/shared/models';
import { AlertMessageCommand, ErrorCommand, OpenCommand, OpenUrlCommand } from '@app/shared/models/leap-host.models';
import { BaseService, DocumentAutomationService, OfflineLauncherService } from '@app/shared/services';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class LeapHostHandlerService extends BaseService {
  constructor(
    private _store: Store<any>,
    private _correspondenceService: CorrespondenceService,
    private _toastrSvc: ToastrService,
    private _translateSvc: TranslateService,
    private _appApiSvc: AppApiService,
    private _offlineLauncherSvc: OfflineLauncherService,
    private _documentAutomationSvc: DocumentAutomationService,
    private _platformSvc: PlatformService,
    private _matterListStorageSvc: MatterListStorageService
  ) {
    super();
  }

  handleAlert(alertMessageCmd: AlertMessageCommand) {
    this._toastrSvc.warning(alertMessageCmd.message, this._translateSvc.instant('DIALOGS_ALERT'));
    if (alertMessageCmd.close) {
      this._appApiSvc.navigateClear();
    }
  }

  handleClose() {
    this._appApiSvc.navigateClear();
  }

  handleError(errorCmd: ErrorCommand) {
    throw new SiriusError({
      type: 'error',
      message: errorCmd.message,
      actionBtnText: 'Close',
      onCloseFun: () => this.handleClose(),
    });
  }

  handleOpenMatter(args: OpenCommand) {
    this._matterListStorageSvc.get(args.matterId).then((matter) => {
      if (!matter || !this.openMatterUrl(matter)) {
        this._toastrSvc.warning(
          this._translateSvc.instant('Global.Addin.Open.MatterNotFound.Message'),
          this._translateSvc.instant('Global.Addin.Open.MatterNotFound.Title')
        );
      }
    });
  }

  openMatterUrl(matterEntry: IMatterListEntry) {
    const { matterId, fileNumber } = matterEntry;
    const matterNumber = fileNumber === DuplicatedFileNumber ? matterId : fileNumber;
    if (!!matterNumber) {
      this.openUrl(`matters/${encodeURIComponent(matterNumber)}`);
      return true;
    }
    return false;
  }

  handleOpenUrl(args: OpenUrlCommand) {
    if (this._platformSvc.isServer) {
      return;
    }

    window.open(args.url);
  }

  openWord(document: IDoc) {
    const params = {
      documentId: document.id,
      ext: document.ext,
      latestVersionId: document.latestVersion,
    } as IDocumentTicketParams;
    return this._offlineLauncherSvc.createEditDocumentTicket({ params });
  }

  openWordOnline(document: IDoc, matterId: string) {

    const automationOptions: IAutomationOptions = {
      action: AutomationActionType.Edit,
      matterId,
      folderId: '',
      docInfo: {
        documentId: document.id,
        ext: document.ext,
        isDesktopOnly: document.desktopOnly,
      },
    };
    return this._documentAutomationSvc.editDocumentOnline(automationOptions);
  }

  public previewDoc(params: { data: IDoc }): void {
    const { data } = params;
    const queryParams = { docGuid: data.id };
    this._appApiSvc.navigate({
      path: [{ outlets: { popup: ['preview'] } }],
      extras: { queryParams },
    });
  }

  private openUrl(url: string) {
    if (this._platformSvc.isServer) {
      return;
    }

    window.open(url);
  }
}
