import { forkJoin, Observable } from 'rxjs';
import { map, map as rxjsMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITimeFeeSummary } from '@app/features/+time-fee-ledger/models/timeFeeLedger.model';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  IBillingUnit,
  ITimeFee,
  ITimeFeeResponse,
  ITimeFeeUpdate,
  UpdateTimeFeesRequestDelta,
} from '@app/features/+time-fee-ledger/models';
import * as accApiActions from '@app/features/accounting/store/actions';
import {
  BillingMode,
  IAccountingFailureAction,
  IAccountingSuccessAction,
  ITaxCode,
} from '@app/features/accounting/models';
import { Store } from '@ngrx/store';
import { State } from '@app/features/+matter-list/store';
import { BaseService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { StartupService } from '@app/core/services';
import { IRate } from '@app/shared/models';
import { v4 as uuidv4 } from 'uuid';
import { ScCurrencyPipe } from '@app/shared/pipes';
import { TaxCodeService } from '@app/features/accounting/services';
import { formatDateHttp, formatStringToDate } from '@app/shared/utils';
import { sortAscBy } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class TimeFeeService extends BaseService {
  url: string;

  constructor(
    private _http: HttpClient,
    private _formBuilder: FormBuilder,
    private _matterStore: Store<State>,
    private _translateSvc: TranslateService,
    private _startupSvc: StartupService,
    private _currencyPipe: ScCurrencyPipe,
    private _taxCodeSvc: TaxCodeService,
  ) {
    super();
    this.url = `${this.accountingPath}/api/cloud/fee`;
  }

  getTimeAndFees(matterId: string): Observable<ITimeFeeSummary[]> {
    const url = this.urlJoin(`${this.accountingPath}/api/cloud/matter`, matterId, '/fee');
    return this._http.get<ITimeFeeSummary[]>(url);
  }

  createFormGroup(options: any = {}, isFee: boolean = true) {
    return this._formBuilder.group({
      Print: false,
      FeeGUID: options.FeeGUID || uuidv4(),
      MatterGUID: options.MatterGUID || '',
      TaxCodeGUID: options.TaxCodeGUID || '',
      TaskCodeGUID: options.TaskCodeGUID || '',
      transactionDate: options.TransactionDate ? new Date(options.TransactionDate) : new Date(),
      TransactionDate: options.TransactionDate || new Date().toISOString(),
      RateId: options.RateId || 1,
      RatePerHour: options.RatePerHour || 0,
      FeeHoursQuantity: options.FeeHoursQuantity != null ? options.FeeHoursQuantity : 1,
      Timed: options.Timed ? options.Timed : isFee ? (0 as any as boolean) : true,
      IncTax: options.IncTax || false,
      TotalIncTax: options.TotalIncTax || 0,
      TotalExTax: options.TotalExTax || 0,
      TotalTax: options.TotalTax || 0,
      BillingDescription: options.BillingDescription || '',
      Memo: options.Memo || '',
      BillingMode: options.BillingMode || BillingMode.BillableNextInvoice,
      WorkDoneByStaffGUID: options.WorkDoneByStaffGUID || '',
      WorkDoneByStaff: this.createWorkDoneByStaff(options.WorkDoneByStaff) || this.createWorkDoneByStaff(),
      TaxCode: options.TaxCode || '',
      RowVersion: options.RowVersion || 0,
      SecondsPerUnit: options.SecondsPerUnit || 0,
      TransactionNumber: [{ value: options.TransactionNumber || 'Auto no.', disabled: true }],
      Deleted: options.Deleted || false,
      SecondsElapsed: options.SecondsElapsed || 0,
      CalculationMode: options.CalculationMode || 0,
      TaskName: options.TaskName || '',
      InvoiceGUID: options.InvoiceGUID || '',
      InvoiceTransactionNumber: options.InvoiceTransactionNumber || '',
      RecordVersion: options.RecordVersion || 0,
      LAActivityTypeCode: options.LAActivityTypeCode || '',
      LABillingStage: options.LABillingStage || 0,
      MatterBillingMode: options.MatterBillingMode || 0,
      MinutesPerUnit: options.MinutesPerUnit || 0,
      FeeUnits: options.FeeUnits || 0,
      LAAdjFeePercent: options.LAAdjFeePercent || 0,
      LACourtType: options.LACourtType || '',
      LAActivityTypeDescription: options.LAActivityTypeDescription || '',
      LAMedSessionAmt: options.LAMedSessionAmt || 0,
      LAMedTime: options.LAMedTime || 0,
      BillingRate: null,
      isNew: !options.FeeGUID,
      MatterFileNumber: '',
    });
  }

  createWorkDoneByStaff(options: any = {}) {
    const staff = this._formBuilder.group({
      staffId: options.staffId || '',
      userId: options.userId || '',
      firstName: options.firstName || '',
      lastName: options.lastName || '',
      middleName: options.middleName || '',
      initials: options.initials || '',
      email: options.email || '',
      phone: options.phone || '',
      rates: this._formBuilder.array([]),
      __id: options.__id || '',
      __className: options.__className || '',
      branch: options.branch || '',
      fullName: options.fullName || '',
      legalFullName: options.legalFullName || '',
      qualifications: options.qualifications || '',
      title: options.title || '',
      fax: options.fax || '',
      immigLicense: options.immigLicense || '',
      certificates: options.certificates || '',
      extension: options.extension || '',
      mobile: options.mobile || '',
      firmReference: options.firmReference || '',
      rate1: options.rate1 || 0,
      rate2: options.rate2 || 0,
      rate3: options.rate3 || 0,
      rate4: options.rate4 || 0,
      rate5: options.rate5 || 0,
      rate6: options.rate6 || 0,
    });

    const rates = staff.get('rates') as FormArray;
    rates.push(this.createRate(0, options.rate1));
    rates.push(this.createRate(1, options.rate2));
    rates.push(this.createRate(2, options.rate3));
    rates.push(this.createRate(3, options.rate4));
    rates.push(this.createRate(4, options.rate5));
    rates.push(this.createRate(5, options.rate6));
    rates.push(
      this._formBuilder.group({
        id: 0,
        name: 'Other',
        rate: 0,
        display: 'Other',
      }),
    );

    return staff;
  }

  createRate(idx: number, n: number): FormGroup {
    const char = String.fromCharCode('A'.charCodeAt(0) + idx);
    const money = this._currencyPipe.transform(n);
    const display =
      `${char} - ` +
      `${this._translateSvc.instant('Core.CurrencySymbol')}` +
      `${money ? money.substring(1, money.length) : '0.00'}`;
    return this._formBuilder.group({
      id: idx + 1 || 0,
      name: char || '',
      rate: n || 0,
      display: display || '',
    });
  }

  defaultBillingRates(): IRate[] {
    const rates = [];
    rates.push(this.createRate(0, 0).value);
    rates.push(this.createRate(1, 0).value);
    rates.push(this.createRate(2, 0).value);
    rates.push(this.createRate(3, 0).value);
    rates.push(this.createRate(4, 0).value);
    rates.push(this.createRate(5, 0).value);
    rates.push({
      id: 0,
      name: 'Other',
      rate: 0,
      display: 'Other',
    });
    return rates;
  }

  getNewTimeFeeInitData(
    matterId?: string,
    taxCodeDeleted?: boolean,
    matterBillingMode?: number,
  ): Observable<ITimeFeeResponse> {
    const url = this.urlJoin(
      this.url,
      '/initialisationdata',
      matterId && `?matterId=${matterId}`,
      taxCodeDeleted && '?taxCodeDeleted=true',
      matterBillingMode && `?matterBillingMode=${matterBillingMode}`,
    );
    return this.handleGetRequest(url);
  }

  getTimeFeeInitData(timeFeeGuid: string, taxCodeDeleted?: boolean): Observable<ITimeFeeResponse> {
    const url = this.urlJoin(
      this.url,
      timeFeeGuid,
      '/initialisationdata',
      taxCodeDeleted ? '?taxCodeDeleted=true' : null,
    );
    return this.handleGetRequest(url);
  }

  private handleGetRequest(url: string): Observable<ITimeFeeResponse> {
    return forkJoin([
      this._http.get<ITimeFeeResponse>(url),
      this._taxCodeSvc
        .getIncomeTaxCodes()
        .pipe(rxjsMap((taxCodeList) => (taxCodeList ? taxCodeList.filter((tC) => !tC.Deleted) : []))),
    ]).pipe(
      rxjsMap((response: [ITimeFeeResponse, ITaxCode[]]) => {
        const [timeFee, taxCodes] = response;
        timeFee.TaskCodeList = sortAscBy(timeFee.TaskCodeList, (t) => t.NameFileAs?.toLowerCase() || '');
        timeFee.BillingUnitList = this.setBillingUnitMinutes(timeFee.BillingUnitList);
        if (!timeFee.Fee) {
          const preferences = timeFee.Preferences;
          let defaultTaxCodeGUID = preferences.MatterTaxFree ? preferences.FRETaxCodeGUID : preferences.GSTTaxCodeGUID;
          if (!timeFee.TaxCodeList?.find((t) => t.TaxCodeGUID === defaultTaxCodeGUID)) {
            defaultTaxCodeGUID = '';
          }
          timeFee.Fee = {
            TransactionDate: new Date().toISOString(),
            TransactionNumber: 'Auto no.',
            Deleted: false,
            RateId: 0,
            RatePerHour: 0,
            SecondsElapsed: 0,
            CalculationMode: 0,
            BillingMode: 0,
            MatterBillingMode: 0,
            SecondsPerUnit: preferences.FeeSecondsPerUnit,
            WorkDoneByStaffGUID: this._startupSvc.userDetails.staffId,
            TaxCodeGUID: defaultTaxCodeGUID,
          } as ITimeFee;
        }
        timeFee.Fee.MinutesPerUnit = timeFee.Fee.SecondsPerUnit / 60;
        timeFee.TaxCodeList = taxCodes;
        return formatStringToDate(timeFee, false);
      }),
    );
  }

  private setBillingUnitMinutes(billingList: IBillingUnit[]): IBillingUnit[] {
    billingList?.forEach((billing: IBillingUnit): void => {
      billing.Minutes = billing.Seconds / 60;
    });
    return billingList;
  }

  moveToMatter(matterId: string, feeIds: string[], targetMatterId: string): Observable<ITimeFeeUpdate> {
    return this.bulkAction(matterId, feeIds, { MatterGUID: targetMatterId });
  }

  setActivityCode(matterId: string, feeIds: string[], targetActivityCodeGuid: string): Observable<ITimeFeeUpdate> {
    return this.bulkAction(matterId, feeIds, { TaskCodeGUID: targetActivityCodeGuid });
  }

  setBillingMode(matterId: string, feeIds: string[], targetBillingMode: any): Observable<ITimeFeeUpdate> {
    return this.bulkAction(matterId, feeIds, { BillingMode: targetBillingMode });
  }

  setDeleted(matterId: string, feeIds: string[], deleted: boolean): Observable<ITimeFeeUpdate> {
    return this.bulkAction(matterId, feeIds, { Deleted: deleted, DeletedDate: formatDateHttp(new Date()) });
  }

  private bulkAction(
    matterId: string,
    feeIds: string[],
    update: UpdateTimeFeesRequestDelta = {},
  ): Observable<ITimeFeeUpdate> {
    const tasks = feeIds.map((feeId: string): Observable<ITimeFeeResponse> => this.getTimeFeeInitData(feeId));
    return forkJoin([...tasks]).pipe(
      map((responses: ITimeFeeResponse[]) => this.createTimeFeeRequest(responses, update, matterId)),
    );
  }

  public dispatchAccountingSave(
    request,
    successAction?: IAccountingSuccessAction,
    failureAction?: IAccountingFailureAction,
  ) {
    this._matterStore.dispatch(this.getAccountingSaveAction(request, successAction, failureAction));
  }

  public getAccountingSaveAction(
    request,
    successAction?: IAccountingSuccessAction,
    failureAction?: IAccountingFailureAction,
  ) {
    return new accApiActions.AccountingSave({
      model: request,
      url: `/api/cloud/fee/`,
      operation: 'put',
      successAction,
      failureAction,
    });
  }

  private createTimeFeeRequest(
    responses: ITimeFeeResponse[],
    update: UpdateTimeFeesRequestDelta,
    matterId: string,
  ): ITimeFeeUpdate {
    const fees = responses.map((response: ITimeFeeResponse): ITimeFee => response.Fee);
    return {
      FeeList: fees.map((fee: ITimeFee) => ({ FeeGUID: fee.FeeGUID, RowVersion: fee.RowVersion })),
      MatterSourceList: [{ MatterGUID: matterId }],
      AppId: 1,
      WarningAcknowledgments: [],

      MatterGUID: update.MatterGUID,
      TaskCodeGUID: update.TaskCodeGUID,
      Deleted: update.Deleted,
      DeletedDate: update.DeletedDate,
      BillingMode: update.BillingMode,
      WorkDoneByStaffGUID: update.WorkDoneByStaffGUID,
    };
  }
}
