import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { xAnimationScaleIn, xAnimationScaleOut } from '@app/shared/animations/animations';
import { LinkLabel } from '@app/core/models';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'sc-tab-item-dropdown',
  templateUrl: './tab-item-dropdown.component.html',

  animations: [
    trigger('xAnimationScale', [
      transition(':enter', [useAnimation(xAnimationScaleIn)]),
      transition(':leave', [useAnimation(xAnimationScaleOut)]),
    ]),
  ],
})
export class TabItemDropdownComponent implements OnInit, OnDestroy {
  hoverOnLabel: boolean;
  hoverOnMenu: boolean;
  isAnyChildActive: boolean = false;
  private routerSubscription: Subscription;

  @Input()
  label: string;
  @Input()
  dropFromRight: boolean;
  @Input()
  dropdownList: any[];

  @Output()
  onTabClicked = new EventEmitter<LinkLabel>();

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {}

  @HostBinding('class.nav-item')
  ngOnInit() {
    this.hoverOnLabel = false;
    this.hoverOnMenu = false;

    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkActiveRoutes();
      });

    this.checkActiveRoutes();
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  checkActiveRoutes() {
    if (this.dropdownList && this.dropdownList.length > 0) {
      this.isAnyChildActive = this.dropdownList.some((item) => {
        if (item.link && !item.isHeader && !item.isDivider && !item.hide) {
          return this.isRouteActive(item.link);
        }
        return false;
      });
      this.cd.markForCheck();
    }
  }

  isRouteActive(link: any[]): boolean {
    if (!link || link.length === 0) return false;

    try {
      const urlTree = this.router.createUrlTree(link);
      return this.router.isActive(urlTree, {
        paths: 'subset',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored',
      });
    } catch (e) {
      console.error('Error checking route active state', e);
      return false;
    }
  }

  dismissDropdownMenu() {
    setTimeout(() => {
      if (this.hoverOnLabel === true) {
        this.hoverOnLabel = false;
      } else if (this.hoverOnMenu === true) {
        this.hoverOnMenu = false;
      }
      this.cd.markForCheck();
    }, 100);
  }

  resetDropdownMenu() {
    this.hoverOnLabel = false;
    this.hoverOnMenu = false;
  }

  showDropdownMenu(): boolean {
    return this.hoverOnLabel || this.hoverOnMenu;
  }

  tabClicked(item: LinkLabel) {
    this.onTabClicked.emit(item);
    this.resetDropdownMenu();
  }
}
