import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers } from './store/reducers';
import { AppSlice } from '@app/core/store';
import { NamedListEffects } from '@app/features/+card/store/effects/named-list.effects';
import {
  CardAddressService,
  CardAddressV2Service,
  CardDetailsService,
  CardDetailsV2Service,
  CardDocumentsService,
  CardListModalService,
  CardListService,
  CardPersonDetailsService,
  NamedListsService,
  TrusteeService,
} from '@app/features/+card/services';
import { CardListEffects } from '@app/features/+card/store/effects/card-list.effects';
import { CardDetailsEffect } from '@app/features/+card/store/effects/card-details.effect';
import { CardModalEffect } from '@app/features/+card/store/effects/card-modal.effect';
import { MatterCardEffect } from '@app/features/+card/store/effects/matter-card.effect';
import { CardListModalEffect } from '@app/features/+card/store/effects/card-list-modal.effect';
import { CardDetailsPopupEffect } from '@app/features/+card/store/effects/card-details-popup.effect';
import { SharedModule } from '@app/shared';
import { MatterDetailsStateModule } from '@app/features/+matter-details/matter-details-state.module';
import { PersonStateModule } from '../+person/person-state.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatterDetailsStateModule, // import this bc MatterCardEffect require MatterDetails state
    PersonStateModule,
    StoreModule.forFeature(AppSlice.Cards, reducers),
    EffectsModule.forFeature([
      NamedListEffects,
      CardListEffects,
      CardDetailsEffect,
      CardModalEffect,
      MatterCardEffect,
      CardListModalEffect,
      CardDetailsPopupEffect,
    ]),
  ],
  providers: [
    NamedListsService,
    CardListService,
    CardAddressService,
    CardAddressV2Service,
    CardPersonDetailsService,
    CardDetailsService,
    CardDetailsV2Service,
    TrusteeService,
    CardListModalService,
    CardDocumentsService,
  ],
})
export class CardStateModule { }
