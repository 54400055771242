/**
 * Toggles seeing the legacy InfoTrack buttons in a matter, this will override the detected value when it is disabled (false).
 * It has no override effects when enabled (true).
 */
export const FEATURE_FLAG_CAN_SEE_INFO_TRACK_BUTTONS = 'can-see-info-track-buttons';

/**
 * Switch to using the InfoTrack SSO Flow
 */
export const FEATURE_FLAG_USE_INFO_TRACK_SSO_FLOW = 'use-info-track-sso-flow';

/**
 * When true, the calc service will use a web worker to evaluate calc paths instead of sirius server
 */
export const FEATURE_FLAG_USE_CALC_WEB_WORKER = 'use-calc-web-worker';
